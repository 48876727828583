import React from "react";
import Validation from "./Validation";

export default function PhoneInputDD({ lableName, validationError, ...rest }) {
  return (
    <>
      <div className="relative flex flex-col items-center justify-start w-[300px] my-1 gap-1 ">
        <div className="text-sm font-medium text-[#344053] self-start pl-[1px]">
          {lableName}
        </div>

        <div className=" flex border-solid border-[#cfd4dc] h-[40px] text-left border-b border rounded-lg  w-full mr-1 text-[12px] lg:text-[14px] bg-[#fff] outline-none text-[#393E46] font-roboto font-medium text-md ">
          <div className=" flex justify-center items-center w-[50px] border-solid b border-r-[#cfd4dc] rounded-l-md h-[38px] text-left border-r  w-2/12mr-1 text-[12px] lg:text-[14px] bg-[#f9f9f9] outline-none text-[#393E46] font-roboto font-medium text-md  px-2">
            +91
          </div>

          <input
            type="text"
            id="phone"
            className=" h-[38px] text-left mb-1 border-none w-[250px] mr-1 text-[12px] lg:text-[14px] outline-none text-[#393E46] font-roboto font-medium text-lg pl-2"
            placeholder="00000 00000"
            {...rest}
          />
        </div>
        <Validation Error={validationError} />
      </div>
    </>
  );
}
