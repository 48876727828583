import React,{useEffect} from "react";
import close from "../../../assets/images/close.png";
import { Toaster, toast } from "react-hot-toast";
import Swal from "sweetalert2";
import axios from "axios";
import InputBoxWithLabel from "../../Driver/LabelInput";
import DateUtils from '../../../Utility/DateUtils'

export default function AddSuperAdmin({ setShowPopUp, id, getUsers }) {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [mobileNumber, setMobileNumber] = React.useState("");
  const [date, setDate] = React.useState(DateUtils.getCurrentDate());

  const [nameError, setNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [mobileNumberError, setMobileNumberError] = React.useState("");

  useEffect(() => {
    if (id) {
      getOneUser();
    }else{
      setName("");
      setEmail("");
      setPassword("");
      setMobileNumber("");
      setDate(DateUtils.getCurrentDate());
    }
  }, []);

  const getOneUser = async () => {
    try {
      const response = await axios.get(window.api + "auth/getOneUser", {
        params: {
          id,
        },
      });
      if (response.status === 200) {
        let users = response.data.data;
        
        setName(users.name);
        setEmail(users.email);
        setMobileNumber(users.mobileNumber);
        setDate(users.date);
        setPassword(users.password);
      }
    } catch (error) {
      console.error("Error getting users:", error);
    }
  };
  
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (handleFormValidation()) {
      try {
        const userData = {
          id: id,
          name: name,
          email: email,
          password: password,
          mobileNumber: mobileNumber,
        };

        const response = await axios.post(
          window.api + "auth/updateUser",
          userData
          );

        if (response.status === 200) {
          toast("New Admin Updated Successfully!", {
            icon: "👏",
            style: {
              borderRadius: "7px",
              background: "#222831",
              color: "#fff",
            },
          });
          setTimeout(() => {
            setShowPopUp(false);
          }, 2000);
          getUsers();
        } else {
          console.error("Error updating user:", response.data);
        }
      } catch (error) {
        console.error("Error updating user:", error);
      }
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleFormValidation()) {
      try {
        const data = {
          name: name,
          email: email,
          password: password,
          mobileNumber: mobileNumber,
          date: date,
          createdBy: 1,
          userType: "superAdmin",
        };

        const response = await axios.post(window.api + "auth/signup", data);
        if (response.status === 201) {
          toast("New Super Admin Created Successfully!", {
            icon: "👏",
            style: {
              borderRadius: "7px",
              background: "#222831",
              color: "#fff",
            },
          });
          setTimeout(() => {
            setShowPopUp(false);
          }, 2000);
          getUsers();
        }
      } catch (e) {
        console.error("Errors adding driver: ", e);
        toast.error(e.response.data.message, {
          autoClose: false,
          closeButton: true,
          className: "toast-error",
          bodyClassName: "toast-error-body",
        });
      }
    }
  };

  const handleFormValidation = () => {
    let formIsValid = true;

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (!name) {
      formIsValid = false;
      setNameError("Please enter name");
    } else {
      setNameError("");
    }

    if (!email || !emailPattern.test(email)) {
      formIsValid = false;
      setEmailError("Please enter a valid email");
    } else {
      setEmailError("");
    }

    if (!password) {
      formIsValid = false;
      setPasswordError("Please enter password");
    } else if (password.length < 8) {
      formIsValid = false;
      setPasswordError("Password must have at least 8 characters");
    } else if (!/[A-Z]/.test(password)) {
      formIsValid = false;
      setPasswordError("Password must have at least 1 uppercase letter");
    } else if (!/\d/.test(password)) {
      formIsValid = false;
      setPasswordError("Password must have at least 1 number");
    } else if (!/[!@#$%^&*]/.test(password)) {
      formIsValid = false;
      setPasswordError("Password must have at least 1 special character");
    } else {
      setPasswordError("");
    }

    if (
      !mobileNumber ||
      mobileNumber.length !== 10 ||
      !/^\d{10}$/.test(mobileNumber)
    ) {
      formIsValid = false;
      setMobileNumberError("Mobile number must be exactly 10 digits");
    } else {
      setMobileNumberError("");
    }

    return formIsValid;
  };

  return (
    <div>
      <Toaster />
      <div className="fixed inset-0 bg-[#222831] bg-opacity-40 backdrop-blur-sm flex justify-center items-center z-40">
        <div className="bg-white px-10 py-5 rounded">
          <div className="mb-4">
            <img
              className="w-5 cursor-pointer ml-auto"
              onClick={() => setShowPopUp(false)}
              src={close}
              alt="close"
            />
          </div>
          <div className="flex justify-center items-center h-10 w-full">
            <p className="text-center font-bold text-md text-[#222831]">
              Add Super Admin
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-8 md:flex md:mb-5">
              <InputBoxWithLabel
                label="Name"
                placeholder="Enter Name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                type="text"
                id="name"
                validationError={nameError}
              />
              <InputBoxWithLabel
                label="Email"
                placeholder="Enter Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="text"
                id="email"
                validationError={emailError}
              />
            </div>
            <div className="grid grid-cols-2 gap-8 md:flex md:mb-5">
              <InputBoxWithLabel
                label="Password"
                placeholder="Enter Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                type="text"
                id="password"
                validationError={passwordError}
              />

              <InputBoxWithLabel
                label="Mobile Number"
                placeholder="Enter Mobile Number"
                onChange={(e) => setMobileNumber(e.target.value)}
                value={mobileNumber}
                type="number"
                id="mobileNumber"
                validationError={mobileNumberError}
              />
            </div>
            <div className="grid grid-cols-2 gap-8 md:flex md:mb-5">
              <InputBoxWithLabel
                label="Created Date"
                placeholder="Enter Created Date"
                onChange={(e) => setDate(e.target.value)}
                value={date}
                type="date"
                id="date"
                disabled
              />
            </div>
            <div className="text-center mt-5 mb-1">
              <button
                type="submit"
                className="bg-[#00ADB5] px-6 py-1 rounded-md text-white"
                onClick={id ? handleUpdate : handleSubmit}
              >
                {id ? "Update" : "Add"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
