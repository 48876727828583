import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import VehicleTrip from "./Vehicle/VehicleTrip";
import VehicleTable from "./Vehicle/VehicleTable";
import { Link } from "react-router-dom";
import download from "../../assets/Icons/IconsForPages/download.png";
import Attendance from "./Attendance/Attendance";
import AttendanceTable from "./Attendance/AttendanceTable";
import Account from "./Accounts/Account";
import AccountTable from "./Accounts/AccountTable";
import axios from "axios";
import { format } from "date-fns";
import DateUtils from "../../Utility/DateUtils";

export default function ReportLayout() {
  const Tabs = ({ color }) => {
    const [openTab, setOpenTab] = React.useState(1);
    const [companyList, setCompanyList] = useState([]);
    const [rowsAccount, setRowsAccount] = useState([]);
    const [rows, setRows] = useState([]);
    const [rowsXlVt, setRowsXlVt] = useState([]);
    const [tripDate, setTripDate] = useState(
      localStorage.getItem("tripDate") || ""
    );

    useEffect(() => {
      if(localStorage.getItem("userType") == "supervisor" ||
      localStorage.getItem("userType") == "admin" ){
        alert("You are not authorized to access this page");
        window.location.href = "/";
      }
    }, []);

    const [fromDate, setFromDate] = useState(
      localStorage.getItem("fromDate") || DateUtils.getTMinusDate(6)
    );

    const [toDate, setToDate] = useState(
      localStorage.getItem("toDate") || DateUtils.getCurrentDate
    );
    const [tripType, setTripType] = useState(
      localStorage.getItem("tripTypeInvoice") || ""
    );
    const [companyId, setCompanyId] = useState(
      localStorage.getItem("companyIdInvoice") || ""
    );

    const [rowsAttendance, setRowsAttendance] = useState([]);
    const [rowsXlAttendance, setRowsXlAttendance] = useState([]);
    const [fromDateAttendance, setFromDateAttendance] = useState(
      localStorage.getItem("fromDateAttendance") || DateUtils.getTMinusDate(6)
    );
    const [toDateAttendance, setToDateAttendance] = useState(
      localStorage.getItem("toDateAttendance") || DateUtils.getCurrentDate
    );
    const [tripTypeAttendance, setTripTypeAttendance] = useState(
      localStorage.getItem("tripTypeAttendance") || ""
    );
    const [companyIdAttendance, setCompanyIdAttendance] = useState(
      localStorage.getItem("companyIdAttendance") || ""
    );

    const [routeIdAttendance, setRouteIdAttendance] = useState(
      localStorage.getItem("routeIdAttendance") || ""
    );

    const [fromDateAccount, setFromDateAccount] = useState(
      localStorage.getItem("fromDateAccount") || DateUtils.getTMinusDate(6)
    );
    const [toDateAccount, setToDateAccount] = useState(
      localStorage.getItem("toDateAccount") || DateUtils.getCurrentDate
    );

    window.onbeforeunload = () => {
      localStorage.removeItem("tripDate");
      localStorage.removeItem("fromDateInvoice");
      localStorage.removeItem("toDateInvoice");
      localStorage.removeItem("tripTypeInvoice");
      localStorage.removeItem("companyIdInvoice");

      localStorage.removeItem("fromDateAttendance");
      localStorage.removeItem("toDateAttendance");
      localStorage.removeItem("tripTypeAttendance");
      localStorage.removeItem("companyIdAttendance");
      localStorage.removeItem("routeIdAttendance");

      localStorage.removeItem("fromDateInvoiceAttendance");
      localStorage.removeItem("toDateInvoiceAttendance");
    };

    useEffect(() => {
      getCompany();
    }, []);

    const getCompany = async () => {
      try {
          const response = await axios.get(
              window.api + "company/getCompanyList",
              {
                  headers: {
                      "x-access-token": localStorage.getItem("accessToken"),
                      "email": localStorage.getItem("email"),
                      "usertype": localStorage.getItem("userType")
                  }
              }
          );
          
          if (response.status === 200) {
              const companyList = response.data.data;
              setCompanyList(companyList);
          }
      } catch (error) {
          console.error("Error getting company list:", error);
          
          if (error.response && error.response.status === 401) {
              localStorage.clear();
              window.location.href = '/';
          }
      }
  };
  

    useEffect(() => {
      getTripsVehicleTrips();
    }, [companyId, tripType, fromDate, toDate, tripDate]);

    const getTripsVehicleTrips = async () => {
      try {
        const response = await axios.get(
          window.api + "report/getTripsVehicleTrips",
          {
            headers: {
              "x-access-token": localStorage.getItem("accessToken"),
              "email": localStorage.getItem("email"),
              "usertype": localStorage.getItem("userType")
            },
            params: {
              tripDate,
              tripType,
              fromDate,
              toDate,
              companyId,
            },
          }
        );
    
        if (response.status === 200) {
          const trips = response.data.data;
          const dataWithSerial = trips.map((item, index) => ({
            ...item,
            serialNumber: index + 1,
          }));
          setRows(dataWithSerial);
    
          const manipulatedArray = trips.map((item, index) => ({
            "Sr. No.": index + 1,
            "Vehicle Number": item.vehicleNo.toUpperCase(),
            "Driver ID": item.driverID.toUpperCase(),
            "Driver Name": item.driverName.toUpperCase(),
            "No. of Trips Completed": item.totalCount,
          }));
    
          const fixedSequenceArray = manipulatedArray.map((item) => ({
            "Sr. No.": item["Sr. No."],
            "Vehicle Number": item["Vehicle Number"],
            "Driver ID": item["Driver ID"],
            "Driver Name": item["Driver Name"],
            "No. of Trips Completed": item["No. of Trips Completed"],
          }));
    
          setRowsXlVt(fixedSequenceArray);
        } else {
          setRows([]);
          setRowsXlVt([]);
        }
      } catch (e) {
        console.error("Error getting drivers:", e);
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } 
      }
    };
    

    useEffect(() => {
      getTripsAttendance();
    }, [
      companyIdAttendance,
      tripTypeAttendance,
      fromDateAttendance,
      toDateAttendance,
      routeIdAttendance,
    ]);

    const getTripsAttendance = async () => {
      try {
        const response = await axios.get(
          window.api + "report/getTripsAttendance",
          {
            headers: {
              "x-access-token": localStorage.getItem("accessToken"),
              "email": localStorage.getItem("email"),
              "usertype": localStorage.getItem("userType")
            },
            params: {
              routeIdAttendance,
              tripTypeAttendance,
              fromDateAttendance,
              toDateAttendance,
              companyIdAttendance,
            },
          }
        );
    
        if (response.status === 200) {
          const trips = response.data.data;
    
          const dataWithSerial = trips.map((item, index) => ({
            ...item,
            serialNumber: index + 1,
          }));
    
          setRowsAttendance(dataWithSerial);
    
          const manipulatedArray = trips.map((item, index) => ({
            "Sr. No.": index + 1,
            "Route ID": item.routeId.toUpperCase(),
            "Route Name": item.routeName.toUpperCase(),
            TripDate: format(new Date(item.tripDate), "dd-MM-yyyy"),
            "Trip Type": item.tripType.toUpperCase(),
            "Vehicle Type": item.vehicleType.toUpperCase(),
            "Trip End Time": item.tripEndTime.slice(0, 5),
            "No Of Emp Alloted": item.noOfPickup,
            "No Of Emp Present": item.noOfEmployeePicked,
          }));
    
          const fixedSequenceArray = manipulatedArray.map((item) => ({
            "Sr. No.": item["Sr. No."],
            "Route ID": item["Route ID"],
            "Route Name": item["Route Name"],
            "Trip Date": item["TripDate"],
            "Trip Type": item["Trip Type"],
            "Vehicle Type": item["Vehicle Type"],
            "Trip End Time": item["Trip End Time"],
            "No Of Emp Alloted": item["No Of Emp Alloted"],
            "No Of Emp Present": item["No Of Emp Present"],
          }));
    
          setRowsXlAttendance(fixedSequenceArray);
        } else {
          setRowsAttendance([]);
          setRowsXlAttendance([]);
        }
      } catch (e) {
        console.error("Error getting attendance data:", e);
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          // handle other types of errors if necessary
        }
      }
    };
    

    return (
      <>
        <div className="flex flex-wrap">
          <div className="w-full ">
            <ul
              className="flex gap-2 mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
              role="tablist"
            >
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3  rounded block leading-normal " +
                    (openTab === 1
                      ? "text-[#222831] bg-[#00ADB5] "
                      : "text-[#00ADB5] bg-[#222831]")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  Accounts
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3  rounded block leading-normal " +
                    (openTab === 2
                      ? "text-[#222831] bg-[#00ADB5] "
                      : "text-[#00ADB5] bg-[#222831]")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                  MIS Sheet
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3  rounded block leading-normal " +
                    (openTab === 3
                      ? "text-[#222831] bg-[#00ADB5] "
                      : "text-[#00ADB5] bg-[#222831]")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(3);
                  }}
                  data-toggle="tab"
                  href="#link3"
                  role="tablist"
                >
                  Attendance
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3  rounded block leading-normal " +
                    (openTab === 4
                      ? "text-[#222831] bg-[#00ADB5] "
                      : "text-[#00ADB5] bg-[#222831]")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(4);
                  }}
                  data-toggle="tab"
                  href="#link3"
                  role="tablist"
                >
                  Vehicle Trip
                </a>
              </li>
            </ul>
            <div className="relative flex flex-col min-w-0 break-words   w-full mb-6  rounded">
              <div className="  w-full py-5 flex-auto">
                <div className="tab-content tab-space">
                  <div
                    className={openTab === 1 ? "block" : "hidden"}
                    id="link1"
                  >
                    <Account
                      setRowsAccount={setRowsAccount}
                      rowsAccount={rowsAccount}
                      toDateAccount={toDateAccount}
                      fromDateAccount={fromDateAccount}
                      setToDateAccount={setToDateAccount}
                      setFromDateAccount={setFromDateAccount}
                    />
                    <AccountTable
                      rowsAccount={rowsAccount}
                      toDateAccount={toDateAccount}
                      fromDateAccount={fromDateAccount}
                      setToDateAccount={setToDateAccount}
                      setFromDateAccount={setFromDateAccount}
                    />
                  </div>
                  <div
                    className={openTab === 2 ? "block" : "hidden"}
                    id="link2"
                  >
                    Mis Sheet
                  </div>
                  <div
                    className={openTab === 3 ? "block" : "hidden"}
                    id="link3"
                  >
                    <Attendance
                      companyList={companyList}
                      companyIdAttendance={companyIdAttendance}
                      setCompanyIdAttendance={setCompanyIdAttendance}
                      setTripTypeAttendance={setTripTypeAttendance}
                      tripTypeAttendance={tripTypeAttendance}
                      setRouteIdAttendance={setRouteIdAttendance}
                      routeIdAttendance={routeIdAttendance}
                      toDateAttendance={toDateAttendance}
                      setToDateAttendance={setToDateAttendance}
                      fromDateAttendance={fromDateAttendance}
                      setFromDateAttendance={setFromDateAttendance}
                    />
                    <AttendanceTable
                      rowsAttendance={rowsAttendance}
                      rowsXlAttendance={rowsXlAttendance}
                    />
                  </div>
                  <div
                    className={openTab === 4 ? "block" : "hidden"}
                    id="link4"
                  >
                    <VehicleTrip
                      companyList={companyList}
                      companyId={companyId}
                      setCompanyId={setCompanyId}
                      setTripType={setTripType}
                      tripType={tripType}
                      setTripDate={setTripDate}
                      tripDate={tripDate}
                      toDate={toDate}
                      fromDate={fromDate}
                      setToDate={setToDate}
                      setFromDate={setFromDate}
                    />

                    <VehicleTable rows={rows} rowsXlVt={rowsXlVt} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <div>
      <Layout>
        <Tabs />
      </Layout>
    </div>
  );
}
