import React, { useEffect } from "react";
import Validation from "./Validation";


const InputBoxWithLabel = ({
  label,
  placeholder,
  value,
  onChange,
  validationError,
  type,
  widthForInput,

  ...rest
}) => {
  var width;
  if (widthForInput) {
    width = widthForInput;
  } else {
    width = "w-[300px]";
  }
  
  var classNameForWidth;
  if (widthForInput) {
    classNameForWidth = ` flex items-center justify-center h-[44px]  ${width} mt-1  self-stretch  gap-8px `;
  } else {
    classNameForWidth = ` flex items-center justify-center h-[44px] w-[300px] mt-1  self-stretch  gap-8px   `;
  }
  return (
    <div className={`  flex flex-col items-start my-1  `}>
     <div className="text-sm font-medium text-[#344053] self-start pl-[1px]">
          {label}
        </div>
      <div
        className={classNameForWidth}
      >
        <input
          className={` ${type==="file" ? "text-sm" :"text-md"} w-full   px-4 py-2   text-[#344053]  bg-white border border-gray-300 rounded-md shadow-xs outline-none`}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          {...rest}
        />
      </div>
      <Validation Error={validationError} />
    </div>
  );
};

export default InputBoxWithLabel;
