import React, { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import Swal from "sweetalert2";
import { useNavigate, Link, useParams } from "react-router-dom";
import backButton from "../../assets/Icons/IconsForPages/backArrow.png";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import Layout from "../../Layout/Layout";
import axios from "axios";
import InputBoxWithLabel from "../Driver/LabelInput";
import Dropdown from "../Driver/DropDown";
import PhoneInputDD from "../Driver/PhoneInput";

export default function AddEmployee() {
  const [EmployeeId, setEmployeeId] = useState("");
  const [EmployeeName, setEmployeeName] = useState("");
  const [CompanyId, setCompanyId] = useState("");
  const [Gender, setGender] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [CompanyList, setCompanyList] = useState([]);

  const [ViewEmployee, setViewEmployee] = useState(true);
  const [pageTitle, setPageTitle] = useState("");

  const [EmployeeIdError, setEmployeeIdError] = useState("");
  const [EmployeeNameError, setEmployeeNameError] = useState("");
  const [CompanyNameError, setCompanyNameError] = useState("");
  const [GenderError, setGenderError] = useState("");
  const [MobileNoError, setMobileNoError] = useState("");
  const [superAdminPlus, setSuperAdminPlus] = useState(false);

  useEffect(() => {
    if(localStorage.getItem("userType") == "supervisor" ){
      alert("You are not authorized to access this page");
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("userType") == "superAdminPlus") {
      setSuperAdminPlus(true);
    }
  }, []);

  const navigate = useNavigate();
  const { id } = useParams();

  const handleEditClick = () => {
    setViewEmployee(false);
    setPageTitle("Update Employee");
  };

  useEffect(() => {
    setPageTitle(id ? "Employee Details" : "Employee Registration");
    if (id) {
      fetchData();
    } else {
      setViewEmployee(false);
    }
    getCompany();
  }, [id]);

  const fetchData = async () => {
    try {
      const response = await axios.get(window.api + "employee/getEmployeeOne", {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          email: localStorage.getItem("email"),
          usertype: localStorage.getItem("userType"),
        },
        params: {
          id: id,
        },
      });

      if (response.status === 200) {
        const employeeData = response.data.data;
        setEmployeeId(employeeData.employeeId);
        setEmployeeName(employeeData.employeeName);
        setCompanyId(employeeData.companyId);
        setGender(employeeData.gender);
        setMobileNo(employeeData.mobileNo);
      }
    } catch (e) {
      console.error("Error fetching employees data:", e);
      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  const getCompany = async () => {
    try {
      const response = await axios.get(window.api + "company/getCompanyList", {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          email: localStorage.getItem("email"),
          usertype: localStorage.getItem("userType"),
        },
      });

      if (response.status === 200) {
        const companyList = response.data.data;
        setCompanyList(companyList);
      }
    } catch (error) {
      console.error("Error getting company list:", error);

      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  const handleAddEmployee = async (e) => {
    e.preventDefault();

    if (handleFormValidation()) {
      try {
        const data = {
          employeeId: EmployeeId,
          employeeName: EmployeeName,
          companyId: CompanyId,
          gender: Gender,
          mobileNo: MobileNo,
        };

        const response = await axios.post(
          window.api + "employee/create",
          data,
          {
            headers: {
              "x-access-token": localStorage.getItem("accessToken"),
              email: localStorage.getItem("email"),
              usertype: localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          toast("New Employee Added Successfully!", {
            icon: "👏",
            style: {
              borderRadius: "7px",
              background: "#222831",
              color: "#fff",
            },
          });
          setTimeout(() => {
            navigate("/employee-list");
          }, 2000);
        }
      } catch (e) {
        console.error("Errors adding employee: ", e);
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      }
    }
  };

  const handleUpdateEmployee = async (e) => {
    e.preventDefault();

    if (handleFormValidation()) {
      try {
        const data = {
          employeeId: EmployeeId,
          employeeName: EmployeeName,
          companyId: CompanyId,
          gender: Gender,
          mobileNo: MobileNo,
        };

        const response = await axios.put(
          window.api + `employee/update/${id}`,
          data,
          {
            headers: {
              "x-access-token": localStorage.getItem("accessToken"),
              email: localStorage.getItem("email"),
              usertype: localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          toast("Employee Updated Successfully!", {
            icon: "👏",
            style: {
              borderRadius: "7px",
              background: "#222831",
              color: "#fff",
            },
          });
          setTimeout(() => {
            navigate("/employee-list");
          }, 2000);
        }
      } catch (e) {
        console.error("Errors updating employee: ", e);
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      }
    }
  };

  const handleFormValidation = () => {
    let formIsValid = true;

    if (!EmployeeId) {
      formIsValid = false;
      setEmployeeIdError("Please enter employee id");
    } else {
      setEmployeeIdError("");
    }

    if (!EmployeeName) {
      formIsValid = false;
      setEmployeeNameError("Please enter employee name");
    } else {
      setEmployeeNameError("");
    }

    if (CompanyId == "") {
      formIsValid = false;
      setCompanyNameError("Please select company");
    } else {
      setCompanyNameError("");
    }
    if (Gender == "") {
      formIsValid = false;
      setGenderError("Please select gender");
    } else {
      setGenderError("");
    }

    // if (!MobileNo) {
    //   formIsValid = false;
    //   setMobileNoError("Please enter mobile number");
    // } else if (!/^[6-9]\d{9}$/.test(MobileNo)) {
    //   formIsValid = false;
    //   setMobileNoError("Please enter a valid Indian mobile number");
    // } else {
    //   setMobileNoError("");
    // }

    return formIsValid;
  };

  const handleDeleteEmployee = async () => {
    const result = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You are about to delete this employee. This action cannot be undone!",
      confirmButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await axios.put(
          window.api + `employee/delete/${id}`,
          {},
          {
            headers: {
              "x-access-token": localStorage.getItem("accessToken"),
              email: localStorage.getItem("email"),
              usertype: localStorage.getItem("userType"),
            },
          }
        );
        navigate("/employee-list");
      } catch (e) {
        console.error("Error deleting employee:", e);
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      }
    }
  };

  const genderList = [
    { type: "Male", id: "male" },
    { type: "Female", id: "female" },
  ];

  return (
    <Layout>
      <div>
        <Toaster />
        <div className='"w-screen h-screen lg:px-48 px-0"'>
          <div className="w-auto  mt-10 bg-white rounded-md shadow-lg">
            <div className="container">
              <div className="flex flex-wrap w-full ">
                <div className="flex flex-wrap items-center justify-center ml-10">
                  <div className="m-5 ml-2 cursor-pointer  lg:ml-10 shadow-xl">
                    <Link
                      to={"/employee-list"}
                      className="flex items-center justify-center w-10 h-10 text-white"
                    >
                      <img className="w-5" src={backButton} alt="" srcset="" />
                    </Link>
                  </div>
                  {!ViewEmployee ? null : (
                    <div className="flex shadow-xl">
                      {!superAdminPlus ? null : (
                        <div
                          className="p-2 bg-white rounded-lg shadow-md cursor-pointer drop-shadow-md hover:bg-gray-200 active:bg-gray-400"
                          onClick={handleDeleteEmployee}
                        >
                          <RiDeleteBin6Line className="text-[#ff0000] w-6 h-6" />
                        </div>
                      )}
                      <Link
                        onClick={handleEditClick}
                        className="flex items-center justify-center w-10 h-10 ml-4 text-white"
                      >
                        <div className="p-2 bg-white rounded-lg shadow-md cursor-pointer drop-shadow-md hover:bg-gray-200 active:bg-gray-400">
                          <FiEdit className="text-[#38e54d] w-6 h-6 " />
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="content-center mb-8 ">
              <h1 className="font-bold text-[25px] text-black justify-center text-center">
                {pageTitle}
              </h1>
            </div>
            <div className="flex items-center justify-between w-full h-fit ">
              <div className="container w-full h-auto  ">
                <div className="container flex-col flex items-center  px-1 md:px-10 lg:px-14  justify-around w-full h-auto gap-5">
                  <div class="flex      lg:justify-between   justify-around w-full  lg:flex-nowrap  flex-wrap mt-2  mb-4">
                    <InputBoxWithLabel
                      label="Employee ID"
                      placeholder="Enter Employee ID"
                      onChange={(e) => setEmployeeId(e.target.value)}
                      value={EmployeeId}
                      type="text"
                      id="employee-id"
                      disabled={ViewEmployee}
                      validationError={EmployeeIdError}
                    />
                    <InputBoxWithLabel
                      label={`Employee Name `}
                      type="text"
                      placeholder="Enter Employee Name"
                      onChange={(e) => setEmployeeName(e.target.value)}
                      value={EmployeeName}
                      disabled={ViewEmployee}
                      validationError={EmployeeNameError}
                    />
                  </div>

                  <div class="flex      lg:justify-between   justify-around w-full  lg:flex-nowrap  flex-wrap mt-2  mb-4">
                    <Dropdown
                      placeholder={" Select Company "}
                      options={CompanyList}
                      updateValue={setCompanyId}
                      lableName="Company Name"
                      disabled={ViewEmployee}
                      validationError={CompanyNameError}
                      objName={"companyName"}
                      value={CompanyId}
                    />
                    <PhoneInputDD
                      lableName="Mobile Number"
                      placeholder="Enter Mobile Number"
                      maxLength={10}
                      minLength={10}
                      onChange={(e) => setMobileNo(e.target.value)}
                      value={MobileNo}
                      type="tel"
                      id="mobile-no"
                      disabled={ViewEmployee}
                      validationError={MobileNoError}
                    />
                  </div>
                  <div class="flex    lg:justify-between justify-around w-full  lg:flex-nowrap  flex-wrap mt-2  mb-4">
                    <Dropdown
                      lableName={"Gender"}
                      placeholder={"Select Gender"}
                      updateValue={setGender}
                      validationError={GenderError}
                      options={genderList}
                      objName={"type"}
                      value={Gender}
                    />
                  </div>
                  <div className="flex items-center justify-center w-full mt-10 mb-10 ">
                    {ViewEmployee ? null : (
                      <button
                        onClick={id ? handleUpdateEmployee : handleAddEmployee}
                      >
                        <Link className="bg-[#00adb5] hover:bg-[#059299] active:bg-[#047481] text-gray-100 text-xl font-bold w-2/12 text-center px-5 py-2 pl-10 pr-10 rounded-lg my-6">
                          {id ? "Update" : "Submit"}
                        </Link>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
