import React, { useEffect, useState } from "react";
import EditImage from "../../assets/Icons/IconImg/Edit_Icon_For_Invoice.png";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import Layout from "../../Layout/Layout";
import { IoMdArrowRoundBack } from "react-icons/io";
import axios from "axios";
import InputBoxWithLabel from "../Driver/LabelInput";
import Dropdown from "../Driver/DropDown";
import DateUtils from "../../Utility/DateUtils";
const dayjs = require("dayjs");

export default function EditInvoice() {
  const [routeId, setRouteId] = useState("");
  const [routeName, setRouteName] = useState("");
  const [tripDate, setTripDate] = useState("");
  const [shiftTime, setShiftTime] = useState("");
  const [fromLocation, setFromLocation] = useState("");
  const [toLocation, setToLocation] = useState("");
  const [tripType, setTripType] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [noOfPickup, setNoOfPickup] = useState("");
  const [tripAmount, setTripAmount] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverVehicleType, setDriverVehicleType] = useState("");
  const [driverVehicleName, setDriverVehicleName] = useState("");
  const [driverVehicleNumber, setDriverVehicleNumber] = useState("");
  const [driverId, setDriverId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [viewPage, setViewPage] = useState(false);

  const [invoicePaidAmount, setInvoicePaidAmount] = useState(0);
  const [invoicePaymentDate, setInvoicePaymentDate] = useState(
    DateUtils.getCurrentDate()
  );
  const [invoicePaymentStatus, setInvoicePaymentStatus] = useState("Not Paid");
  const [invoicePaymentMode, setInvoicePaymentMode] = useState("");
  const [invoiceTransactionId, setInvoiceTransactionId] = useState("");
  const [invoicePaidBy, setInvoicePaidBy] = useState("");
  const [invoicePaymentModeError, setInvoicePaymentModeError] = useState("");
  const [invoicePaymentStatusError, setInvoicePaymentStatusError] =
    useState("");
  const [invoicePaymentDateError, setInvoicePaymentDateError] = useState("");
  const [invoicePaidAmountError, setInvoicePaidAmountError] = useState("");
  const [invoicePaidByError, setInvoicePaidByError] = useState("");
  const [payerList, setPayerList] = useState([]);
  const [superAdminPlus, setSuperAdminPlus] = useState(false);
  const [invoice, setInvoice] = useState("");

  useEffect(() => {
    if(localStorage.getItem("userType") == "supervisor" ||
    localStorage.getItem("userType") == "admin" ){
      alert("You are not authorized to access this page");
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("userType") == "superAdminPlus") {
      setSuperAdminPlus(true);
    }
  }, []);

  const { invoiceId } = useParams();
  const navigate = useNavigate();

  const getPayer = async () => {
    try {
      const response = await axios.get(window.api + "passbook/getPayer", {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          email: localStorage.getItem("email"),
          usertype: localStorage.getItem("userType"),
        },
      });
      if (response.status === 200) {
        const payerList = response.data.data;
        setPayerList(payerList);
      }
    } catch (e) {
      console.error("Error getting payer list:", e);
      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  useEffect(() => {
    getPayer();
    getTripById(invoiceId);
  }, []);

  const getTripById = async (id) => {
    try {
      const headers = {
        "x-access-token": localStorage.getItem("accessToken"),
        email: localStorage.getItem("email"),
        usertype: localStorage.getItem("userType"),
      };

      const response = await axios.get(window.api + "trip/getTripOne", {
        params: {
          id: id,
        },
        headers, // Include the headers in the request
      });

      if (response.status === 200) {
        const tripData = response.data.data;
        const formattedDate =
          tripData && tripData.invoice
            ? tripData.invoice.invoicePaymentDate.split("T")[0]
            : "";
        setCompanyName(tripData.company.companyName);
        setRouteId(tripData.routeId);
        setRouteName(tripData.routeName);
        setTripDate(tripData.tripDate);
        setShiftTime(tripData.shiftTime);
        setFromLocation(tripData.fromLocation);
        setToLocation(tripData.toLocation);
        setTripType(tripData.tripType);
        setVehicleType(tripData.vehicleType);
        setNoOfPickup(tripData.noOfPickup);
        setTripAmount(tripData.tripAmount);
        setDriverId(
          tripData.driver && tripData.driver.driverID
            ? tripData.driver.driverID
            : "-"
        );
        setDriverName(tripData.driver ? tripData.driver.driverName : "-");
        setDriverVehicleNumber(
          tripData.driver ? tripData.driver.vehicleNo : "-"
        );
        setDriverVehicleType(
          tripData.driver ? tripData.driver.vehicleType : "-"
        );
        setDriverVehicleName(
          tripData.driver ? tripData.driver.vehicleName : "-"
        );
        setInvoicePaidAmount(tripData.invoice.invoicePaidAmount);
        setInvoicePaymentDate(formattedDate);
        setInvoicePaymentStatus(tripData.invoice.invoicePaymentStatus);
        setInvoicePaymentMode(tripData.invoice.invoicePaymentMode);
        setInvoiceTransactionId(tripData.invoice.invoiceTransactionId);
        setInvoicePaidBy(tripData.invoice.invoicePaidBy);
        setInvoice(tripData.invoiceId);
      } else {
        console.log("Trip not found");
      }
    } catch (error) {
      console.error("Error getting trip:", error);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleFormValidation()) {
      try {
        if (invoicePaymentStatus === "Paid") {
          const entryData = {
            invoicePaidBy: invoicePaidBy,
            invoicePaidAmount: invoicePaidAmount,
            date: invoicePaymentDate,
            paymentMode: invoicePaymentMode,
            transactionId: invoiceTransactionId,
            description:
              companyName +
              "/" +
              routeId +
              "/" +
              routeName +
              "/" +
              tripType +
              "/" +
              dayjs(tripDate).format("DD-MM-YYYY"),
            entryStatus: "Debit",
            tripId: invoiceId,
            routeId: routeId,
            routeName: routeName,
            tripDate: tripDate,
          };

          await axios.post(window.api + "passbook/createDebites", entryData, {
            headers: {
              "x-access-token": localStorage.getItem("accessToken"),
              email: localStorage.getItem("email"),
              usertype: localStorage.getItem("userType"),
            },
          });
        }
      } catch (error) {
        console.error("Errors adding passbook entry: ", error);
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      }

      try {
        const data = {
          invoicePaidAmount: invoicePaidAmount,
          invoicePaymentDate: invoicePaymentDate,
          invoicePaymentStatus: invoicePaymentStatus,
          invoicePaymentMode: invoicePaymentMode,
          invoiceTransactionId: invoiceTransactionId,
          invoicePaidBy: invoicePaidBy,
          tripId: invoiceId,
        };

        const response = await axios.post(window.api + "invoice/create", data, {
          headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            email: localStorage.getItem("email"),
            usertype: localStorage.getItem("userType"),
          },
        });
        if (response.status === 200) {
          navigate("/invoice-list");
          toast("Invoice Created Successfully!", {
            icon: "👏",
            style: {
              borderRadius: "7px",
              background: "#222831",
              color: "#fff",
            },
          });


        }
      } catch (e) {
        console.error("Errors creating invoice: ", e);
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      }
    }
  };

  const handleDiscard = async () => {
    setInvoicePaidAmount("");
    setInvoicePaymentDate("");
    setInvoicePaymentStatus("");
    setInvoicePaymentMode("");
    setInvoiceTransactionId("");
    setInvoicePaidBy("");
    // try {
    //   await axios.put(window.api + `invoice/delete/${invoiceId}`);
    //   toast("Invoice Discard Successfully!", {
    //     icon: "👏",
    //     style: {
    //       borderRadius: "7px",
    //       background: "#222831",
    //       color: "#fff",
    //     },
    //   });

    //   setTimeout(() => {
    //     navigate("/invoice-list");
    //   }, 2000);
    // } catch (error) {
    //   console.error("Error deleting driver:", error);
    // }
  };

  const handleFormValidation = () => {
    let formIsValid = true;

    if (invoicePaymentStatus !== "On Hold") {
      if (!invoicePaidAmount) {
        formIsValid = false;
        setInvoicePaidAmountError("Please enter paid amount");
      } else {
        setInvoicePaidAmountError("");
      }

      if (!invoicePaymentDate) {
        formIsValid = false;
        setInvoicePaymentDateError("Please enter payment date");
      } else {
        setInvoicePaymentDateError("");
      }

      if (!invoicePaymentStatus) {
        formIsValid = false;
        setInvoicePaymentStatusError("Please enter payment status");
      } else {
        setInvoicePaymentStatusError("");
      }

      if (invoicePaymentMode == "") {
        formIsValid = false;
        setInvoicePaymentModeError("Please enter payment mode");
      } else {
        setInvoicePaymentModeError("");
      }

      if (invoicePaidBy == "") {
        formIsValid = false;
        setInvoicePaidByError("Please enter paid by");
      } else {
        setInvoicePaidByError("");
      }
    }

    return formIsValid;
  };

  return (
    <Layout>
      <div>
        <div>
          <Toaster />
          <div class="bg-gray-100 ">
            <div class="container mx-auto">
              <div className="flex flex-wrap ml-8">
                <div className=" cursor-pointer  shadow-lgrounded-xl">
                  <Link
                    to="#" onClick={() => navigate(-1)}
                    className="flex items-center justify-center w-10 h-10 text-black"
                  >
                    <IoMdArrowRoundBack className="w-6 h-6 text-black" />
                  </Link>
                </div>
              </div>
              <div class="flex justify-center m-2">
                <h1 class="text-3xl font-bold">Trip Payment Details</h1>
              </div>
              <div class="grid grid-cols-3 gap-8">
                <div class="bg-white p-8 rounded-lg shadow-md">
                  <h2 class="text-xl font-bold m-2">Trip Details</h2>
                  <p>
                    <span class="font-semibold m-2">Route ID:</span> {routeId}
                  </p>
                  <p>
                    <span class="font-semibold m-2">Route Name:</span>{" "}
                    {routeName}
                  </p>
                  <p>
                    <span class="font-semibold m-2">From Location:</span>{" "}
                    {fromLocation}
                  </p>
                  <p>
                    <span class="font-semibold m-2">To Location:</span>{" "}
                    {toLocation}
                  </p>
                  <p>
                    <span class="font-semibold m-2">Trip Date:</span> {tripDate}
                  </p>
                </div>
                <div class="bg-white p-8 rounded-lg shadow-md">
                  <h2 class="text-xl font-bold m-2">Trip Summary</h2>
                  <p>
                    <span class="font-semibold m-3">No of Pickups:</span>{" "}
                    {noOfPickup}
                  </p>
                  <p>
                    <span class="font-semibold m-3">Vehicle Type:</span>{" "}
                    {vehicleType}
                  </p>
                  <p>
                    <span class="font-semibold m-3">Shift Time:</span>{" "}
                    {shiftTime}
                  </p>
                  <p>
                    <span class="font-semibold m-3">Trip Amount:</span>{" "}
                    {tripAmount}
                  </p>
                  <p>
                    <span class="font-semibold m-3">Trip Type:</span> {tripType}
                  </p>
                </div>
                <div class="bg-white p-8 rounded-lg shadow-md">
                  <h2 class="text-xl font-bold m-2">
                    Driver &amp; Vehicle Details
                  </h2>
                  <p>
                    <span class="font-semibold m-2">Driver ID:</span> {driverId}
                  </p>
                  <p>
                    <span class="font-semibold m-2">Driver Name:</span>{" "}
                    {driverName}
                  </p>
                  <p>
                    <span class="font-semibold m-2">Vehicle Number:</span>{" "}
                    {driverVehicleNumber}
                  </p>
                  <p>
                    <span class="font-semibold m-2">Vehicle Type:</span>{" "}
                    {driverVehicleType}
                  </p>
                  <p>
                    <span class="font-semibold m-2">Vehicle Name:</span>{" "}
                    {driverVehicleName}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-100 mt-4">
          <div class="container mx-auto shadow-lg p-2x">
            <div class="bg-white p-8 rounded-lg shadow-md">
              {!viewPage && (
                <div className="flex justify-end">
                  {invoice == "" ||
                  localStorage.getItem("userType") === "superAdminPlus" ? (
                    <Link>
                      <button onClick={() => setViewPage(true)}>
                        <img src={EditImage} alt="Edit" />
                      </button>
                    </Link>
                  ) : null}
                </div>
              )}
              <div class="flex flex-wrap  lg:flex-nowrap   justify-around items-center w-full">
                <InputBoxWithLabel
                  label="Paid Amount"
                  id="paid-amount"
                  type="text"
                  placeholder="Enter paid amount"
                  onChange={(e) => setInvoicePaidAmount(e.target.value)}
                  value={invoicePaidAmount}
                  disabled={!viewPage}
                  validationError={invoicePaidAmountError}
                />
                <InputBoxWithLabel
                  label="Payment Date"
                  id="payment-date"
                  type="date"
                  placeholder="Select payment date"
                  onChange={(e) => setInvoicePaymentDate(e.target.value)}
                  value={invoicePaymentDate}
                  disabled={!viewPage}
                  validationError={invoicePaymentDateError}
                />

                <Dropdown
                  lableName={"Payment Status"}
                  placeholder={"Select Payment Status"}
                  updateValue={setInvoicePaymentStatus}
                  options={[
                    { name: "Paid", id: "Paid" },
                    { name: "Not Paid", id: "Not Paid" },
                    { name: "On Hold", id: "On Hold" },
                  ]}
                  validationError={invoicePaymentStatusError}
                  objName={"name"}
                  disabled={!viewPage}
                  value={invoicePaymentStatus}
                />
              </div>
              <div class="flex flex-wrap  lg:flex-nowrap   justify-around items-center w-full">
                <Dropdown
                  lableName={"Payment Mode"}
                  placeholder={"Select Payment Status"}
                  updateValue={setInvoicePaymentMode}
                  options={[
                    { name: "Cash", id: "Cash" },
                    { name: "Gpay", id: "Gpay" },
                    { name: "Amazon Pay", id: "Amazon Pay" },
                    { name: "Phone pe", id: "Phone pe" },
                    { name: "Paytm", id: "Paytm" },
                    { name: "Acount Transfer", id: "Acount Transfer" },
                    { name: "Others", id: "Others" },
                  ]}
                  validationError={invoicePaymentModeError}
                  objName={"name"}
                  disabled={!viewPage}
                  value={invoicePaymentMode}
                />
                <InputBoxWithLabel
                  label="Transaction ID"
                  id="paid-amount"
                  type="text"
                  placeholder="Enter Transaction ID"
                  onChange={(e) => setInvoiceTransactionId(e.target.value)}
                  value={invoiceTransactionId}
                  disabled={!viewPage}
                />

                <Dropdown
                  lableName={"Paid By"}
                  placeholder={"Select Paid by"}
                  updateValue={setInvoicePaidBy}
                  options={payerList}
                  validationError={invoicePaidByError}
                  objName={"payerName"}
                  // isKey={false}
                  disabled={!viewPage}
                  value={invoicePaidBy}
                  // isValueObj={true}
                  valueKey={"payerName"}
                />
              </div>

              {viewPage && (
                <div className="flex justify-center mt-4">
                  <Link
                    // to="/invoice/"
                    className="px-4 py-2 mr-2 text-white bg-blue-500 rounded"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Link>
                  <Link
                    // to="/invoice/"
                    className="px-4 py-2 text-white bg-red-500 rounded"
                    onClick={handleDiscard}
                  >
                    Discard
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
