import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../../Layout/Layout";
import DataTable from "react-data-table-component";
import axios from "axios";
import Dropdown from "../Driver/DropDown";
import InputBoxWithLabel from "../Driver/LabelInput";
import Table from "../Driver/Table";
import eye from "../../assets/Icons/eye.png";
import DateUtils from "../../Utility/DateUtils";
import arrowIcon from "./right-arrow.png";

export default function ViewInvoice() {
  const [openTab, setOpenTab] = useState(1);
  const [companyList, setCompanyList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [driverId, setDriverId] = useState(
    window.localStorage.getItem("invoicePageDriverId") || ""
  );
  const [searchQuery, setSearchQuery] = useState("");

  const [toDate, setToDate] = useState(
    window.localStorage.getItem("invoicePageToDate") ||
      DateUtils.getCurrentDate()
  );

  const [fromDate, setFromDate] = useState(
    window.localStorage.getItem("invoicePageFromDate") ||
      DateUtils.getTMinusDate(6)
  );
  const [statusSelected, setStatusSelected] = useState(
    window.localStorage.getItem("invoicePageStatusSelected") || "Not Paid"
  );
  const [paidAmount, setPaidAmount] = useState("");
  const [onHoldAmount, setOnHoldAmount] = useState("");
  const [notPaidAmount, setNotPaidAmount] = useState("");
  const [notPaidTodayAmount, setNotPaidTodayAmount] = useState("");
  const [todaysPaidAmount, setTodayPaidAmount] = useState("");

  const [companyId, setCompanyId] = useState(
    window.localStorage.getItem("invoicePageCompanyId") || ""
  );
  const [rows, setRows] = useState([]);
  const [filterRows, setFilterRows] = useState([]);
  
  const [invoiceStatus, setInvoiceStatus] = useState("Not Paid");

  useEffect(() => {
    if (
      localStorage.getItem("userType") == "supervisor" ||
      localStorage.getItem("userType") == "admin"
    ) {
      alert("You are not authorized to access this page");
      window.location.href = "/";
    }
  }, []);

  const PriceTable = {
    AmmountTitle: [
      "Today Paid Amount",
      "Today Unpaid Amount",
      "Total Paid Amount",
      "Total Onhold Amount",
      "Total Unpaid Amount",
    ],
    Ammount: [
      todaysPaidAmount,
      notPaidTodayAmount,
      paidAmount,
      onHoldAmount,
      notPaidAmount,
    ],
  };

  function TotalCard({ title, amount }) {
    return (
      <div className="flex flex-col w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5">
        <div className="p-2 mx-2 mb-5 bg-teal-500 rounded-lg">
          <h1 className="p-1 font-bold text-[16px] text-center">{title}</h1>
          <h1 className="text-3xl font-bold text-center text-white">
            ₹ {amount}
          </h1>
        </div>
      </div>
    );
  }

  useEffect(() => {
    getCompany();
    getTrips();
    getDriver();
    getTripsCalculation();
  }, [companyId, driverId, fromDate, toDate, statusSelected]);

  const getDriver = async () => {
    try {
      const response = await axios.get(window.api + "driver/getDrivers", {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          email: localStorage.getItem("email"),
          usertype: localStorage.getItem("userType"),
        },
      });

      if (response.status === 200) {
        const drivers = response.data.data;
        setDriverList(drivers);
      }
    } catch (e) {
      console.error("Error getting drivers:", e);

      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  const getCompany = async () => {
    try {
      const response = await axios.get(window.api + "company/getCompanyList", {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          email: localStorage.getItem("email"),
          usertype: localStorage.getItem("userType"),
        },
      });

      if (response.status === 200) {
        const companyList = response.data.data;
        setCompanyList(companyList);
      }
    } catch (error) {
      console.error("Error getting company list:", error);

      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  const getTrips = async () => {
    try {
      const headers = {
        "x-access-token": localStorage.getItem("accessToken"),
        email: localStorage.getItem("email"),
        usertype: localStorage.getItem("userType"),
      };

      const response = await axios.get(
        window.api + "trip/getTripsInvoicePage",
        {
          params: {
            tripStatus: "Completed",
            companyId: companyId,
            driverId: driverId,
            fromDate: fromDate,
            toDate: toDate,
            statusSelected: statusSelected,
          },
          headers, // Include the headers in the request
        }
      );

      if (response.status === 200) {
        const trips = response.data.data;

        setRows(trips);
      }
    } catch (error) {
      console.error("Error getting trips:", error);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  useEffect(() => {
    // Filter trips by searchQuery
    const filteredTrips = rows.filter((trip) => {
      const { routeId, driver } = trip;
      if(driver == null) return false;
      const { vehicleNo, driverID } = driver;
      const searchQueryLower = searchQuery.toLowerCase();
      return (
        routeId.toLowerCase().includes(searchQueryLower) ||
        vehicleNo.toLowerCase().includes(searchQueryLower) ||
        driverID.toLowerCase().includes(searchQueryLower)
      );
    });
    setFilterRows(filteredTrips);

  }, [rows, searchQuery]);

  const getTripsCalculation = async () => {
    try {
      const headers = {
        "x-access-token": localStorage.getItem("accessToken"),
        email: localStorage.getItem("email"),
        usertype: localStorage.getItem("userType"),
      };

      const response = await axios.get(
        window.api + "trip/getTripsCalculation",
        {
          params: {
            fromDate: fromDate,
            toDate: toDate,
          },
          headers, // Include the headers in the request
        }
      );

      if (response.status === 200) {
        const tripsCalculations = response.data.data;
        setTodayPaidAmount(tripsCalculations.todayPaidAmount);
        setNotPaidTodayAmount(tripsCalculations.todayUnPaidAmount);
        setPaidAmount(tripsCalculations.totalPaidAmount);
        setOnHoldAmount(tripsCalculations.totalOnHoldAmount);
        setNotPaidAmount(tripsCalculations.totalUnPaidAmount);
        // setRows(trips);
      }
    } catch (error) {
      console.error("Error getting trips calculations:", error);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  // Clear localStorage on page refresh
  window.onbeforeunload = () => {
    localStorage.removeItem("invoicePageDriverId");
    localStorage.removeItem("invoicePageFromDate");
    localStorage.removeItem("invoicePageToDate");
    localStorage.removeItem("invoicePageCompanyId");
    localStorage.removeItem("invoicePageStatusSelected");
  };
  const [arrowColor, setArrowColor] = useState("text-[#00ADB5]");
  const columns = [
    {
      name: "Route ID",
      selector: "routeId",
      sortable: true,
      cell: (row) => (
        <div className="p-3 text-[#393e46]  font-medium   uppercase text-center cursor-pointer text-sm font-roboto">
          {row.routeId}
        </div>
      ),
    },
    {
      name: "Route Name",
      selector: "routeName",
      sortable: true,
      cell: (row) => (
        <div className="p-3 text-[#393e46]  font-medium   uppercase text-left cursor-pointer text-sm font-roboto">
          {row.routeName}
        </div>
      ),
    },
    {
      name: "Vehicle No",
      selector: "vehicleNo",
      sortable: true,
      cell: (row) => (
        <div className="p-3 text-[#393e46]  font-medium   uppercase text-left cursor-pointer text-sm font-roboto">
          {row.driver && row.driver.vehicleNo ? row.driver.vehicleNo : "-"}
        </div>
      ),
    },
    {
      name: "Trip Date",
      selector: "tripDate",
      sortable: true,
      cell: (row) => (
        <div className=" text-[#393e46] flex justify-center items-center w-full  font-medium   uppercase text-center text-sm font-roboto">
          {new Date(row.tripDate)
            .toLocaleDateString("en-GB")
            .replace(/\//g, "-")}
        </div>
      ),
    },
    {
      name: "Trip Type",
      selector: "tripType",
      sortable: true,
      cell: (row) => (
        <div className=" text-[#393e46] flex justify-center items-center w-full  font-medium   uppercase text-center text-sm font-roboto">
          {row.tripType}
        </div>
      ),
    },
    {
      name: "Company Name",
      selector: "companyName",
      sortable: true,
      cell: (row) => (
        <div className="p-3 text-[#393e46]  font-medium   uppercase text-center text-sm font-roboto">
          {row.company.companyName}
        </div>
      ),
    },
    {
      name: "Trip Amount",
      selector: "tripAmount",
      sortable: true,
      cell: (row) => (
        <div className="p-3 text-[#393e46]  font-medium   uppercase text-center text-sm font-roboto">
          {row.tripAmount}
        </div>
      ),
    },
    {
      name: "Status",
      selector: "invoice.invoicePaymentStatus",
      // sortable: true,
      cell: (row) => (
        <div className="p-3 text-[#393e46]  font-medium   uppercase text-center text-sm font-roboto">
          <span className="inline-block w-6 h-6 rounded-full">
            <div
              className={
                row.invoice && row.invoice.invoicePaymentStatus === "Not Paid"
                  ? "text-[#E90064] text-sm font-roboto whitespace-nowrap  flex justify-center"
                  : row.invoice && row.invoice.invoicePaymentStatus === "Paid"
                  ? "text-[#03C988] text-sm font-roboto whitespace-nowrap  flex justify-center"
                  : row.invoice &&
                    row.invoice.invoicePaymentStatus === "On Hold"
                  ? "text-[#FF8B13] text-sm font-roboto whitespace-nowrap  flex justify-center"
                  : invoiceStatus === "Not Paid"
                  ? "text-[#E90064] text-sm font-roboto whitespace-nowrap  flex justify-center"
                  : invoiceStatus === "Paid"
                  ? "text-[#03C988] text-sm font-roboto whitespace-nowrap  flex justify-center"
                  : invoiceStatus === "On Hold"
                  ? "text-[#FF8B13] text-sm font-roboto whitespace-nowrap  flex justify-center"
                  : null
              }
            >
              {row.invoice && row.invoice.invoicePaymentStatus
                ? row.invoice.invoicePaymentStatus
                : invoiceStatus}
            </div>
          </span>
        </div>
      ),
    },
    {
      name: "Paid Amount",
      selector: "invoice.invoicePaidAmount",
      // sortable: true,
      cell: (row) => (
        <div className=" text-[#393e46]  font-medium  fon   uppercase text-center text-sm font-roboto">
          {row.invoice?.invoicePaidAmount || "-"}
        </div>
      ),
    },
    {
      name: "Paid Date",
      selector: "invoice.invoicePaymentDate",
      // sortable: true,
      cell: (row) => (
        <div className=" text-[#393e46]  font-medium   uppercase text-center text-sm font-roboto">
          {row.invoice?.invoicePaymentDate
            ? new Date(row.invoice.invoicePaymentDate)
                .toLocaleDateString("en-GB")
                .replace(/\//g, "-")
            : "-"}
        </div>
      ),
    },
    {
      name: "Action",
      selector: "id",
      sortable: false,
      cell: (row) => (
        <div className=" text-[#393e46] flex  w-full  pl-5  gap-2  font-medium  justify-center items-center   uppercase text-center text-sm font-roboto">
          <Link
            to={`/view-invoice/${row.id}`}
            className="text-center text-blue-600 flex      items-center w-full hover:text-blue-800"
          >
            <img className=" w-8 " src={eye} alt="" />
          </Link>
          {openTab === 2 ? (
            <button
              className="text-center text-blue-600 flex  pr-5     items-center w-full hover:text-blue-800"
              onClick={() => pushRemittance(row.id)}
            >
              <div>
                <img width={35} src={arrowIcon} alt="" />
              </div>
            </button>
          ) : null}
        </div>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        color: "#ffffff",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#222831",
        marginBottom: "-2px",
      },
    },
    cells: {
      style: {
        color: "#393e46",
        paddingLeft: "40px",
        fontWeight: "800",
      },
    },
    rows: {
      style: {
        color: "#393e46",
        backgroundColor: "#ffffff",
        borderRadius: "0.5rem",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "17px",
        font: "bold",
        fontWeight: "800",
      },
    },
    head: {
      style: {
        borderRadius: "1rem",
        backgroundColor: "#222831",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",
        alignItems: "center",
        // justifyContent: "center",
        fontSize: "1rem",
        height: "60px",
      },
    },
  };

  useEffect(() => {
    localStorage.setItem("invoicePageCompanyId", companyId);
  }, [companyId]);

  useEffect(() => {
    localStorage.setItem("invoicePageDriverId", driverId);
  }, [driverId]);

  const pushRemittance = async (id) => {
    try {
      const headers = {
        "x-access-token": localStorage.getItem("accessToken"),
        email: localStorage.getItem("email"),
        usertype: localStorage.getItem("userType"),
      };

      const response = await axios.post(
        window.api + "trip/pushRemittance",
        {
          tripIds: id,
          status: "Pending",
        },
        {
          headers,
        }
      );

      if (response.status === 200) {
        alert("Remittance pushed successfully");
        getTrips();
      }
    } catch (error) {
      console.error("Error getting trips:", error);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  return (
    <Layout>
      <div>
        <div className="flex flex-wrap -mx-4">
          <div className="container w-full h-auto ">
            <div className="w-auto h-auto p-2 m-2 rounded-md shadow-lg paperEffect shadow-slate-250 ">
              <div className="flex flex-wrap mb-2 items-center justify-around w-full  h-auto md:h-[220px] lg:h-[110px] labelInputContainer">
                <Dropdown
                  lableName={"Driver ID"}
                  options={driverList}
                  updateValue={setDriverId}
                  placeholder={"Driver ID"}
                  widthForSelect={"w-[260px]"}
                  objName={"driverID"}
                  value={driverId}
                />
                <InputBoxWithLabel
                  label={"From Date"}
                  type={"date"}
                  value={fromDate}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setFromDate(selectedValue);
                    localStorage.setItem("invoicePageFromDate", selectedValue);
                  }}
                  widthForInput={"w-[260px]"}
                />

                <InputBoxWithLabel
                  label={"To Date"}
                  type={"date"}
                  value={toDate}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setToDate(selectedValue);
                    localStorage.setItem("invoicePageToDate", selectedValue);
                  }}
                  widthForInput={"w-[260px]"}
                />
                <Dropdown
                  lableName={"Company"}
                  options={companyList}
                  updateValue={setCompanyId}
                  placeholder={"Company"}
                  widthForSelect={"w-[260px]"}
                  objName={"companyName"}
                  value={companyId}
                />
              </div>
              <div className="flex flex-wrap items-center justify-center w-full h-auto ">
                {PriceTable.AmmountTitle.map((title, index) => (
                  <TotalCard
                    key={index}
                    title={title}
                    amount={PriceTable.Ammount[index].toLocaleString("en-IN")}
                  />
                ))}
              </div>
            </div>
            <div className="flex flex-wrap w-full">
              <div className="w-full flex justify-between items-center  ">
                <div className="flex flex-wrap items-center justify-center w-full h-auto gap-2 p-2 lg:gap-0 labelInputContainer">
                  <div className="flex flex-col w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5">
                    <div className="flex w-auto h-full mx-5">
                      <button
                        className={
                          "text-xs font-bold uppercase px-5 py-3  w-full rounded block leading-normal " +
                          (openTab === 1
                            ? "text-[#222831] bg-[#00ADB5] "
                            : "text-[#00ADB5] bg-[#222831]")
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(1);
                          setStatusSelected("Not Paid");
                        }}
                      >
                        Not Paid
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5">
                    <div className="flex w-auto h-full mx-5">
                      <button
                        className={
                          "text-xs font-bold uppercase px-5 py-3  w-full rounded block leading-normal " +
                          (openTab === 2
                            ? "text-[#222831] bg-[#00ADB5] "
                            : "text-[#00ADB5] bg-[#222831]")
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(2);
                          setStatusSelected("On Hold");
                        }}
                        data-toggle="tab"
                        href="#link2"
                        role="tablist"
                      >
                        On Hold
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5">
                    <div className="flex w-auto h-full mx-5">
                      <button
                        className={
                          "text-xs font-bold uppercase px-5 py-3  w-full rounded block leading-normal " +
                          (openTab === 3
                            ? "text-[#222831] bg-[#00ADB5] "
                            : "text-[#00ADB5] bg-[#222831]")
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(3);
                          setStatusSelected("Paid");
                        }}
                      >
                        Paid
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col invisible w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5"></div>
                  <div className="flex flex-col invisible w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5"></div>
                </div>
                <form className="w-full mb-2 md:mb-0 md:w-auto md:mr-4">
                  <label
                    htmlFor="default-search"
                    className="mb-2 text-sm font-medium text-gray-900 sr-only "
                  >
                    Search
                  </label>
                  <div className="relative w-[250px]">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 "
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => {
                        const searchQuery = e.target.value;
                        setSearchQuery(searchQuery);
                      }}
                      className="block w-full p-2 pl-10 text-sm border-2 rounded-lg "
                      placeholder="Search"
                      required
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="table w-full h-auto ">
              <div className="w-full h-auto rounded-lg shadow-xl shadow-slate-250">
                <div className="flex flex-col gap-6 m-2 bg-white rounded-lg shadow-md">
                  <Table
                    columns={columns}
                    rows={filterRows}
                    headerStyle={[
                      " text-center ",
                      "text-left ",
                      "text-center ",

                      "text-center ",
                      "text-center ",
                      "text-center ",
                      "text-center ",
                      "text-center ",
                      "text-center ",
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
