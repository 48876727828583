import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import axios from "axios";
import Balance from "./Balance";
import Credits from "./Credits";
import TableForBalance from "./TableForBalance";

export default function Passbook() {
  const [openTable, setOpenTable] = React.useState("Balance");
  const [payerSelected, setPayerSelected] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const [payerSelectedName, setPayerSelectedName] = useState(null);

  const [totalCurrentBalance, setTotalCurrentBalance] = useState("");
  const [payerList, setPayerList] = useState([]);

  useEffect(() => {
    if(localStorage.getItem("userType") == "supervisor" ||
    localStorage.getItem("userType") == "admin" ){
      alert("You are not authorized to access this page");
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    getPayer();
  }, []);

  const getPayer = async () => {
    try {
      const response = await axios.get(window.api + "passbook/getPayerList", {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          "email": localStorage.getItem("email"),
          "usertype": localStorage.getItem("userType")
        }
      });
    
      if (response.status !== 200) return;
    
      const data = response.data.data;
      const sumForDate = response.data.sumForDate;
      setTotalCurrentBalance(sumForDate.toLocaleString());
      setPayerList(data);
    } catch (e) {
      console.error("Error getting payer list:", e);
      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      }
    }
    
  };


  const tableOpener = () => {
    setOpenTable("Table");
  };
  const balanceOpener = () => {
    setOpenTable("Balance");
  };
  const Tabs = ({ color }) => {
    const [openTab, setOpenTab] = React.useState(1);

    return (
      <>
        <div className="flex flex-wrap">
          <div className="w-full ">
            <ul
              className="flex flex-row flex-wrap gap-2 pt-3 pb-4 mb-0 list-none"
              role="tablist"
            >
              <li className="flex-auto mr-2 -mb-px text-center last:mr-0">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3  rounded block leading-normal " +
                    (openTab === 1
                      ? "text-[#222831] bg-[#00ADB5] "
                      : "text-[#00ADB5] bg-[#222831]")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                    getPayer();
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  Balance
                </a>
              </li>
              <li className="flex-auto mr-2 -mb-px text-center last:mr-0">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3  rounded block leading-normal " +
                    (openTab === 2
                      ? "text-[#222831] bg-[#00ADB5] "
                      : "text-[#00ADB5] bg-[#222831]")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                  Credits
                </a>
              </li>
              <li className="flex-auto invisible mr-2 -mb-px text-center last:mr-0"></li>
              <li className="flex-auto invisible mr-2 -mb-px text-center last:mr-0"></li>
            </ul>
            <div className="relative flex flex-col min-w-0 break-words  w-full mb-6  ">
              <div className="flex-auto  py-5 ">
                <div className="tab-content tab-space">
                  <div
                    className={openTab === 1 ? "block" : "hidden"}
                    id="link1"
                  >
                    {}
                    {openTable === "Balance" && (
                      <Balance tableOpener={tableOpener} setPayerSelected={setPayerSelected} setFilterDate={setFilterDate} setPayerSelectedName={setPayerSelectedName} payerList={payerList} totalCurrentBalance={totalCurrentBalance} getPayer={getPayer}/>
                    )}
                    {openTable === "Table" && (
                      <TableForBalance balanceOpener={balanceOpener} payerSelected={payerSelected} filterDate={filterDate} payerSelectedName = {payerSelectedName} />
                    )}
                  </div>
                  <div
                    className={openTab === 2 ? "block" : "hidden"}
                    id="link2"
                  >
                    <Credits getPayerHome={getPayer} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <div>
      <Layout>
        <Tabs />
      </Layout>
    </div>
  );
}
