import React from "react";
import close from "../../assets/images/close.png";
import Dropdown from "../Driver/DropDown";

export default function UpdateTripStatus({
  visible,
  onClose,
  handleSubmit,
  setInvoicePaidBy,
  payerList,
  invoicePaidBy,
  invoicePaidByError,

  }) {
  if (!visible) return null;

  return (
<div className="fixed inset-0 bg-[#222831] bg-opacity-40 backdrop-blur-sm flex justify-center items-center">
  <div className="bg-white px-10 py-5 rounded w-full max-w-[400px] mx-auto sm:max-w-[600px]">
    <div className="mb-4">
      <img
        className="w-5 cursor-pointer ml-auto"
        onClick={onClose}
        src={close}
        alt="close"
      />
    </div>
    {/* <div className="flex justify-center items-center h-10">
      <p className="text-center font-bold text-md text-[#222831]">{`${RouteId} - ${RouteName} - ${TripType}`}</p>
    </div> */}
    <br />
    <form onSubmit={handleSubmit}>
      {/* <div className="grid grid-cols-1 sm:grid-cols-2 "> */}
        <div className="flex flex-col items-center">
        <Dropdown
                  lableName={"Paid By"}
                  placeholder={"Select Paid by"}
                  updateValue={setInvoicePaidBy}
                  options={payerList}
                  validationError={invoicePaidByError}
                  objName={"payerName"}
                  // isKey={false}
                  value={invoicePaidBy}
                  // isValueObj={true}
                  valueKey={"payerName"}
                />


        {/* </div> */}
      </div>
        <div className="text-center mt-5 mb-1">
          <button className="bg-[#00ADB5] px-6 py-1 rounded-md text-white">
            Submit
          </button>
        </div>

    </form>
  </div>
</div>

  );
}
