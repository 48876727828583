import React, { useEffect, useState } from "react";
import EditImage from "../../assets/Icons/IconImg/Edit_Icon_For_Invoice.png";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import Layout from "../../Layout/Layout";
import { IoMdArrowRoundBack } from "react-icons/io";
import axios from "axios";
import InputBoxWithLabel from "../Driver/LabelInput";
import Dropdown from "../Driver/DropDown";
import DateUtils from "../../Utility/DateUtils";
const dayjs = require("dayjs");
export default function CreateInvoice() {
  const [routeId, setRouteId] = useState("");
  const [routeName, setRouteName] = useState("");
  const [tripStartDate, setTripStartDate] = useState("");
  const [tripEndDate, setTripEndDate] = useState("");
  const [shiftTime, setShiftTime] = useState("");
  const [fromLocation, setFromLocation] = useState("");
  const [toLocation, setToLocation] = useState("");
  const [tripType, setTripType] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [noOfGuest, setNoOfGuest] = useState("");
  const [pickupTime, setPickupTime] = useState("");
  const [dropTime, setDropTime] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverVehicleType, setDriverVehicleType] = useState("");
  const [driverVehicleName, setDriverVehicleName] = useState("");
  const [driverVehicleNumber, setDriverVehicleNumber] = useState("");
  const [driverId, setDriverId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [tripAmount, setTripAmount] = useState("");
  const [viewPage, setViewPage] = useState(false);

  const [invoicePaidAmount, setInvoicePaidAmount] = useState(0);
  const [invoicePaymentDate, setInvoicePaymentDate] = useState(
    DateUtils.getCurrentDate()
  );
  const [invoicePaymentStatus, setInvoicePaymentStatus] = useState("Not Paid");
  const [invoicePaymentMode, setInvoicePaymentMode] = useState("");
  const [invoiceTransactionId, setInvoiceTransactionId] = useState("");
  const [invoicePaidBy, setInvoicePaidBy] = useState("");
  const [invoicePaymentModeError, setInvoicePaymentModeError] = useState("");
  const [invoicePaymentStatusError, setInvoicePaymentStatusError] =
  useState("");
  const [invoiceTransactionIdError, setInvoiceTransactionIdError] = useState("");
  const [invoicePaymentDateError, setInvoicePaymentDateError] = useState("");
  const [invoicePaidAmountError, setInvoicePaidAmountError] = useState("");
  const [invoicePaidByError, setInvoicePaidByError] = useState("");
  const [payerList, setPayerList] = useState([]);

  const { id } = useParams();
  const navigate = useNavigate();

  const getPayer = async () => {
    try {
      const response = await axios.get(window.api + "passbook/getPayer", {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          "email": localStorage.getItem("email"),
          "usertype": localStorage.getItem("userType")
        }
      });
      if (response.status === 200) {
        const payerList = response.data.data;
        setPayerList(payerList);
      }
    } catch (e) {
      console.error("Error getting payer list:", e);
      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      }
    }
  };
  

  useEffect(() => {
    getPayer();
    getTripById(id);
  }, []);

  const getTripById = async (id) => {
    try {
      const response = await axios.get(
        window.api + "outstation/getOutstationTripOne",
        {
          params: {
            id: id,
          },
        }
      );
      if (response.status === 200) {
        const tripData = response.data.data;
        setRouteId(tripData.routeId);
        setTripStartDate(tripData.tripStartDate);
        setTripEndDate(tripData.tripEndDate);
        setFromLocation(tripData.pickupLocation);
        setToLocation(tripData.dropLocation);
        setTripType(tripData.tripType);
        setVehicleType(tripData.vehicleType);
        setNoOfGuest(tripData.noOfGuest);
        setDropTime(tripData.dropTime);
        setPickupTime(tripData.pickupTime);
        setTripAmount(tripData.tripAmount);
        setCompanyName(tripData.company.companyName);
        setDriverId(
          tripData.driver && tripData.driver.driverID
            ? tripData.driver.driverID
            : "-"
        );
        setDriverName(tripData.driver ? tripData.driver.driverName : "-");
        setDriverVehicleNumber(
          tripData.driver ? tripData.driver.vehicleNo : "-"
        );
        setDriverVehicleType(
          tripData.driver ? tripData.driver.vehicleType : "-"
        );
        setDriverVehicleName(
          tripData.driver ? tripData.driver.vehicleName : "-"
        );
        setInvoicePaidAmount(tripData.invoicePaidAmount ? tripData.invoicePaidAmount : 0);
        setInvoicePaymentDate( tripData.invoicePaymentDate ? tripData.invoicePaymentDate : DateUtils.getCurrentDate());
        setInvoicePaymentStatus(tripData.invoicePaymentStatus);
        setInvoicePaymentMode(tripData.invoicePaymentMode);
        setInvoiceTransactionId(tripData.invoiceTransactionId);
        setInvoicePaidBy(tripData.invoicePaidBy);
      } else {
        console.log("Trip not found");
      }
    } catch (error) {
      console.error("Error getting trip:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleFormValidation()) {
      try {
        const data = {
          invoicePaidAmount: invoicePaidAmount,
          invoicePaymentDate: invoicePaymentDate,
          invoicePaymentStatus: invoicePaymentStatus,
          invoicePaymentMode: invoicePaymentMode,
          invoiceTransactionId: invoiceTransactionId,
          invoicePaidBy: invoicePaidBy,
          tripStatus: "Completed",
        };
  
        const response = await axios.put(window.api + `outstation/update/${id}`, data, {
          headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "email": localStorage.getItem("email"),
            "usertype": localStorage.getItem("userType")
          }
        });
  
        if (response.status === 200) {
          toast("Invoice Created Successfully!", {
            icon: "👏",
            style: {
              borderRadius: "7px",
              background: "#222831",
              color: "#fff",
            },
          });
          setTimeout(() => {
            navigate("/outstation");
          }, 2000);
        }
      } catch (e) {
        console.error("Errors creating invoice: ", e);
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        }
      }
    }
  };
  

  const handleFormValidation = () => {
    let formIsValid = true;
    debugger

    if (invoicePaymentStatus == "Paid") {
      debugger
      // if (!invoicePaidAmount) {
      //   formIsValid = false;
      //   setInvoicePaidAmountError("Please enter paid amount");
      // } else {
      //   setInvoicePaidAmountError("");
      // }

      if (!invoicePaymentDate) {
        formIsValid = false;
        setInvoicePaymentDateError("Please enter payment date");
      } else {
        setInvoicePaymentDateError("");
      }

      if (!invoicePaymentStatus) {
        formIsValid = false;
        setInvoicePaymentStatusError("Please enter payment status");
      } else {
        setInvoicePaymentStatusError("");
      }

      if (!invoicePaymentMode) {
        formIsValid = false;
        setInvoicePaymentModeError("Please enter payment mode");
      } else {
        setInvoicePaymentModeError("");
      }

      if (!invoicePaidBy) {
        formIsValid = false;
        setInvoicePaidByError("Please enter paid by");
      } else {
        setInvoicePaidByError("");
      }

      if (!invoiceTransactionId) {
        formIsValid = false;
        setInvoiceTransactionIdError("Please enter transaction id");
      } else {
        setInvoiceTransactionIdError("");
      }
    }

    return formIsValid;
  };

  return (
    <Layout>
      <div>
        <div>
          <Toaster />
          <div class="bg-gray-100 ">
            <div class="container mx-auto">
              <div className="flex flex-wrap ml-8">
                <div className=" cursor-pointer  shadow-lgrounded-xl">
                  <Link
                    to={"/outstation"}
                    className="flex items-center justify-center w-10 h-10 text-black"
                  >
                    <IoMdArrowRoundBack className="w-6 h-6 text-black" />
                  </Link>
                </div>
              </div>
              <div class="flex justify-center m-2">
                <h1 class="text-3xl font-bold">Trip Payment Details</h1>
              </div>
              <div class="grid grid-cols-3 gap-8">
                <div class="bg-white p-8 rounded-lg shadow-md">
                  <h2 class="text-xl font-bold m-2">Trip Details</h2>
                  <p>
                    <span class="font-semibold m-2">Route ID:</span>
                    {routeId}
                  </p>
                  <p>
                    <span class="font-semibold m-2">From Location:</span>
                    {fromLocation}
                  </p>
                  <p>
                    <span class="font-semibold m-2">To Location:</span>
                    {toLocation}
                  </p>
                  <p>
                    <span class="font-semibold m-2">Trip Start Date:</span>
                    {tripStartDate}
                  </p>
                  <p>
                    <span class="font-semibold m-2">Trip End Date:</span>
                    {tripEndDate}
                  </p>
                  <p>
                    <span class="font-semibold m-2">Company Name:</span>
                    {companyName}
                  </p>
                </div>
                <div class="bg-white p-8 rounded-lg shadow-md">
                  <h2 class="text-xl font-bold m-2">Trip Summary</h2>
                  <p>
                    <span class="font-semibold m-3">No of Guest:</span>{" "}
                    {noOfGuest}
                  </p>
                  <p>
                    <span class="font-semibold m-3">Vehicle Type:</span>{" "}
                    {vehicleType}
                  </p>
                  <p>
                    <span class="font-semibold m-3">Pickup Time:</span>{" "}
                    {pickupTime}
                  </p>
                  <p>
                    <span class="font-semibold m-3">Drop Time:</span> {dropTime}
                  </p>
                  <p>
                    <span class="font-semibold m-3">Trip Type:</span>
                    {tripType}
                  </p>
                  <p>
                    <span class="font-semibold m-3">Trip Amount:</span>
                    {tripAmount}
                  </p>
                </div>
                <div class="bg-white p-8 rounded-lg shadow-md">
                  <h2 class="text-xl font-bold m-2">
                    Driver &amp; Vehicle Details
                  </h2>
                  <p>
                    <span class="font-semibold m-2">Driver ID:</span>
                    {driverId}
                  </p>
                  <p>
                    <span class="font-semibold m-2">Driver Name:</span>{" "}
                    {driverName}
                  </p>
                  <p>
                    <span class="font-semibold m-2">Vehicle Number:</span>{" "}
                    {driverVehicleNumber}
                  </p>
                  <p>
                    <span class="font-semibold m-2">Vehicle Type:</span>{" "}
                    {driverVehicleType}
                  </p>
                  <p>
                    <span class="font-semibold m-2">Vehicle Name:</span>{" "}
                    {driverVehicleName}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-100 mt-4">
          <div class="container mx-auto shadow-lg p-2x">
            <div class="bg-white p-8 rounded-lg shadow-md">
              {!viewPage && (
                <div className="flex justify-end">
                  <Link>
                    <button onClick={() => setViewPage(true)}>
                      <img src={EditImage} alt="Edit" />
                    </button>
                  </Link>
                </div>
              )}
              <div class="flex flex-wrap  lg:flex-nowrap   justify-around items-center w-full">
                <InputBoxWithLabel
                  label="Paid Amount"
                  id="paid-amount"
                  type="text"
                  placeholder="Enter paid amount"
                  onChange={(e) => setInvoicePaidAmount(e.target.value)}
                  value={invoicePaidAmount}
                  disabled={!viewPage}
                  validationError={invoicePaidAmountError}
                />
                <InputBoxWithLabel
                  label="Payment Date"
                  id="payment-date"
                  type="date"
                  placeholder="Select payment date"
                  onChange={(e) => setInvoicePaymentDate(e.target.value)}
                  value={invoicePaymentDate}
                  disabled={!viewPage}
                  validationError={invoicePaymentDateError}
                />

                <Dropdown
                  lableName={"Payment Status"}
                  placeholder={"Select Payment Status"}
                  updateValue={setInvoicePaymentStatus}
                  options={[
                    { name: "Paid", id: "Paid" },
                    { name: "Not Paid", id: "Not Paid" },
                    { name: "On Hold", id: "On Hold" },
                  ]}
                  validationError={invoicePaymentStatusError}
                  objName={"name"}
                  disabled={!viewPage}
                  value={invoicePaymentStatus}
                />
              </div>
              <div class="flex flex-wrap  lg:flex-nowrap   justify-around items-center w-full">
                <Dropdown
                  lableName={"Payment Mode"}
                  placeholder={"Select Payment Status"}
                  updateValue={setInvoicePaymentMode}
                  options={[
                    { name: "Cash", id: "Cash" },
                    { name: "Gpay", id: "Gpay" },
                    { name: "Amazon Pay", id: "Amazon Pay" },
                    { name: "Phone pe", id: "Phone pe" },
                    { name: "Paytm", id: "Paytm" },
                    { name: "Acount Transfer", id: "Acount Transfer" },
                    { name: "Others", id: "Others" },
                  ]}
                  validationError={invoicePaymentModeError}
                  objName={"name"}
                  disabled={!viewPage}
                  value={invoicePaymentMode}
                />
                <InputBoxWithLabel
                  label="Transaction ID"
                  id="paid-amount"
                  type="text"
                  placeholder="Enter Transaction ID"
                  onChange={(e) => setInvoiceTransactionId(e.target.value)}
                  value={invoiceTransactionId}
                  disabled={!viewPage}
                  validationError={invoiceTransactionIdError}
                />

                <Dropdown
                  lableName={"Paid By"}
                  placeholder={"Select Paid by"}
                  updateValue={setInvoicePaidBy}
                  options={payerList}
                  validationError={invoicePaidByError}
                  objName={"payerName"}
                  // isKey={false}
                  disabled={!viewPage}
                  value={invoicePaidBy}
                  // isValueObj={true}
                  valueKey={"payerName"}
                />
              </div>

              {viewPage && (
              <div className="flex justify-center mt-4">
                <Link
                  to="/outsatation"
                  className="px-4 py-2 mr-2 text-white bg-blue-500 rounded"
                  onClick={handleSubmit}
                >
                  Submit
                </Link>
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
