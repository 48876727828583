import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import html2pdf from "html2pdf.js";
import axios from "axios";
import Dropdown from "../../Driver/DropDown";
import InputBoxWithLabel from "../../Driver/LabelInput";
import AccountPopup from "./Account Popup/AccountPopup";
import DownloadPdfButton from "./Account Popup/download-pdf.png"

export default function Account({
  setRowsAccount,
  rowsAccount,
  fromDateAccount,
  setFromDateAccount,
  toDateAccount,
  setToDateAccount,
}) {
  const [companyList, setCompanyList] = useState([]);
  const [companyId, setCompanyId] = useState(
    localStorage.getItem("companyIdAccount") || ""
  );

  useEffect(() => {
    localStorage.setItem("companyIdAccount", companyId);
  }, [companyId]);

  const getCompany = async () => {
    try {
        const response = await axios.get(
            window.api + "company/getCompanyList",
            {
                headers: {
                    "x-access-token": localStorage.getItem("accessToken"),
                    "email": localStorage.getItem("email"),
                    "usertype": localStorage.getItem("userType")
                }
            }
        );
        
        if (response.status === 200) {
            const companyList = response.data.data;
            setCompanyList(companyList);
        }
    } catch (error) {
        console.error("Error getting company list:", error);
        
        if (error.response && error.response.status === 401) {
            localStorage.clear();
            window.location.href = '/';
        }
    }
};


  window.onbeforeunload = () => {
    localStorage.removeItem("fromDateAccount");
    localStorage.removeItem("toDateAccount");
    localStorage.removeItem("companyIdAccount");
  };

  useEffect(() => {
    getCompany();
    getTrips();
  }, [companyId, fromDateAccount, toDateAccount]);

  const getTrips = async () => {
    try {
      const response = await axios.get(
        window.api + "report/getTripsAccount",
        {
          headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "email": localStorage.getItem("email"),
            "usertype": localStorage.getItem("userType")
          },
          params: {
            fromDateAccount,
            toDateAccount,
            companyId,
          },
        }
      );
  
      if (response.status === 200) {
        const trips = response.data.data.data;
        setRowsAccount(trips);
      } else {
        setRowsAccount([]);
      }
    } catch (e) {
      console.error("Error getting trip accounts:", e);
      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      } else {
        // handle other types of errors if necessary
      }
    }
  };
  

  let formattedFromDate = "N/A";
  let formattedToDate = "N/A";

  if (fromDateAccount && toDateAccount) {
    const fromDate = new Date(fromDateAccount);
    const toDate = new Date(toDateAccount);

    // Format the dates as "dd/mm/yyyy"
    formattedFromDate = `${fromDate.getDate()}/${
      fromDate.getMonth() + 1
    }/${fromDate.getFullYear()}`;
    formattedToDate = `${toDate.getDate()}/${
      toDate.getMonth() + 1
    }/${toDate.getFullYear()}`;
  }

  const tableRows = rowsAccount.map((item) => {
    return `
        <tr>
          <td class="  border-solid border-black py-4 text-center">${item.serialNumber}</td>
          <td class=" border-solid border-black py-4 text-center">${item.companyName}</td>
          <td class=" border-solid border-black py-4 text-center">${item.invoicePaidBy}</td>
          <td class=" border-solid border-black py-4 text-center">${item.totalCountOfInvoicePaidByTrips}</td>
          <td class=" border-solid border-black py-4 text-center">${item.sumOfInvoicePaidAmount}</td>
        </tr>
      `;
  });

  const sumOfAmount = rowsAccount.reduce(
    (total, item) => total + item.sumOfInvoicePaidAmount,
    0
  );

  const sumOfTotalTrip = rowsAccount.reduce(
    (total, item) => total + item.totalCountOfInvoicePaidByTrips,
    0
  );
    const [showPopup, setShowPopup] = useState(false);
    const [data, setData] = useState({}); 
 
    const htmlContent = `<!DOCTYPE html>
    <html lang="en">
    
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Document</title>
        <script src="https://cdn.tailwindcss.com"></script>
    
        <style>
@page {
size: A4;
margin: 0;
}

body {
font-family: Arial, sans-serif;
margin: 0;
padding: 0;
}

.container {
max-width: 1294px;
height: 1122px;
margin: 0 auto;
padding: 30px;
}

.logo img {
width: 75px;
}

.header {
display: flex;
align-items: center;
margin-bottom: 30px;
}

.header h1 {
font-size: 20px;
font-weight: bold;
margin-left: 10px;
}

.title {
text-align: center;
margin-bottom: 30px;
}

.title h1 {
font-size: 30px;
font-weight: bold;
margin-bottom: 10px;
}

.title hr {
background-color: #000;
height: 2px;
width: 270px;
margin: 0 auto;
border: none;
margin-bottom: 30px;
}

.info {
display: flex;
justify-content: space-between;
margin-bottom: 10px;
}

.info h5 {
font-size: 14px;
font-weight: bold;
}

.table-container {
margin-bottom: 30px;
}

table {
width: 100%;
border-collapse: collapse;
font-size: 14px;
}

th,
td {
border: 1px solid #000;
border-collapse: collapse;
padding: 10px;
text-align: center;
}

.total-row td:first-child {
border-left: none;
/* Remove the left border */
}



.signature {
display: flex;
justify-content: flex-end;
margin-top: 360px;
padding-right: 7px;
font-weight: bold;
}

</style>

    </head>
    
    <body class="w-1200">
        <div class="container w-1200">
            <div class="pl-6 py-4  w-full w-1200  justify-start items-start flex flex-col ">
               
                <img class="ml-2 " width="75px" src="${logo}" alt="Logo">
    
                <h1 class="text-base font-semibold  ">Chennai Cabs</h1>
            </div>
    
    
            <div class="title">
                <h1>Accounts Summary</h1>
                <hr>
            </div>
    
            <div class="info">
                <div>
                    <h5>From Date: ${formattedFromDate}</h5>
                </div>
                <div>
                    <h5>To Date: ${formattedToDate}</h5>
                </div>
                <div>
                    <h5>Date: ${getCurrentDate()}</h5>
                </div>
            </div>
            <div class=" flex justify-center items-center bg-black h-[2px] w-full"></div>
            <div class="table-container  mt-10 w-1200">
                <table>
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>Project Name</th>
                            <th>Paid By</th>
                            <th>Total No of Trips</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${tableRows.join("")}
                        <tr class="total-row">
                            <td colspan="2" class="border-none"></td>
                            <td class=" text-center font-bold">Total</td>
                            <td>${sumOfTotalTrip}</td>
                            <td>₹ ${sumOfAmount}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
    
            <div class="signature">
                <div>
                    Authorised Signature
                </div>
            </div>
        </div>
    </body>
    
    </html>`;
  const showPdf = () => {

    setShowPopup(true);

    setData(htmlContent);
    // html2pdf()
    //   .from(htmlContent)
    //   .save(`Accounts Report ${getCurrentDate()}.pdf`, () => {
    //     console.log("PDF saved successfully!");
    //   });
  };
    const handleDownloadPDF = () => {
  
      const element = document.createElement('div');
  element.innerHTML = htmlContent;

  // Use html2pdf to convert the element
  html2pdf()
    .from(element)
    .set({
      margin: 10,
      filename: 'document.pdf',
      html2canvas: { scale: 2 },
      jsPDF: { orientation: 'portrait', unit: 'mm', format: 'a4' }
    })
    .save();
  } 

  function getCurrentDate() {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();
    return `${day}/${month}/${year}`;
  }

  return (
    <>
      <div>
        <div className="flex flex-wrap justify-around rounded-lg">
          <div className="flex flex-wrap items-center justify-around w-full bg-white rounded-lg  shadow-lg h-auto mb-4 pb-2  labelInputContainer">
            <Dropdown
              lableName={"Company"}
              options={companyList}
              updateValue={setCompanyId}
              placeholder={"Company"}
              widthForSelect={"w-[280px]"}
              objName={"companyName"}
              value={companyId}
            />
            <InputBoxWithLabel
              label={"From Date"}
              type={"date"}
              value={fromDateAccount}
              onChange={(e) => {
                setFromDateAccount(e.target.value);
                localStorage.setItem("fromDateAccount", e.target.value);
              }}
            />
            <InputBoxWithLabel
              label={"To Date"}
              type={"date"}
              value={toDateAccount}
              onChange={(e) => {
                setToDateAccount(e.target.value);
                localStorage.setItem("toDateAccount", e.target.value);
              }}
            />{" "}
            <button onClick={showPdf} className="flex mb-5 mt-9 ">
              <Link className="bg-[#00adb5] flex justify-between items-center  hover:bg-[#059299] active:bg-[#047481] text-gray-100 text-xl font-bold text-center p-3 pl-5 pr-5 rounded-lg ">
                <span className="mr-2">Download Now</span>

                <svg
                  className="text-2xl "
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-download"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg>
              </Link>
            </button>
          </div>
          <div></div>
        </div>
      </div>
      <div className=" w-screen">
         <AccountPopup
      visible={showPopup}
      data={data}
      onClose={() => setShowPopup(false)}
      handleDownloadPDF={handleDownloadPDF}      
      />
      </div>
     
    </>
  );
}
