import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import avatar from "../../assets/images/Avatar.svg";
import arrowRightBlue from "../../assets/images/arrow-right.svg";
import calendar from "../../assets/images/calendar.svg";
import InputBoxWithLabel from "../Driver/LabelInput";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import moment from "moment-timezone";
import momentTimezone from "moment-timezone";

export default function Balance({
  tableOpener,
  setPayerSelected,
  setFilterDate,
  setPayerSelectedName,
  totalCurrentBalance,
  payerList,
  getPayer,
}) {
  const [showPopUp, setShowPopUp] = React.useState(false);
  const [payerName, setPayerName] = useState("");
  const [payerNameError, setPayerNameError] = useState("");

  const handleSaveClick = async () => {
    if (handleFormValidation()) {
      try {
        const data = {
          payerName: payerName,
        };
  
        const response = await axios.post(
          window.api + "passbook/createPayer",
          data,
          {
            headers: {
              "x-access-token": localStorage.getItem("accessToken"),
              "email": localStorage.getItem("email"),
              "usertype": localStorage.getItem("userType")
            }
          }
        );
        
        if (response.status === 200) {
          toast("New Payer Added Successfully!", {
            icon: "👏",
            style: {
              borderRadius: "7px",
              background: "#222831",
              color: "#fff",
            },
          });
          setShowPopUp(false);
          getPayer();
        }
      } catch (e) {
        console.error("Errors adding payer: ", e);
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          toast.error(e.response.data.message, {
            autoClose: false,
            closeButton: true,
            className: "toast-error",
            bodyClassName: "toast-error-body",
          });
        }
      }
    }
  };
  

  const handleFormValidation = () => {
    let formIsValid = true;

    if (!payerName) {
      formIsValid = false;
      setPayerNameError("Please enter payer name");
    } else {
      setPayerNameError("");
    }
    return formIsValid;
  };

  const handlePopUp = () => {
    setShowPopUp(!showPopUp);
  };

  const handleClick = (id, payerName, date) => {
    setPayerSelected(id);
    setPayerSelectedName(payerName);
    setFilterDate(momentTimezone(date).tz("Asia/Kolkata").format("YYYY-MM-DD"));
    tableOpener();
  };

  return (
    <div className="w-full h-full p-0  lg:p-5 ">
      <Toaster />
      <div className="flex justify-between h-full w-full rounded-lg items-center  bg-white py-2 pr-5 mb-5 mr-10 ">
        <div className="flex flex-col items-center w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-[30%]">
          <div className="p-2 mx-2 my-3 bg-teal-500 rounded-lg">
            <h1 className="p-1 font-bold text-[16px] text-center">
              Total Current Balance
            </h1>
            <h1 className="text-3xl font-bold text-center text-white">
              ₹ {totalCurrentBalance ? totalCurrentBalance : "0"}
            </h1>
          </div>
        </div>

        <div className="flex justify-end w-full  items-center  pr-5  ">
          <Link
            onClick={handlePopUp}
            className="bg-[#00adb5] border-4 border-[#EEEEEE]  border-solid flex justify-between items-center  hover:bg-[#059299] active:bg-[#047481] text-gray-100  text-[16px] font-semibold text-center py-3  px-8 rounded-xl "
          >
            <span className="text-center ">ADD PERSON</span>
          </Link>
        </div>
      </div>
      <div class=" flex justify-between   gap-5 lg:gap-3 items-center flex-wrap w-full ">
        {payerList.map((item) => (
          <div class=" w-full lg:w-[30%]  m-0  lg:my-3  lg:mx-1 h-auto lg:h-[145px] flex flex-col justify-between rounded-lg border border-solid border-gray-300 bg-white shadow-md">
            <div class="flex justify-between  px-0 lg:px-2 items-center h-full">
              <div class="w-4/12 flex justify-center items-center h-full">
                <div class="avatar w-20 h-20 bg-white rounded-full">
                  <img src={avatar} class="w-full h-full" alt="" />
                </div>
              </div>
              <div class="w-7/12">
                <div class="text-[#175CD3] pl-[6px] font-semibold font-roboto  text-md leading-6">
                  {item.payer && item.payer.payerName
                    ? item.payer.payerName
                    : "N/A"}
                </div>
                <div class="text-[#667085] font-roboto text-sm font-medium leading-6">
                  Opening Balance:
                  <span className=" text-[#C11574] font-bold">
                    {item.openingBalance}
                  </span>
                </div>
                <div class="text-[#667085] pl-[6px] font-roboto text-sm font-medium leading-6">
                  {momentTimezone().tz("Asia/Kolkata").format("DD-MM-YYYY") ===
                  momentTimezone(item.date)
                    .tz("Asia/Kolkata")
                    .format("DD-MM-YYYY")
                    ? "Current Balance"
                    : "Closing Balance"}
                  :
                  <span className="text-[#039855] font-bold">
                    {item.currentBalance}
                  </span>
                </div>
              </div>
            </div>
            <div class="bg-gray-100 flex items-center justify-center border-t border-solid border-gray-300 rounded-b-lg w-full h-10">
              <div class="w-full flex justify-between  px-5 items-center">
                <div className="flex items-center justify-start ">
                  <img src={calendar} className="w-5 h-5 " alt="" />
                  <div className=" text-[#4E5664] pl-2   font-semibold">
                    {momentTimezone(item.date)
                      .tz("Asia/Kolkata")
                      .format("DD-MM-YYYY")}
                  </div>
                </div>
                <button
                  // onClick={() => tableOpener()}
                  onClick={() =>
                    handleClick(item.payerId, item.payer.payerName, item.date)
                  }
                  class="text-blue-600 px-4 my-3 rounded-md h-6 flex justify-center items-center bg-blue-200 font-inter text-sm font-semibold leading-8"
                >
                  View
                  <img class="ml-1" src={arrowRightBlue} alt="" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {showPopUp && (
        <div
          className={`  fixed left-0 top-0    bg-[#2b2a2a69] w-screen  h-screen  overflow-y-scroll overflow-scroll overscroll-y-auto py-2  flex justify-center items-center`}
        >
          <div className=" h-auto w-40  lg:w-[920px]  flex flex-wrap justify-center items-center px-16 ">
            <div
              class={`w-[900px] h-full    rounded-[8px]  bg-white  shadow-box `}
            >
              <div className="flex relative flex-col p-5 w-full rounded-[8px] h-full   ">
                <div className="flex justify-end mx-[30px] ">
                  <button
                    onClick={handlePopUp}
                    className="flex justify-center items-center bg-[#D9D9D985] rounded-full w-[40px] h-[40px]"
                  >
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 1L1 16M1 1L16 16"
                        stroke="#344054"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className="text  text-center text-[#101828] font-bold">
                  Add Payer Details
                </div>
                <div className="flex items-center justify-center w-full h-full gap-10 mt-5 ">
                  <div className="flex items-center justify-center w-full px-8">
                    <InputBoxWithLabel
                      label={"Payer Name"}
                      placeholder={"Enter Payer Name"}
                      onChange={(e) => setPayerName(e.target.value)}
                      // value={vehicleNo}
                      type="text"
                      validationError={payerNameError}
                    />
                  </div>
                </div>

                <div className="flex justify-center mt-10 font-semibold tracking-normal text-center item font-roboto text-12 leading-20">
                  <button
                    onClick={handleSaveClick}
                    className=" bg-[#175CD3] text-white w-[77px] h-[30px] border border-gray-500 rounded-md "
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
