import React, { useEffect, useState } from "react";
import axios from "axios";
import backButton from "../../assets/Icons/IconsForPages/backArrow.png";
import avatar from "../../assets/images/Avatar.svg";
import Table from "../Driver/Table";
import { Link } from "react-router-dom";
import ExcelJS from "exceljs";
import momentTimezone from 'moment-timezone';

export default function TableForBalance({
  balanceOpener,
  payerSelected,
  filterDate,
  payerSelectedName,
  getPayer,
}) {
  const [toDate, setToDate] = useState(filterDate);
  const [fromDate, setFromDate] = useState(filterDate);
  const [balanceList, setBalanceList] = useState([]);

  useEffect(() => {
    getBalanceData();
  }, [fromDate, toDate]);

  const getBalanceData = async () => {
    try {
      const response = await axios.get(window.api + "passbook/getBalanceData", {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          "email": localStorage.getItem("email"),
          "usertype": localStorage.getItem("userType")
        },
        params: {
          toDate,
          fromDate,
          payerSelected,
        },
      });
  
      if (response.status === 200) {
        const balanceData = response.data.data;
        const dataWithSrNo = balanceData.map((item, index) => ({
          ...item,
          srNo: index + 1,
        }));
        setBalanceList(dataWithSrNo);
      } else {
        setBalanceList([]);
      }
    } catch (e) {
      console.error("Error getting balance data:", e);
      setBalanceList([]);
      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      }
    }
  };
  

  const columns = [
    {
      name: "Sr No",
      sortable: true,
      cell: (row) => (
        <div className="text-[#3C4048] text-center font-medium text-sm font-roboto">
          <span className="text-[#3C4048] uppercase font-medium">
            {row.srNo}
          </span>
        </div>
      ),
    },
    {
      name: "Date",
      selector: "paidDate",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#393e46] text-center w-full font-medium text-sm font-roboto">
          <span className="text-[#393e46] uppercase  font-medium">
            {momentTimezone(row.paidDate).tz('Asia/Kolkata').format('DD-MM-YYYY')}
          </span>
        </div>
      ),
    },
    {
      name: "Description",
      selector: "description",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#393e46] text-left w-full font-medium text-sm font-roboto">
          <span className="text-[#393e46] uppercase  font-medium">
            {row.description}
          </span>
        </div>
      ),
    },
    {
      name: "Transaction ID",
      selector: "transactionId",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#393e46] text-center w-full font-medium text-sm font-roboto">
          <span className="text-[#393e46] uppercase  font-medium">
            {row.transactionId}
          </span>
        </div>
      ),
    },
    {
      name: "Credit",
      selector: "paidAmount",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-green-500 text-center w-full font-medium text-sm font-roboto">
          <span className="text-green-500 uppercase font-medium">
            {row.paidAmount}
          </span>
        </div>
      ),
    },
    {
      name: "Debit",
      selector: "debitAmount",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-red-500 text-center w-full font-medium text-sm font-roboto">
          <span className="text-red-500 uppercase font-medium">
            {row.debitAmount}
          </span>
        </div>
      ),
    },
    {
      name: "Balance",
      selector: "balance",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#393e46] text-center w-full font-medium text-sm font-roboto">
          <span className="text-[#393e46] uppercase  font-medium">
            {row.balance}
          </span>
        </div>
      ),
    },
  ];

  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Define header style
    const headerStyle = {
      font: { bold: true },
      alignment: { horizontal: "center" },
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "C0C0C0" } },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
      numFmt: "dd-mmm-yyyy", // Format the date if needed
      textRotation: 90, // Rotate the header text if needed
    };

    // Define cell style
    const cellStyle = {
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "DDDDDD" } },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
    };

    // Define headers and columns
    const headers = [
      "Sr. No.",
      "Date",
      "Description",
      "Transaction ID",
      "Credit",
      "Debit",
      "Balance",
    ];
    const columns = [
      { key: "srNo", header: "Sr. No." },
      { key: "paidDate", header: "Date" },
      { key: "description", header: "Description" },
      { key: "transactionId", header: "Transaction ID" },
      { key: "paidAmount", header: "Credit" },
      { key: "debitAmount", header: "Debit" },
      { key: "balance", header: "Balance" },
    ];

    // Add header row
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.style = headerStyle;
    });

    balanceList.forEach((row, index) => {
      const dataRow = worksheet.addRow([
        row["srNo"], // Use 'srNo' key
        new Date(row["paidDate"]).toLocaleDateString(), // Format the 'paidDate' to a readable date string
        row["description"], // Use 'description' key
        row["transactionId"], // Use 'transactionId' key
        row["paidAmount"], // Use 'paidAmount' key
        row["debitAmount"], // Use 'debitAmount' key
        row["balance"], // Use 'balance' key
      ]);
      dataRow.eachCell((cell) => {
        cell.style = cellStyle;
        if (cell.col === 1) {
          cell.alignment = { horizontal: "center" };
        }
      });
    });
    // Auto-fit column widths
    columns.forEach((column, index) => {
      const columnWidth =
        headers[index].length < column.header.length
          ? column.header.length
          : headers[index].length;
      worksheet.getColumn(index + 1).width =
        columnWidth < 12 ? 12 : columnWidth;
    });

    // Generate buffer
    const excelBuffer = await workbook.xlsx.writeBuffer();

    // Create and download the file
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}${currentDate
      .toLocaleString("default", { month: "long" })
      .toLowerCase()}${currentDate.getFullYear()}`;
    const fileName = `balance_report_${payerSelected}_${formattedDate}.xlsx`;

    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
  };

  return (
    <>
      <div className="w-full h-full py-2 ">
        <div className=" w-10 pl-1 mb-2  justify-center items-center flex cursor-pointer  shadow-lgrounded-xl">
          <button
            onClick={() => balanceOpener()}
            className="flex items-center justify-center w-10 h-10 text-white"
          >
            <img src={backButton} className="w-5" alt="" srcset="" />
          </button>
        </div>{" "}
        <div className="flex flex-wrap items-center justify-between w-full rounded-lg shadow-lg lg:flex-nowrap">
          <div className="flex items-center justify-start w-1/2 h-full ml-2 labelInput sm:w-1/2 md:w-1/3 lg:w-1/3">
            <div class="w-4/12 ml-3 flex justify-center items-center h-full">
              <div class="avatar w-20 h-20 bg-white rounded-full">
                <img src={avatar} class="w-full h-full" alt="" />
              </div>
            </div>
            <div class="text-[#175CD3] pl-[6px] font-semibold font-roboto  text-md leading-6">
              {payerSelectedName}
            </div>
          </div>
          <div className="flex items-center justify-around w-7/12 ">
            <div className="flex mb-5 mt-9 w-full ">
              <button onClick={handleExport}>
                <Link className="bg-[#00adb5] flex justify-between items-center  hover:bg-[#059299] active:bg-[#047481] text-gray-100 text-xl font-bold text-center p-2 pl-5 pr-5 rounded-lg ">
                  <span className="mr-2">Download Now</span>

                  <svg
                    className="text-2xl "
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-download"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                </Link>
              </button>
            </div>
            <div className="flex flex-col w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-full">
              <div className="flex flex-col w-auto h-full m-5 ">
                <label className="pl-1 font-bold ">From Date</label>
                <input
                  type="date"
                  className="font-medium font-roboto text-sm bg-white text-[#393e46] border-2 border-[#D6D6D6] focus:bg-white focus:border-[#393E46] hover:border-gray-500 px-2 py-2 rounded-lg shadow leading-tight focus:outline-none focus:shadow-outline"
                  id="from"
                  onChange={(e) => setFromDate(e.target.value)}
                  // value={moment.utc(fromDate).format("YYYY-MM-DD")}
                  value={momentTimezone(fromDate).tz('Asia/Kolkata').format('YYYY-MM-DD')}
                />
              </div>
            </div>
            <div className="flex flex-col w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-full">
              <div className="flex flex-col w-auto h-full m-5 ">
                <label className="pl-1 font-bold ">To Date</label>
                <input
                  type="date"
                  className="font-medium font-roboto text-sm bg-white text-[#393e46] border-2 border-[#D6D6D6] focus:bg-white focus:border-[#393E46] hover:border-gray-500 px-2 py-2 rounded-lg shadow leading-tight focus:outline-none focus:shadow-outline"
                  id="to"
                  onChange={(e) => setToDate(e.target.value)}
                  value={toDate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-full  py-2  ">
        <Table
          columns={columns}
          rows={balanceList}
          headerStyle={[
            " text-center   ",
            "text-center ",
            "text-left pl-0 ",
            "text-center",
          ]}
        />
      </div>
    </>
  );
}
