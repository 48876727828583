import React, { useEffect } from "react";
import Table from "../../Driver/Table";
import eye from "../../../assets/Icons/eye.png";
import { Link } from "react-router-dom";
import axios from "axios";
import Dropdown from "../../Driver/DropDown";
import InputBoxWithLabel from "../../Driver/LabelInput";
import DateUtils from "../../../Utility/DateUtils";
import ExcelJS from "exceljs";

export default function OutstationTripTable() {
  const [outstationTripList, setOutstationTripList] = React.useState([]);
  const [companyList, setCompanyList] = React.useState([]);
  const [rowsXl, setRowsXl] = React.useState([]);
  const [companyId, setCompanyId] = React.useState(
    localStorage.getItem("companyIdOutstationTrip") || ""
  );
  const [fromDate, setFromDate] = React.useState(
    localStorage.getItem("fromDateOutstationTrip") || DateUtils.getCurrentDate
  );
  const [toDate, setToDate] = React.useState(
    localStorage.getItem("toDateOutstationTrip") || DateUtils.getCurrentDate
  );
  const [openButton, setOpenButton] = React.useState(1);
  const [tripStatus, setTripStatus] = React.useState("Not Alloted");


  window.onbeforeunload = () => {
    localStorage.removeItem("companyIdOutstationTrip");
    localStorage.removeItem("fromDateOutstationTrip");
    localStorage.removeItem("toDateOutstationTrip");
  };

  useEffect(() => {
    getCompany();
  }, []);

  const getCompany = async () => {
    try {
        const response = await axios.get(
            window.api + "company/getCompanyList",
            {
                headers: {
                    "x-access-token": localStorage.getItem("accessToken"),
                    "email": localStorage.getItem("email"),
                    "usertype": localStorage.getItem("userType")
                }
            }
        );
        
        if (response.status === 200) {
            const companyList = response.data.data;
            setCompanyList(companyList);
        }
    } catch (error) {
        console.error("Error getting company list:", error);
        
        if (error.response && error.response.status === 401) {
            localStorage.clear();
            window.location.href = '/';
        }
    }
};

  useEffect(() => {
    getAllOutstationTrip();
  }, [companyId, fromDate, toDate, tripStatus]);

  const getAllOutstationTrip = async () => {
    try {
      const response = await axios.get(window.api + "outstation/getAllOutstationTrip", {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          "email": localStorage.getItem("email"),
          "usertype": localStorage.getItem("userType")
        },
        params: {
          companyId: companyId,
          fromDate: fromDate,
          toDate: toDate,
          tripStatus: tripStatus,
        }
      });
    
      if (response.status === 200) {
        const list = response.data.data;
        setOutstationTripList(list);
    
        const manipulatedArray = list.map((item) => ({
          "Sr. No.": item.serialNumber,
          "Route ID": item.routeId.toUpperCase(),
          "Company Name": item.company.companyName.toUpperCase(),
          "Guest Name": item.guestName.toUpperCase(),
          "Trip Type": item.tripType.toUpperCase(),
          "Guest Contact Number": item.guestContactNumber,
          "Pickup Location": item.pickupLocation.toUpperCase(),
          "No of Days": item.noOfDays,
          "Vehicle Type": item.vehicleType.toUpperCase(),
          "Vehicle Name": item.vehicleName.toUpperCase(),
        }));
    
        const fixedSequenceArray = manipulatedArray.map((item) => ({
          "Sr. No.": item["Sr. No."],
          "Route ID": item["Route ID"],
          "Company Name": item["Company Name"],
          "Guest Name": item["Guest Name"],
          "Trip Type": item["Trip Type"],
          "Guest Contact Number": item["Guest Contact Number"],
          "Pickup Location": item["Pickup Location"],
          "No of Days": item["No of Days"],
          "Vehicle Type": item["Vehicle Type"],
          "Vehicle Name": item["Vehicle Name"],
        }));
    
        setRowsXl(fixedSequenceArray);
      }
    } catch (e) {
      console.error("Error getting outstation list:", e);
      setOutstationTripList([]);
      setRowsXl([]);
      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      }
    }
    
  };

  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Define header and cell styles
    const headerStyle = {
      font: { bold: true },
      alignment: { horizontal: "center" },
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "C0C0C0" } },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
      numFmt: "dd-mmm-yyyy",
      textRotation: 90,
    };

    const cellStyle = {
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "DDDDDD" } },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
    };

    // Define headers based on rowsXl structure
    const headers = Object.keys(rowsXl[0]);

    // Add header row
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.style = headerStyle;
    });

    // Add data rows based on rowsXl structure
    rowsXl.forEach((row) => {
      const rowData = headers.map((header) => row[header]);
      const dataRow = worksheet.addRow(rowData);
      dataRow.eachCell((cell) => {
        cell.style = cellStyle;
        if (cell.col === 1) {
          cell.alignment = { horizontal: "center" };
        }
      });
    });

    // Auto-fit column widths
    headers.forEach((header, index) => {
      const columnWidth = header.length;
      worksheet.getColumn(index + 1).width =
        columnWidth < 12 ? 12 : columnWidth;
    });

    // Generate buffer and download
    const excelBuffer = await workbook.xlsx.writeBuffer();
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}${currentDate
      .toLocaleString("default", { month: "long" })
      .toLowerCase()}${currentDate.getFullYear()}`;
    const fileName = `Outstation-Trip-${formattedDate}.xlsx`;

    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
  };

  const columns = [
    {
      name: "Sr.No.",
      selector: "serialNumber",
      sortable: true,
      width: "10%",
      cell: (row) => (
        <div className="  w-full  py-3 text-[#393e46] text-center font-medium text-sm font-roboto">
          <div className="text-[#393e46] uppercase  font-medium">
            {row.serialNumber}
          </div>
        </div>
      ),
    },
    {
      name: "Route ID",
      selector: "routeID",
      sortable: true,
      cell: (row) => (
        <div className="py-4 text-[#3C4048] text-center w-full cursor-pointer font-medium text-sm font-roboto hover:transform hover:scale-125 hover:font-base transition duration-300 ease-in-out">
          <Link to={`/view-outstation/${row.id}`}>
            <span
              className={`py-4 text-center w-full text-sm font-roboto ${
                row.expiryAlert ? "text-red-600" : "text-[#323EDD]"
              } cursor-pointer font-medium`}
            >
              {row.routeId}
            </span>
          </Link>
        </div>
      ),
    },
    {
      name: "Company Name",
      selector: "companyName",
      sortable: true,
      cell: (row) => (
        <div className="  w-full  text-[#393e46]    font-medium text-sm font-roboto">
          <div className="text-[#393e46] text-center  uppercase font-medium">
            {row.company.companyName}
          </div>
        </div>
      ),
    },
    {
      name: "Guest Name",
      selector: "guestName",
      sortable: true,
      cell: (row) => (
        <div className="   py-3 text-[#393e46]   font-medium text-sm font-roboto">
          <div className="text-[#393e46] uppercase text-center font-medium">
            {row.guestName}
          </div>
        </div>
      ),
    },
    {
      name: "Trip Type",
      selector: "tripType",
      sortable: true,
      cell: (row) => (
        <div className=" flex justify-center items-center w-full  py-3 text-[#393e46] text-center font-medium text-sm font-roboto">
          <div className="text-[#393e46] uppercase  font-medium">
            {row.tripType}
          </div>
        </div>
      ),
    },
    {
      name: "Guest Contact Number",
      selector: "guestContactNumber",
      sortable: true,
      cell: (row) => (
        <div className=" flex justify-center  items-center w-full  py-3 text-[#393e46] text-center font-medium text-sm font-roboto">
          <div className="text-[#393e46] uppercase  font-medium">
            {row.guestContactNumber}
          </div>
        </div>
      ),
    },
    {
      name: "Pickup Location",
      selector: "pickupLocation",
      sortable: true,
      cell: (row) => (
        <div className=" flex justify-center  items-center w-full  py-3 text-[#393e46] text-center font-medium text-sm font-roboto">
          <div className="text-[#393e46] uppercase  font-medium">
            {row.pickupLocation}
          </div>
        </div>
      ),
    },
    {
      name: "No of Days",
      selector: "noOfDays",
      sortable: true,
      cell: (row) => (
        <div className=" flex justify-center  items-center w-full  py-3 text-[#393e46] text-center font-medium text-sm font-roboto">
          <div className="text-[#393e46] uppercase  font-medium">
            {row.noOfDays}
          </div>
        </div>
      ),
    },
    {
      name: "Vehicle Type",
      selector: "vehicleType",
      sortable: true,
      cell: (row) => (
        <div className=" flex justify-center  items-center w-full  py-3 text-[#393e46] text-center font-medium text-sm font-roboto">
          <div className="text-[#393e46] uppercase  font-medium">
            {row.vehicleType}
          </div>
        </div>
      ),
    },
    {
      name: "Vehicle Name",
      selector: "vehicleName",
      sortable: true,
      cell: (row) => (
        <div className=" flex justify-center  items-center w-full  py-3 text-[#393e46] text-center font-medium text-sm font-roboto">
          <div className="text-[#393e46] uppercase  font-medium">
            {row.vehicleName}
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className=" w-full">
        <div className="flex w-full flex-wrap justify-around rounded-lg">
          <div className="flex flex-wrap items-center justify-around w-full bg-white rounded-lg mt-10  shadow-lg h-auto mb-4 pb-2  labelInputContainer">
            <Dropdown
              lableName={"Company"}
              options={companyList}
              updateValue={setCompanyId}
              placeholder={"Company"}
              widthForSelect={"w-[280px]"}
              objName={"companyName"}
              value={companyId}
              local={"companyIdOutstationTrip"}
            />
            <InputBoxWithLabel
              label={"From Date"}
              type={"date"}
              value={fromDate}
              onChange={(e) => {
                setFromDate(e.target.value);
                localStorage.setItem("fromDateOutstationTrip", e.target.value);
              }}
            />
            <InputBoxWithLabel
              label={"To Date"}
              type={"date"}
              value={toDate}
              onChange={(e) => {
                setToDate(e.target.value);
                localStorage.setItem("toDateOutstationTrip", e.target.value);
              }}
            />{" "}
            <button onClick={handleExport} className="flex mb-5 mt-9 ">
              <Link className="bg-[#00adb5] flex justify-between items-center  hover:bg-[#059299] active:bg-[#047481] text-gray-100 text-xl font-bold text-center p-3 pl-5 pr-5 rounded-lg ">
                <span className="mr-2">Download Now</span>

                <svg
                  className="text-2xl "
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-download"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg>
              </Link>
            </button>
          </div>
          <div className="flex flex-col w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5">
            <div className="flex w-auto h-full mx-5">
              <button
                className={
                  "text-xs font-bold uppercase px-5 py-3  w-full rounded block leading-normal " +
                  (openButton === 1
                    ? "text-[#222831] bg-[#00ADB5] "
                    : "text-[#00ADB5] bg-[#222831]")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenButton(1);
                  setTripStatus("Not Alloted")
                  localStorage.setItem("openButton", 1);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                Not Alloted
              </button>
            </div>
          </div>
          <div className="flex flex-col w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5">
            <div className="flex w-auto h-full mx-5">
              <button
                className={
                  "text-xs font-bold uppercase px-5 py-3  w-full rounded block leading-normal " +
                  (openButton === 2
                    ? "text-[#222831] bg-[#00ADB5] "
                    : "text-[#00ADB5] bg-[#222831]")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenButton(2);
                  setTripStatus("Alloted");
                  localStorage.setItem("openButton", 2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                Alloted
              </button>
            </div>
          </div>
          <div className="flex flex-col w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5">
            <div className="flex w-auto h-full mx-5">
              <button
                className={
                  "text-xs font-bold uppercase px-5 py-3  w-full rounded block leading-normal " +
                  (openButton === 3
                    ? "text-[#222831] bg-[#00ADB5] "
                    : "text-[#00ADB5] bg-[#222831]")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenButton(3);
                  setTripStatus("Completed");
                  localStorage.setItem("openButton", 3);
                }}
              >
                Completed
              </button>
            </div>
          </div>
          <div className="flex flex-col invisible w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5"></div>
          <div className="flex flex-col invisible w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5"></div>
          <div className="flex flex-col invisible w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5"></div>
        </div>
      </div>

      <div className=" w-full">
        <div className="flex w-full flex-col gap-4 mt-8">
          <div className="table w-full h-auto ">
            <div className="w-full h-auto rounded-lg shadow-xl shadow-slate-250">
              <div className="flex w-full flex-col gap-6   rounded-lg shadow-md">
                <Table
                  columns={columns}
                  rows={outstationTripList}
                  headerStyle={[
                    "text-center pl-0 ",
                    "text-center pl-0 ",
                    " text-center pl-0 ",
                    "text-center pl-0 ",
                    "text-center pl-0 ",
                    "text-center pl-0 ",
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
