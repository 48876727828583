import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MdOutlineContentCopy } from "react-icons/md";
import TripCopy from "../TripManagement/TripCopy";
import UpdateTripStatus from "../TripManagement/UpdateTripStatus";
import { Toaster, toast } from "react-hot-toast";
import { format } from "date-fns";
import axios from "axios";
import Table from "../Driver/Table";

export default function TripList({ rows, getTrips }) {
  const [RouteId, setRouteId] = useState("");
  const [TripDate, setTripDate] = useState("");
  const [RouteName, setRouteName] = useState("");
  const [ShiftTime, setShiftTime] = useState("");
  const [TripType, setTripType] = useState("");
  const [VehicleType, setVehicleType] = useState("");
  const [FromLocation, setFromLocation] = useState("");
  const [ToLocation, setToLocation] = useState("");
  const [PickupTime, setPickupTime] = useState("");
  const [NoOfPickup, setNoOfPickup] = useState("");
  const [CompanyId, setCompanyId] = useState("");
  const [TripAmount, setTripAmount] = useState("");
  const [NoOfEmployeePicked, setNoOfEmployeePicked] = useState("");
  const [TripEndTime, setTripEndTime] = useState("");
  const [SelectedId, setSelectedId] = useState("");
  const [SelectedEmployees, setSelectedEmployees] = useState([]);
  const [TripCompleted, setTripCompleted] = useState(false);

  const [TripDateError, setTripDateError] = useState("");
  const [RouteIdError, setRouteIdError] = useState("");
  const [RouteNameError, setRouteNameError] = useState("");
  const [ShiftTimeError, setShiftTimeError] = useState("");
  const [TripTypeError, setTripTypeError] = useState("");
  const [VehicleTypeError, setVehicleTypeError] = useState("");
  const [NoOfEmployeePickedError, setNoOfEmployeePickedError] = useState("");
  const [TripEndTimeError, setTripEndTimeError] = useState("");

  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);

  const [showMyStatusModal, setShowMyStatusModal] = useState(false);
  const handleOnCloseStatus = () => setShowMyStatusModal(false);

  const handleClick = async (id) => {
    setShowMyModal(true);
  
    try {
      const headers = {
        "x-access-token": localStorage.getItem("accessToken"),
        "email": localStorage.getItem("email"),
        "usertype": localStorage.getItem("userType")
      };
  
      const response = await axios.get(window.api + "trip/getTripOne", {
        params: {
          id: id,
        },
        headers,  // Include the headers in the request
      });
  
      if (response.status === 200) {
        const tripWithSelectedEmployees = {
          id: response.id,
          ...response.data.data,
          selectedEmployees: response.data.data.selectedEmployees,
        };
  
  
        setRouteId(tripWithSelectedEmployees.routeId);
        setRouteName(tripWithSelectedEmployees.routeName);
        setTripDate(tripWithSelectedEmployees.tripDate);
        setShiftTime(tripWithSelectedEmployees.shiftTime);
        setFromLocation(tripWithSelectedEmployees.fromLocation);
        setToLocation(tripWithSelectedEmployees.toLocation);
        setPickupTime(tripWithSelectedEmployees.pickupTime);
        setTripType(tripWithSelectedEmployees.tripType);
        setVehicleType(tripWithSelectedEmployees.vehicleType);
        setNoOfPickup(tripWithSelectedEmployees.noOfPickup);
        setCompanyId(tripWithSelectedEmployees.companyId);
        setTripAmount(tripWithSelectedEmployees.tripAmount);
        setSelectedEmployees(tripWithSelectedEmployees.selectedEmployees);
      } else {
        console.log("Trip not found");
      }
    } catch (error) {
      console.error("Error getting trip:", error);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      }
    }
  };
  
  const handleClickStatus = async (id) => {
    setShowMyStatusModal(true);
    setSelectedId(id);
  
    try {
      const headers = {
        "x-access-token": localStorage.getItem("accessToken"),
        "email": localStorage.getItem("email"),
        "usertype": localStorage.getItem("userType")
      };
  
      const response = await axios.get(window.api + "trip/getTripOne", {
        params: {
          id: id,
        },
        headers,  // Include the headers in the request
      });
  
      if (response.status === 200) {
        const tripWithSelectedEmployees = {
          id: response.id,
          ...response.data.data,
          selectedEmployees: response.data.data.selectedEmployees,
        };
  
        setNoOfEmployeePicked(
          tripWithSelectedEmployees.noOfEmployeePicked
            ? tripWithSelectedEmployees.noOfEmployeePicked
            : tripWithSelectedEmployees.noOfPickup
        );
        setTripEndTime(tripWithSelectedEmployees.tripEndTime);
        setRouteId(tripWithSelectedEmployees.routeId);
        setRouteName(tripWithSelectedEmployees.routeName);
        setTripType(tripWithSelectedEmployees.tripType);
        setTripCompleted(
          tripWithSelectedEmployees.tripStatus === "Completed" ? true : false
        );
        setTripDate(tripWithSelectedEmployees.tripDate);
      } else {
        console.log("Trip not found");
      }
    } catch (error) {
      console.error("Error getting trip:", error);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      }
    }
  };
  

  const options = { timeZone: "Asia/Kolkata" };
  const currentDate = new Date().toLocaleString("en-US", options).split(",")[0];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleFormValidation()) {
      try {
        const data = {
          routeId: RouteId,
          companyId: CompanyId,
          tripAmount: TripAmount,
          routeName: RouteName,
          tripDate: TripDate,
          shiftTime: ShiftTime,
          fromLocation: FromLocation,
          toLocation: ToLocation,
          pickupTime: PickupTime,
          tripType: TripType,
          vehicleType: VehicleType,
          noOfPickup: NoOfPickup,
          tripStatus: "Not Alloted",
          emrTrip: "false",
          selectedEmployees: SelectedEmployees,
        };
  
        const headers = {
          "x-access-token": localStorage.getItem("accessToken"),
          "email": localStorage.getItem("email"),
          "usertype": localStorage.getItem("userType")
        };
  
        const response = await axios.post(window.api + "trip/create", data, { headers });
  
        if (response.status === 200) {
          handleOnClose();
          getTrips();
          toast("Copy Trip Created Successfully!", {
            icon: "👏",
            style: {
              borderRadius: "7px",
              background: "#222831",
              color: "#fff",
            },
          });
        }
      } catch (error) {
        console.error("Error creating trip: ", error);
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        }
      }
    }
  };
  

  const handleSubmitStatus = async (e) => {
    e.preventDefault();
    if (handleStatusFormValidation()) {
      try {
        const data = {
          noOfEmployeePicked: NoOfEmployeePicked,
          tripEndTime: TripEndTime,
          tripStatus: "Completed",
          tripDate: TripDate,
        };

        const headers = {
          "x-access-token": localStorage.getItem("accessToken"),
          "email": localStorage.getItem("email"),
          "usertype": localStorage.getItem("userType")
        };
  
        const response = await axios.patch(
          window.api + `trip/update/${SelectedId}`,
          data,
          { headers }
        );
        if (response.status === 200) {
          handleOnCloseStatus();
          getTrips();
          toast("Status Updated Successfully!", {
            icon: "👏",
            style: {
              borderRadius: "7px",
              background: "#222831",
              color: "#fff",
            },
          });
          handleOnCloseStatus();
          getTrips();
        }
      } catch (error) {
        console.error("Error allocating driver: ", error);
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        }
      }
    }
  };

  const handleFormValidation = () => {
    let formIsValid = true;

    if (!TripDate) {
      formIsValid = false;
      setTripDateError("Please enter trip date");
    } else {
      setTripDateError("");
    }
    if (!RouteId) {
      formIsValid = false;
      setRouteIdError("Please enter route id");
    } else {
      setRouteIdError("");
    }
    if (!RouteName) {
      formIsValid = false;
      setRouteNameError("Please enter route name");
    } else {
      setRouteNameError("");
    }
    if (!ShiftTime) {
      formIsValid = false;
      setShiftTimeError("Please select shift time");
    } else {
      setShiftTimeError("");
    }
    if (!TripType) {
      formIsValid = false;
      setTripTypeError("Please select trip type");
    } else {
      setTripTypeError("");
    }
    if (!VehicleType) {
      formIsValid = false;
      setVehicleTypeError("Please select vehicle type");
    } else {
      setVehicleTypeError("");
    }

    return formIsValid;
  };

  const handleStatusFormValidation = () => {
    let formIsValid = true;

    if (!NoOfEmployeePicked && NoOfEmployeePicked !== 0) {
      formIsValid = false;
      setNoOfEmployeePickedError("Please enter the number of employees picked");
    } else if (NoOfEmployeePicked < 0) {
      formIsValid = false;
      setNoOfEmployeePickedError(
        "Negative number of employees is not accepted"
      );
    } else {
      setNoOfEmployeePickedError("");
    }

    if (!TripEndTime) {
      formIsValid = false;
      setTripEndTimeError("Please enter trip end time");
    } else {
      setTripEndTimeError("");
    }
    return formIsValid;
  };

  const columns = React.useMemo(
    () => [
      {
        name: "Route ID",
        selector: "routeId",
        sortable: true,
        width: "10%",
        cell: (row) => (
          <Link
            className="flex  w-full justify-center items-center  text-[#393e46] text-center cursor-pointer font-medium text-sm font-roboto hover:transform hover:scale-125 hover:font-base transition duration-300 ease-in-out"
            to={`/view-trip/${row.id}`}
          >
            <div className="py-3 w-full  text-[#393e46] text-center font-medium text-sm font-roboto">
              <div className="text-center text-sm font-roboto text-[#323EDD] cursor-pointer  font-semibold ">
                {row.routeId}
              </div>
            </div>
          </Link>
        ),
      },
      {
        name: "Date",
        selector: "tripDate",

        sortable: true,
        cell: (row) => (
          <div className="py-3 flex  justify-center items-center  w-full  text-[#393e46] text-center font-medium text-sm font-roboto">
            <div className="text-[#393e46] uppercase font-medium ">
              {format(new Date(row.tripDate), "dd-MM-yyyy")}
            </div>
          </div>
        ),
      },
      {
        name: "Route Name",
        selector: "routeName",
        width: "15%",
        sortable: true,
        cell: (row) => (
          <div className="py-3 text-[#393e46] text-left flex justify-start  font-medium text-sm font-roboto">
            <div className="text-[#393e46] uppercase font-medium ">
              {row.routeName}
            </div>
          </div>
        ),
      },
      {
        name: "Shift Time",
        selector: "shiftTime",

        sortable: true,
        cell: (row) => (
          <div className="py-3 flex  justify-center items-center  w-full text-[#393e46] text-center font-medium text-sm font-roboto">
            <div className="text-[#393e46] uppercase font-medium ">
              {row.shiftTime.slice(0, 5)}
            </div>
          </div>
        ),
      },
      {
        name: "Trip Type",
        selector: "tripType",

        sortable: true,
        cell: (row) => (
          <div className="py-3  w-full text-[#393e46] font-medium text-sm font-roboto">
            <div className="text-[#393e46] text-center  uppercase font-medium ">
              {row.tripType}
            </div>
          </div>
        ),
      },
      {
        name: "Vehicle Type",
        selector: "vehicleType",
        sortable: true,
        cell: (row) => (
          <div className="py-3 flex  justify-center items-center  w-full text-[#393e46] text-center font-medium text-sm font-roboto">
            <div className="text-[#393e46] uppercase font-medium ">
              {row.vehicleType}
            </div>
          </div>
        ),
      },
      {
        name: "No. of Emp",
        selector: "noOfPickup",

        sortable: true,
        cell: (row) => (
          <div className="py-3 flex  justify-center items-center  w-full text-[#393e46] text-center font-medium text-sm font-roboto">
            <div className="text-[#393e46] uppercase font-medium ">
              {row.noOfPickup}
            </div>
          </div>
        ),
      },
      {
        name: "Status",
        selector: "tripStatus",
        sortable: true,
        cell: (row) => (
          <div className="flex items-center justify-center w-full text-center cursor-pointer ">
            <div
              className={
                row.tripStatus === "Alloted"
                  ? "text-[#ff8103] text-base font-medium   font-roboto"
                  : row.tripStatus === "Not Alloted"
                  ? "text-[#31007a] text-base font-medium   font-roboto"
                  : row.tripStatus === "Ongoing"
                  ? "text-[#e8d100] text-base font-medium   font-roboto"
                  : "text-[#2daa00] text-base font-medium   font-roboto"
              }
              onClick={() => handleClickStatus(row.id)}
            >
              {row.tripStatus}
            </div>
          </div>
        ),
      },
      {
        name: "Copy",
        width: "8%",
        cell: (row) => (
          <div
            className=" flex  justify-center items-center w-full text-[#393e46]   cursor-pointer font-medium text-sm font-roboto hover:transform hover:scale-125 hover:font-base transition duration-300 ease-in-out"
            onClick={() => handleClick(row.id)}
          >
            <MdOutlineContentCopy className="text-xl cursor-pointer hover:text-red-600" />
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Toaster />
      <div className="table w-full h-auto ">
        <div className="w-full h-auto rounded-lg ">
          <div className="flex flex-col gap-6 w-full  rounded-lg -md">
            <Table
              columns={columns}
              rows={rows}
              headerStyle={[
                " text-center ",
                "text-center",
                "text-left pl-0",
                "text-center",
                "text-center pl-0 pr-0",
              ]}
            />
          </div>
        </div>
      </div>

      <TripCopy
        onClose={handleOnClose}
        visible={showMyModal}
        handleSubmit={handleSubmit}
        getTrips={getTrips}
        getRouteId={RouteId}
        tripDate={TripDate}
        getRouteName={RouteName}
        getShiftTime={ShiftTime}
        getTripType={TripType}
        getVehicleType={VehicleType}
        setRouteId={setRouteId}
        setTripDate={setTripDate}
        setRouteName={setRouteName}
        setShiftTime={setShiftTime}
        setTripType={setTripType}
        setVehicleType={setVehicleType}
        TripDateError={TripDateError}
        RouteIdError={RouteIdError}
        RouteNameError={RouteNameError}
        ShiftTimeError={ShiftTimeError}
        TripTypeError={TripTypeError}
        VehicleTypeError={VehicleTypeError}
      />
      <UpdateTripStatus
        onClose={handleOnCloseStatus}
        visible={showMyStatusModal}
        handleSubmit={handleSubmitStatus}
        NoOfEmployeePicked={NoOfEmployeePicked}
        NoOfPickup={NoOfPickup}
        TripEndTime={TripEndTime}
        setNoOfEmployeePicked={setNoOfEmployeePicked}
        setTripEndTime={setTripEndTime}
        NoOfEmployeePickedError={NoOfEmployeePickedError}
        TripEndTimeError={TripEndTimeError}
        TripCompleted={TripCompleted}
        TripType={TripType}
        RouteName={RouteName}
        RouteId={RouteId}
      />
    </>
  );
}
