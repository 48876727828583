import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import CClogo from "./assets/CClogo.png";
import phoneIcon from "./assets/phoneIcon.png";
import Hashids from "hashids";

export default function EmployeeDetailModal() {
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [routeId, setRouteId] = useState("");
  const [routeName, setRouteName] = useState("");
  const [tripDate, setTripDate] = useState("");
  const [tripType, setTripType] = useState("");
  const [noOfPickup, setNoOfPickup] = useState("");

  const hashids = new Hashids(
    "PrrDmOJKJtjmKZTmotTkPRNmxA2naEHKI4gnr58IVRj6ZWz3H7",
    10
  );

  const { id } = useParams();

  const tripIdDecode = hashids.decode(id);

  useEffect(() => {
    getTripById(tripIdDecode);
  }, []);

  const getTripById = async (searchId) => {
    try {
      const response = await axios.get(window.api + "trip/getEmployeeDetails", {
        params: {
          id: searchId,
        },
      });

      if (response.status === 200) {
        if (response.data.data.tripStatus === "Completed") {
          alert("This trip has been Completed.");
          return;
        }

        const tripWithSelectedEmployees = {
          id: response.id,
          ...response.data.data,
          selectedEmployees: response.data.data.selectedEmployees,
        };

        setRouteId(tripWithSelectedEmployees.routeId);
        setRouteName(tripWithSelectedEmployees.routeName);
        setTripDate(tripWithSelectedEmployees.tripDate);
        setTripType(tripWithSelectedEmployees.tripType);
        setNoOfPickup(tripWithSelectedEmployees.noOfPickup);
        const sortedSelectedEmployees =
          tripWithSelectedEmployees.selectedEmployees.sort((a, b) =>
            a.pickupTime.localeCompare(b.pickupTime)
          );
        setSelectedEmployees(sortedSelectedEmployees);
      } else {
        console.log("Trip not found");
      }
    } catch (error) {
      console.error("Error getting trip:", error);
      alert(
        "Error getting trip. Please contact the administrator at 9841346080."
      );
    }
  };

  const parts = tripDate.split("-");
  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

  return (
    <>
      <div className="fixed inset-0 z-40 w-full  flex items-center justify-center bg-black bg-opacity-50">
        <div className="max-w-md mt-10 z-40 py-3  px-6 rounded-lg  w-full bg-[#fff]">
          <div className=" flex w-full z-40 justify-end ml-3"></div>
          <div className="flex w-full  mb-3">
            <div className="flex items-center justify-around w-full px-5">
              <img width={200} src={CClogo} alt="logo" />
            </div>
          </div>

          <div className="px-5 py-4 bg-white shadow rounded-lg overflow-y-auto overflow-x-hidden h-[600px]">
            <p className="text-gray-600 text-center font-bold">
              {routeId} - {routeName} - {tripType}
            </p>
            <p className="text-gray-600 text-center">
              Trip date:
              <span
                className="  font-bold
                "
              >
                {formattedDate}
              </span>
            </p>
            <p className="text-gray-600 text-center mb-4">
              No. of Employees:
              <span className="  font-bold"> {noOfPickup}</span>
            </p>

            <div className="border-t pt-4">
              <h3 className="font-semibold text-gray-700 mb-2">
                Employee Details:
              </h3>
              {selectedEmployees.map((employee) => (
                <div
                  key={employee.id}
                  className="bg-gray-50 p-3  space-y-2 rounded-md mb-3"
                >
                  <div className=" w-full flex justify-between items-center ">
                    <div className="text-gray-700 w-full text-sm">
                      Employee Id:
                    </div>
                    <div className="  text-gray-700 w-full text-sm">
                      {employee.employeeId}
                    </div>
                  </div>
                  <div className=" w-full flex justify-between items-center ">
                    <div className="text-gray-700 w-full text-sm">
                      Employee Name:
                    </div>
                    <div className="  text-gray-700 w-full text-sm">
                      {employee.employeeName}
                    </div>
                  </div>
                  <div className=" w-full flex justify-between items-center ">
                    <div className="text-gray-700 w-full text-sm">
                      Mobile No:
                    </div>

                    <a
                      href={`tel:${employee.mobileNo}`}
                      className=" flex     w-full  items-center  text-sm"
                    >
                      <div className="text-[#4699ff] ">{employee.mobileNo}</div>
                      <div className=" ml-2 mb-[1px] ">
                        <img width={23} src={phoneIcon} alt="" />
                      </div>
                    </a>
                  </div>
                  <div className=" w-full flex justify-between items-center ">
                    <div className="text-gray-700 w-full text-sm">
                      Pickup Location:
                    </div>
                    <div className="  text-gray-700 w-full text-sm">
                      {employee.pickupLocation}
                    </div>
                  </div>
                  <div className=" w-full flex justify-between items-center ">
                    <div className="text-gray-700 w-full text-sm">
                      Pickup Time:
                    </div>
                    <div className="  text-gray-700 w-full text-sm">
                      {employee.pickupTime}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="text-center mt-5">
              <p className="text-gray-600">Best regards,</p>
              <p className="text-gray-600">Chennai Cabs Team</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
