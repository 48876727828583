import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import { Link, useParams, useNavigate } from "react-router-dom";
import Table from "../Driver/TableCheckBox";
import Dropdown from "../Driver/DropDown";
import eye from "../../assets/Icons/eye.png";
import InputBoxWithLabel from "../Driver/LabelInput";
import DateUtils from "../../Utility/DateUtils";
import axios from "axios";
import { set } from "date-fns/esm";
import { IoMdArrowRoundBack } from "react-icons/io";
import UpdateOnHoldTrip from "./UpdateOnHoldTrip";

export default function RemittanceDetails() {
  const navigate = useNavigate();
  const [fromDate, setFromDate] = React.useState(DateUtils.getTMinusDate(30));
  const [toDate, setToDate] = React.useState(DateUtils.getCurrentDate());

  const { id } = useParams();
  const [driverId, setDriverId] = React.useState(id);
  const [tripId, setTripId] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [rowsFroDropDown, setRowsFroDropDown] = React.useState([]);
  const [selectedIds, setSelectedIds] = React.useState([]);
  const [payerList, setPayerList] = React.useState([]);
  const [invoicePaidBy, setInvoicePaidBy] = React.useState("");
  const [invoicePaidByError, setInvoicePaidByError] = React.useState("");

  const [showMyStatusModal, setShowMyStatusModal] = React.useState(false);
  const handleOnCloseStatus = () => setShowMyStatusModal(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (invoicePaidBy === "") {
      setInvoicePaidByError("Please select paid by");
      return;
    }
    for (let i = 0; i < selectedIds.length; i++) {
      try {
        const entryData = {
          invoicePaidBy: invoicePaidBy,
          invoicePaidAmount: selectedIds[i].invoice.invoicePaidAmount,
          date: selectedIds[i].invoice.invoicePaymentDate,
          paymentMode: "Cash",
          transactionId: selectedIds[i].invoice.invoiceTransactionId,
          description:
            selectedIds[i].company.companyName +
            "/" +
            selectedIds[i].routeId +
            "/" +
            selectedIds[i].routeName +
            "/" +
            selectedIds[i].tripType +
            "/" +
            DateUtils.getExDateFormat(selectedIds[i].tripDate),
          entryStatus: "Debit",
          tripId: selectedIds[i].id,
          routeId: selectedIds[i].routeId,
          routeName: selectedIds[i].routeName,
          tripDate: selectedIds[i].tripDate,
        };

        await axios.post(window.api + "passbook/createDebites", entryData, {
          headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            email: localStorage.getItem("email"),
            usertype: localStorage.getItem("userType"),
          },
        });
      } catch (error) {
        console.error("Errors adding passbook entry: ", error);
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      }

      try {
        const data = {
          invoicePaidAmount: selectedIds[i].invoice.invoicePaidAmount,
          invoicePaymentDate: selectedIds[i].invoice.invoicePaymentDate,
          invoicePaymentStatus: "Paid",
          invoicePaymentMode: "Cash",
          invoiceTransactionId: selectedIds[i].invoice.invoiceTransactionId,
          invoicePaidBy: invoicePaidBy,
          tripId: selectedIds[i].id,
        };

        const response = await axios.post(window.api + "invoice/create", data, {
          headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            email: localStorage.getItem("email"),
            usertype: localStorage.getItem("userType"),
          },
        });
      } catch (e) {
        console.error("Errors creating invoice: ", e);
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      }

      try {
        const headers = {
          "x-access-token": localStorage.getItem("accessToken"),
          email: localStorage.getItem("email"),
          usertype: localStorage.getItem("userType"),
        };

        const response = await axios.post(
          window.api + "trip/pushRemittance",
          {
            tripIds: selectedIds[i].id,
            status: "Completed",
          },
          {
            headers,
          }
        );
        if (response.status === 200) {
          getTrips();
          getTripsFroDropDown();
          setShowMyStatusModal(false);
        }
      } catch (error) {
        console.error("Error getting trips:", error);
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      }
    }
    alert("Remittance pushed successfully");
  };

  const getPayer = async () => {
    try {
      const response = await axios.get(window.api + "passbook/getPayer", {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          email: localStorage.getItem("email"),
          usertype: localStorage.getItem("userType"),
        },
      });
      if (response.status === 200) {
        const payerList = response.data.data;
        setPayerList(payerList);
      }
    } catch (e) {
      console.error("Error getting payer list:", e);
      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  const handleCheckboxChange = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  useEffect(() => {
    getTrips();
    getTripsFroDropDown();
  }, [tripId, fromDate, toDate]);

  const getTrips = async () => {
    try {
      const headers = {
        "x-access-token": localStorage.getItem("accessToken"),
        email: localStorage.getItem("email"),
        usertype: localStorage.getItem("userType"),
      };

      const response = await axios.get(
        window.api + "trip/getTripsRemittanceView",
        {
          params: {
            driverId: driverId,
            tripId: tripId,
            fromDate: fromDate,
            toDate: toDate,
          },
          headers,
        }
      );

      if (response.status === 200) {
        const trips = response.data.data;
        setRows(trips);
        if (trips.length === 0) {
          navigate("/Remittance");
        }
      }
    } catch (error) {
      console.error("Error getting trips:", error);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  const getTripsFroDropDown = async () => {
    try {
      const headers = {
        "x-access-token": localStorage.getItem("accessToken"),
        email: localStorage.getItem("email"),
        usertype: localStorage.getItem("userType"),
      };

      const response = await axios.get(
        window.api + "trip/getTripsRemittanceView",
        {
          params: {
            driverId: id,
          },
          headers,
        }
      );

      if (response.status === 200) {
        const trips = response.data.data;
        setRowsFroDropDown(trips);
      }
    } catch (error) {
      console.error("Error getting trips:", error);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  const handlePushButtonClick = () => {
    if (selectedIds.length > 0) {
      pushRemittance();
    } else {
      alert("Please select atleast one trip");
    }
  };

  const pushRemittance = async () => {
    setShowMyStatusModal(true);
    getPayer();
  };

  const columns = [
    {
      name: "",
      selector: "checkbox",
      headerCheckbox: true, // Flag to identify the header checkbox
      cell: (row) => (
        <div className="py-4 text-[#3C4048] text-center w-full cursor-pointer font-medium text-sm font-roboto hover:transform hover:scale-125 hover:font-base transition duration-300 ease-in-out">
          <span
            className={`py-4 text-center w-full text-sm font-roboto cursor-pointer font-medium`}
          >
            <input
              type="checkbox"
              value={row.tripList}
              onChange={() => handleCheckboxChange(row.tripList)}
              checked={selectedIds.includes(row.tripList)}
            />
          </span>
        </div>
      ),
    },
    {
      name: "Route ID",
      selector: "routeId",
      sortable: true,
      cell: (row) => (
        <div className="py-4 text-[#3C4048] text-center w-full cursor-pointer font-medium text-sm font-roboto hover:transform hover:scale-125 hover:font-base transition duration-300 ease-in-out">
          <span
            className={`py-4 text-center w-full text-sm font-roboto   cursor-pointer font-medium`}
          >
            {row.routeId}
          </span>
        </div>
      ),
    },
    {
      name: "Route Name",
      selector: "routeName",
      sortable: true,
      cell: (row) => (
        <div className="py-4 text-[#3C4048] text-center w-full cursor-pointer font-medium text-sm font-roboto hover:transform hover:scale-125 hover:font-base transition duration-300 ease-in-out">
          <span
            className={`py-4 text-center w-full text-sm font-roboto   cursor-pointer font-medium`}
          >
            {row.routeName}
          </span>
        </div>
      ),
    },
    {
      name: "Vehicle No",
      selector: "vehicleNo",
      sortable: true,
      cell: (row) => (
        <div className="py-4 text-[#3C4048] text-center w-full cursor-pointer font-medium text-sm font-roboto hover:transform hover:scale-125 hover:font-base transition duration-300 ease-in-out">
          <span
            className={`py-4 text-center w-full text-sm font-roboto   cursor-pointer font-medium`}
          >
            {row.vehicleNo}
          </span>
        </div>
      ),
    },
    {
      name: "Trip Date",
      selector: "tripDate",
      sortable: true,
      cell: (row) => (
        <div className="text-[#3C4048] text-center   font-medium text-sm font-roboto">
          <span className="text-[#3C4048] uppercase font-medium">
            {DateUtils.getExDateFormat(row.tripDate)}
          </span>
        </div>
      ),
    },
    {
      name: "Trip Type",
      selector: "tripType",
      sortable: true,
      cell: (row) => (
        <div className="text-[#3C4048] text-center   font-medium text-sm font-roboto">
          <span className="text-[#3C4048] uppercase font-medium">
            {row.tripType}
          </span>
        </div>
      ),
    },
    {
      name: "Company Name",
      selector: "companyName",
      sortable: true,
      cell: (row) => (
        <div className="text-[#3C4048] text-center   font-medium text-sm font-roboto">
          <span className="text-[#3C4048] uppercase font-medium">
            {row.companyName}
          </span>
        </div>
      ),
    },
    {
      name: "Status",
      selector: "tripStatus",
      sortable: true,
      cell: (row) => (
        <div className="text-[#3C4048] text-center   font-medium text-sm font-roboto">
          <span className="text-[#3C4048] uppercase font-medium">
            {row.tripStatus}
          </span>
        </div>
      ),
    },
    {
      name: "On Hold Amount",
      selector: "invoicePaidAmount",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#3C4048] text-center w-full font-medium text-sm font-roboto">
          <span className="text-[#3C4048] uppercase  font-medium">
            {row.invoicePaidAmount}
          </span>
        </div>
      ),
    },
    {
      name: "Paid Date",
      selector: "invoicePaymentDate",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#3C4048] text-center w-full font-medium text-sm font-roboto">
          <span className="text-[#3C4048] uppercase  font-medium">
            {DateUtils.getExDateFormat(row.invoicePaymentDate)}
          </span>
        </div>
      ),
    },
    {
      name: "Action",
      selector: "id",
      sortable: false,
      cell: (row) => (
        <div className=" text-[#393e46] flex  w-full  pl-5  gap-2  font-medium  justify-center items-center   uppercase text-center text-sm font-roboto">
          <Link
            to={`/view-invoice/${row.id}`}
            className="text-center text-blue-600 flex      items-center w-full hover:text-blue-800"
          >
            <img className=" w-8 " src={eye} alt="" />
          </Link>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div className="flex flex-wrap ml-8">
        <div className=" cursor-pointer  shadow-lgrounded-xl">
          <Link
            to={"/Remittance"}
            className="flex items-center justify-center w-10 h-10 text-black"
          >
            <IoMdArrowRoundBack className="w-6 h-6 text-black" />
          </Link>
        </div>
      </div>
      <div className="flex flex-col gap-4 ">
        <div className="flex flex-col items-center justify-between px-4 py-3 mx-0 mt-2 bg-white border-2 md:flex-row border-1 rounded-xl">
          <div className="flex flex-row gap-2 items-center justify-between w-full flex-wrap">
            <div className=" items-center flex gap-2 flex-wrap  ">
              <InputBoxWithLabel
                label={"From Date"}
                type={"date"}
                value={fromDate}
                onChange={(e) => {
                  setFromDate(e.target.value);
                }}
              />
              <InputBoxWithLabel
                label={"To Date"}
                type={"date"}
                value={toDate}
                onChange={(e) => {
                  setToDate(e.target.value);
                }}
              />
              <Dropdown
                lableName={"Route ID"}
                options={rowsFroDropDown}
                placeholder={"Select Route ID"}
                widthForSelect={"w-[280px]"}
                objName={"routeId"}
                updateValue={setTripId}
                value={id}
              />
            </div>
            <div className=" items-center flex ">
              {" "}
              <button
                className="flex mb-5 mt-9 "
                onClick={handlePushButtonClick}
              >
                <Link className="bg-[#00adb5] flex justify-between items-center  hover:bg-[#059299] active:bg-[#047481] text-gray-100 text-xl font-bold text-center p-3 pl-5 pr-5 rounded-lg ">
                  <span className="mr-2">Push</span>

                  <svg
                    className="text-2xl "
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-download transform rotate-180"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                </Link>
              </button>
            </div>
          </div>
        </div>

        <div className="table w-full h-auto ">
          <div className="w-full h-auto rounded-lg ">
            <div className="flex w-full flex-col gap-6  ">
              <Table
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                columns={columns}
                rows={rows}
                headerStyle={[
                  " text-center ",
                  " text-center ",
                  " text-center ",
                  " text-center ",
                ]}
              />
            </div>
          </div>
        </div>
        <UpdateOnHoldTrip
          onClose={handleOnCloseStatus}
          visible={showMyStatusModal}
          handleSubmit={handleSubmit}
          payerList={payerList}
          setPayerList={setPayerList}
          setInvoicePaidBy={setInvoicePaidBy}
          invoicePaidByError={invoicePaidByError}
        />
      </div>
    </Layout>
  );
}
