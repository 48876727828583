import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import InputBoxWithLabel from "../Driver/LabelInput";
import { Toaster, toast } from "react-hot-toast";
import axios from "axios";
import Table from "../Driver/Table";
import momentTimezone from "moment-timezone";

export default function Wallet() {
  const [showPopUp, setShowPopUp] = React.useState(false);
  const [amount, setAmount] = React.useState("");
  const [amountError, setAmountError] = React.useState("");
  const [balanceList, setBalanceList] = React.useState([]);

  const handlePopUp = (item) => {
    if (item === false) return setShowPopUp(false);
    setShowPopUp(item);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleFormValidation()) {
      try {
        const data = {
          balance: amount,
        };
      
        const response = await axios.post(
          window.api + "passbook/addBalance",
          data,
          {
            headers: {
              "x-access-token": localStorage.getItem("accessToken"),
              "email": localStorage.getItem("email"),
              "usertype": localStorage.getItem("userType")
            }
          }
        );
        if (response.status === 200) {
          toast("Balance Added Successfully!", {
            icon: "👏",
            style: {
              borderRadius: "7px",
              background: "#222831",
              color: "#fff",
            },
          });
          setTimeout(() => {
            setShowPopUp(false);
          }, 2000);
        }
      } catch (e) {
        console.error("Errors adding balance: ", e);
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else if (e.response) {
          toast.error(e.response.data.message, {
            autoClose: false,
            closeButton: true,
            className: "toast-error",
            bodyClassName: "toast-error-body",
          });
        }
      }
      
    }
  };

  useEffect(() => {
    getBalance();
  }, []);

  const getBalance = async () => {
    try {
      const response = await axios.get(window.api + "passbook/getBalance", {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          "email": localStorage.getItem("email"),
          "usertype": localStorage.getItem("userType")
        }
      });
  
      if (response.status === 200) {
        const data = response.data.data;
        const dataWithSerial = data.map((item, index) => ({
          ...item,
          serialNumber: index + 1,
        }));
        setBalanceList(dataWithSerial);
      }
    } catch (e) {
      console.error("Errors fetching balance: ", e);
      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      } else {
        toast.error(e.response.data.message, {
          autoClose: false,
          closeButton: true,
          className: "toast-error",
          bodyClassName: "toast-error-body",
        });
      }
    }
  };
  

  const handleFormValidation = () => {
    let formIsValid = true;

    if (!amount) {
      formIsValid = false;
      setAmountError("Please enter amount");
    } else {
      setAmountError("");
    }

    return formIsValid;
  };

  const columns = [
    {
      name: "Sr No.",
      selector: "id",
      sortable: true,
      cell: (row) => (
        <div className="py-3  text-[#393e46] text-center w-full font-medium text-sm font-roboto">
          <span className="py-3 text-sm font-roboto uppercase text-[#393e46]  font-medium cursor-pointer">
            {row.id}
          </span>
        </div>
      ),
    },
    {
      name: "Amount",
      selector: "balance",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#393e46] w-full font-medium text-sm font-roboto">
          <span className="py-3  text-center   text-sm font-roboto uppercase text-[#393e46]  font-medium cursor-pointer">
            {row.balance}
          </span>
        </div>
      ),
    },
    {
      name: "Date",
      selector: "createdAt",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#393e46] text-center w-full font-medium text-sm font-roboto">
          <span className="py-3 text-center   text-sm font-roboto uppercase text-[#393e46]  font-medium cursor-pointer">
            {/* {format(new Date(row.createdAt), "dd/MM/yyyy")} */}
            {momentTimezone(row.date).tz("Asia/Kolkata").format("DD-MM-YYYY")}
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <Toaster />
      <div className="flex flex-wrap w-full">
      <div className="w-full h-auto my-2   rounded-md  ">
          <div className="flex flex-wrap mb-2 items-center justify-end w-full  h-auto md:h-[220px] lg:h-auto ">
            <div className="flex flex-wrap justify-end pr-5 w-full shadow-sm shadow-[#00adb5] pb-4 bg-white border-[#00adb5]  border-2  rounded-lg">
              <button
                onClick={() => {
                  handlePopUp(1);
                }}
                className="flex mb-5 mt-9 "
              >
                <Link className="bg-[#00adb5] flex justify-center items-center  hover:bg-[#059299] active:bg-[#047481] text-gray-100 text-xl font-bold text-center  px-6 py-3 rounded-lg ">
                  <span className="">Add Money</span>
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>

      {showPopUp === 1 && (
        <div
          className={`  fixed left-0 top-0    bg-[#2b2a2a69] w-screen  h-screen z-40  overflow-y-scroll overflow-scroll overscroll-y-auto py-2  flex justify-center items-center`}
        >
          <div className=" h-auto w-40  lg:w-[920px]  flex flex-wrap justify-center items-center px-16 ">
            <div
              class={`w-[900px] h-full    rounded-[8px]  bg-white  shadow-box `}
            >
              <div className="flex relative flex-col p-5 w-full rounded-[8px] h-full   ">
                <div className="flex justify-end mx-[30px] ">
                  <button
                    onClick={handlePopUp}
                    className="flex justify-center items-center bg-[#D9D9D985] rounded-full w-[40px] h-[40px]"
                  >
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 1L1 16M1 1L16 16"
                        stroke="#344054"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className="text  text-center text-[#101828] font-bold">
                  Add Money
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="flex items-center justify-center w-full h-full gap-10 mt-5 ">
                    <div className="flex items-center justify-center w-full px-8">
                      <InputBoxWithLabel
                        label={"Add Money"}
                        placeholder={"Select Money"}
                        type={"number"}
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        id={"amount"}
                        validationError={amountError}
                      />
                    </div>
                  </div>

                  <div className="flex justify-center mt-10 font-semibold tracking-normal text-center item font-roboto text-12 leading-20">
                    <button
                      type="submit"
                      className=" bg-[#175CD3] text-white w-[77px] h-[30px] border border-gray-500 rounded-md "
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="table w-full h-auto ">
        <div className="w-full h-auto rounded-lg ">
          <div className="flex flex-col gap-6 w-full  rounded-lg -md">
            <Table
              columns={columns}
              rows={balanceList}
              headerStyle={[" text-center ", "text-center ", "text-center"]}
            />
          </div>
        </div>
      </div>
    </>
  );
}
