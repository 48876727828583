import React from "react";

export default function Validation({ Error }) {
  return (
    <>
      <div>
      <p className="text-sm text-red-500"> {Error} </p>
      </div>
    </>
  );
}
