import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Login from "./components/SignIn";
import Home from "./components/Home/Home";

import DriversList from "./components/Driver/DriverList";
import AddEditViewDriver from "./components/Driver/AddEditViewDriver";

import AddEditViewEmployee from "./components/Employee/AddEditViewEmployee";
import EmployeeList from "./components/Employee/EmployeeList";

import AddTrip from "./components/TripManagement/AddTrip";
// import TripList from "./components/TripManagement/TripList";
import EditViewTrip from "./components/TripManagement/EditViewTrip";

import InvoiceList from "./components/Invoice/InvoiceList";
import ViewInvoice from "./components/Invoice/ViewInvoice";
import ZeroPage from "./components/ZeroPage";
import ReportLayout from "./components/Report/ReportLayout";
import TableDetails from "./components/Report/Accounts/TableDetails";
import Passbook from "./components/Passbook/Passbook";
import Expenses from "./components/Expenses/Expenses";
import Admin from "./components/Admin/Admin";
import OutStation from "./components/OutStation/OutStation";
import AddOutStationTrip from "./components/OutStation/AddOutStationTrip";
import CreateInvoice from "./components/OutStation/CreateInvoice";
import Remittance from "./components/Remittance/Remittance";
import RemittanceDetails from "./components/Remittance/RemittanceDetails";
import EmployeeDetailModal from "./components/EmployeeDetailModal/EmployeeDetailModal";

function App() {
  // Example authentication check
  const isAuthenticated = () => {
    const user = localStorage.getItem("accessToken");

    if (user === null) {
      alert("You are not logged in. Please log in to access this page.");
    }

    return user !== null;
  };

  const AccessDenied = () => {
    alert("You are not authorized for this page.");
    return <Navigate to="/home" />;
  };

  // Higher-order component for protected routes
  const ProtectedRoute = ({ path, element }) => {
    return isAuthenticated() ? element : <Navigate to="/" />;
  };

  const GuestRoute = () => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      return <Navigate to="/home" />;
    }

    return <Login />;
  };

  return (
    <>
      <div className="font-sf_bold">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<GuestRoute />} />
            <Route
              path="/home"
              element={<ProtectedRoute element={<Home />} />}
            />
            <Route
              path="/trip-list"
              element={<ProtectedRoute element={<Home />} />}
            />
            <Route
              path="/add-trip"
              element={<ProtectedRoute element={<AddTrip />} />}
            />
            <Route
              path="/emr-trip/:id"
              element={<ProtectedRoute element={<AddTrip />} />}
            />
            <Route
              path="/view-trip/:id"
              element={<ProtectedRoute element={<EditViewTrip />} />}
            />

            <Route
              path="/driver-list"
              element={<ProtectedRoute element={<DriversList />} />}
            />
            <Route
              path="/add-driver"
              element={<ProtectedRoute element={<AddEditViewDriver />} />}
            />
            <Route
              path="/view-driver/:id"
              element={<ProtectedRoute element={<AddEditViewDriver />} />}
            />
            <Route
              path="/employee-list"
              element={<ProtectedRoute element={<EmployeeList />} />}
            />
            <Route
              path="/add-employee"
              element={<ProtectedRoute element={<AddEditViewEmployee />} />}
            />
            <Route
              path="/view-employee/:id"
              element={<ProtectedRoute element={<AddEditViewEmployee />} />}
            />
            <Route
              path="expenses"
              element={<ProtectedRoute element={<Expenses />} />}
            />

            <Route
              path="/invoice-list"
              element={<ProtectedRoute element={<InvoiceList />} />}
            />

            <Route
              path="/Passbook"
              element={<ProtectedRoute element={<Passbook />} />}
            />

            <Route
              path="/view-invoice/:invoiceId"
              element={<ProtectedRoute element={<ViewInvoice />} />}
            />

            <Route
              path="/report"
              element={<ProtectedRoute element={<ReportLayout />} />}
            />

            <Route
              path="/accounts-details"
              element={<ProtectedRoute element={<TableDetails />} />}
            />

            <Route
              path="/admin"
              element={<ProtectedRoute element={<Admin />} />}
            />
            <Route path="/outstation" element={<OutStation />} />
            <Route path="/add-outstation" element={<AddOutStationTrip />} />
            <Route path="/Remittance" element={<Remittance />} />
            <Route path="/Remittance/:id" element={<RemittanceDetails />} />
            <Route
              path="/view-outstation/:id"
              element={<AddOutStationTrip />}
            />
            <Route path="/create-invoice/:id" element={<CreateInvoice />} />
            <Route path="*" element={<ZeroPage />} />
            <Route path="/employeedetails/:id" element={<EmployeeDetailModal />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
