import React from "react";
import Validation from "./Validation";

export default function SelectOption({ lableName ,select,validationError,}) {
  return (
    <>
      <div className="relative flex flex-col items-center justify-start w-[300px] gap-1 ">
        <div className="text-sm font-medium text-[#344053] self-start pl-[1px]">
          {lableName}
        </div>
    {select}

        <Validation Error={validationError} />
      </div>
    </>
  );
}
