import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function AddTripButton() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/emr-trip/0');
  };

  return (
    <>
      <Link to="/add-trip">
        <button className="bg-[#3C4048] px-4 py-1 hover:bg-gray-900 focus:outline-none rounded-lg border-2 border-white font-sf_bold ">
          Add Trip
        </button>
      </Link>
      <span style={{ marginLeft: '20px' }}></span>
      <button
        className="bg-[#ff0078] px-4 py-1 hover:bg-gray-900 focus:outline-none rounded-lg border-2 border-white font-sf_bold"
        onClick={handleButtonClick}
      >
        Emergency Trip
      </button>
    </>
  );
}

export default AddTripButton;
