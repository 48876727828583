import React, { useEffect, useState } from "react";
// import { db } from "../../firebase/config";
// import { collection, getDocs, where, query } from "firebase/firestore";
import { Link } from "react-router-dom";
import { FiFilter } from "react-icons/fi";
import Layout from "../../Layout/Layout";
import DataTable from "react-data-table-component";
import axios from "axios";
import Dropdown from "./DropDown";
import Table from "./Table";


export default function DriversList() {
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState(
    window.localStorage.getItem("driversSearchQuery") || ""
  );
  const [selectedVehicleType, setSelectedVehicleType] = useState(
    window.localStorage.getItem("driversSelectedVehicleType") || ""
  );
  const [driverStatus, setDriverStatus] = useState(
    window.localStorage.getItem("driversDriverStatus") || ""
  );

  useEffect(() => {
    if(localStorage.getItem("userType") == "supervisor" ){
      alert("You are not authorized to access this page");
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getDrivers();
    };
    fetchData();
  }, [searchQuery, selectedVehicleType, driverStatus]);

  async function getDrivers() {
    try {
      const response = await axios.get(
        window.api + "driver/getDrivers",
        {
          headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "email": localStorage.getItem("email"),
            "usertype": localStorage.getItem("userType")
          },
          params: {
            searchQuery,
            selectedVehicleType,
            driverStatus,
          }
        }
      );
  
      if (response.status === 200) {
        const drivers = response.data.data;
        setRows(drivers);
      }
    } catch (e) {
      console.error("Error getting drivers:", e);
  
      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      } 
  
      setRows([]);
    }
  }
  

  window.onbeforeunload = () => {
    localStorage.removeItem("driversSearchQuery");
    localStorage.removeItem("driversSelectedVehicleType");
    localStorage.removeItem("driversDriverStatus");
  };

  const columns = [
    {
      name: "Driver ID",
      selector: "driverID",
      sortable: true,
      cell: (row) => (
        <div className="py-4 text-[#3C4048] text-center w-full cursor-pointer font-medium text-sm font-roboto hover:transform hover:scale-125 hover:font-base transition duration-300 ease-in-out">
          <Link to={`/view-driver/${row.id}`}>
            <span
              className={`py-4 text-center w-full text-sm font-roboto ${
                row.expiryAlert ? "text-red-600" : "text-[#323EDD]"
              } cursor-pointer font-medium`}
            >
              {row.driverID}
            </span>
          </Link>
        </div>
      ),
    },
    {
      name: "Driver Name",
      selector: "driverName",
      sortable: true,
      cell: (row) => (
        <div className="text-[#3C4048] text-left   font-medium text-sm font-roboto">
          <span className="text-[#3C4048] uppercase font-medium">
            {row.driverName}
          </span>
        </div>
      ),
    },
    {
      name: "Vehicle Name",
      selector: "vehicleName",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#3C4048] text-left w-full font-medium text-sm font-roboto">
          <span className="text-[#3C4048] uppercase  font-medium">
            {row.vehicleName}
          </span>
        </div>
      ),
    },
    {
      name: "Vehicle Number",
      selector: "vehicleNo",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#3C4048] text-center w-full font-medium text-sm font-roboto">
          <span className="text-[#3C4048] uppercase  font-medium">
            {row.vehicleNo}
          </span>
        </div>
      ),
    },
    {
      name: "Vehicle Type",
      selector: "vehicleType",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#3C4048] text-center w-full font-medium text-sm font-roboto">
          <span className="text-[#3C4048] uppercase  font-medium">
            {row.vehicleType}
          </span>
        </div>
      ),
    },
    {
      name: "Mobile Number",
      selector: "mobileNo",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#3C4048] text-center w-full font-medium text-sm font-roboto">
          <a
            href={`https://wa.me/+91${
              row.mobileNo
            }?text=Hello,%20${encodeURIComponent(`${row.driverName}.`)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="text-[#3C4048] uppercase  font-medium">
              {row.mobileNo}
            </span>
          </a>
        </div>
      ),
    },
    {
      name: "Driver Status",
      selector: "driverStatus",
      sortable: true,
      cell: (row) => {
        return (
          <div className="py-3 text-[#3C4048] text-center w-full font-medium text-sm font-roboto">
            <span className="text-[#3C4048] uppercase font-medium">
              {row.driverStatus}
            </span>
          </div>
        );
      },
    },
  ];

  const vehicleTypeList = [
    { type: "Sedan", id: "Sedan" },
    { type: "SUV", id: "SUV" },
    { type: "Tempo", id: "Tempo" },
    { type: "Coach", id: "Coach" },
  ];
  const driverStatusList = [
    { name: "Dedicated", id: "Dedicated" },
    { name: "Part Time", id: "Part Time" },
  ];

  useEffect(() => {
    window.localStorage.setItem(
      "driversSelectedVehicleType",
      selectedVehicleType
    );
    window.localStorage.setItem("driversDriverStatus", driverStatus);
  }, [selectedVehicleType, driverStatus]);

  return (
    <>
      <Layout>
        <div className="flex flex-col gap-4 ">
          <div className="flex flex-col items-center justify-between px-4 py-3 mx-0 mt-2 bg-white border-2 md:flex-row border-1 rounded-xl">
            <form className="w-full mb-2 md:mb-0 md:w-auto md:mr-4">
              <label
                htmlFor="default-search"
                className="mb-2 text-sm font-medium text-gray-900 sr-only "
              >
                Search
              </label>
              <div className="relative w-full">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-gray-500 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => {
                    const searchQuery = e.target.value;
                    setSearchQuery(searchQuery);
                    window.localStorage.setItem(
                      "driversSearchQuery",
                      searchQuery
                    );
                  }}
                  className="block w-full p-2 pl-10 text-sm border-2 rounded-lg "
                  placeholder="Search"
                  required
                />
              </div>
            </form>
            <div className="flex flex-row gap-2">
              <Dropdown
                lableName={"Vehicle Type"}
                options={vehicleTypeList}
                updateValue={setSelectedVehicleType}
                placeholder={"Select Vehicle Type"}
                widthForSelect={"w-[280px]"}
                objName={"type"}
                value={selectedVehicleType}
              />
            </div>
            <div className="flex flex-row gap-2">
              <Dropdown
                lableName={"Driver Status"}
                options={driverStatusList}
                updateValue={setDriverStatus}
                placeholder={"Select Vehicle Type"}
                widthForSelect={"w-[280px]"}
                objName={"name"}
                value={selectedVehicleType}
              />
            </div>
          </div>

          <div className="table w-full h-auto ">
            <div className="w-full h-auto rounded-lg ">
              <div className="flex w-full flex-col gap-6  ">
                {/* <DataTable
                  columns={columns}
                  data={rows}
                  noHeader
                  pagination
                  highlightOnHover
                  pointerOnHover
                  className="table"
                  customStyles={customStyles}
                  paginationPerPage={25}
                /> */}
                <Table
                  columns={columns}
                  rows={rows}
                  headerStyle={[
                    " text-center ",
                    "text-left pl-0",
                    "text-left pl-0",
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
