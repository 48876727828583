import React, { useEffect } from "react";
import Table from "../../Driver/Table";
import eye from "../../../assets/Icons/eye.png";
import { Link } from "react-router-dom";
import axios from "axios";
import Dropdown from "../../Driver/DropDown";
import InputBoxWithLabel from "../../Driver/LabelInput";
import DateUtils from "../../../Utility/DateUtils";

export default function OutstationInvoiceTable({
  openTab,
  openButton,
  setOpenButton,
}) {
  const [outstationTripList, setOutstationTripList] = React.useState([]);
  const [companyList, setCompanyList] = React.useState([]);
  const [companyId, setCompanyId] = React.useState(localStorage.getItem("companyIdOutstationInvoice") || "");
  const [fromDate, setFromDate] = React.useState(localStorage.getItem("fromDateOutstationInvoice") || DateUtils.getCurrentDate);
  const [toDate, setToDate] = React.useState(localStorage.getItem("toDateOutstationInvoice") || DateUtils.getCurrentDate);
  const [invoicePaymentStatus, setInvoicePaymentStatus] = React.useState(localStorage.getItem("invoicePaymentStatusOutstationInvoice") || "Not Paid");


  window.onbeforeunload = () => {
    localStorage.removeItem("companyIdOutstationInvoice");
    localStorage.removeItem("fromDateOutstationInvoice");
    localStorage.removeItem("toDateOutstationInvoice");
    localStorage.removeItem("invoicePaymentStatusOutstationInvoice");
  };

  
  useEffect(() => {
    getCompany();
  }, []);
  
  const getCompany = async () => {
    try {
        const response = await axios.get(
            window.api + "company/getCompanyList",
            {
                headers: {
                    "x-access-token": localStorage.getItem("accessToken"),
                    "email": localStorage.getItem("email"),
                    "usertype": localStorage.getItem("userType")
                }
            }
        );
        
        if (response.status === 200) {
            const companyList = response.data.data;
            setCompanyList(companyList);
        }
    } catch (error) {
        console.error("Error getting company list:", error);
        
        if (error.response && error.response.status === 401) {
            localStorage.clear();
            window.location.href = '/';
        }
    }
};


  useEffect(() => {
    getAllOutstationTrip();
  }, [companyId, fromDate, toDate, invoicePaymentStatus]);

  const getAllOutstationTrip = async () => {
    try {
      const response = await axios.get(window.api + "outstation/getAllOutstationTrip", {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          "email": localStorage.getItem("email"),
          "usertype": localStorage.getItem("userType")
        },
        params: {
          tripStatus: "Completed",
          companyId: companyId,
          fromDate: fromDate,
          toDate: toDate,
          invoicePaymentStatus: invoicePaymentStatus,
        }
      });
    
      if (response.status === 200) {
        const list = response.data.data;
        setOutstationTripList(list);
      }
    } catch (e) {
      console.error("Error getting outstation list:", e);
      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      }
    }
    
  };

  const columns = [
    {
      name: "Sr.No.",
      selector: "serialNumber",
      sortable: true,
      width: "10%",
      cell: (row) => (
        <div className="  w-full  py-3 text-[#393e46] text-center font-medium text-sm font-roboto">
          <div className="text-[#393e46] uppercase  font-medium">
            {row.serialNumber}
          </div>
        </div>
      ),
    },
    {
      name: "Route ID",
      selector: "routeID",
      sortable: true,
      cell: (row) => (
        <div className="py-4 text-[#3C4048] text-center w-full cursor-pointer font-medium text-sm font-roboto hover:transform hover:scale-125 hover:font-base transition duration-300 ease-in-out">
          {/* <Link to={`/view-outstation/${row.id}`}> */}
            <span
              className={`py-4 text-center w-full text-sm font-roboto ${
                row.expiryAlert ? "text-red-600" : "text-[#323EDD]"
              } cursor-pointer font-medium`}
            >
              {row.routeId}
            </span>
          {/* </Link> */}
        </div>
      ),
    },
    {
      name: "VEHICLE NO",
      selector: "vehicleNo",
      sortable: true,
      cell: (row) => (
        <div className="  w-full  text-[#393e46]    font-medium text-sm font-roboto">
          <div className="text-[#393e46] text-center  uppercase font-medium">
            {row.driver.vehicleNo}
          </div>
        </div>
      ),
    },
    {
      name: "TRIP DATE",
      selector: "tripStartDate",
      sortable: true,
      cell: (row) => (
        <div className="   py-3 text-[#393e46]   font-medium text-sm font-roboto">
          <div className="text-[#393e46] uppercase text-center font-medium">
            {row.tripStartDate}
          </div>
        </div>
      ),
    },
    {
      name: "Trip Type",
      selector: "tripType",
      sortable: true,
      cell: (row) => (
        <div className=" flex justify-center items-center w-full  py-3 text-[#393e46] text-center font-medium text-sm font-roboto">
          <div className="text-[#393e46] uppercase  font-medium">
            {row.tripType}
          </div>
        </div>
      ),
    },
    {
      name: "Company Name",
      selector: "companyName",
      sortable: true,
      cell: (row) => (
        <div className=" flex justify-center  items-center w-full  py-3 text-[#393e46] text-center font-medium text-sm font-roboto">
          <div className="text-[#393e46] uppercase  font-medium">
            {row.company.companyName}
          </div>
        </div>
      ),
    },
    {
      name: "Status",
      selector: "invoicePaymentStatus",
      sortable: true,
      cell: (row) => (
        <div className=" flex justify-center  items-center w-full  py-3 text-[#393e46] text-center font-medium text-sm font-roboto">
          <div className="text-[#393e46] uppercase  font-medium">
            {row.invoicePaymentStatus}
          </div>
        </div>
      ),
    },
    {
      name: "Paid Amount",
      selector: "invoicePaidAmount",
      sortable: true,
      cell: (row) => (
        <div className=" flex justify-center  items-center w-full  py-3 text-[#393e46] text-center font-medium text-sm font-roboto">
          <div className="text-[#393e46] uppercase  font-medium">
            {row.invoicePaidAmount ? row.invoicePaidAmount : 0}
          </div>
        </div>
      ),
    },
    {
      name: "Paid Date",
      selector: "invoicePaymentDate",
      sortable: true,
      cell: (row) => (
        <div className=" flex justify-center  items-center w-full  py-3 text-[#393e46] text-center font-medium text-sm font-roboto">
          <div className="text-[#393e46] uppercase  font-medium">
            { row.invoicePaymentDate ? row.invoicePaymentDate : " - "}
          </div>
        </div>
      ),
    },
    {
      name: "Action",
      selector: "id",
      sortable: false,
      cell: (row) => (
        <div className=" text-[#393e46]  font-medium   uppercase text-center text-sm font-roboto">
          <Link
            to={`/create-invoice/${row.id}`}
            className="text-center text-blue-600 flex justify-center items-center w-full hover:text-blue-800"
          >
            <img className=" w-8 " src={eye} alt="" />
          </Link>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className=" w-full">
        <div className="flex w-full flex-wrap justify-around items-center  ">
          <div className="flex flex-wrap items-center justify-around w-full bg-white rounded-lg py-4 mt-10  shadow-lg h-auto mb-4  labelInputContainer">
            <Dropdown
              lableName={"Company"}
              options={companyList}
              updateValue={setCompanyId}
              placeholder={"Company"}
              widthForSelect={"w-[280px]"}
              objName={"companyName"}
              value={companyId}
              local = {"companyIdOutstationInvoice"}
              />
            <InputBoxWithLabel
              label={"From Date"}
              type={"date"}
              value={fromDate}
              onChange={(e) => {
                setFromDate(e.target.value);
                localStorage.setItem("openButton", e.target.value);
              }}
            />
            <InputBoxWithLabel
              label={"To Date"}
              type={"date"}
              value={toDate}
              onChange={(e) => {
                setToDate(e.target.value);
                localStorage.setItem("openButton", e.target.value);
              }}
            />{" "}
          </div>
        </div>
      </div>
      <div className={openTab === 2 ? "block" : "hidden"} id="link1">
        <div className="flex flex-wrap -mx-4">
          <div className="container w-full h-auto ">
            <div className="w-auto h-auto p-2 m-2    ">
              <div className="flex flex-wrap w-full">
                <div className="w-full ">
                  <div className="flex flex-wrap items-center justify-center w-full h-auto  ">
                    <div className="flex flex-col w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5">
                      <div className="flex w-auto h-full mx-5">
                        <button
                          className={
                            "text-xs font-bold uppercase px-5 py-3  w-full rounded block leading-normal " +
                            (openButton === 1
                              ? "text-[#222831] bg-[#00ADB5] "
                              : "text-[#00ADB5] bg-[#222831]")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenButton(1);
                            setInvoicePaymentStatus("Not Paid");
                            localStorage.setItem("openButton", 1);
                          }}
                          data-toggle="tab"
                          href="#link2"
                          role="tablist"
                        >
                          Not Paid
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-col w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5">
                      <div className="flex w-auto h-full mx-5">
                        <button
                          className={
                            "text-xs font-bold uppercase px-5 py-3  w-full rounded block leading-normal " +
                            (openButton === 2
                              ? "text-[#222831] bg-[#00ADB5] "
                              : "text-[#00ADB5] bg-[#222831]")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenButton(2);
                            setInvoicePaymentStatus("On Hold");
                            localStorage.setItem("openButton", 2);
                          }}
                          data-toggle="tab"
                          href="#link2"
                          role="tablist"
                        >
                          On Hold
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-col w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5">
                      <div className="flex w-auto h-full mx-5">
                        <button
                          className={
                            "text-xs font-bold uppercase px-5 py-3  w-full rounded block leading-normal " +
                            (openButton === 3
                              ? "text-[#222831] bg-[#00ADB5] "
                              : "text-[#00ADB5] bg-[#222831]")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenButton(3);
                            setInvoicePaymentStatus("Paid");
                            localStorage.setItem("openButton", 3);
                          }}
                        >
                          Paid
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-col invisible w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5"></div>
                    <div className="flex flex-col invisible w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5"></div>
                    <div className="flex flex-col invisible w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5"></div>

                    <div className="flex w-full flex-col gap-4 mt-8">
                      <div className="table w-full h-auto ">
                        <div className="w-full h-auto rounded-lg shadow-xl shadow-slate-250">
                          <div className="flex w-full flex-col gap-6   rounded-lg shadow-md">
                            <Table
                              columns={columns}
                              rows={outstationTripList}
                              headerStyle={[
                                "text-center",
                                "text-center",
                                " text-center",
                                "text-center",
                                "text-center",
                                "text-center",
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
