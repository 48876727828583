import React from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import ExcelJS from "exceljs";
import Table from "../../Driver/Table";

export default function VehicleTable({ rows, rowsXlVt }) {
  const columns = [
    {
      name: "Sr. No",
      selector: "srNo",
      width: "10%",
      sortable: false,
      cell: (row, index) => (
        <div className="py-3 text-[#393e46] text-center cursor-pointer font-medium text-sm font-sans hover:transform hover:scale-125 hover:font-base transition duration-300 ease-in-out">
          {row.serialNumber}
        </div>
      ),
    },
    {
      name: "Vehicle Number",
      selector: "vehicleNo",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#393e46] text-center font-medium text-sm font-sans">
          <span className="text-[#393e46] uppercase w-full  font-bold">
            {row.driver && row.driver.vehicleNo ? row.driver.vehicleNo : "-"}
          </span>
        </div>
      ),
    },
    {
      name: "Driver ID",
      selector: "driverID",
      sortable: true,
      cell: (row) => (
        <div className="py-3 w-full text-[#393e46] text-center cursor-pointer font-medium text-sm font-sans hover:transform hover:scale-125 hover:font-base transition duration-300 ease-in-out">
          {/* <Link to={`/view-driver/${row.id}`}> */}
          <span className="py-3 w-full text-center text-sm font-sans text-[#323EDD] cursor-pointer font-semibold">
            {row.driver && row.driver.driverID ? row.driver.driverID : "-"}
          </span>
          {/* </Link> */}
        </div>
      ),
    },
    {
      name: "Driver Name",
      selector: "driverName",
      width: "20%",
      sortable: true,
      cell: (row) => (
        <div className="text-[#393e46]  w-full    font-medium text-sm font-sans">
          <div className="text-[#393e46] uppercase w-full pl-2 font-bold  ">
            {row.driver && row.driver.driverName ? row.driver.driverName : "-"}
          </div>
        </div>
      ),
    },
    {
      name: "Trips Completed",
      selector: "totalCount",
      sortable: true,
      cell: (row) => (
        <div className="py-3 w-full text-[#393e46] text-center font-medium text-sm font-sans">
          <span className="text-[#393e46] uppercase w-full  font-bold">
            {row.totalCount}
          </span>
        </div>
      ),
    },
  ];

  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Define header style
    const headerStyle = {
      font: { bold: true },
      alignment: { horizontal: "center" },
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "C0C0C0" } },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
      numFmt: "dd-mmm-yyyy", // Format the date if needed
      textRotation: 90, // Rotate the header text if needed
    };

    // Define cell style
    const cellStyle = {
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "DDDDDD" } },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
    };

    // Define headers and columns
    const headers = [
      "Sr. No.",
      "Vehicle Number",
      "Driver ID",
      "Driver Name",
      "No. of Trips Completed",
    ];
    const columns = [
      { key: "srNo", header: "Sr. No." },
      { key: "vehicleNumber", header: "Vehicle Number" },
      { key: "driverID", header: "Driver ID" },
      { key: "driverName", header: "Driver Name" },
      { key: "tripsCompleted", header: "No. of Trips Completed" },
    ];

    // Add header row
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.style = headerStyle;
    });

    // Add data rows
    rowsXlVt.forEach((row, index) => {
      const dataRow = worksheet.addRow([
        index + 1,
        row["Vehicle Number"],
        row["Driver ID"],
        row["Driver Name"],
        row["No. of Trips Completed"],
      ]);
      dataRow.eachCell((cell) => {
        cell.style = cellStyle;
        if (cell.col === 1) {
          // Adjust the column index if needed
          cell.alignment = { horizontal: "center" };
        }
      });
    });

    // Auto-fit column widths
    columns.forEach((column, index) => {
      const columnWidth =
        headers[index].length < column.header.length
          ? column.header.length
          : headers[index].length;
      worksheet.getColumn(index + 1).width =
        columnWidth < 12 ? 12 : columnWidth;
    });

    // Generate buffer
    const excelBuffer = await workbook.xlsx.writeBuffer();

    // Create and download the file
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}${currentDate
      .toLocaleString("default", { month: "long" })
      .toLowerCase()}${currentDate.getFullYear()}`;
    const fileName = `vehicle_trip_report_${formattedDate}.xlsx`;

    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
  };

  return (
    <>
      <>
        <div className="flex justify-end w-full pr-5 pt-5 mb-5 mr-10 ">
          <div className="flex">
            <Link className="bg-[#00adb5] flex justify-between items-center  hover:bg-[#059299] active:bg-[#047481] text-gray-100 text-xl font-bold text-center p-3 pl-5 pr-5 rounded-lg ">
              <span onClick={handleExport} className="mr-2">
                Download Now
              </span>

              <svg
                className="text-2xl "
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-download"
                viewBox="0 0 16 16"
              >
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
              </svg>
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="table w-full h-auto mx-auto">
            <div className="w-full h-auto rounded-lg shadow-xl shadow-slate-250">
              <div className="flex flex-col gap-6  w-full">
                <Table
                  columns={columns}
                  rows={rows}
                  headerStyle={[
                    "text-center ",
                    "text-center",
                    "text-center",
                    "text-center ",
                    "text-center",
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
