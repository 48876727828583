import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../Layout/Layout";
import DataTable from "react-data-table-component";
import axios from "axios";
import Dropdown from "../Driver/DropDown";
import Table from "../Driver/Table";

export default function ViewEmployee() {
  const [rows, setRows] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [searchQuery, setSearchQuery] = useState(
    window.localStorage.getItem("searchQuery") || ""
  );
  const [companyId, setCompanyId] = useState(
    window.localStorage.getItem("companyId") || ""
  );

  const handleQueryChange = (e) => {
    setSearchQuery(e.target.value);
    localStorage.setItem("searchQuery", e.target.value);
  };

  useEffect(() => {
    if(localStorage.getItem("userType") == "supervisor" ){
      alert("You are not authorized to access this page");
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("companyId", companyId);
  }, [companyId]);

  useEffect(() => {
    getCompany();
    const fetchData = async () => {
      await getEmployees();
    };
    fetchData();
  }, [searchQuery, companyId]);

  const getCompany = async () => {
    try {
        const response = await axios.get(
            window.api + "company/getCompanyList",
            {
                headers: {
                    "x-access-token": localStorage.getItem("accessToken"),
                    "email": localStorage.getItem("email"),
                    "usertype": localStorage.getItem("userType")
                }
            }
        );
        
        if (response.status === 200) {
            const companyList = response.data.data;
            setCompanyList(companyList);
        }
    } catch (error) {
        console.error("Error getting company list:", error);
        
        if (error.response && error.response.status === 401) {
            localStorage.clear();
            window.location.href = '/';
        }
    }
};


const getEmployees = async () => {
  try {
    const response = await axios.get(window.api + "employee/getEmployees", {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "email": localStorage.getItem("email"),
        "usertype": localStorage.getItem("userType")
      },
      params: {
        searchQuery,
        companyId,
      },
    });

    if (response.status === 200) {
      const employees = response.data.data;
      setRows(employees);
    }
  } catch (e) {
    console.error("Error getting employees:", e);
    if (e.response && e.response.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    }
  }
};


  window.onbeforeunload = () => {
    localStorage.removeItem("searchQuery");
    localStorage.removeItem("companyId");
  };

  const columns = [
    {
      name: "Employee ID",
      selector: "employeeId",
      sortable: true,
      cell: (row) => (
        <Link to={`/view-employee/${row.id}`}>
          <div className="py-3  text-[#393e46]   w-full font-medium text-sm font-roboto">
            <span className="  text-sm w-full  text-center font-roboto uppercase text-[#323EDD] cursor-pointer font-medium">
              {row.employeeId}
            </span>
          </div>
        </Link>
      ),
    },
    {
      name: "Employee Name",
      selector: "employeeName",
      sortable: true,
      cell: (row) => (
        <div className="py-3  text-[#393e46] text-left w-full font-medium text-sm font-roboto">
          <span className="py-3 text-sm font-roboto uppercase text-[#393e46]  font-medium cursor-pointer">
            {row.employeeName}
          </span>
        </div>
      ),
    },
    {
      name: "Company Name",
      selector: "companyName",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#393e46]  w-full font-medium text-sm font-roboto">
          <span className="py-3 text-center   text-sm font-roboto uppercase text-[#393e46]  font-medium cursor-pointer">
            {row.company.companyName}
          </span>
        </div>
      ),
    },
    {
      name: "Gender",
      selector: "gender",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#393e46] text-center w-full font-medium text-sm font-roboto">
          <span className="py-3   text-sm font-roboto uppercase text-[#393e46]  font-medium cursor-pointer">
            {row.gender}
          </span>
        </div>
      ),
    },
    {
      name: "Mobile Number",
      selector: "mobileNo",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#393e46] text-center w-full font-medium text-sm font-roboto">
          <span className="py-3  text-sm font-roboto uppercase text-[#393e46]  font-medium cursor-pointer">
            {row.mobileNo}
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <Layout>
        <div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col items-center justify-between px-4 py-3 mx-0 mt-2 bg-white border-2 md:flex-row border-1 rounded-xl">
              <form className="mb-2 md:mb-0 md:w-auto md:mr-4">
                <label
                  htmlFor="default-search"
                  className="mb-2 text-sm font-medium text-gray-900 sr-only"
                >
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={handleQueryChange}
                    className="border-2 text-[#393E46] font-normal font-roboto text-sm rounded-lg block w-full pl-10 p-2  "
                    placeholder="Search"
                    required
                  />
                </div>
              </form>

              <div className="flex flex-row gap-2">
                <Dropdown
                  lableName={"Company"}
                  placeholder={"Select Company "}
                  updateValue={setCompanyId}
                  options={companyList}
                  widthForSelect={"w-[250px]"}
                  objName={"companyName"}
                  value={companyId}
                />

                {/* <select
                  onChange={handleCompanyNameChange}
                  value={companyId}
                  className="mb-2 md:mb-0 w-full md:w-auto md:mr-4 border-2 text-[#393E46] font-normal font-roboto text-sm rounded-lg p-2 bg-[#F0F0F0] border-[#D6D6D6] focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">All Company</option>
                  {companyList &&
                    companyList.map((company) => (
                      <option key={company.id} value={company.id}>
                        {company.companyName}
                      </option>
                    ))}
                </select> */}
              </div>
            </div>
            <div className="table w-full h-auto ">
              <div className="w-full h-auto rounded-lg shadow-xl shadow-slate-250">
                <div className="flex flex-col gap-6  w-full  rounded-lg shadow-md">
                  {/* <DataTable
                    columns={columns}
                    data={rows}
                    noHeader
                    pagination
                    highlightOnHover
                    pointerOnHover
                    className="table"
                    customStyles={customStyles}
                    paginationPerPage={25}
                  /> */}
                  <Table
                    columns={columns}
                    rows={rows}
                    headerStyle={[
                      " text-center ",
                      "text-left pl-0 ",
                      "text-center",

                      "text-center  ",
                      "text-center ",
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
