import React, { useEffect } from "react";

import AdminLayout from "./AdminLayout";

export default function Admin() {
  useEffect(() => {
    if (localStorage.getItem("userType") != "superAdminPlus") {
      alert("You are not authorized to access this page");
      window.location.href = "/";
    }
  }, []);

  return (
    <>
      <AdminLayout />
    </>
  );
}
