import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import moment from "moment-timezone";
import backButton from "../../assets/Icons/IconsForPages/backArrow.png";
import user from "../../assets/images/user.svg";
import Swal from "sweetalert2";
import { Toaster, toast } from "react-hot-toast";
import axios from "axios";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import Layout from "../../Layout/Layout";
import InputBoxWithLabel from "./LabelInput";
import PhoneInputDD from "./PhoneInput";
import Dropdown from "./DropDownDriver";
import "font-awesome/css/font-awesome.min.css";
import DateUtils from "../../Utility/DateUtils";

export default function AddDriver({ props }) {
  const [driverNo, setDriverNo] = useState("CCD0001");
  const [driverName, setDriverName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [vehicleName, setVehicleName] = useState("");
  const [vehicleNo, setVehicleNo] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [driverStatus, setDriverStatus] = useState("");

  const [tempStreetAddress, setTempStreetAddress] = useState("");
  const [tempHouseNo, setTempHouseNo] = useState("");
  const [selectedTemState, setSelectedTemState] = useState("");
  const [selectedTemDistrict, setSelectedTemDistrict] = useState("");
  const [tempPincode, setTempPincode] = useState("");

  const [perStreetAddress, setPerStreetAddress] = useState("");
  const [perHouseNo, setPerHouseNo] = useState("");
  const [selectedPerState, setSelectedPerState] = useState("");
  const [selectedPerDistrict, setSelectedPerDistrict] = useState("");
  const [perPincode, setPerPincode] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const [upiId, setUpiId] = useState("");
  const [aadharNum, setAadharNum] = useState("");
  const [insuranceExpDate, setInsuranceExpDate] = useState(
    DateUtils.getCurrentDate
  );
  const [licenseExpDate, setLicenseExpDate] = useState(
    DateUtils.getCurrentDate
  );
  const [licenseNumber, setLicenseNumber] = useState("");
  const [permitExpDate, setPermitExpDate] = useState(DateUtils.getCurrentDate);
  const [taxExpDate, setTaxExpDate] = useState(DateUtils.getCurrentDate);
  const [stateList, setStateList] = useState([]);
  const [statesWithIds, setStatesWithIds] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [districtPerList, setDistrictPerList] = useState([]);
  const [isAddressPermanent, setAddressPermanent] = useState(false);

  const [adhaarUrls, setAdhaarUrls] = useState([]);
  const [dlUrls, setDlUrls] = useState([]);
  const [pcUrls, setPcUrls] = useState([]);
  const [cvUrls, setCvUrls] = useState([]);
  const [policeCUrls, setPoliceCUrls] = useState([]);
  const [fcUrls, setFcUrls] = useState([]);
  const [rcUrls, setRcUrls] = useState([]);

  const [ViewDriver, setViewDriver] = useState(true);

  const [pageTitle, setPageTitle] = useState("");
  const [driverError, setDriverError] = useState("");
  const [mobileNoError, setMobileNoError] = useState("");
  const [vehicleNameError, setVehicleNameError] = useState("");
  const [vehicleNoError, setVehicleNoError] = useState("");
  const [vehicleTypeError, setVehicleTypeError] = useState("");
  const [superAdminPlus, setSuperAdminPlus] = useState(false);

  useEffect(() => {
    if(localStorage.getItem("userType") == "supervisor" ){
      alert("You are not authorized to access this page");
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("userType") == "superAdminPlus") {
      setSuperAdminPlus(true);
    }
  }, []);

  const navigate = useNavigate();
  const { id } = useParams();

  const handleEditClick = () => {
    setViewDriver(false);
    setPageTitle("Update Driver");
  };

  useEffect(() => {
    getStates();
    setPageTitle(id ? "Driver Details" : "Driver Registration");
    if (!id) {
      setViewDriver(false);
      getLatestDriver();
    } else {
      fetchData();
    }
  }, [id]);

  const getStates = async () => {
    try {
      const response = await axios.get(window.api + "driver/getStates");

      if (response.status === 200) {
        const states = response.data.data.map((state) => state.stateName);
        const statesWithIds = response.data.data;
        setStateList(states);
        setStatesWithIds(statesWithIds);
      }
    } catch (error) {
      console.error("Error getting company list:", error);
    }
  };

  useEffect(() => {
    const fetchDistricts = async () => {
      const stateId = getIdByStateName(selectedTemState);

      try {
        const response = await axios.get(window.api + "driver/getDistricts", {
          params: { id: stateId },
        });

        if (response.status === 200) {
          const districts = response.data.data.map(
            (district) => district.districtName
          );
          setDistrictList(districts);
        }
      } catch (error) {
        console.error("Error getting districts:", error);
      }
    };

    fetchDistricts();
  }, [selectedTemState]);

  useEffect(() => {
    const fetchDistricts = async () => {
      const stateId = getIdByStateName(selectedPerState);

      try {
        const response = await axios.get(window.api + "driver/getDistricts", {
          params: { id: stateId },
        });

        if (response.status === 200) {
          const districts = response.data.data.map(
            (district) => district.districtName
          );
          setDistrictPerList(districts);
        }
      } catch (error) {
        console.error("Error getting districts:", error);
      }
    };

    fetchDistricts();
  }, [selectedPerState]);

  const getIdByStateName = (stateName) => {
    const state = statesWithIds.find((s) => s.stateName === stateName);
    return state ? state.id : null;
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        window.api + "driver/getDriverOne",
        {
          params: {
            id: id,
          },
          headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "email": localStorage.getItem("email"),
            "usertype": localStorage.getItem("userType")
          }
        }
      );
  
      if (response.status === 200) {
        const driverData = response.data.data;
        setDriverNo(driverData.driverID);
        setDriverName(driverData.driverName);
        setMobileNo(driverData.mobileNo);
        setVehicleName(driverData.vehicleName);
        setVehicleNo(driverData.vehicleNo);
        setVehicleType(driverData.vehicleType);
        setBloodGroup(driverData.bloodGroup);
        setDriverStatus(driverData.driverStatus);
        setTempStreetAddress(driverData.tempStreetAddress);
        setTempHouseNo(driverData.tempHouseNo);
        setSelectedTemState(driverData.selectedTemState);
        setSelectedTemDistrict(driverData.selectedTemDistrict);
        setTempPincode(driverData.tempPincode);
        setPerStreetAddress(driverData.perStreetAddress);
        setPerHouseNo(driverData.perHouseNo);
        setSelectedPerState(driverData.selectedPerState);
        setSelectedPerDistrict(driverData.selectedPerDistrict);
        setPerPincode(driverData.perPincode);
        setSelectedImage(driverData.image);
        setUpiId(driverData.upiId);
        setAadharNum(driverData.aadharNum);
        setAdhaarUrls(driverData.adhaarUrls);
        setLicenseNumber(driverData.licenseNumber);
        setDlUrls(driverData.dlUrls);
        setLicenseExpDate(driverData.licExpDate);
        setPermitExpDate(driverData.permitExpDate);
        setInsuranceExpDate(driverData.insExpDate);
        setTaxExpDate(driverData.taxExpDate);
        setPcUrls(driverData.pcUrls);
        setCvUrls(driverData.cvUrls);
        setPoliceCUrls(driverData.policeCUrls);
        setRcUrls(driverData.rcUrls);
        setFcUrls(driverData.fcUrls);
        setSelectedImage(driverData.selectedImage);
        setAddressPermanent(driverData.isAddressPermanent);
      }
    } catch (e) {
      console.error("Error fetching driver data:", e);
      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      }
    }
  }
  

  async function getLatestDriver() {
    try {
      const response = await axios.post(
        window.api + "driver/getLatestDriver",
        {},
        {
          headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "email": localStorage.getItem("email"),
            "usertype": localStorage.getItem("userType")
          }
        }
      );
  
      if (response.status === 200) {
        const latestDriver = response.data.data;
        // Process the latestDriver data as needed
        setDriverNo(latestDriver);
      }
    } catch (e) {
      console.error("Failed to get the latest driver:", e);
  
      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      } else {
        toast.error(e.response.data.message, {
          autoClose: false,
          closeButton: true,
          className: "toast-error",
          bodyClassName: "toast-error-body",
        });
      }
    }
  }
  

  const handleAddDriver = async (e) => {
    e.preventDefault();

    if (handleFormValidation()) {
      const data = {
        driverID: driverNo,
        driverName: driverName,
        mobileNo: mobileNo,
        vehicleName: vehicleName,
        vehicleNo: vehicleNo,
        vehicleType: vehicleType,
        bloodGroup: bloodGroup,
        driverStatus: driverStatus,
        tempStreetAddress: tempStreetAddress,
        tempHouseNo: tempHouseNo,
        selectedTemState: selectedTemState,
        selectedTemDistrict: selectedTemDistrict,
        tempPincode: tempPincode,
        perStreetAddress: perStreetAddress,
        perHouseNo: perHouseNo,
        selectedPerState: selectedPerState,
        selectedPerDistrict: selectedPerDistrict,
        perPincode: perPincode,
        upiId: upiId,
        aadharNum: aadharNum,
        adhaarUrls: adhaarUrls,
        licenseNumber: licenseNumber,
        dlUrls: dlUrls,
        licExpDate: licenseExpDate,
        permitExpDate: permitExpDate,
        insExpDate: insuranceExpDate,
        taxExpDate: taxExpDate,
        pcUrls: pcUrls,
        cvUrls: cvUrls,
        policeCUrls: policeCUrls,
        fcUrls: fcUrls,
        rcUrls: rcUrls,
        selectedImage: selectedImage,
        isAddressPermanent: isAddressPermanent,
      };

      try {
        const response = await axios.post(window.api + "driver/create", data, {
          headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            email: localStorage.getItem("email"),
            usertype: localStorage.getItem("userType"),
          },
        });

        if (response.status === 200) {
  //         let messages = `Dear ${driverName},
  // \nWelcome to Chennai Cabs! We are thrilled to have you join our team of expert drivers. Your driver ID is *${driverNo}*.
  // \nThank you for choosing to work with Chennai Cabs. We look forward to a Great partnership with you!
  // \n*Best regards*,
  // Chennai Cabs Team`;

  try {
    const response = await axios.post(
      'https://backend.aisensy.com/campaign/t1/api/v2',
      {
        apiKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1NmMyZWViZTIyNWZhNDE5ZTQ0ZWM0MSIsIm5hbWUiOiJDaGVubmFpIENiYXMiLCJhcHBOYW1lIjoiQWlTZW5zeSIsImNsaWVudElkIjoiNjU2YzJlZWFlMjI1ZmE0MTllNDRlYzNhIiwiYWN0aXZlUGxhbiI6IkJBU0lDX01PTlRITFkiLCJpYXQiOjE3MDE1ODg3MTV9.tenhwxFtZaO17Epki82dEqYjMjyMeeHWF_mLsravAqA',
        campaignName: '1123_welcome_driver_new_01_join_group',
        destination: `+91${mobileNo}`,
        userName: 'mukundpasi.developer@gmail.com',
        templateParams: [
          `${driverName}`,
          `${driverNo}`,
        ]
      }
    );
    console.log('SMS sent successfully:', response.data);
  } catch (error) {
    console.log('Error sending SMS: ', error);
  }
  

          // try {
          //   await axios.get(
          //     `https://api.textmebot.com/send.php?recipient=+91${mobileNo}&apikey=Hwd2BzkcxSY4&text=${encodeURIComponent(
          //       messages
          //     )}`
          //   );
          // } catch (error) {
          //   console.log("Error sending SMS: ", error);
          // }

          toast("New Driver Added Successfully!", {
            icon: "👏",
            style: {
              borderRadius: "7px",
              background: "#222831",
              color: "#fff",
            },
          });

          setTimeout(() => {
            navigate("/driver-list");
          }, 2000);
        }
      } catch (e) {
        console.error("Errors adding driver: ", e);

        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        } else {
          toast.error(e.response.data.message, {
            autoClose: false,
            closeButton: true,
            className: "toast-error",
            bodyClassName: "toast-error-body",
          });
        }
      }
    }
  };

  const handleUpdateDriver = async (e) => {
    e.preventDefault();
    
    if (handleFormValidation()) {
      const data = {
        driverID: driverNo,
        driverName: driverName,
        mobileNo: mobileNo,
        vehicleName: vehicleName,
        vehicleNo: vehicleNo,
        vehicleType: vehicleType,
        bloodGroup: bloodGroup,
        driverStatus: driverStatus,
        tempStreetAddress: tempStreetAddress,
        tempHouseNo: tempHouseNo,
        selectedTemState: selectedTemState,
        selectedTemDistrict: selectedTemDistrict,
        tempPincode: tempPincode,
        perStreetAddress: perStreetAddress,
        perHouseNo: perHouseNo,
        selectedPerState: selectedPerState,
        selectedPerDistrict: selectedPerDistrict,
        perPincode: perPincode,
        upiId: upiId,
        aadharNum: aadharNum,
        adhaarUrls: adhaarUrls,
        licenseNumber: licenseNumber,
        dlUrls: dlUrls,
        licExpDate: licenseExpDate,
        permitExpDate: permitExpDate,
        insExpDate: insuranceExpDate,
        taxExpDate: taxExpDate,
        pcUrls: pcUrls,
        cvUrls: cvUrls,
        policeCUrls: policeCUrls,
        fcUrls: fcUrls,
        rcUrls: rcUrls,
        selectedImage: selectedImage,
        isAddressPermanent: isAddressPermanent,
      };
  
      try {
        const response = await axios.put(
          window.api + `driver/update/${id}`,
          data,
          {
            headers: {
              "x-access-token": localStorage.getItem("accessToken"),
              "email": localStorage.getItem("email"),
              "usertype": localStorage.getItem("userType")
            }
          }
        );
  
        if (response.status === 200) {
          toast("Driver Updated Successfully!", {
            icon: "👏",
            style: {
              borderRadius: "7px",
              background: "#222831",
              color: "#fff",
            },
          });
  
          setTimeout(() => {
            navigate("/driver-list");
          }, 2000);
        }
      } catch (e) {
        console.error("Errors updating driver: ", e);
  
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          toast.error(e.response.data.message, {
            autoClose: false,
            closeButton: true,
            className: "toast-error",
            bodyClassName: "toast-error-body",
          });
        }
      }
    }
  };
  
  const handleFormValidation = () => {
    let formIsValid = true;

    if (!driverName) {
      formIsValid = false;
      setDriverError("Please enter driver name");
    } else {
      setDriverError("");
    }

    if (!mobileNo) {
      formIsValid = false;
      setMobileNoError("Please enter mobile number");
    } else if (!/^[6-9]\d{9}$/.test(mobileNo)) {
      formIsValid = false;
      setMobileNoError("Please enter a valid Indian mobile number");
    } else {
      setMobileNoError("");
    }

    if (!vehicleName) {
      formIsValid = false;
      setVehicleNameError("Please enter vehicle name");
    } else {
      setVehicleNameError("");
    }
    if (!vehicleNo) {
      formIsValid = false;
      setVehicleNoError("Please enter vehicle number");
    } else {
      setVehicleNoError("");
    }

    if (!vehicleType) {
      formIsValid = false;
      setVehicleTypeError("Please select vehicle type");
    } else {
      setVehicleTypeError("");
    }

    return formIsValid;
  };

  const handleDeleteDriver = async () => {
    const result = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You are about to delete this driver. This action cannot be undone!",
      confirmButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
  
    if (result.isConfirmed) {
      try {
        await axios.put(
          window.api + `driver/delete/${id}`,
          {},
          {
            headers: {
              "x-access-token": localStorage.getItem("accessToken"),
              "email": localStorage.getItem("email"),
              "usertype": localStorage.getItem("userType")
            }
          }
        );
        navigate("/driver-list");
      } catch (e) {
        console.error("Error deleting driver:", e);
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        }
      }
    }
  }
  

  const handleCheckboxChange = (event) => {
    setAddressPermanent(event.target.checked);
    if (event.target.checked) {
      setPerStreetAddress(tempStreetAddress);
      setPerHouseNo(tempHouseNo);
      setSelectedPerState(selectedTemState);
      setSelectedPerDistrict(selectedTemDistrict);
      setPerPincode(tempPincode);
    } else {
      setPerStreetAddress("");
      setPerHouseNo("");
      setSelectedPerState("");
      setSelectedPerDistrict("");
      setPerPincode("");
    }
  };

  const uploadAdhaar = async (files) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await axios.post(
        window.api + "driver/uploadDocument",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status && response.data.data) {
        setAdhaarUrls(response.data.data);
      }
    } catch (error) {
      console.error("Error uploading documents:", error);
    }
  };

  const uploadDL = async (files) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await axios.post(
        window.api + "driver/uploadDocument",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status && response.data.data) {
        setDlUrls(response.data.data);
      }
    } catch (error) {
      console.error("Error uploading documents:", error);
    }
  };

  const uploadPollutionCertificate = async (files) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await axios.post(
        window.api + "driver/uploadDocument",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status && response.data.data) {
        setPcUrls(response.data.data);
      }
    } catch (error) {
      console.error("Error uploading documents:", error);
    }
  };

  const uploadCovidVaccineCertificate = async (files) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await axios.post(
        window.api + "driver/uploadDocument",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status && response.data.data) {
        setCvUrls(response.data.data);
      }
    } catch (error) {
      console.error("Error uploading documents:", error);
    }
  };

  const uploadPoliceCertificate = async (files) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await axios.post(
        window.api + "driver/uploadDocument",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status && response.data.data) {
        setPoliceCUrls(response.data.data);
      }
    } catch (error) {
      console.error("Error uploading documents:", error);
    }
  };

  const uploadFc = async (files) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await axios.post(
        window.api + "driver/uploadDocument",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status && response.data.data) {
        setFcUrls(response.data.data);
      }
    } catch (error) {
      console.error("Error uploading documents:", error);
    }
  };

  const uploadRc = async (files) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await axios.post(
        window.api + "driver/uploadDocument",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status && response.data.data) {
        setRcUrls(response.data.data);
      }
    } catch (error) {
      console.error("Error uploading documents:", error);
    }
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Prepare formData for API call
      const formData = new FormData();
      formData.append("images", file);

      try {
        const response = await axios.post(
          window.api + "driver/uploadDocument",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.status && response.data.data) {
          setSelectedImage(response.data.data);
        }
      } catch (error) {
        console.error("Error uploading documents:", error);
      }
    }
  };

  const handleAdhaarDelete = (index, event) => {
    event.stopPropagation();
    const newUrls = adhaarUrls.filter((_, idx) => idx !== index);
    setAdhaarUrls(newUrls);
  };

  const handleDLDelete = (index, event) => {
    event.stopPropagation();
    const newUrls = dlUrls.filter((_, idx) => idx !== index);
    setDlUrls(newUrls);
  };

  const handlePCDelete = (index, event) => {
    event.stopPropagation();
    const newUrls = pcUrls.filter((_, idx) => idx !== index);
    setPcUrls(newUrls);
  };

  const handleCVDelete = (index, event) => {
    event.stopPropagation();
    const newUrls = cvUrls.filter((_, idx) => idx !== index);
    setCvUrls(newUrls);
  };

  const handlePoliceCDelete = (index, event) => {
    event.stopPropagation();
    const newUrls = policeCUrls.filter((_, idx) => idx !== index);
    setPoliceCUrls(newUrls);
  };

  const handleFCDelete = (index, event) => {
    event.stopPropagation();
    const newUrls = fcUrls.filter((_, idx) => idx !== index);
    setFcUrls(newUrls);
  };

  const handleRCDelete = (index, event) => {
    event.stopPropagation();
    const newUrls = rcUrls.filter((_, idx) => idx !== index);
    setRcUrls(newUrls);
  };

  return (
    <Layout>
      <div>
        <Toaster />
        <div className="w-full h-full bg-white rounded-md">
          <div className="container w-full">
            <div className="flex flex-wrap items-center justify-start w-full ">
              <div className="flex flex-wrap ">
                <div className="p-2 ml-8 ">
                  <Link
                    to={"/driver-list"}
                    className="flex items-center justify-center w-10 h-10 mt-3 text-white cursor-pointer  shadow-lgrounded-xl "
                  >
                    <img src={backButton} className="w-5" alt="" srcset="" />
                  </Link>
                </div>
              </div>
              {!ViewDriver ? null : (
                <div className="flex p-2 ">
                  {!superAdminPlus ? null : (
                    <div
                      className="p-2 bg-white rounded-lg shadow-md cursor-pointer drop-shadow-md hover:bg-gray-200 active:bg-gray-400"
                      onClick={handleDeleteDriver}
                    >
                      <RiDeleteBin6Line className="text-[#ff0000] w-6 h-6" />
                    </div>
                  )}
                  <div
                    onClick={handleEditClick}
                    className="p-2 ml-4 bg-white rounded-lg shadow-md cursor-pointer drop-shadow-md hover:bg-gray-200 active:bg-gray-400"
                  >
                    <FiEdit className="text-[#38e54d] w-6 h-6 " />
                  </div>
                </div>
              )}
            </div>
            <div className="content-center mb-3 text-center ">
              <h1 className="font-bold text-[25px] text-black justify-center text-center">
                {pageTitle}
              </h1>
            </div>
            <div className="flex items-center justify-center object-center gap-5">
              <div className="flex items-center justify-center w-full ">
                <div className="flex  justify-center items-center  p-1 bg-[#fff] border-[#F9F5FF] border rounded-full">
                  <div className=" flex relative justify-center items-center rounded-full w-24 h-24 bg-[#7f56d924]">
                    <img
                      className="z-30 "
                      src={selectedImage || user}
                      alt="User"
                    />
                    <input
                      disabled={ViewDriver}
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: 0,
                        left: 0,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="container h-auto px-0 py-0 lg:px-1">
              <div className="container h-auto lg:px-8 lg:py-2 ">
                {/* <div className="p-4 mb-4 border border-gray-500 border-solid rounded-md shadow-md"> */}
                <div className=" rounded-md my-4 pt-2 pl-2 pb-2 pr-2  border-[#00abb3]  shadow-lgbg-white ">
                  <div>
                    <h1 className=" text-[17px] pl-7  font-[700]">
                      Personal Details
                    </h1>
                  </div>
                  <div class="flex  justify-between px-10 flex-wrap mt-2 -mx-3 mb-4">
                    <InputBoxWithLabel
                      label="Driver ID"
                      placeholder="Enter driver ID"
                      value={driverNo}
                    />
                    <InputBoxWithLabel
                      label="Enter Driver Name"
                      type="text"
                      name="driverName"
                      id="driverName"
                      placeholder="Enter Driver Name"
                      value={driverName}
                      onChange={(e) => setDriverName(e.target.value)}
                      pattern="^[A-Za-z]+$"
                      required
                      disabled={ViewDriver}
                      validationError={driverError}
                    />
                    <PhoneInputDD
                      lableName="Mobile Number"
                      placeholder="Enter Mobile Number"
                      maxLength={10}
                      minLength={10}
                      onChange={(e) => setMobileNo(e.target.value)}
                      value={mobileNo}
                      type="tel"
                      disabled={ViewDriver}
                      validationError={mobileNoError}
                    />
                  </div>
                  <div class="flex  justify-between px-10  flex-wrap mt-2 -mx-3 mb-4">
                    <InputBoxWithLabel
                      placeholder="Enter Vehicle Name"
                      onChange={(e) => setVehicleName(e.target.value)}
                      value={vehicleName}
                      label="Vehicle Name"
                      type="text"
                      disabled={ViewDriver}
                      validationError={vehicleNameError}
                    />
                    <InputBoxWithLabel
                      label="Vehicle Number"
                      placeholder="Enter Vehicle Number"
                      onChange={(e) => setVehicleNo(e.target.value)}
                      value={vehicleNo}
                      type="text"
                      disabled={ViewDriver}
                      validationError={vehicleNoError}
                    />
                    <Dropdown
                      placeholder={" Select Vehicle Type "}
                      options={["Sedan", "SUV", "Tempo", "Coach"]}
                      updateValue={setVehicleType}
                      validationError={vehicleTypeError}
                      lableName="Vehicle Type"
                      value={vehicleType}
                      disabled={ViewDriver}
                    />
                  </div>
                  <div class="flex flex-wrap mt-2 -mx-3 mb-4  justify-between px-10  gap-5 items-center ">
                    <InputBoxWithLabel
                      label="Blood Group"
                      placeholder="Enter Blood Group"
                      onChange={(e) => setBloodGroup(e.target.value)}
                      value={bloodGroup}
                      type="text"
                      disabled={ViewDriver}
                    />
                    <Dropdown
                      lableName="Driver Status"
                      placeholder="Select Status"
                      options={["Dedicated", "Part Time"]}
                      updateValue={setDriverStatus}
                      value={driverStatus}
                      disabled={ViewDriver}
                    />

                    <div className="invisible  w-[300px]"></div>
                  </div>
                  <div className="pt-3">
                    <h1 className=" text-[16px] pl-7  font-[620]">
                      Temporary Address
                    </h1>
                  </div>
                  <div class="flex flex-wrap mt-3 -mx-3 mb-4 justify-between px-10   gap-5 ">
                    <InputBoxWithLabel
                      label="Street Address"
                      placeholder="Enter Street Address"
                      onChange={(e) => setTempStreetAddress(e.target.value)}
                      value={tempStreetAddress}
                      disabled={ViewDriver}
                    />
                    <InputBoxWithLabel
                      label="House Number"
                      placeholder="Enter House Number"
                      onChange={(e) => setTempHouseNo(e.target.value)}
                      value={tempHouseNo}
                      disabled={ViewDriver}
                    />
                    <Dropdown
                      lableName="State"
                      placeholder="Select State"
                      options={stateList}
                      updateValue={setSelectedTemState}
                      value={selectedTemState}
                      disabled={ViewDriver}
                    />
                  </div>
                  <div class="flex flex-wrap mt-2 -mx-3 mb-4  justify-between px-10  gap-5 items-center ">
                    <Dropdown
                      lableName="District"
                      placeholder="Select District"
                      options={districtList}
                      updateValue={setSelectedTemDistrict}
                      value={selectedTemDistrict}
                      disabled={ViewDriver}
                    />

                    <InputBoxWithLabel
                      label="Pincode"
                      placeholder="Enter Pincode"
                      onChange={(e) => setTempPincode(e.target.value)}
                      value={tempPincode}
                      disabled={ViewDriver}
                    />
                    <div className="invisible  w-[300px]"></div>
                  </div>
                  <div>
                    <h1 className=" text-[16px] pl-7 font-[600] pt-1">
                      Permanent Address
                    </h1>
                  </div>
                  <div className="mt-2 mb-4 pl-7 ">
                    <input
                      type="checkbox"
                      name="addressCheckbox"
                      id="name"
                      checked={isAddressPermanent}
                      onChange={handleCheckboxChange}
                      disabled={ViewDriver}
                    />
                    <label className="pl-2 text-sm " htmlFor="name">
                      Set temporary address as permanent address
                    </label>
                  </div>

                  <div class="flex  justify-between px-10  flex-wrap mt-2 -mx-3 mb-4">
                    {isAddressPermanent ? (
                      <>
                        <InputBoxWithLabel
                          label="Street Address"
                          placeholder="Enter Street Address"
                          value={tempStreetAddress}
                          disabled
                        />
                        <InputBoxWithLabel
                          label="House Number"
                          placeholder="Enter House Number"
                          value={tempHouseNo}
                          disabled
                        />
                      </>
                    ) : (
                      <>
                        <InputBoxWithLabel
                          label="Street Address"
                          placeholder="Enter Street Address"
                          onChange={(e) => setPerStreetAddress(e.target.value)}
                          value={perStreetAddress}
                          disabled={ViewDriver}
                        />
                        <InputBoxWithLabel
                          label="House Number"
                          placeholder="Enter House Number"
                          onChange={(e) => setPerHouseNo(e.target.value)}
                          value={perHouseNo}
                          disabled={ViewDriver}
                        />
                      </>
                    )}
                    {isAddressPermanent ? (
                      <InputBoxWithLabel
                        label="State"
                        value={selectedTemState}
                        disabled
                      />
                    ) : (
                      <Dropdown
                        lableName="State"
                        placeholder="Select State"
                        options={stateList}
                        updateValue={setSelectedPerState}
                        value={selectedPerState}
                        disabled={ViewDriver}
                      />
                    )}
                  </div>
                  <div class="flex  justify-between px-10  flex-wrap mt-2 -mx-3 mb-4">
                    {isAddressPermanent ? (
                      <InputBoxWithLabel
                        label="District"
                        value={selectedTemDistrict}
                        disabled
                      />
                    ) : (
                      <Dropdown
                        lableName="District"
                        placeholder="Select District"
                        options={districtPerList}
                        updateValue={setSelectedPerDistrict}
                        value={selectedPerDistrict}
                        disabled={ViewDriver}
                      />
                    )}
                    {isAddressPermanent ? (
                      <InputBoxWithLabel
                        label="Pincode"
                        placeholder="Enter Pincode"
                        value={tempPincode}
                        disabled
                      />
                    ) : (
                      <InputBoxWithLabel
                        label="Pincode"
                        placeholder="Enter Pincode"
                        onChange={(e) => setPerPincode(e.target.value)}
                        value={perPincode}
                        disabled={ViewDriver}
                      />
                    )}
                    <div className="invisible  w-[300px]"></div>
                  </div>
                </div>
                <div className=" rounded-md pt-2 pl-2 pb-2 pr-2  border-[#00abb3]  shadow-lgbg-white ">
                  <div>
                    <h1 className=" text-[17px] pl-7 font-[700]">
                      Documents Details
                    </h1>
                  </div>

                  <div class="flex  justify-between px-10  flex-wrap mt-2 -mx-3 mb-4">
                    <InputBoxWithLabel
                      placeholder="Enter UPI ID"
                      onChange={(e) => setUpiId(e.target.value)}
                      value={upiId}
                      type="text"
                      disabled={ViewDriver}
                      label="UPI ID"
                    />
                    <InputBoxWithLabel
                      label="Aadhar Number"
                      placeholder="Enter Aadhar Number"
                      onChange={(e) => setAadharNum(e.target.value)}
                      value={aadharNum}
                      type="number"
                      disabled={ViewDriver}
                    />

                    <div className="hidden lg:block">
                      <label className="text-[14px] text-start mb-1 font-medium leading-5 text-gray-700 font-inter">
                        Aadhar Card
                      </label>
                      <div className="w-[300px] relative flex flex-col justify-start mt-1 pl-4 h-[45px] text-[#1849A9] py-2 text-lg bg-white border border-gray-300 rounded-md shadow-xs outline-none">
                        <input
                          disabled={ViewDriver}
                          type="file"
                          name="Adhaar"
                          id="Adhaar"
                          multiple
                          className="w-0 text-white outline-none focus:outline-0"
                          onChange={(e) => {
                            const files = Array.from(e.target.files);
                            if (files.length) uploadAdhaar(files);
                          }}
                        />
                        <label
                          for="Adhaar"
                          className="flex items-center justify-start"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="20"
                            viewBox="0 0 12 20"
                            fill="none"
                          >
                            <path
                              d="M10.3636 5.30116V15.2121C10.3636 17.1167 8.41091 18.6593 6 18.6593C3.58909 18.6593 1.63636 17.1167 1.63636 15.2121V4.43934C1.63636 3.25003 2.85818 2.28479 4.36364 2.28479C5.86909 2.28479 7.09091 3.25003 7.09091 4.43934V13.4884C7.09091 13.9624 6.60545 14.3502 6 14.3502C5.39455 14.3502 4.90909 13.9624 4.90909 13.4884V5.30116H3.27273V13.4884C3.27273 14.6777 4.49455 15.643 6 15.643C7.50545 15.643 8.72727 14.6777 8.72727 13.4884V4.43934C8.72727 2.53472 6.77455 0.992065 4.36364 0.992065C1.95273 0.992065 0 2.53472 0 4.43934V15.2121C0 17.832 2.68909 19.9521 6 19.9521C9.31091 19.9521 12 17.832 12 15.2121V5.30116H10.3636Z"
                              fill="#1849A9"
                            />
                          </svg>
                          <span className="pl-3 cursor-pointer text-[#1849A9]">
                            Add Attachment
                          </span>
                        </label>
                      </div>
                      <div className="mt-2 pl-4">
                        {" "}
                        {/* Notice the added padding to align with other elements */}
                        {adhaarUrls.map((url, index) => (
                          <span key={index} className="block mb-2">
                            <a
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Adhaar Card {index + 1}
                            </a>
                            <i
                              onClick={(e) =>
                                !ViewDriver && handleAdhaarDelete(index, e)
                              }
                              className="fa fa-times-circle ml-2"
                              style={{ color: "red", cursor: "pointer" }}
                            ></i>
                          </span>
                        ))}
                      </div>
                    </div>

                    <div className="block lg:hidden">
                      <InputBoxWithLabel
                        label="Aadhar Card"
                        placeholder="Enter Driving License"
                        type="file"
                        disabled={ViewDriver}
                      />
                    </div>
                  </div>

                  <div class="flex  justify-between px-10  flex-wrap mt-2 -mx-3 mb-4">
                    <InputBoxWithLabel
                      placeholder="Enter Driving License"
                      type="text"
                      disabled={ViewDriver}
                      label="Driving License"
                      onChange={(e) => setLicenseNumber(e.target.value)}
                      value={licenseNumber}
                    />

                    <div className="hidden lg:block">
                      <label className="text-[14px] text-start mb-1 font-medium leading-5 text-gray-700 font-inter">
                        Driving License (Front / Back)
                      </label>
                      <div className="w-[300px] relative flex flex-col justify-start mt-1 pl-4 h-[45px] text-[#1849A9] py-2 text-lg bg-white border border-gray-300 rounded-md shadow-xs outline-none">
                        <input
                          disabled={ViewDriver}
                          type="file"
                          name="DL"
                          id="DL"
                          multiple
                          className="w-0 text-white outline-none focus:outline-0"
                          onChange={(e) => {
                            const files = Array.from(e.target.files);
                            if (files.length) uploadDL(files);
                          }}
                        />
                        <label
                          for="DL"
                          className="flex items-center justify-start"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="20"
                            viewBox="0 0 12 20"
                            fill="none"
                          >
                            <path
                              d="M10.3636 5.30116V15.2121C10.3636 17.1167 8.41091 18.6593 6 18.6593C3.58909 18.6593 1.63636 17.1167 1.63636 15.2121V4.43934C1.63636 3.25003 2.85818 2.28479 4.36364 2.28479C5.86909 2.28479 7.09091 3.25003 7.09091 4.43934V13.4884C7.09091 13.9624 6.60545 14.3502 6 14.3502C5.39455 14.3502 4.90909 13.9624 4.90909 13.4884V5.30116H3.27273V13.4884C3.27273 14.6777 4.49455 15.643 6 15.643C7.50545 15.643 8.72727 14.6777 8.72727 13.4884V4.43934C8.72727 2.53472 6.77455 0.992065 4.36364 0.992065C1.95273 0.992065 0 2.53472 0 4.43934V15.2121C0 17.832 2.68909 19.9521 6 19.9521C9.31091 19.9521 12 17.832 12 15.2121V5.30116H10.3636Z"
                              fill="#1849A9"
                            />
                          </svg>
                          <span className="pl-3 cursor-pointer text-[#1849A9]">
                            Add Attachment
                          </span>
                        </label>
                      </div>
                      <div className="mt-2 pl-4">
                        {" "}
                        {/* added padding to align with other elements */}
                        {dlUrls.map((url, index) => (
                          <span key={index} className="block mb-2">
                            <a
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Driving License {index + 1}
                            </a>
                            <i
                              onClick={(e) =>
                                !ViewDriver && handleDLDelete(index, e)
                              }
                              className="fa fa-times-circle ml-2"
                              style={{ color: "red", cursor: "pointer" }}
                            ></i>
                          </span>
                        ))}
                      </div>
                    </div>

                    <div className="block lg:hidden">
                      <InputBoxWithLabel
                        label=" Driving License (Front / Back)"
                        type="file"
                        disabled={ViewDriver}
                      />
                    </div>

                    <InputBoxWithLabel
                      label="License Batch Exp"
                      type="date"
                      disabled={ViewDriver}
                      onChange={(e) => setLicenseExpDate(e.target.value)}
                      value={licenseExpDate}
                    />
                  </div>
                  <div class="flex  justify-between px-10  flex-wrap mt-2 -mx-3 mb-4">
                    <InputBoxWithLabel
                      label="Permit Exp Date"
                      type="date"
                      disabled={ViewDriver}
                      onChange={(e) => setPermitExpDate(e.target.value)}
                      value={permitExpDate}
                    />
                    <InputBoxWithLabel
                      label="Insurance Exp Date"
                      type="date"
                      disabled={ViewDriver}
                      onChange={(e) => setInsuranceExpDate(e.target.value)}
                      value={insuranceExpDate}
                    />
                    <InputBoxWithLabel
                      label="Tax Exp Date"
                      type="date"
                      disabled={ViewDriver}
                      onChange={(e) => setTaxExpDate(e.target.value)}
                      value={taxExpDate}
                    />
                  </div>
                </div>

                <div className=" rounded-md pt-2 pl-2 pb-2 pr-2  border-[#00abb3] drop-shadow-lg bg-white ">
                  <div>
                    <h1 className=" text-[17px] pl-7  font-[700]">
                      Proof Upload
                    </h1>
                  </div>
                  <div class="flex  justify-between px-10  flex-wrap mt-2 -mx-3 mb-4">
                    <div className="hidden lg:block">
                      <label className="text-[14px] text-start mb-1 font-medium leading-5 text-gray-700 font-inter">
                        Pollution Certificate
                      </label>
                      <div className="w-[300px] relative flex flex-col justify-start mt-1 pl-4 h-[45px] text-[#1849A9] py-2 text-lg bg-white border border-gray-300 rounded-md shadow-xs outline-none">
                        <input
                          disabled={ViewDriver}
                          type="file"
                          name="PC"
                          id="PC"
                          multiple
                          className="w-0 text-white outline-none focus:outline-0"
                          onChange={(e) => {
                            const files = Array.from(e.target.files);
                            if (files.length) uploadPollutionCertificate(files);
                          }}
                        />
                        <label
                          for="PC"
                          className="flex items-center justify-start"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="20"
                            viewBox="0 0 12 20"
                            fill="none"
                          >
                            <path
                              d="M10.3636 5.30116V15.2121C10.3636 17.1167 8.41091 18.6593 6 18.6593C3.58909 18.6593 1.63636 17.1167 1.63636 15.2121V4.43934C1.63636 3.25003 2.85818 2.28479 4.36364 2.28479C5.86909 2.28479 7.09091 3.25003 7.09091 4.43934V13.4884C7.09091 13.9624 6.60545 14.3502 6 14.3502C5.39455 14.3502 4.90909 13.9624 4.90909 13.4884V5.30116H3.27273V13.4884C3.27273 14.6777 4.49455 15.643 6 15.643C7.50545 15.643 8.72727 14.6777 8.72727 13.4884V4.43934C8.72727 2.53472 6.77455 0.992065 4.36364 0.992065C1.95273 0.992065 0 2.53472 0 4.43934V15.2121C0 17.832 2.68909 19.9521 6 19.9521C9.31091 19.9521 12 17.832 12 15.2121V5.30116H10.3636Z"
                              fill="#1849A9"
                            />
                          </svg>
                          <span className="pl-3 cursor-pointer text-[#1849A9]">
                            Add Attachment
                          </span>
                        </label>
                      </div>
                      <div className="mt-2 pl-4">
                        {pcUrls.map((url, index) => (
                          <span key={index} className="block mb-2">
                            <a
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Pollution Certificate {index + 1}
                            </a>
                            <i
                              onClick={(e) =>
                                !ViewDriver && handlePCDelete(index, e)
                              }
                              className="fa fa-times-circle ml-2"
                              style={{ color: "red", cursor: "pointer" }}
                            ></i>
                          </span>
                        ))}
                      </div>
                    </div>

                    <div className="block lg:hidden">
                      <InputBoxWithLabel
                        label=" Pollution Certificate"
                        type="file"
                        disabled={ViewDriver}
                      />
                    </div>

                    <div className="hidden lg:block">
                      <label className="text-[14px] text-start mb-1 font-medium leading-5 text-gray-700 font-inter">
                        Covid Vaccine Certificate
                      </label>
                      <div className="w-[300px] relative flex flex-col justify-start mt-1 pl-4 h-[45px] text-[#1849A9] py-2 text-lg bg-white border border-gray-300 rounded-md shadow-xs outline-none">
                        <input
                          disabled={ViewDriver}
                          type="file"
                          name="CVC"
                          id="CVC"
                          multiple
                          className="w-0 text-white outline-none focus:outline-0"
                          onChange={(e) => {
                            const files = Array.from(e.target.files);
                            if (files.length)
                              uploadCovidVaccineCertificate(files);
                          }}
                        />
                        <label
                          for="CVC"
                          className="flex items-center justify-start"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="20"
                            viewBox="0 0 12 20"
                            fill="none"
                          >
                            <path
                              d="M10.3636 5.30116V15.2121C10.3636 17.1167 8.41091 18.6593 6 18.6593C3.58909 18.6593 1.63636 17.1167 1.63636 15.2121V4.43934C1.63636 3.25003 2.85818 2.28479 4.36364 2.28479C5.86909 2.28479 7.09091 3.25003 7.09091 4.43934V13.4884C7.09091 13.9624 6.60545 14.3502 6 14.3502C5.39455 14.3502 4.90909 13.9624 4.90909 13.4884V5.30116H3.27273V13.4884C3.27273 14.6777 4.49455 15.643 6 15.643C7.50545 15.643 8.72727 14.6777 8.72727 13.4884V4.43934C8.72727 2.53472 6.77455 0.992065 4.36364 0.992065C1.95273 0.992065 0 2.53472 0 4.43934V15.2121C0 17.832 2.68909 19.9521 6 19.9521C9.31091 19.9521 12 17.832 12 15.2121V5.30116H10.3636Z"
                              fill="#1849A9"
                            />
                          </svg>
                          <span className="pl-3 cursor-pointer text-[#1849A9]">
                            Add Attachment
                          </span>
                        </label>
                      </div>
                      <div className="mt-2 pl-4">
                        {cvUrls.map((url, index) => (
                          <span key={index} className="block mb-2">
                            <a
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Covid Vaccine Certificate {index + 1}
                            </a>
                            <i
                              onClick={(e) =>
                                !ViewDriver && handleCVDelete(index, e)
                              }
                              className="fa fa-times-circle ml-2"
                              style={{ color: "red", cursor: "pointer" }}
                            ></i>
                          </span>
                        ))}
                      </div>
                    </div>

                    <div className="block lg:hidden">
                      <InputBoxWithLabel
                        label="Covid Vaccine Certificate"
                        type="file"
                        disabled={ViewDriver}
                      />
                    </div>

                    <div className="hidden lg:block">
                      <label className="text-[14px] text-start mb-1 font-medium leading-5 text-gray-700 font-inter">
                        Police Certificate
                      </label>
                      <div className="w-[300px] relative flex flex-col justify-start mt-1 pl-4 h-[45px] text-[#1849A9] py-2 text-lg bg-white border border-gray-300 rounded-md shadow-xs outline-none">
                        <input
                          disabled={ViewDriver}
                          type="file"
                          name="PoliceC"
                          id="PoliceC"
                          multiple
                          className="w-0 text-white outline-none focus:outline-0"
                          onChange={(e) => {
                            const files = Array.from(e.target.files);
                            if (files.length) uploadPoliceCertificate(files);
                          }}
                        />
                        <label
                          htmlFor="PoliceC"
                          className="flex items-center justify-start"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="20"
                            viewBox="0 0 12 20"
                            fill="none"
                          >
                            <path
                              d="M10.3636 5.30116V15.2121C10.3636 17.1167 8.41091 18.6593 6 18.6593C3.58909 18.6593 1.63636 17.1167 1.63636 15.2121V4.43934C1.63636 3.25003 2.85818 2.28479 4.36364 2.28479C5.86909 2.28479 7.09091 3.25003 7.09091 4.43934V13.4884C7.09091 13.9624 6.60545 14.3502 6 14.3502C5.39455 14.3502 4.90909 13.9624 4.90909 13.4884V5.30116H3.27273V13.4884C3.27273 14.6777 4.49455 15.643 6 15.643C7.50545 15.643 8.72727 14.6777 8.72727 13.4884V4.43934C8.72727 2.53472 6.77455 0.992065 4.36364 0.992065C1.95273 0.992065 0 2.53472 0 4.43934V15.2121C0 17.832 2.68909 19.9521 6 19.9521C9.31091 19.9521 12 17.832 12 15.2121V5.30116H10.3636Z"
                              fill="#1849A9"
                            />
                          </svg>
                          <span className="pl-3 cursor-pointer text-[#1849A9]">
                            Add Attachment
                          </span>
                        </label>
                      </div>
                      <div className="mt-2 pl-4">
                        {policeCUrls.map((url, index) => (
                          <span key={index} className="block mb-2">
                            <a
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Police Certificate {index + 1}
                            </a>
                            <i
                              onClick={(e) =>
                                !ViewDriver && handlePoliceCDelete(index, e)
                              }
                              className="fa fa-times-circle ml-2"
                              style={{ color: "red", cursor: "pointer" }}
                            ></i>
                          </span>
                        ))}
                      </div>
                    </div>

                    <div className="block lg:hidden">
                      <InputBoxWithLabel
                        label=" Police Certificate"
                        type="file"
                        disabled={ViewDriver}
                      />
                    </div>
                  </div>
                  <div class="flex  justify-between px-10  flex-wrap mt-2 -mx-3 mb-4">
                    <div className="hidden lg:block">
                      <label className="text-[14px] text-start mb-1 font-medium leading-5 text-gray-700 font-inter">
                        FC Copy
                      </label>
                      <div className="w-[300px] relative flex flex-col justify-start mt-1 pl-4 h-[45px] text-[#1849A9] py-2 text-lg bg-white border border-gray-300 rounded-md shadow-xs outline-none">
                        <input
                          disabled={ViewDriver}
                          type="file"
                          name="FC"
                          id="FC"
                          multiple
                          className="w-0 text-white outline-none focus:outline-0"
                          onChange={(e) => {
                            const files = Array.from(e.target.files);
                            if (files.length) uploadFc(files);
                          }}
                        />
                        <label
                          for="FC"
                          className="flex items-center justify-start"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="20"
                            viewBox="0 0 12 20"
                            fill="none"
                          >
                            <path
                              d="M10.3636 5.30116V15.2121C10.3636 17.1167 8.41091 18.6593 6 18.6593C3.58909 18.6593 1.63636 17.1167 1.63636 15.2121V4.43934C1.63636 3.25003 2.85818 2.28479 4.36364 2.28479C5.86909 2.28479 7.09091 3.25003 7.09091 4.43934V13.4884C7.09091 13.9624 6.60545 14.3502 6 14.3502C5.39455 14.3502 4.90909 13.9624 4.90909 13.4884V5.30116H3.27273V13.4884C3.27273 14.6777 4.49455 15.643 6 15.643C7.50545 15.643 8.72727 14.6777 8.72727 13.4884V4.43934C8.72727 2.53472 6.77455 0.992065 4.36364 0.992065C1.95273 0.992065 0 2.53472 0 4.43934V15.2121C0 17.832 2.68909 19.9521 6 19.9521C9.31091 19.9521 12 17.832 12 15.2121V5.30116H10.3636Z"
                              fill="#1849A9"
                            />
                          </svg>
                          <span className="pl-3 cursor-pointer text-[#1849A9]">
                            Add Attachment
                          </span>
                        </label>
                      </div>
                      <div className="mt-2 pl-4">
                        {fcUrls.map((url, index) => (
                          <span key={index} className="block mb-2">
                            <a
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              FC Copy {index + 1}
                            </a>
                            <i
                              onClick={(e) =>
                                !ViewDriver && handleFCDelete(index, e)
                              }
                              className="fa fa-times-circle ml-2"
                              style={{ color: "red", cursor: "pointer" }}
                            ></i>
                          </span>
                        ))}
                      </div>
                    </div>

                    <div className="block lg:hidden">
                      <InputBoxWithLabel
                        label=" FC Copy"
                        type="file"
                        disabled={ViewDriver}
                      />
                    </div>

                    <div className="hidden lg:block">
                      <label className="text-[14px] text-start mb-1 font-medium leading-5 text-gray-700 font-inter">
                        RC Copy
                      </label>
                      <div className="w-[300px] relative flex flex-col justify-start mt-1 pl-4 h-[45px] text-[#1849A9] py-2 text-lg bg-white border border-gray-300 rounded-md shadow-xs outline-none">
                        <input
                          disabled={ViewDriver}
                          type="file"
                          name="RC"
                          id="RC"
                          multiple
                          className="w-0 text-white outline-none focus:outline-0"
                          onChange={(e) => {
                            const files = Array.from(e.target.files);
                            if (files.length) uploadRc(files);
                          }}
                        />
                        <label
                          for="RC"
                          className="flex items-center justify-start"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="20"
                            viewBox="0 0 12 20"
                            fill="none"
                          >
                            <path
                              d="M10.3636 5.30116V15.2121C10.3636 17.1167 8.41091 18.6593 6 18.6593C3.58909 18.6593 1.63636 17.1167 1.63636 15.2121V4.43934C1.63636 3.25003 2.85818 2.28479 4.36364 2.28479C5.86909 2.28479 7.09091 3.25003 7.09091 4.43934V13.4884C7.09091 13.9624 6.60545 14.3502 6 14.3502C5.39455 14.3502 4.90909 13.9624 4.90909 13.4884V5.30116H3.27273V13.4884C3.27273 14.6777 4.49455 15.643 6 15.643C7.50545 15.643 8.72727 14.6777 8.72727 13.4884V4.43934C8.72727 2.53472 6.77455 0.992065 4.36364 0.992065C1.95273 0.992065 0 2.53472 0 4.43934V15.2121C0 17.832 2.68909 19.9521 6 19.9521C9.31091 19.9521 12 17.832 12 15.2121V5.30116H10.3636Z"
                              fill="#1849A9"
                            />
                          </svg>
                          <span className="pl-3 cursor-pointer text-[#1849A9]">
                            Add Attachment
                          </span>
                        </label>
                      </div>
                      <div className="mt-2 pl-4">
                        {rcUrls.map((url, index) => (
                          <span key={index} className="block mb-2">
                            <a
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              RC Copy {index + 1}
                            </a>
                            <i
                              onClick={(e) =>
                                !ViewDriver && handleRCDelete(index, e)
                              }
                              className="fa fa-times-circle ml-2"
                              style={{ color: "red", cursor: "pointer" }}
                            ></i>
                          </span>
                        ))}
                      </div>
                    </div>

                    <div className="block lg:hidden">
                      <InputBoxWithLabel label="RC Copy" type="file" />
                    </div>
                    <div className="invisible  w-[300px]"></div>
                  </div>
                </div>

                <div className="flex items-center justify-center w-full mt-10 mb-4 ">
                  {ViewDriver ? null : (
                    <button
                      className=""
                      onClick={id ? handleUpdateDriver : handleAddDriver}
                    >
                      <Link className="bg-[#00adb5] hover:bg-[#059299] active:bg-[#047481] text-gray-100 text-xl font-bold w-2/12 text-center  py-3 pl-10 pr-10 rounded-lg my-6">
                        {id ? "Update" : "Submit"}
                      </Link>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
