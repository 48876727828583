import DataTable from "react-data-table-component";
import ExcelJS from "exceljs";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Table from "../../Driver/Table";
import momentTimezone from 'moment-timezone';

export default function AttendanceTable({ rowsAttendance, rowsXlAttendance }) {
  const columns = [
    {
      name: "S.No",
      selector: "sno",
      width: "5%",
      sortable: true,
      cell: (row, index) => (
        <div className="py-3 w-full text-[#393e46] text-center cursor-pointer font-medium text-sm font-roboto hover:transform hover:scale-125 hover:font-base transition duration-300 ease-in-out">
          {row.serialNumber}
        </div>
      ),
    },
    {
      name: "Route ID",
      selector: "routeId",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#393e46]  w-full font-medium text-sm font-roboto">
          <span className="text-[#393e46] text-center uppercase  font-medium">
            {row.routeId}
          </span>
        </div>
      ),
    },
    {
      name: "Route Name",
      selector: "routeName",
      sortable: true,
      width: "20%",
      cell: (row) => (
        <div className="py-3   text-[#393e46]  font-medium text-sm font-roboto">
          <div className="text-[#393e46] text-left uppercase  font-medium">
            {row.routeName}
          </div>
        </div>
      ),
    },
    {
      name: "Trip Date",
      selector: "tripDate",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#393e46] text-center w-full font-medium text-sm font-roboto">
          <span className="text-[#393e46] uppercase  font-medium">
            {momentTimezone(row.tripDate).tz('Asia/Kolkata').format('DD-MM-YYYY')}
          </span>
        </div>
      ),
    },
    {
      name: "Trip Type",
      selector: "tripType",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#393e46] text-center w-full font-medium text-sm font-roboto">
          <span className="text-[#393e46] uppercase  font-medium">
            {row.tripType}
          </span>
        </div>
      ),
    },
    {
      name: "Vehicle Type",
      selector: "vehicleType",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#393e46] text-center w-full font-medium text-sm font-roboto">
          <span className="text-[#393e46] uppercase  font-medium">
            {row.vehicleType}
          </span>
        </div>
      ),
    },
    {
      name: "End Trip Time",
      selector: "endTripTime",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#393e46] text-center w-full font-medium text-sm font-roboto">
          <span className="text-[#393e46] uppercase  font-medium">
            {row.tripEndTime.slice(0, 5)}
          </span>
        </div>
      ),
    },
    {
      name: "Emp Alloted",
      selector: "noOfEmpAlloted",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#393e46] text-center w-full font-medium text-sm font-roboto">
          <span className="text-[#393e46] uppercase  font-medium">
            {row.noOfPickup}
          </span>
        </div>
      ),
    },
    {
      name: "Emp Present",
      selector: "noOfEmpPresent",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#393e46] text-center w-full font-medium text-sm font-roboto">
          <span className="text-[#393e46] uppercase  font-medium">
            {row.noOfEmployeePicked}
          </span>
        </div>
      ),
    },
  ];

  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Define header style
    const headerStyle = {
      font: { bold: true },
      alignment: { horizontal: "center" },
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "C0C0C0" } },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
      numFmt: "dd-mmm-yyyy", // Format the date if needed
      textRotation: 90, // Rotate the header text if needed
    };

    // Define cell style
    const cellStyle = {
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "DDDDDD" } },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
    };

    // Define headers and columns
    const headers = [
      "Sr. No.",
      "Route ID",
      "Route Name",
      "Trip Date",
      "Trip Type",
      "Vehicle Type",
      "Trip End Time",
      "No Of Emp Alloted",
      "No Of Emp Present",
    ];
    const columns = [
      { key: "srNo", header: "Sr. No." },
      { key: "routeId", header: "Route ID" },
      { key: "routeName", header: "Route Name" },
      { key: "tripDate", header: "Trip Date" },
      { key: "tripType", header: "Trip Type" },
      { key: "vehicleType", header: "Vehicle Type" },
      { key: "tripEndTime", header: "Trip End Time" },
      { key: "noOfEmpAlloted", header: "No Of Emp Alloted" },
      { key: "noOfEmpPresent", header: "No Of Emp Present" },
    ];

    // Add header row
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.style = headerStyle;
    });

    // Add data rows
    rowsXlAttendance.forEach((row, index) => {
      const dataRow = worksheet.addRow([
        index + 1,
        row["Route ID"],
        row["Route Name"],
        row["Trip Date"],
        row["Trip Type"],
        row["Vehicle Type"],
        row["Trip End Time"],
        row["No Of Emp Alloted"],
        row["No Of Emp Present"],
      ]);
      dataRow.eachCell((cell) => {
        cell.style = cellStyle;
        if (cell.col === 1) {
          // Adjust the column index if needed
          cell.alignment = { horizontal: "center" };
        }
      });
    });

    // Auto-fit column widths
    columns.forEach((column, index) => {
      const columnWidth =
        headers[index].length < column.header.length
          ? column.header.length
          : headers[index].length;
      worksheet.getColumn(index + 1).width =
        columnWidth < 12 ? 12 : columnWidth;
    });

    // Generate buffer
    const excelBuffer = await workbook.xlsx.writeBuffer();

    // Create and download the file
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}${currentDate
      .toLocaleString("default", { month: "long" })
      .toLowerCase()}${currentDate.getFullYear()}`;
    const fileName = `attendance_report_${formattedDate}.xlsx`;

    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
  };

  return (
    <>
      <>
        <div className="flex justify-end w-full pr-5 pt-5  mr-10 ">
          <div className="flex ">
            <button onClick={handleExport} className="flex  mb-5 ">
              <Link className="bg-[#00adb5] flex justify-between items-center  hover:bg-[#059299] active:bg-[#047481] text-gray-100 text-xl font-bold text-center w-full p-3 pl-5 pr-5 rounded-lg ">
                <span className="mr-2">Download Now</span>

                <svg
                  className="text-2xl "
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-download"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg>
              </Link>
            </button>
          </div>
        </div>
        <div>
          <div className="flex flex-col  w-full">
            <div className="table w-full h-auto ">
              <div className="w-full h-auto rounded-lg shadow-xl shadow-slate-250">
                <div className="flex flex-col w-full gap-6  rounded-lg shadow-md">
                  {/* <DataTable
                    columns={columns}
                    data={rowsAttendance}
                    noHeader
                    pagination
                    highlightOnHover
                    pointerOnHover
                    className="table"
                    customStyles={customStyles}
                    paginationPerPage={25}
                  /> */}
                  <Table
                    columns={columns}
                    rows={rowsAttendance}
                    headerStyle={[
                      " text-center ",
                      "text-center ",
                      "text-left pl-0",
                      " text-center ",
                      " text-center ",
                      " text-center ",
                      " text-center ",
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
