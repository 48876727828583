import React from "react";
import Dropdown from "../../Driver/DropDown";
import InputBoxWithLabel from "../../Driver/LabelInput";

export default function VehicleTrip({
  companyList,
  tripDate,
  setTripDate,
  setTripType,
  tripType,
  setCompanyId,
  companyId,
  toDate,
  fromDate,
  setToDate,
  setFromDate,
}) {
  const tripList = [
    { type: "Drop", id: "Drop" },
    { type: "Pickup", id: "Pickup" },
    { type: "Adhoc", id: "Adhoc" },
  ];
  return (
    <div className="flex rounded-lg bg-white py-2">
      <div className="flex flex-wrap items-center justify-around w-full h-auto gap-1 py-3 mb-2 items-cente labelInputContainer">
        <Dropdown
          lableName={"Company"}
          options={companyList}
          updateValue={setCompanyId}
          placeholder={"Company"}
          widthForSelect={"w-[200px]"}
          objName={"companyName"}
          value={companyId}
        />
        <InputBoxWithLabel
          label={"From Date"}
          type={"date"}
          value={fromDate}
          onChange={(event) => {
            setFromDate(event.target.value);
            setTripDate("");
            localStorage.setItem("fromDateInvoice", event.target.value);
            localStorage.removeItem("tripDate");
          }}
          widthForInput="w-[200px]"
        />
        <InputBoxWithLabel
          label={"To Date"}
          type={"date"}
          value={toDate}
          onChange={(event) => {
            setToDate(event.target.value);
            setTripDate("");
            localStorage.setItem("toDateInvoice", event.target.value);
            localStorage.removeItem("tripDate");
          }}
          widthForInput="w-[200px]"
        />{" "}
        <InputBoxWithLabel
          label={"Trip date"}
          type={"date"}
          value={tripDate}
          onChange={(event) => {
            const newDate = event.target.value;
            setTripDate(newDate);
            setFromDate("");
            setToDate("");
            localStorage.removeItem("fromDateInvoice");
            localStorage.removeItem("toDateInvoice");
            localStorage.setItem("tripDate", event.target.value);
          }}
          widthForInput="w-[200px]"
        />
        <Dropdown
          lableName={"Trip type"}
          options={tripList}
          updateValue={setTripType}
          placeholder={"Trip type"}
          widthForSelect={"w-[200px]"}
          objName={"type"}
          value={tripType}
        />
      </div>
    </div>
  );
}
