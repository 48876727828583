import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { IoMdArrowRoundBack } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import DriverDetailsAlloted from "../TripManagement/EditTripComponent/DriverDetailsAlloted";
import Swal from "sweetalert2";
import axios from "axios";
import Layout from "../../Layout/Layout";
import InputBoxWithLabel from "../Driver/LabelInput";
import { tr } from "date-fns/locale";

export default function AddOutStationTrip() {
  const [companyList, setCompanyList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [routeId, setRouteId] = useState("OS00001");
  const [tripType, setTripType] = useState("");
  const [guestName, setGuestName] = useState("");
  const [tripStartDate, setTripStartDate] = useState("");
  const [tripEndDate, setTripEndDate] = useState("");
  const [pickupTime, setPickupTime] = useState("");
  const [pickupLocation, setPickupLocation] = useState("");
  const [dropLocation, setDropLocation] = useState("");
  const [dropTime, setDropTime] = useState("");
  const [guestContactNumber, setGuestContactNumber] = useState("");
  const [noOfDays, setNoOfDays] = useState("");
  const [vehicleName, setVehicleName] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [noOfGuest, setNoOfGuest] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverMobileNumber, setDriverMobileNumber] = useState("");
  const [driverVehicleType, setDriverVehicleType] = useState("");
  const [driverVehicleNumber, setDriverVehicleNumber] = useState("");
  const [driverId, setDriverId] = useState("");
  const [selectedDriver, setSelectedDriver] = useState("");
  const [tripStatus, setTripStatus] = useState("Not Alloted");
  const [viewTrip, setViewTrip] = useState(false);

  const [companyIdError, setCompanyIdError] = useState("");
  const [routeIdError, setRouteIdError] = useState("");
  const [tripTypeError, setTripTypeError] = useState("");
  const [guestNameError, setGuestNameError] = useState("");
  const [tripStartDateError, setTripStartDateError] = useState("");
  const [tripEndDateError, setTripEndDateError] = useState("");
  const [pickupTimeError, setPickupTimeError] = useState("");
  const [dropTimeError, setDropTimeError] = useState("");
  const [pickupLocationError, setPickupLocationError] = useState("");
  const [dropLocationError, setDropLocationError] = useState("");
  const [guestContactNumberError, setGuestContactNumberError] = useState("");
  const [noOfDaysError, setNoOfDaysError] = useState("");
  const [vehicleNameError, setVehicleNameError] = useState("");
  const [vehicleTypeError, setVehicleTypeError] = useState("");
  const [noOfGuestError, setNoOfGuestError] = useState("");
  const [allocatedDriverError, setAllocatedDriverError] = useState("");
  const [showPopUp, setShowPopUp] = React.useState(false);
  const [tripAmount, setTripAmount] = React.useState("");
  const [tripAmountError, setTripAmountError] = React.useState("");
  const [superAdminPlus, setSuperAdminPlus] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("userType") == "superAdminPlus") {
      setSuperAdminPlus(true);
    }
  }, []);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getCompany();
    getDriver();
    if (id) {
      fetchData();
      setViewTrip(true);
    } else {
      setViewTrip(false);
      getLatestTripId();
    }
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        window.api + "outstation/getOutstationTripOne",
        {
          params: {
            id: id,
          },
        }
      );
      if (response.status === 200) {
        const data = response.data.data;

        setCompanyId(data.companyId);
        setRouteId(data.routeId);
        setTripType(data.tripType);
        setGuestName(data.guestName);
        setTripStartDate(data.tripStartDate);
        setTripEndDate(data.tripEndDate);
        setPickupTime(data.pickupTime);
        setPickupLocation(data.pickupLocation);
        setDropLocation(data.dropLocation);
        setDropTime(data.dropTime);
        setGuestContactNumber(data.guestContactNumber);
        setNoOfDays(data.noOfDays);
        setVehicleName(data.vehicleName);
        setVehicleType(data.vehicleType);
        setNoOfGuest(data.noOfGuest);
        setDriverName(data.driver && data.driver.driverName);
        setDriverMobileNumber(data.driver && data.driver.mobileNo);
        setDriverVehicleType(data.driver && data.driver.vehicleType);
        setDriverVehicleNumber(data.driver && data.driver.vehicleNo);
        setDriverId(data.allocatedDriver);
        setTripStatus(data.tripStatus);
        setSelectedDriver(data.allocatedDriver);
      }
    } catch (error) {
      console.error("Error fetching driver data:", error);
    }
  };

  async function getLatestTripId() {
    try {
      const response = await axios.post(
        window.api + "outstation/getLatestTripId",
        null,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            email: localStorage.getItem("email"),
            usertype: localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const latestTripId = response.data.data;
        setRouteId(latestTripId);
      }
    } catch (e) {
      console.error("Failed to get the latest trip ID:", e);
      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  }

  const getCompany = async () => {
    try {
      const response = await axios.get(window.api + "company/getCompanyList", {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          email: localStorage.getItem("email"),
          usertype: localStorage.getItem("userType"),
        },
      });

      if (response.status === 200) {
        const companyList = response.data.data;
        setCompanyList(companyList);
      }
    } catch (error) {
      console.error("Error getting company list:", error);

      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  const getDriver = async () => {
    try {
      const response = await axios.get(window.api + "driver/getDrivers", {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          email: localStorage.getItem("email"),
          usertype: localStorage.getItem("userType"),
        },
      });

      if (response.status === 200) {
        const drivers = response.data.data;
        setDriverList(drivers);
      }
    } catch (e) {
      console.error("Error getting drivers:", e);

      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  const handleDriverChange = async (event) => {
    setSelectedDriver(event.target.value);

    try {
      const response = await axios.get(window.api + "driver/getDriverOne", {
        params: {
          id: event.target.value,
        },
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          email: localStorage.getItem("email"),
          usertype: localStorage.getItem("userType"),
        },
      });

      if (response.status === 200) {
        const driverData = response.data.data;
        setDriverName(driverData.driverName);
        setDriverMobileNumber(driverData.mobileNo);
        setDriverVehicleType(driverData.vehicleType);
        setDriverVehicleNumber(driverData.vehicleNo);
        setDriverId(driverData.driverID);
      }
    } catch (e) {
      console.error("Error fetching driver data:", e);

      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }

      setDriverName("");
      setDriverMobileNumber("");
      setDriverVehicleType("");
      setDriverVehicleNumber("");
      setDriverId("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleFormValidation()) {
      try {
        const data = {
          companyId: companyId,
          routeId: routeId,
          tripType: tripType,
          guestName: guestName,
          tripStartDate: tripStartDate,
          tripEndDate: tripEndDate,
          pickupTime: pickupTime,
          dropTime: dropTime,
          guestContactNumber: guestContactNumber,
          noOfDays: noOfDays,
          vehicleName: vehicleName,
          vehicleType: vehicleType,
          noOfGuest: noOfGuest,
          tripStatus: "Not Alloted",
          dropLocation: dropLocation,
          pickupLocation: pickupLocation,
        };

        const response = await axios.post(
          window.api + "outstation/create",
          data,
          {
            headers: {
              "x-access-token": localStorage.getItem("accessToken"),
              email: localStorage.getItem("email"),
              usertype: localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          toast("New Trip Created Successfully!", {
            icon: "👏",
            style: {
              borderRadius: "7px",
              background: "#222831",
              color: "#fff",
            },
          });
          setTimeout(() => {
            navigate("/outstation");
          }, 2000);
        }
      } catch (e) {
        console.error("Errors creating trip: ", e);
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      }
    }
  };

  const handleFormValidation = () => {
    let formIsValid = true;

    if (!companyId) {
      formIsValid = false;
      setCompanyIdError("Please select company");
    } else {
      setCompanyIdError("");
    }
    if (!routeId) {
      formIsValid = false;
      setRouteIdError("Please enter route id");
    } else {
      setRouteIdError("");
    }
    if (!tripType) {
      formIsValid = false;
      setTripTypeError("Please select trip type");
    } else {
      setTripTypeError("");
    }
    if (!guestName) {
      formIsValid = false;
      setGuestNameError("Please enter guest name");
    } else {
      setGuestNameError("");
    }
    if (!tripStartDate) {
      formIsValid = false;
      setTripStartDateError("Please select start date");
    } else {
      setTripStartDateError("");
    }
    if (!tripEndDate) {
      formIsValid = false;
      setTripEndDateError("Please select end date");
    } else {
      setTripEndDateError("");
    }
    if (!pickupLocation) {
      formIsValid = false;
      setPickupLocationError("Please enter pick up location");
    } else {
      setPickupLocationError("");
    }
    if (!dropLocation) {
      formIsValid = false;
      setDropLocationError("Please emter drop location");
    } else {
      setDropLocationError("");
    }
    if (!pickupTime) {
      formIsValid = false;
      setPickupTimeError("Please select pick up time");
    } else {
      setPickupTimeError("");
    }
    if (!dropTime) {
      formIsValid = false;
      setDropTimeError("Please select drop time");
    } else {
      setDropTimeError("");
    }
    if (!guestContactNumber) {
      formIsValid = false;
      setGuestContactNumberError("Please enter contact number");
    } else {
      setGuestContactNumberError("");
    }
    if (!noOfDays) {
      formIsValid = false;
      setNoOfDaysError("Please enter no of days");
    } else {
      setNoOfDaysError("");
    }
    if (!vehicleName) {
      formIsValid = false;
      setVehicleNameError("Please enter vehicle name");
    } else {
      setVehicleNameError("");
    }
    if (!vehicleType) {
      formIsValid = false;
      setVehicleTypeError("Please select vehicle type");
    } else {
      setVehicleTypeError("");
    }
    if (!noOfGuest) {
      formIsValid = false;
      setNoOfGuestError("Please enter no of guest");
    } else {
      setNoOfGuestError("");
    }

    return formIsValid;
  };

  const handleEdit = async () => {
    setViewTrip(false);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (handleFormValidation()) {
      try {
        const data = {
          companyId: companyId,
          routeId: routeId,
          tripType: tripType,
          guestName: guestName,
          tripStartDate: tripStartDate,
          tripEndDate: tripEndDate,
          pickupTime: pickupTime,
          dropTime: dropTime,
          guestContactNumber: guestContactNumber,
          noOfDays: noOfDays,
          vehicleName: vehicleName,
          vehicleType: vehicleType,
          noOfGuest: noOfGuest,
          tripStatus: tripStatus,
          dropLocation: dropLocation,
          pickupLocation: pickupLocation,
        };

        const response = await axios.put(
          window.api + `outstation/update/${id}`,
          data,
          {
            headers: {
              "x-access-token": localStorage.getItem("accessToken"),
              email: localStorage.getItem("email"),
              usertype: localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          toast("Trip Updated Successfully!", {
            icon: "👏",
            style: {
              borderRadius: "7px",
              background: "#222831",
              color: "#fff",
            },
          });

          setTimeout(() => {
            navigate("/outstation");
          }, 2000);
        }
      } catch (e) {
        console.error("Error updating the trip:", e);
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      }
    }
  };

  const handleDelete = async () => {
    const result = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You are about to delete this trip. This action cannot be undone!",
      confirmButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await axios.put(
          window.api + `outstation/delete/${id}`,
          {},
          {
            headers: {
              "x-access-token": localStorage.getItem("accessToken"),
              email: localStorage.getItem("email"),
              usertype: localStorage.getItem("userType"),
            },
          }
        );
        navigate("/outstation");
      } catch (e) {
        console.error("Error deleting trip:", e);
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      }
    }
  };

  const handleAllocateDriver = async (e) => {
    if (handleAllocatedValidation()) {
      setTripStatus("Alloted");
      setViewTrip(true);

      try {
        const data = {
          allocatedDriver: selectedDriver,
          tripStatus: "Alloted",
        };

        const response = await axios.put(
          window.api + `outstation/update/${id}`,
          data,
          {
            headers: {
              "x-access-token": localStorage.getItem("accessToken"),
              email: localStorage.getItem("email"),
              usertype: localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          toast("Trip Updated Successfully!", {
            icon: "👏",
            style: {
              borderRadius: "7px",
              background: "#222831",
              color: "#fff",
            },
          });
        }
      } catch (e) {
        console.error("Error allocating driver:", e);
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      }
    }
  };

  const handleCompleteTrip = async (e) => {
    if (handleAllocatedValidation()) {
      setShowPopUp(1);
    }
  };

  const handleTripAmount = async (e) => {
    e.preventDefault();
    if (handleTripAmountValidation()) {
      try {
        setShowPopUp(0);
        const data = {
          tripAmount: tripAmount,
          allocatedDriver: selectedDriver,
          tripStatus: "Completed",
        };

        const response = await axios.put(
          window.api + `outstation/update/${id}`,
          data,
          {
            headers: {
              "x-access-token": localStorage.getItem("accessToken"),
              email: localStorage.getItem("email"),
              usertype: localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          toast("Trip Completed Successfully!", {
            icon: "👏",
            style: {
              borderRadius: "7px",
              background: "#222831",
              color: "#fff",
            },
          });

          setTimeout(() => {
            navigate("/outstation");
          }, 2000);
        }
      } catch (e) {
        console.error("Error updating the trip:", e);
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      }
    }
  };

  const handleTripAmountValidation = () => {
    let formIsValid = true;
    if (!tripAmount) {
      formIsValid = false;
      setTripAmountError("Please enter trip amount");
    } else {
      setTripAmountError("");
    }
    return formIsValid;
  };

  const handleAllocatedValidation = () => {
    let formIsValid = true;
    if (!selectedDriver) {
      formIsValid = false;
      setAllocatedDriverError("Please select driver");
    } else {
      setAllocatedDriverError("");
    }
    if (!id) {
      formIsValid = false;
    }

    return formIsValid;
  };

  return (
    <Layout>
      <div>
        <Toaster />
        <div className="flex flex-col gap-2">
          <div className="flex flex-row items-center sm:mx-0">
            <div className="flex flex-row gap-4 mt-3 ml-5">
              <div className=" bg-[#ffffff] cursor-pointer  shadow-lgrounded-xl">
                <Link
                  to={"/outstation"}
                  className="flex items-center justify-center w-10 h-10 text-black"
                >
                  <IoMdArrowRoundBack className="w-6 h-6 text-black" />
                </Link>
              </div>
              {!superAdminPlus ? null : (
                <div
                  className="p-2 bg-white rounded-lg shadow-md cursor-pointer drop-shadow-md hover:bg-gray-200 active:bg-gray-400"
                  onClick={handleDelete}
                >
                  <RiDeleteBin6Line className="text-[#ff0000] w-6 h-6" />
                </div>
              )}
              {!viewTrip || tripStatus === "Completed" ? null : (
                <Link to="#" onClick={handleEdit}>
                  <div className="p-2 bg-white rounded-lg shadow-md cursor-pointer drop-shadow-md hover:bg-gray-200 active:bg-gray-400">
                    <FiEdit className="text-[#38e54d] w-6 h-6 " />
                  </div>
                </Link>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-4 m-4">
            <div className="flex flex-col gap-1 md:flex-row md:gap-1">
              <div className="container mx-auto ">
                <div className="max-w-screen-md px-8 py-6 mx-auto bg-white border-2 rounded-lg shadow-lg">
                  <div className="flex flex-col mb-6 md:flex-row md:-mx-3">
                    <div className="w-full mb-6 md:w-1/2 md:px-3 md:mb-0">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-first-name"
                      >
                        Route ID
                      </label>
                      <input
                        placeholder="Route ID"
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        type="text"
                        value={routeId}
                        disabled
                      />
                      {routeIdError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {routeIdError}
                        </p>
                      )}
                    </div>
                    <div className="w-full mb-6 md:w-1/2 md:px-3 md:mb-0">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-first-name"
                      >
                        Company
                      </label>
                      <select
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        onChange={(e) => setCompanyId(e.target.value)}
                        value={companyId}
                        id="company-name"
                        disabled={viewTrip}
                      >
                        <option value="">--Select Company-- </option>
                        {companyList &&
                          companyList.map((company) => (
                            <option value={company.id} key={company.id}>
                              {company.companyName}
                            </option>
                          ))}
                      </select>
                      {companyIdError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {companyIdError}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col mb-6 md:flex-row md:-mx-3">
                    <div className="w-full mb-6 md:w-1/2 md:px-3 md:mb-0">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-last-name"
                      >
                        Trip Type
                      </label>
                      <select
                        onChange={(e) => setTripType(e.target.value)}
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        id="vehicle-type"
                        value={tripType}
                        disabled={viewTrip}
                      >
                        <option value="">--- Select Trip Type ---</option>
                        <option value="Local Rental">Local Rental</option>
                        <option value="Outstation">Outstation</option>
                        <option value="One way drop">One way drop</option>
                      </select>
                      {tripTypeError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {tripTypeError}
                        </p>
                      )}
                    </div>
                    <div className="w-full mb-6 md:w-1/2 md:px-3 md:mb-0">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-last-name"
                      >
                        Guest Name
                      </label>
                      <input
                        onChange={(e) => setGuestName(e.target.value)}
                        placeholder="Guest Name"
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        type="text"
                        value={guestName}
                        disabled={viewTrip}
                      />
                      {guestNameError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {guestNameError}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col mb-6 md:flex-row md:-mx-3">
                    <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0 ">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-first-name"
                      >
                        Trip Start Date
                      </label>
                      <input
                        onChange={(e) => setTripStartDate(e.target.value)}
                        placeholder="Date"
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        type="date"
                        value={tripStartDate}
                        disabled={viewTrip}
                      />
                      {tripStartDateError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {tripStartDateError}
                        </p>
                      )}
                    </div>
                    <div className="w-full px-3 md:w-1/2">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-first-name"
                      >
                        Trip End Date
                      </label>
                      <input
                        onChange={(e) => setTripEndDate(e.target.value)}
                        placeholder="Date"
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        type="date"
                        value={tripEndDate}
                        disabled={viewTrip}
                      />
                      {tripEndDateError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {tripEndDateError}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col mb-6 md:flex-row md:-mx-3">
                    <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0 ">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-first-name"
                      >
                        Pickup Location
                      </label>
                      <input
                        onChange={(e) => setPickupLocation(e.target.value)}
                        placeholder="Pickup Location"
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        type="text"
                        value={pickupLocation}
                        disabled={viewTrip}
                      />
                      {pickupLocationError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {pickupLocationError}
                        </p>
                      )}
                    </div>
                    <div className="w-full px-3 md:w-1/2">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-first-name"
                      >
                        Drop Location
                      </label>
                      <input
                        onChange={(e) => setDropLocation(e.target.value)}
                        placeholder="Drop Location"
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        type="text"
                        value={dropLocation}
                        disabled={viewTrip}
                      />
                      {dropLocationError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {dropLocationError}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col mb-6 md:flex-row md:-mx-3">
                    <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0 ">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-first-name"
                      >
                        Pickup Time
                      </label>
                      <input
                        onChange={(e) => setPickupTime(e.target.value)}
                        placeholder="Pickup Time"
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        type="time"
                        value={pickupTime}
                        disabled={viewTrip}
                      />
                      {pickupTimeError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {pickupTimeError}
                        </p>
                      )}
                    </div>
                    <div className="w-full px-3 md:w-1/2">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-first-name"
                      >
                        Drop Time
                      </label>
                      <input
                        onChange={(e) => setDropTime(e.target.value)}
                        placeholder="Drop Time"
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        type="time"
                        value={dropTime}
                        disabled={viewTrip}
                      />
                      {dropTimeError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {dropTimeError}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col mb-6 md:flex-row md:-mx-3">
                    <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0 ">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-last-name"
                      >
                        Guest Contact Number
                      </label>
                      <input
                        onChange={(e) => setGuestContactNumber(e.target.value)}
                        placeholder="Guest Contact Number"
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        type="number"
                        value={guestContactNumber}
                        disabled={viewTrip}
                      />
                      {guestContactNumberError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {guestContactNumberError}
                        </p>
                      )}
                    </div>
                    <div className="w-full px-3 md:w-1/2">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-last-name"
                      >
                        No Of Days
                      </label>
                      <input
                        onChange={(e) => setNoOfDays(e.target.value)}
                        placeholder="No of Days"
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        type="number"
                        value={noOfDays}
                        disabled={viewTrip}
                      />
                      {noOfDaysError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {noOfDaysError}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col mb-6 md:flex-row md:-mx-3">
                    <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0 ">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-last-name"
                      >
                        Vehicle Name
                      </label>
                      <input
                        onChange={(e) => setVehicleName(e.target.value)}
                        placeholder="Vehicle Name"
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        type="text"
                        value={vehicleName}
                        disabled={viewTrip}
                      />
                      {vehicleNameError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {vehicleNameError}
                        </p>
                      )}
                    </div>
                    <div className="w-full px-3 md:w-1/2">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-last-name"
                      >
                        Vehicle Type
                      </label>
                      <select
                        onChange={(e) => setVehicleType(e.target.value)}
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        id="vehicle-type"
                        value={vehicleType}
                        disabled={viewTrip}
                      >
                        <option value="">--- Select Vehicle Type ---</option>
                        <option value="Sedan">Sedan</option>
                        <option value="SUV">SUV</option>
                        <option value="SUV +">SUV +</option>
                        <option value="Others">Others</option>
                      </select>
                      {vehicleTypeError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {vehicleTypeError}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col mb-6 md:flex-row md:-mx-3">
                    <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0 ">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-last-name"
                      >
                        No of Guest
                      </label>
                      <input
                        onChange={(e) => setNoOfGuest(e.target.value)}
                        placeholder="No of Guest"
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        type="number"
                        value={noOfGuest}
                        disabled={viewTrip}
                      />
                      {noOfGuestError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {noOfGuestError}
                        </p>
                      )}
                    </div>
                    <div className="w-full px-3 md:w-1/2"></div>
                  </div>

                  {viewTrip ? null : (
                    <button
                      onClick={id ? handleUpdate : handleSubmit}
                      className="bg-[#00adb5] rounded-lg py-3 px-2 text-white w-full hover:bg-[#00b5ad] active:bg-[#008080]"
                      type="submit"
                    >
                      {id ? "Update" : "Add"}
                    </button>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2 ml-4 ">
                <div className="w-full md:w-[550px] h-auto border-2 bg-white rounded-lg mx-auto">
                  <div className="container h-auto p-8">
                    <div className="flex flex-col mb-6 -mx-2 md:flex-row">
                      <div className="w-full px-2 mb-6 md:w-1/2 md:mb-0">
                        <label
                          className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                          htmlFor="grid-first-name"
                        >
                          Driver ID
                        </label>
                        <select
                          placeholder="Driver ID"
                          className="appearance-none block w-full md:w-[229px] focus:outline-none focus:bg-white focus:border-gray-500 bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                          value={selectedDriver}
                          onChange={handleDriverChange}
                          disabled={viewTrip}
                        >
                          <option value="">--Select Driver--</option>
                          {driverList &&
                            driverList.map((driver) => (
                              <option value={driver.id} key={driver.id}>
                                {driver.driverID}
                              </option>
                            ))}
                        </select>
                        {allocatedDriverError && (
                          <p className="mt-1 text-sm text-red-500 ">
                            {allocatedDriverError}
                          </p>
                        )}
                      </div>
                      <div className="w-full px-2 mb-6 md:w-1/2 md:mb-0 ">
                        <label
                          className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                          for="grid-first-name"
                        >
                          Driver Name
                        </label>
                        <input
                          placeholder="Driver Name"
                          type="text"
                          className="appearance-none block w-full md:w-[229px] focus:outline-none focus:bg-white focus:border-gray-500 bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                          value={driverName}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="flex flex-col mb-6 -mx-2 md:flex-row ">
                      <div className="w-full px-2 md:w-1/2">
                        <label
                          className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                          for="grid-last-name"
                        >
                          Mobile Number
                        </label>
                        <input
                          placeholder="Mobile Number"
                          type="text"
                          className="appearance-none block w-full md:w-[229px] focus:outline-none focus:bg-white focus:border-gray-500 bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                          value={driverMobileNumber}
                          disabled
                        />
                      </div>
                      <div className="w-full px-2 mb-6 md:w-1/2 md:mb-0 ">
                        <label
                          className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                          for="grid-first-name"
                        >
                          Vehicle Type
                        </label>
                        <input
                          placeholder="Vehicle Type"
                          type="text"
                          className="appearance-none block w-full md:w-[229px] focus:outline-none focus:bg-white focus:border-gray-500 bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                          value={driverVehicleType}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="flex flex-col mb-6 -mx-2 md:flex-row ">
                      <div className="w-full px-3 md:w-1/2">
                        <label
                          className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                          for="grid-last-name"
                        >
                          Vehicle Number
                        </label>
                        <input
                          placeholder="Vehicle Number"
                          type="text"
                          className="appearance-none block w-full md:w-[229px] focus:outline-none focus:bg-white focus:border-gray-500 bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                          value={driverVehicleNumber}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="flex w-full ">
                      <div className="flex items-end w-full px-2 md:w-1/2 justify-evenly">
                        <>
                          <button
                            type="submit"
                            className="bg-[#00adb5] text-white rounded py-3 px-8 text-sm font-bold md:text-base focus:border-[#4dd9e0] hover:bg-[#008c8f] active:bg-[#006d6f]"
                            onClick={handleAllocateDriver}
                            disabled={viewTrip}
                          >
                            Allocate
                          </button>
                        </>
                      </div>
                      <div className="flex items-end w-full px-2 md:w-1/2 justify-evenly">
                        <>
                          <button
                            type="submit"
                            className="bg-green-500 text-white rounded py-3 px-8 text-sm font-bold md:text-base focus:border-[#4dd9e0] hover:bg-green-600 active:bg-green-700"
                            onClick={handleCompleteTrip}
                            disabled={viewTrip}
                          >
                            Complete
                          </button>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row gap-6">
                  <div className="w-full md:w-[154px]  md:h-[167px] lg:w-full border bg-white flex flex-col shadow-lg rounded-lg p-4 gap-5 ">
                    <div className="flex items-center justify-center w-full h-full gap-8">
                      <div className="text-lg font-sf_bold">Trip Status</div>
                      <div
                        className={
                          tripStatus === "Alloted"
                            ? "bg-[#ff8103] w-12 h-6 rounded "
                            : tripStatus === "Not Alloted"
                            ? "bg-[#31007a] w-12 h-6 rounded "
                            : tripStatus === "Ongoing"
                            ? "bg-[#e8d100] w-12 h-6 rounded "
                            : "bg-[#2daa00] w-12 h-6 rounded "
                        }
                      />
                      <div>
                        <select
                          value={tripStatus}
                          className="bg-gray-300 text-black p-2 rounded-lg border-2 border-[#222831] appearance-none hover:bg-[#222831] hover:text-white cursor-pointer text-center"
                          disabled={viewTrip}
                        >
                          <option value="Alloted">Alloted</option>
                          <option value="Not Alloted">Not Alloted</option>
                          <option value="Ongoing">Ongoing</option>
                          <option value="Completed">Completed</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex">
                  <DriverDetailsAlloted
                    DriverName={driverName}
                    DriverVehicleType={driverVehicleType}
                    DriverVehicleNumber={driverVehicleNumber}
                    DriverId={driverId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPopUp === 1 && (
        <div
          className={`  fixed left-0 top-0    bg-[#2b2a2a69] w-screen  h-screen z-40  overflow-y-scroll overflow-scroll overscroll-y-auto py-2  flex justify-center items-center`}
        >
          <div className=" h-auto w-40  lg:w-[920px]  flex flex-wrap justify-center items-center px-16 ">
            <div
              class={`w-[900px] h-full    rounded-[8px]  bg-white  shadow-box `}
            >
              <div className="flex relative flex-col p-5 w-full rounded-[8px] h-full   ">
                <div className="flex justify-end mx-[30px] ">
                  <button
                    onClick={() => setShowPopUp(0)}
                    className="flex justify-center items-center bg-[#D9D9D985] rounded-full w-[40px] h-[40px]"
                  >
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 1L1 16M1 1L16 16"
                        stroke="#344054"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className="text  text-center text-[#101828] font-bold">
                  Add Trip Amount
                </div>
                <form onSubmit={handleTripAmount}>
                  <div className="flex items-center justify-center w-full h-full gap-10 mt-5 ">
                    <div className="flex items-center justify-center w-full px-8">
                      <InputBoxWithLabel
                        label={"Trip Amount"}
                        placeholder={"Enter Amount"}
                        type={"number"}
                        value={tripAmount}
                        onChange={(e) => setTripAmount(e.target.value)}
                        id={"amount"}
                        validationError={tripAmountError}
                      />
                    </div>
                  </div>

                  <div className="flex justify-center mt-10 font-semibold tracking-normal text-center item font-roboto text-12 leading-20">
                    <button
                      type="submit"
                      className=" bg-[#175CD3] text-white w-[77px] h-[30px] border border-gray-500 rounded-md "
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
