import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import HomeTripData from "../Home/HomeTripData";
import { TodayTripChart } from "../Home/TodayTripChart";
import { WeeklyBarChart } from "../Home/WeeklyBarChart";
import TripList from "../TripManagement/TripList";
import Layout from "../../Layout/Layout";
import axios from "axios";
import Dropdown from "../Driver/DropDown";
import InputBoxWithLabel from "../Driver/LabelInput";
import DateUtils from '../../Utility/DateUtils';

function Home() {
  const [openTab, setOpenTab] = React.useState(1);

  const location = useLocation();
  const [totalTrips, setTotalTrips] = useState(0);
  const [totalNotAllocated, setTotalNotAllocated] = useState(0);
  const [totalAllocated, setTotalAllocated] = useState(0);
  const [totalCompleted, setTotalCompleted] = useState(0);
  const [totalCancelled, setTotalCancelled] = useState(0);
  const [totalOngoing, setTotalOngoing] = useState(0);
  const [rows, setRows] = useState([]);
  const [weeklyTrips, setWeeklyTrips] = useState([]);
  const [lastSevenDaysOfWeek, setLastSevenDaysOfWeek] = useState([]);
  const [tripStatus, setTripStatus] = useState("Not Alloted");
  const [emrStatus, setEmrStatus] = useState("");

  const [fromDate, setFromDate] = useState(
    localStorage.getItem("fromDate") || DateUtils.getCurrentDate()
  );
  const [toDate, setToDate] = useState(
    localStorage.getItem("toDate") || DateUtils.getCurrentDate()
  );

  const [fromHomeDate, setFromHomeDate] = useState(
    localStorage.getItem("fromHomeDate") || DateUtils.getCurrentDate()
  );
  const [toHomeDate, setToHomeDate] = useState(
    localStorage.getItem("toHomeDate") || DateUtils.getCurrentDate()
  );

  const [tripType, setTripType] = useState(
    localStorage.getItem("tripType") || ""
  );
  const [vehicleType, setVehicleType] = useState(
    localStorage.getItem("vehicleType") || ""
  );

  const [companyList, setCompanyList] = useState([]);

  const [companyId, setCompanyId] = useState(
    localStorage.getItem("tripCompany") || ""
  );

  useEffect(() => {
    getCompany();
  }, []);

  const getCompany = async () => {
    try {
        const response = await axios.get(
            window.api + "company/getCompanyList",
            {
                headers: {
                    "x-access-token": localStorage.getItem("accessToken"),
                    "email": localStorage.getItem("email"),
                    "usertype": localStorage.getItem("userType")
                }
            }
        );
        
        if (response.status === 200) {
            const companyList = response.data.data;
            setCompanyList(companyList);
        }
    } catch (error) {
        console.error("Error getting company list:", error);
        
        if (error.response && error.response.status === 401) {
            localStorage.clear();
            window.location.href = '/';
        }
    }
};


  const handleDateChange = (e) => {
    if (e.target.id === "from") {
      setFromDate(e.target.value);
      localStorage.setItem("fromDate", e.target.value);
    } else {
      setToDate(e.target.value);
      localStorage.setItem("toDate", e.target.value);
    }
  };

  const handleHomeDateChange = (e) => {
    if (e.target.id === "fromHome") {
      setFromHomeDate(e.target.value);
      localStorage.setItem("fromHomeDate", e.target.value);
    } else {
      setToHomeDate(e.target.value);
      localStorage.setItem("toHomeDate", e.target.value);
    }
  };

  useEffect(() => {
    localStorage.setItem("tripCompany", companyId);
  }, [companyId]);

  // Clear localStorage on page refresh
  window.onbeforeunload = () => {
    localStorage.removeItem("vehicleType");
    localStorage.removeItem("tripType");
    localStorage.removeItem("toHomeDate");
    localStorage.removeItem("fromHomeDate");
    localStorage.removeItem("toDate");
    localStorage.removeItem("fromDate");
    localStorage.removeItem("tripCompany");
  };

  const getWeeklyChartData = async () => {
    try {
      const headers = {
        "x-access-token": localStorage.getItem("accessToken"),
        "email": localStorage.getItem("email"),
        "usertype": localStorage.getItem("userType")
      };
  
      const response = await axios.get(
        window.api + "trip/getTripsGetWeeklyChartData",
        {
          headers,  // Include the headers in the request
        }
      );
  
      if (response.status === 200) {
        setWeeklyTrips(response.data.data);
      }
    } catch (error) {
      console.error("Error getting weekly chart data:", error);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      }
    }
  
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDayIndex = new Date().getDay();
  
    const lastSevenDays = [];
    let count = 0;
  
    while (lastSevenDays.length < 7) {
      const weekdayIndex = (currentDayIndex - count + 7) % 7;
      lastSevenDays.unshift(weekdays[weekdayIndex]);
      count++;
    }
  
    setLastSevenDaysOfWeek(lastSevenDays);
  };
  

  useEffect(() => {
    if (location.pathname === "/trip-list") {
      getTrips();
    }
    if (location.pathname === "/home") {
      chartTripsData();
    }
  }, [
    vehicleType,
    tripType,
    fromDate,
    toDate,
    location,
    fromHomeDate,
    toHomeDate,
    companyId,
    emrStatus,
    tripStatus,
  ]);

  useEffect(() => {
    getWeeklyChartData();
  }, []);

  const chartTripsData = async () => {
    try {
      const headers = {
        "x-access-token": localStorage.getItem("accessToken"),
        "email": localStorage.getItem("email"),
        "usertype": localStorage.getItem("userType")
      };
  
      const response = await axios.get(
        window.api + "trip/getTripsChartTripsData",
        {
          params: {
            fromHomeDate,
            toHomeDate,
          },
          headers,  // Include the headers in the request
        }
      );
  
      if (response.status === 200) {
        setTotalAllocated(response.data.data.totalAllocated);
        setTotalNotAllocated(response.data.data.totalNotAllocated);
        setTotalCompleted(response.data.data.totalCompleted);
        setTotalCancelled(response.data.data.totalCancelled);
        setTotalOngoing(response.data.data.totalOngoing);
        setTotalTrips(response.data.data.totalTrips);
        setRows(response.data.data.tripList);
      }
    } catch (error) {
      console.error("Error getting chart data:", error);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      }
    }
  };
  

  const getTrips = async () => {
    try {
      const headers = {
        "x-access-token": localStorage.getItem("accessToken"),
        "email": localStorage.getItem("email"),
        "usertype": localStorage.getItem("userType")
      };
      
      const response = await axios.get(window.api + "trip/getTrips", {
        params: {
          vehicleType,
          tripType,
          fromDate,
          toDate,
          companyId,
          tripStatus,
          emrStatus,
        },
        headers,  // Include the headers in the request
      });
  
      if (response.status === 200) {
        const trips = response.data.data;
        setRows(trips);
      }
    } catch (error) {
      console.error("Error getting trips:", error);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      }
    }
  };
  

  const hideChart = location.pathname === "/trip-list";
  const hideSearchBar = location.pathname === "/home";

  const vehicleTypeList = [
    { type: "Sedan", id: "Sedan" },
    { type: "SUV", id: "SUV" },
    { type: "Hatchback", id: "Hatchback" },
  ];

  return (
    <>
      <Layout>
        {!hideChart ? (
          <>
            <div className="flex flex-col md:flex-row md:gap-3">
              <>
                <div className="w-full md:w-[420px] border-1 shadow-lg bg-white p-2 m-1 rounded-xl">
                  <TodayTripChart
                    totalAllocated={totalAllocated}
                    totalNotAllocated={totalNotAllocated}
                    totalCompleted={totalCompleted}
                    totalTrips={totalTrips}
                    totalCancelled={totalCancelled}
                    totalOngoing={totalOngoing}
                  />
                </div>
                <div className="w-full md:w-[572px] border-1 shadow-lg bg-white p-2 rounded-xl mt-2 md:mt-0">
                  <div className="flex flex-col items-center justify-center gap-8 py-6 item-center">
                    <WeeklyBarChart
                      weeklyTrips={weeklyTrips}
                      lastSevenDaysOfWeek={lastSevenDaysOfWeek}
                    />
                  </div>
                </div>
                <div className="w-full md:w-[380px] border-1 shadow-lg bg-white p-2 rounded-xl mt-2">
                  <div className="flex flex-col gap-2 p-2 ">
                    <div className="flex flex-row items-center gap-2">
                      <label
                        htmlFor="from"
                        className="font-medium text-lg text-[#222831] font-roboto "
                      >
                        From
                      </label>
                      <input
                        className="block w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-600 rounded-lg appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                        type="date"
                        id="fromHome"
                        onChange={handleHomeDateChange}
                        value={fromHomeDate}
                      />
                    </div>
                    <div className="flex flex-row items-center gap-2 mt-2">
                      <input
                        className="block w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-600 rounded-lg appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                        type="date"
                        id="toHome"
                        onChange={handleHomeDateChange}
                        value={toHomeDate}
                      />
                      <label className="text-lg font-medium cursor-pointer font-roboto">
                        To
                      </label>
                    </div>
                  </div>
                  <HomeTripData
                    totalAllocated={totalAllocated}
                    totalNotAllocated={totalNotAllocated}
                    totalCompleted={totalCompleted}
                    totalTrips={totalTrips}
                  />
                </div>
              </>
            </div>
            <TripList rows={rows} getTrips={getTrips} />
          </>
        ) : (
          <div>
            {!hideSearchBar && (
              <div className="flex flex-col justify-between px-4 py-1 mx-0 mt-2 bg-white border-2 md:flex-row border-1 rounded-xl">
                <form className="flex flex-wrap items-center justify-between w-full pl-1 pr-1 lg:flex-nowrap font-roboto">
                  <div className="flex items-center justify-center w-auto h-full my-4 labelInput ">
                    <Dropdown
                      lableName={"Company"}
                      options={companyList}
                      updateValue={setCompanyId}
                      placeholder={"Select Company"}
                      widthForSelect={"w-[280px]"}
                      objName={"companyName"}
                      value={companyId}
                    />
                  </div>

                  <div className="flex items-center justify-center w-auto h-full my-4 labelInput ">
                    <InputBoxWithLabel
                      label={"From"}
                      type={"date"}
                      value={fromDate}
                      onChange={handleDateChange}
                      required
                      id="from"
                    />
                  </div>
                  <div className="flex items-center justify-center w-auto h-full my-4 labelInput ">
                    <InputBoxWithLabel
                      label={"To"}
                      type={"date"}
                      value={toDate}
                      onChange={handleDateChange}
                      required
                      id="to"
                    />
                  </div>
                </form>
              </div>
            )}
            <div className="flex flex-wrap w-full">
              <div className="w-full ">
                <div className="flex flex-row flex-wrap items-center justify-between w-full gap-2 px-2 pt-3 pb-4 mb-0 list-none">
                  <div className="flex items-center justify-start flex-auto mr-2 -mb-px text-center last:mr-0">
                    <button
                      className={
                        "text-xs font-bold uppercase px-5 py-3    w-full rounded block leading-normal " +
                        (openTab === 1
                          ? "text-[#222831] bg-[#00ADB5] "
                          : "text-[#00ADB5] bg-[#222831]")
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(1);
                        setTripStatus("Not Alloted");
                        setEmrStatus("");
                        setTripType("");
                      }}
                    >
                      Not Alloted
                    </button>
                  </div>
                  <div className="flex items-center justify-center flex-auto mr-2 -mb-px text-center last:mr-0">
                    <button
                      className={
                        "text-xs font-bold uppercase px-5 py-3    w-full rounded block leading-normal " +
                        (openTab === 2
                          ? "text-[#222831] bg-[#00ADB5] "
                          : "text-[#00ADB5] bg-[#222831]")
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(2);
                        setTripStatus("Alloted");
                        setEmrStatus("");
                        setTripType("");
                      }}
                      data-toggle="tab"
                      href="#link2"
                      role="tablist"
                    >
                      Alloted
                    </button>
                  </div>
                  <div className="flex items-center justify-end flex-auto mr-2 -mb-px text-center last:mr-0">
                    <button
                      className={
                        "text-xs font-bold uppercase px-5 py-3    w-full rounded block leading-normal " +
                        (openTab === 3
                          ? "text-[#222831] bg-[#00ADB5] "
                          : "text-[#00ADB5] bg-[#222831]")
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(3);
                        setTripStatus("Completed");
                        setEmrStatus("");
                        setTripType("");
                      }}
                    >
                      Completed
                    </button>
                  </div>
                  <div className="flex items-center justify-start flex-auto mr-2 -mb-px text-center last:mr-0">
                    <button
                      className={
                        "text-xs font-bold uppercase px-5 py-3    w-full rounded block leading-normal " +
                        (openTab === 4
                          ? "text-[#222831] bg-[#00ADB5] "
                          : "text-[#00ADB5] bg-[#222831]")
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(4);
                        setTripType("Pickup");
                        setEmrStatus("");
                        setTripStatus("");
                      }}
                    >
                      Pickup
                    </button>
                  </div>
                  <div className="flex items-center justify-center flex-auto mr-2 -mb-px text-center last:mr-0">
                    <button
                      className={
                        "text-xs font-bold uppercase px-5 py-3    w-full rounded block leading-normal " +
                        (openTab === 5
                          ? "text-[#522831] bg-[#00ADB5] "
                          : "text-[#00ADB5] bg-[#222831]")
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(5);
                        setTripType("Drop");
                        setEmrStatus("");
                        setTripStatus("");
                      }}
                      data-toggle="tab"
                      href="#link2"
                      role="tablist"
                    >
                      Drop
                    </button>
                  </div>
                  <div className="flex items-center justify-end flex-auto mr-2 -mb-px text-center last:mr-0">
                    <button
                      className={
                        "text-xs font-bold uppercase px-5 py-3    w-full rounded block leading-normal " +
                        (openTab === 6
                          ? "text-[#222831] bg-[#00ADB5] "
                          : "text-[#00ADB5] bg-[#222831]")
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(6);
                        setTripType("Adhoc");
                        setEmrStatus("");
                        setTripStatus("");
                      }}
                    >
                      ADHOC
                    </button>
                  </div>
                  <div className="flex items-center justify-end flex-auto mr-2 -mb-px text-center last:mr-0">
                    <button
                      className={
                        "text-xs font-bold uppercase px-5 py-3    w-full rounded block leading-normal " +
                        (openTab === 7
                          ? "text-[#222831] bg-[#00ADB5] "
                          : "text-[#00ADB5] bg-[#222831]")
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(7);
                        setEmrStatus("true");
                        setTripType("");
                        setTripStatus("");
                      }}
                    >
                      Emergency
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <TripList rows={rows} getTrips={getTrips} />
          </div>
        )}
      </Layout>
    </>
  );
}

export default Home;
