import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import { Link } from "react-router-dom";
import Table from "../Driver/Table";
import Dropdown from "../Driver/DropDown";
import eye from "../../assets/Icons/eye.png";
import axios from "axios";

export default function Remittance() {
  const [rows, setRows] = React.useState([]);
  const [rowsFroDropDown, setRowsFroDropDown] = React.useState([]);
  const [driverId, setDriverId] = React.useState("");

  useEffect(() => {
    getTrips();
    getTripsFroDropDown();
  }, [driverId]);


  const getTrips = async () => {
    try {
      const headers = {
        "x-access-token": localStorage.getItem("accessToken"),
        email: localStorage.getItem("email"),
        usertype: localStorage.getItem("userType"),
      };

      const response = await axios.get(
        window.api + "trip/getTripsRemittancePage",
        {
          params: {
            driverId: driverId,
          },
          headers, 
        }
      );

      if (response.status === 200) {
        const trips = response.data.data;
        setRows(trips);
      }
    } catch (error) {
      console.error("Error getting trips:", error);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  const getTripsFroDropDown = async () => {
    try {
      const headers = {
        "x-access-token": localStorage.getItem("accessToken"),
        email: localStorage.getItem("email"),
        usertype: localStorage.getItem("userType"),
      };

      const response = await axios.get(
        window.api + "trip/getTripsRemittancePage",
        {
          params: {
            // driverId: driverId,
          },
          headers, 
        }
      );

      if (response.status === 200) {
        const trips = response.data.data;
        setRowsFroDropDown(trips);
      }
    } catch (error) {
      console.error("Error getting trips:", error);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  const columns = [
    {
      name: "Driver ID",
      selector: "driverID",
      sortable: true,
      cell: (row) => (
        <div className="py-4 text-[#3C4048] text-center w-full cursor-pointer font-medium text-sm font-roboto hover:transform hover:scale-125 hover:font-base transition duration-300 ease-in-out">
          <span
            className={`py-4 text-center w-full text-sm font-roboto   cursor-pointer font-medium`}
          >
            {row.driverID}
          </span>
        </div>
      ),
    },
    {
      name: "Driver Name",
      selector: "driverName",
      sortable: true,
      cell: (row) => (
        <div className="py-4 text-[#3C4048] text-center w-full cursor-pointer font-medium text-sm font-roboto hover:transform hover:scale-125 hover:font-base transition duration-300 ease-in-out">
          <span
            className={`py-4 text-center w-full text-sm font-roboto   cursor-pointer font-medium`}
          >
            {row.driverName}
          </span>
        </div>
      ),
    },
    {
      name: "Vehicle No",
      selector: "vehicleNo",
      sortable: true,
      cell: (row) => (
        <div className="py-4 text-[#3C4048] text-center w-full cursor-pointer font-medium text-sm font-roboto hover:transform hover:scale-125 hover:font-base transition duration-300 ease-in-out">
          <span
            className={`py-4 text-center w-full text-sm font-roboto   cursor-pointer font-medium`}
          >
            {row.vehicleNo}
          </span>
        </div>
      ),
    },
    {
      name: "Total no of trips",
      selector: "noOfTrips",
      sortable: true,
      cell: (row) => (
        <div className="text-[#3C4048] text-center   font-medium text-sm font-roboto">
          <span className="text-[#3C4048] uppercase font-medium">
            {row.totalNoOfTrip}
          </span>
        </div>
      ),
    },
    {
      name: "On Hold Amount",
      selector: "totalRemainingAmmount",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#3C4048] text-center w-full font-medium text-sm font-roboto">
          <span className="text-[#3C4048] uppercase  font-medium">
            {row.totalRemaingAmount}
          </span>
        </div>
      ),
    },
    {
      name: "Action",
      selector: "id",
      sortable: false,
      cell: (row) => (
        <div className=" text-[#393e46]  font-medium   uppercase text-center text-sm font-roboto">
          <Link
            to={`/Remittance/${row.id}`}
            className="text-center text-blue-600 flex justify-center items-center w-full hover:text-blue-800"
          >
            <img className=" w-8 " src={eye} alt="" />
          </Link>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div className="flex flex-col gap-4 ">
        <div className="flex flex-col items-center justify-between px-4 py-3 mx-0 mt-2 bg-white border-2 md:flex-row border-1 rounded-xl">
          <div className="flex flex-row gap-2">
            <Dropdown
              lableName={"Driver ID"}
              options={rowsFroDropDown}
              placeholder={"Select Driver ID"}
              widthForSelect={"w-[280px]"}
              objName={"driverID"}
              updateValue={setDriverId}
              value={driverId}
            />
          </div>
        </div>

        <div className="table w-full h-auto ">
          <div className="w-full h-auto rounded-lg ">
            <div className="flex w-full flex-col gap-6  ">
              <Table
                columns={columns}
                rows={rows}
                headerStyle={[
                  " text-center ",
                  " text-center ",
                  " text-center ",
                  " text-center ",
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
