import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import Select from "react-select";
import backButton from "../../assets/Icons/IconsForPages/backArrow.png";
import Layout from "../../Layout/Layout";
import axios from "axios";
import InputBoxWithLabel from "../Driver/LabelInput";
import SelectOption from "../Driver/SelectOption";
import Dropdown from "../Driver/DropDown";
import Button from "../Driver/Button";

export default function AddEditTrip() {
  const { id } = useParams();
  const pageTitle = "Create Trip";

  const [RouteName, setRouteName] = useState("");
  const [TripDate, setTripDate] = useState("");
  const [ShiftTime, setShiftTime] = useState("00:00:00");
  const [FromLocation, setFromLocation] = useState("");
  const [ToLocation, setToLocation] = useState("");
  const [PickupTime, setPickupTime] = useState("00:00:00");
  const [TripType, setTripType] = useState("");
  const [VehicleType, setVehicleType] = useState("");
  const [NoOfPickup, setNoOfPickup] = useState("");
  const [CompanyId, setCompanyId] = useState("");
  const [TripAmount, setTripAmount] = useState("");
  const [CompanyList, setCompanyList] = useState([]);
  const [EmployeesList, setEmployeesList] = useState([]);
  const [SelectedEmployees, setSelectedEmployees] = useState([]);

  const [RouteIdError, setRouteIdError] = useState("");
  const [RouteNameError, setRouteNameError] = useState("");
  const [TripDateError, setTripDateError] = useState("");
  const [ShiftTimeError, setShiftTimeError] = useState("");
  const [FromLocationError, setFromLocationError] = useState("");
  const [ToLocationError, setToLocationError] = useState("");
  const [PickupTimeError, setPickupTimeError] = useState("");
  const [TripTypeError, setTripTypeError] = useState("");
  const [VehicleTypeError, setVehicleTypeError] = useState("");
  const [NoOfPickupError, setNoOfPickupError] = useState("");
  const [CompanyIdError, setCompanyIdError] = useState("");
  const [TripAmountError, setTripAmountError] = useState("");

  const navigate = useNavigate();
  const emrTrip = id ? true : false;
  const [RouteId, setRouteId] = useState(emrTrip ? "EMRTRIP" : "");

  useEffect(() => {
    getCompany();
    handleCompanyChange();
  }, [CompanyId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleFormValidation()) {
      try {
        const data = {
          routeId: RouteId,
          companyId: CompanyId,
          tripAmount: TripAmount,
          routeName: RouteName,
          tripDate: TripDate,
          shiftTime: ShiftTime,
          fromLocation: FromLocation,
          toLocation: ToLocation,
          pickupTime: PickupTime,
          tripType: TripType,
          vehicleType: VehicleType,
          noOfPickup: NoOfPickup,
          tripStatus: "Not Alloted",
          emrTrip: emrTrip ? true : false,
          selectedEmployees: SelectedEmployees,
        };
  
        const response = await axios.post(window.api + "trip/create", data, {
          headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "email": localStorage.getItem("email"),
            "usertype": localStorage.getItem("userType")
          }
        });
  
        if (response.status === 200) {
          toast("New Trip Created Successfully!", {
            icon: "👏",
            style: {
              borderRadius: "7px",
              background: "#222831",
              color: "#fff",
            },
          });
  
          setTimeout(() => {
            navigate("/trip-list");
          }, 2000);
        }
      } catch (e) {
        console.error("Errors creating trip: ", e);
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          // Optionally handle other types of errors if necessary
        }
      }
    }
  };
  

  const getCompany = async () => {
    try {
        const response = await axios.get(
            window.api + "company/getCompanyList",
            {
                headers: {
                    "x-access-token": localStorage.getItem("accessToken"),
                    "email": localStorage.getItem("email"),
                    "usertype": localStorage.getItem("userType")
                }
            }
        );
        
        if (response.status === 200) {
            const companyList = response.data.data;
            setCompanyList(companyList);
        }
    } catch (error) {
        console.error("Error getting company list:", error);
        
        if (error.response && error.response.status === 401) {
            localStorage.clear();
            window.location.href = '/';
        }
    }
};

const getEmployeesByCompanyId = async (id) => {
  try {
    const response = await axios.get(window.api + "employee/getEmployees", {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "email": localStorage.getItem("email"),
        "usertype": localStorage.getItem("userType")
      },
      params: {
        companyId: id,
      },
    });

    if (response.status === 200) {
      const employees = response.data.data;
      return employees;
    }
  } catch (e) {
    console.error("Error getting employees:", e);
    if (e.response && e.response.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    }
    return [];
  }
};


  const handleCompanyChange = async (e) => {
    const selectedCompanyId = CompanyId;
    setCompanyId(selectedCompanyId);

    if (selectedCompanyId) {
      const employees = await getEmployeesByCompanyId(selectedCompanyId);
      const convertedEmployeeList = employees.map((employee) => ({
        value: employee.id,
        label: employee.employeeId,
      }));
      setEmployeesList(convertedEmployeeList);
    } else {
      setEmployeesList([]);
    }
  };

  let serialNumber = 1;

  const handleChangeEmployees = async (selectedValues) => {
    const selectedValuesArray = selectedValues.map((option) => option.value);

    if (selectedValuesArray.length === 0) {
      // No selected values, so no need to make the API call
      setNoOfPickup(0);
      setSelectedEmployees([]);
      return;
    }

    const getSelectedEmployees = async () => {
      try {
        const response = await axios.get(window.api + "employee/getEmployees", {
          headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "email": localStorage.getItem("email"),
            "usertype": localStorage.getItem("userType")
          },
          params: {
            selectedValuesArray: selectedValuesArray,
          },
        });
    
        if (response.status === 200) {
          const employees = response.data.data;
    
          const selectedEmployees = employees.map((employee) => {
            const existingEmployee = SelectedEmployees.find(
              (prevEmployee) => prevEmployee.id === employee.id
            );
            if (existingEmployee) {
              return { ...existingEmployee };
            } else {
              return {
                ...employee,
                pickupLocation: "",
                pickupTime: "",
                dropLocation: "",
              };
            }
          });
    
          setNoOfPickup(selectedEmployees.length);
          setSelectedEmployees(selectedEmployees);
        }
      } catch (e) {
        console.error("Error getting employees:", e);
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        }
      }
    };
    getSelectedEmployees();
  };

  const handleInputChange = (e, index, field) => {
    const { value } = e.target;
    setSelectedEmployees((prevEmployees) => {
      const updatedEmployees = [...prevEmployees];
      const updatedEmployee = { ...updatedEmployees[index], [field]: value };
      updatedEmployees[index] = updatedEmployee;
      return updatedEmployees;
    });
  };

  const handleFormValidation = () => {
    let formIsValid = true;
    if (emrTrip === false) {
      if (!RouteId) {
        formIsValid = false;
        setRouteIdError("Please enter route id");
      } else {
        setRouteIdError("");
      }
      if (!RouteName) {
        formIsValid = false;
        setRouteNameError("Please enter route name");
      } else {
        setRouteNameError("");
      }
      if (!TripDate) {
        formIsValid = false;
        setTripDateError("Please enter trip date");
      } else {
        setTripDateError("");
      }
      if (!ShiftTime) {
        formIsValid = false;
        setShiftTimeError("Please enter shift time");
      } else {
        setShiftTimeError("");
      }
      if (!PickupTime) {
        formIsValid = false;
        setPickupTimeError("Please enter pickup time");
      } else {
        setPickupTimeError("");
      }
      if (!FromLocation) {
        formIsValid = false;
        setFromLocationError("Please enter from location");
      } else {
        setFromLocationError("");
      }
      if (!ToLocation) {
        formIsValid = false;
        setToLocationError("Please enter to location");
      } else {
        setToLocationError("");
      }
      if (!TripType) {
        formIsValid = false;
        setTripTypeError("Please select trip type");
      } else {
        setTripTypeError("");
      }
      if (!VehicleType) {
        formIsValid = false;
        setVehicleTypeError("Please select vehicle type");
      } else {
        setVehicleTypeError("");
      }
      if (!NoOfPickup) {
        formIsValid = false;
        setNoOfPickupError("Please select atlest one pickup");
      } else {
        setNoOfPickupError("");
      }
      if (!CompanyId) {
        formIsValid = false;
        setCompanyIdError("Please select company");
      } else {
        setCompanyIdError("");
      }
      if (!TripAmount) {
        formIsValid = false;
        setTripAmountError("Please enter trip amount");
      } else {
        setTripAmountError("");
      }
    } else {
      if (!FromLocation) {
        formIsValid = false;
        setFromLocationError("Please enter from location");
      } else {
        setFromLocationError("");
      }
      if (!ToLocation) {
        formIsValid = false;
        setToLocationError("Please enter to location");
      } else {
        setToLocationError("");
      }
      if (!TripType) {
        formIsValid = false;
        setTripTypeError("Please select trip type");
      } else {
        setTripTypeError("");
      }
      if (!VehicleType) {
        formIsValid = false;
        setVehicleTypeError("Please select vehicle type");
      } else {
        setVehicleTypeError("");
      }
      if (!TripDate) {
        formIsValid = false;
        setTripDateError("Please enter trip date");
      } else {
        setTripDateError("");
      }
    }

    return formIsValid;
  };

  const tripList = [
    { type: "Drop", id: "Drop" },
    { type: "Pickup", id: "Pickup" },
    { type: "Adhoc", id: "Adhoc" },
  ];

  const vehicleTypeList = [
    { type: "Sedan", id: "Sedan" },
    { type: "SUV", id: "SUV" },
    { type: "Tempo", id: "Tempo" },
    { type: "Coach", id: "Coach" },
  ];

  return (
    <>
      <Layout>
        <Toaster />
        <div className="p-5">
          <div className='"w-full max-w-lg"'>
            <div className="bg-white rounded-md shadow-lg ">
              <div className="container">
                <div className="flex flex-wrap w-full ">
                  <div className="flex items-center justify-between w-full pr-16 pl-9">
                    <div className="m-8 cursor-pointer  shadow-lgrounded-xl">
                      <Link
                        to={"/trip-list"}
                        className="flex items-center justify-center w-10 h-10 text-white"
                      >
                        <img
                          src={backButton}
                          className="w-5"
                          alt=""
                          srcset=""
                        />
                      </Link>
                    </div>
                    <div className="content-center m-8 text-center ">
                      <h1 className="font-bold text-[25px] text-black pr-4 justify-center text-center">
                        {pageTitle}
                      </h1>
                    </div>
                    <Button onClick={handleSubmit} name={"Submit"} />
                  </div>
                </div>

                <div className="container w-full h-auto px-6 py-0">
                  <div className="container w-full h-auto ">
                    <div class="flex flex-wrap  lg:flex-nowrap   justify-around items-center w-full">
                      <InputBoxWithLabel
                        label="Route ID"
                        placeholder="Enter Route ID"
                        type="text"
                        onChange={(e) => setRouteId(e.target.value)}
                        value={RouteId}
                        validationError={RouteIdError}
                      />

                      <Dropdown
                        lableName={"Company"}
                        placeholder={"Select Company"}
                        updateValue={setCompanyId}
                        options={CompanyList}
                        validationError={CompanyIdError}
                        objName={"companyName"}
                        value={CompanyId}
                      />

                      <InputBoxWithLabel
                        label="Trip Amount"
                        placeholder="Enter Trip Amount"
                        type="number"
                        onChange={(e) => setTripAmount(e.target.value)}
                        value={TripAmount}
                        validationError={TripAmountError}
                      />
                    </div>
                    <div class="flex flex-wrap  lg:flex-nowrap   justify-around items-center w-full">
                      <InputBoxWithLabel
                        label="Route Name"
                        placeholder="Enter Route Name"
                        type="text"
                        onChange={(e) => setRouteName(e.target.value)}
                        value={RouteName}
                        validationError={RouteNameError}
                      />

                      <InputBoxWithLabel
                        label="Trip Date"
                        placeholder="Enter Trip Date"
                        type="date"
                        onChange={(e) => setTripDate(e.target.value)}
                        value={TripDate}
                        validationError={TripDateError}
                      />

                      <InputBoxWithLabel
                        label="Shift Time"
                        placeholder="Enter Shift Time"
                        type="time"
                        onChange={(e) => setShiftTime(e.target.value)}
                        value={ShiftTime}
                        validationError={ShiftTimeError}
                      />
                    </div>
                    <div class="flex flex-wrap  lg:flex-nowrap   justify-around items-center w-full">
                      <InputBoxWithLabel
                        label="From Location"
                        placeholder="Enter From Location"
                        type="text"
                        onChange={(e) => setFromLocation(e.target.value)}
                        value={FromLocation}
                        validationError={FromLocationError}
                      />

                      <InputBoxWithLabel
                        label="To Location"
                        placeholder="Enter To Location"
                        type="text"
                        onChange={(e) => setToLocation(e.target.value)}
                        value={ToLocation}
                        validationError={ToLocationError}
                      />

                      <InputBoxWithLabel
                        label="Pickup Time"
                        placeholder="Enter Pickup Time"
                        type="time"
                        onChange={(e) => setPickupTime(e.target.value)}
                        value={PickupTime}
                        validationError={PickupTimeError}
                      />
                    </div>
                    <div class="flex flex-wrap  lg:flex-nowrap   justify-around items-center w-full">
                      <Dropdown
                        lableName={"Trip type"}
                        options={tripList}
                        updateValue={setTripType}
                        placeholder={"Select Trip Type"}
                        validationError={TripTypeError}
                        objName={"type"}
                        value={TripType}
                      />
                      <Dropdown
                        lableName={"Vehicle Type"}
                        placeholder={"Select Vehicle Type"}
                        options={vehicleTypeList}
                        updateValue={setVehicleType}
                        validationError={VehicleTypeError}
                        objName={"type"}
                        value={VehicleType}
                      />

                      <InputBoxWithLabel
                        label="No of Pickup"
                        placeholder="Enter No of Pickup"
                        type="number"
                        onChange={(e) => setNoOfPickup(e.target.value)}
                        value={NoOfPickup}
                        validationError={NoOfPickupError}
                        disabled={true}
                      />
                    </div>
                    <div class="flex flex-wrap  lg:flex-nowrap   justify-around items-center w-1/3 mt-1">
                      <SelectOption
                        lableName="Add Empoyees"
                        select={
                          <Select
                            className="w-full"
                            isMulti
                            onChange={handleChangeEmployees}
                            options={EmployeesList}
                            placeholder="---Select Employees---"
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative w-full h-full mt-4 overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-gray-500 ">
              <thead className="text-xs text-white uppercase  w-full  border-[1px] border-[#3C4048] bg-[#3C4048] ">
                <tr>
                  <th
                    className={` py-4 font-[600]   tracking-wider  text-center `}
                  >
                    Sl.No
                  </th>
                  <th
                    className={`px-6 py-4 font-[600]   tracking-wider  text-center `}
                  >
                    Employee ID
                  </th>
                  <th
                    className={`px-6 py-4 font-[600]   tracking-wider  text-center `}
                  >
                    Employee Name
                  </th>
                  <th
                    className={`px-6 py-4 font-[600]   tracking-wider  text-center `}
                  >
                    Gender
                  </th>
                  <th
                    className={`px-6 py-4 font-[600]   tracking-wider  text-center `}
                  >
                    Contact Number
                  </th>
                  <th
                    className={`px-6 py-4 font-[600]   tracking-wider  text-center `}
                  >
                    Pickup Location
                  </th>
                  <th
                    className={`px-6 py-4 font-[600]   tracking-wider  text-center `}
                  >
                    Pickup Time
                  </th>
                  <th
                    className={`px-6 py-4 font-[600]   tracking-wider  text-center `}
                  >
                    Drop Location
                  </th>
                </tr>
              </thead>

              {SelectedEmployees.length > 0 && (
                <tbody className="table-row-group p-4 text-lg uppercase divide-x-0 rounded-md">
                  {SelectedEmployees.map((row, index) => {
                    return (
                      <tr
                        className="justify-center table-row text-center align-middle rounded-lg shadow-lg outline-none drop-shadow-2xl"
                        tabIndex={-1}
                        // key={}
                      >
                        <td className="  text-[#393e46]  text-left  font-medium text-sm font-roboto">
                          <span className="text-[#393e46]">
                            {serialNumber++}
                          </span>
                        </td>
                        <td className="  text-[#393e46]  text-left  font-medium text-sm font-roboto">
                          <span className="text-[#393e46]">
                            {row.employeeId}
                          </span>
                        </td>
                        <td className=" py-3 text-[#393e46]  text-left  font-medium text-sm font-roboto">
                          <span className="text-[#393e46]">
                            {row.employeeName}
                          </span>
                        </td>
                        <td className=" py-3 text-[#393e46]  text-center  font-medium text-sm font-roboto">
                          <span className="text-[#393e46]">{row.gender}</span>
                        </td>
                        <td className=" py-3 text-[#393e46]  text-center  font-medium text-sm font-roboto">
                          <span className="text-[#393e46]">{row.mobileNo}</span>
                        </td>
                        <td className=" py-3 text-[#393e46]  text-center  font-medium text-sm font-roboto">
                          <input
                            type="text"
                            placeholder="Enter Pickup Location"
                            className="w-full text-[#393E46]  mr-2 mt-2 mb-2  font-medium text-sm font-roboto p-2 border-2 border-[#D6D6D6] rounded-lg focus:outline-none focus:border-[#393E46]"
                            value={row.pickupLocation} // Set the initial value from the array
                            onChange={(e) =>
                              handleInputChange(e, index, "pickupLocation")
                            } // Call the event handler
                          />
                        </td>
                        <td className=" py-3 text-[#393e46]  text-center  font-medium text-sm font-roboto">
                          <input
                            type="time"
                            placeholder="Enter Pickup Time"
                            className="w-full text-[#393E46]  mr-2 mt-2 mb-2  font-medium text-sm font-roboto p-2 border-2 border-[#D6D6D6] rounded-lg focus:outline-none focus:border-[#393E46]"
                            value={row.pickupTime} // Set the initial value from the array
                            onChange={(e) =>
                              handleInputChange(e, index, "pickupTime")
                            } // Call the event handler
                          />
                        </td>
                        <td className="py-3 text-[#393e46] text-center font-medium text-sm font-roboto">
                          <input
                            type="text"
                            placeholder="Enter Drop Location"
                            className="w-full text-[#393E46] mr-2 mt-2 mb-2 font-medium text-sm font-roboto p-2 border-2 border-[#D6D6D6] rounded-lg focus:outline-none focus:border-[#393E46]"
                            value={row.dropLocation}
                            onChange={(e) =>
                              handleInputChange(e, index, "dropLocation")
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </Layout>
    </>
  );
}
