import React from "react";
import Layout from "../../Layout/Layout";

import Wallet from "./Wallet";
import Create from "./Create";

export default function AdminLayout() {
  const Tabs = ({ color }) => {
    const [openTab, setOpenTab] = React.useState(1);

    return (
      <>
        <div className="flex flex-wrap">
          <div className="w-full ">
            <ul
              className="flex flex-row flex-wrap gap-2 pt-3 pb-4 mb-0 list-none"
              role="tablist"
            >
              <li className="flex-auto mr-2 -mb-px text-center last:mr-0">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3  rounded block leading-normal " +
                    (openTab === 1
                      ? "text-[#222831] bg-[#00ADB5] "
                      : "text-[#00ADB5] bg-[#222831]")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  Wallet
                </a>
              </li>
              <li className="flex-auto mr-2 -mb-px text-center last:mr-0">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3  rounded block leading-normal " +
                    (openTab === 2
                      ? "text-[#222831] bg-[#00ADB5] "
                      : "text-[#00ADB5] bg-[#222831]")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                  Create
                </a>
              </li>
              <li className="flex-auto invisible mr-2 -mb-px text-center last:mr-0"></li>
              <li className="flex-auto invisible mr-2 -mb-px text-center last:mr-0"></li>
            </ul>
            <div className="relative flex flex-col min-w-0 break-words  w-full mb-6  ">
              <div className="flex-auto  py-5 ">
                <div className="tab-content tab-space">
                  <div
                    className={openTab === 1 ? "block" : "hidden"}
                    id="link1"
                  >
                    <Wallet />
                  </div>
                  <div
                    className={openTab === 2 ? "block" : "hidden"}
                    id="link2"
                  >
                    <Create />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <div>
      <Layout>
        <Tabs />
      </Layout>
    </div>
  );
}
