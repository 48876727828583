import React from 'react'
import Employee from "./IconImg/Employees.png"

function EmployeeIcon() {
  return (   
  <img src={Employee} className="w-8 h-8" alt="Emlployee"  />

  )
}

export default EmployeeIcon