import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import axios from "axios";
import Dropdown from "../Driver/DropDown";
import InputBoxWithLabel from "../Driver/LabelInput";
import Table from "../Driver/Table";
import OutstationTripTable from "./OutstationTrip/OutstationTripTable";
import OutstationInvoiceTable from "./OutstationInvoice/OutstationInvoiceTable";

export default function OutStation() {
  const [openTab, setOpenTab] = useState(
    Number(localStorage.getItem("openTab")) || 1
  );
  const [openButton, setOpenButton] = useState(
    Number(localStorage.getItem("openButton")) || 1
  );


  window.onbeforeunload = () => {
    localStorage.removeItem("openTab");
    localStorage.removeItem("openButton");
  };

  return (
    <Layout>
      <div className="flex flex-wrap -mx-4">
        <div className="container w-full h-auto ">
          <div className="w-auto h-auto p-2 m-2   ">
            <div className="flex flex-wrap w-full">
              <div className="w-full ">
                <div className="flex flex-wrap items-center justify-center w-full">
                  <div className="flex flex-col w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5">
                    <div className="flex w-auto h-full mx-5">
                      <button
                        className={
                          "text-xs font-bold uppercase px-5 py-3  w-full rounded block leading-normal " +
                          (openTab === 1
                            ? "text-[#222831] bg-[#00ADB5] "
                            : "text-[#00ADB5] bg-[#222831]")
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(1);
                          localStorage.setItem("openTab", 1);
                        }}
                      >
                        Trips
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5">
                    <div className="flex w-auto h-full mx-5">
                      <button
                        className={
                          "text-xs font-bold uppercase px-5 py-3  w-full rounded block leading-normal " +
                          (openTab === 2
                            ? "text-[#222831] bg-[#00ADB5] "
                            : "text-[#00ADB5] bg-[#222831]")
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(2);
                          localStorage.setItem("openTab", 2);
                        }}
                        data-toggle="tab"
                        href="#link2"
                        role="tablist"
                      >
                        Invoice
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col invisible w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5"></div>
                  <div className="flex flex-col invisible w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5"></div>
                  <div className="flex flex-col invisible w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5"></div>
                  <div className="flex flex-wrap mb-2 items-center justify-around w-full ">
                    <div
                      className={openTab === 1 ? "block w-full" : "hidden"}
                      id="link1"
                    >
                      <OutstationTripTable />
                    </div>

                    <div
                      className={openTab === 2 ? "block  w-full" : "hidden"}
                      id="link1"
                    >
                      <OutstationInvoiceTable
                        setOpenButton={setOpenButton}
                        openTab={openTab}
                        openButton={openButton}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
