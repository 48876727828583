import React from "react";
import Dropdown from "../../Driver/DropDown";
import InputBoxWithLabel from "../../Driver/LabelInput";

export default function Attendance({
  companyList,
  companyIdAttendance,
  setCompanyIdAttendance,
  setTripTypeAttendance,
  tripTypeAttendance,
  setRouteIdAttendance,
  routeIdAttendance,
  toDateAttendance,
  fromDateAttendance,
  setToDateAttendance,
  setFromDateAttendance,
}) {
  const tripList = [
    { type: "Drop", id: "Drop" },
    { type: "Pickup", id: "Pickup" },
    { type: "Adhoc", id: "Adhoc" },
  ];
  return (
    <div className="flex rounded-lg bg-white py-2">
      <div className="flex flex-wrap items-center justify-around w-full h-auto gap-1 py-3 mb-2 items-cente labelInputContainer">
        <Dropdown
          lableName={"Company"}
          options={companyList}
          updateValue={setCompanyIdAttendance}
          placeholder={"Company"}
          widthForSelect={"w-[200px]"}
          objName={"companyName"}
          value={companyIdAttendance}
        />
        <InputBoxWithLabel
          widthForInput={"w-[200px]"}
          label={"From Date"}
          type={"date"}
          value={fromDateAttendance}
          onChange={(event) => {
            setFromDateAttendance(event.target.value);
            localStorage.setItem(
              "fromDateInvoiceAttendance",
              event.target.value
            );
          }}
        />
        <InputBoxWithLabel
          widthForInput={"w-[200px]"}
          label={"To Date"}
          type={"date"}
          value={toDateAttendance}
          onChange={(event) => {
            setToDateAttendance(event.target.value);
            localStorage.setItem("toDateInvoiceAttendance", event.target.value);
          }}
        />{" "}
        <InputBoxWithLabel
          widthForInput={"w-[200px]"}
          label={"Route Id"}
          type={"text"}
          value={routeIdAttendance}
          onChange={(event) => {
            setRouteIdAttendance(event.target.value);
            localStorage.setItem("routeIdAttendance", event.target.value);
          }}
        />
        <Dropdown
          lableName={"Trip type"}
          options={tripList}
          updateValue={setTripTypeAttendance}
          placeholder={"Trip type"}
          widthForSelect={"w-[200px]"}
          objName={"type"}
          value={tripTypeAttendance}
        />
      </div>
    </div>
  );
}
