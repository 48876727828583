import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../../Layout/Layout";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import AddSuperAdmin from "./PopUp/AddSuperAdmin";
import AddAdmin from "./PopUp/AddAdmin";
import AddSupervisor from "./PopUp/AddSupervisor";
import Table from "../Driver/Table";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

export default function Create() {
  const [openTab, setOpenTab] = useState(1);
  const [showPopUp, setShowPopUp] = React.useState(false);
  const [companyList, setCompanyList] = React.useState([]);
  const [userType, setUserType] = React.useState("superAdmin");
  const [rows, setRows] = React.useState([]);
  const [id, setId] = React.useState("");
  const [superAdminPlus, setSuperAdminPlus] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("userType") == "superAdminPlus") {
      setSuperAdminPlus(true);
    }
  }, []);

  useEffect(() => {
    // Define an async function
    const fetchData = async () => {
      await getCompany();
      await getUsers();
    };

    // Call the async function
    fetchData();
  }, [userType]);

  const getUsers = async () => {
    try {
      const response = await axios.get(window.api + "auth/getUsers", {
        params: {
          userType,
        },
      });
      if (response.status === 200) {
        let users = response.data.data;
        const companyMapping = {};
        companyList.forEach((company) => {
          companyMapping[company.id] = company.companyName;
        });

        users = users.map((user) => {
          return {
            ...user,
            companyIds:
              user.companyIds &&
              user.companyIds
                .map((companyId) => companyMapping[companyId])
                .filter(Boolean)
                .join(" / "),
          };
        });

        const dataWithSerial = users.map((item, index) => ({
          ...item,
          serialNumber: index + 1,
        }));

        setRows(dataWithSerial);
      }
    } catch (error) {
      setRows([]);
      console.error("Error getting users:", error);
    }
  };

  const getCompany = async () => {
    try {
      const response = await axios.get(window.api + "company/getCompanyList", {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          email: localStorage.getItem("email"),
          usertype: localStorage.getItem("userType"),
        },
      });

      if (response.status === 200) {
        const companyList = response.data.data;
        setCompanyList(companyList);
      }
    } catch (error) {
      console.error("Error getting company list:", error);

      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  const handlePopUp = (item) => {
    if (item === false) return setShowPopUp(false);
    setShowPopUp(item);
  };

  useEffect(() => {
    if (showPopUp === false) {
      setId("");
    }
  }, [showPopUp]);

  const handleEdit = (id) => {
    setId(id);
    if (userType === "superAdmin") return handlePopUp(1);
    if (userType === "Admin") return handlePopUp(2);
    if (userType === "supervisor") return handlePopUp(3);
  };

  const columns = [
    {
      name: "Sr No.",
      selector: "id",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#393e46] text-center w-full font-medium text-sm font-roboto">
          <span className="py-3 text-sm font-roboto uppercase text-[#393e46]  font-medium cursor-pointer">
            {row.serialNumber}
          </span>
        </div>
      ),
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#393e46] text-left w-full font-medium text-sm font-roboto">
          <span className="py-3  text-sm font-roboto uppercase text-[#393e46]  font-medium cursor-pointer">
            {row.name}
          </span>
        </div>
      ),
    },
    {
      name: "Mobile Number",
      selector: "mobileNumber",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#393e46] text-center w-full font-medium text-sm font-roboto">
          <span className="py-3   text-sm font-roboto uppercase text-[#393e46]  font-medium cursor-pointer">
            {row.mobileNumber}
          </span>
        </div>
      ),
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#393e46] text-left w-full font-medium text-sm font-roboto">
          <span className="py-3  text-sm font-roboto uppercase text-[#393e46]  font-medium  cursor-pointer">
            {row.email}
          </span>
        </div>
      ),
    },
  ];

  if (userType === "supervisor") {
    columns.push({
      name: "Company",
      selector: "company",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#393e46] text-center w-full font-medium text-sm font-roboto">
          <span className="py-3  text-sm font-roboto uppercase text-[#393e46]  font-medium cursor-pointer">
            {row.companyIds}
          </span>
        </div>
      ),
    });
  }

  columns.push({
    name: "Actions",
    selector: "actions",
    sortable: false, // Typically, action columns are not sortable.
    cell: (row) => (
      <div className="py-3 text-[#393e46] text-center w-full flex justify-center items-center font-medium text-sm font-roboto">
        <span
          className="py-3 text-sm font-roboto uppercase text-[#393e46] font-medium cursor-pointer"
          onClick={() => handleEdit(row.id)}
        >
          <FiEdit />
        </span>
        {!superAdminPlus ? null : (
          <span
            className="py-3 ml-4 text-sm font-roboto uppercase text-[#393e46] font-medium cursor-pointer"
            onClick={() => handleDelete(row.id)}
          >
            <RiDeleteBin6Line />
          </span>
        )}
      </div>
    ),
  });

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You are about to delete this user. This action cannot be undone!",
      confirmButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await axios.put(window.api + `auth/deleteUser/${id}`);
        await getUsers();
        Swal.fire("Deleted!", "User has been deleted.", "success");
      } catch (error) {
        console.error("Error deleting driver:", error);
      }
    }
  };

  return (
    <>
      <div className="flex flex-wrap w-full">
        <div className="w-full h-auto my-2   rounded-md  ">
          <div className="flex flex-wrap mb-2 items-center justify-around w-full  h-auto md:h-[220px] lg:h-auto ">
            <div className="flex flex-wrap justify-around w-full shadow-sm shadow-[#00adb5] pb-4 bg-white border-[#00adb5]  border-2  rounded-lg">
              <button
                onClick={() => {
                  handlePopUp(1);
                }}
                className="flex mb-5 mt-9 "
              >
                <Link className="bg-[#00adb5] flex justify-center items-center  hover:bg-[#059299] active:bg-[#047481] text-gray-100 text-lg font-bold text-center  px-4 py-[6px] rounded-lg ">
                  <span className="">Add Super Admin</span>
                </Link>
              </button>
              <button
                onClick={() => {
                  handlePopUp(2);
                }}
                className="flex mb-5 mt-9 "
              >
                <Link className="bg-[#00adb5] flex justify-center items-center  hover:bg-[#059299] active:bg-[#047481] text-gray-100 text-lg font-bold text-center  px-4 py-[6px] rounded-lg ">
                  <span className="">Add Admin</span>
                </Link>
              </button>
              <button
                onClick={() => {
                  handlePopUp(3);
                }}
                className="flex mb-5 mt-9 "
              >
                <Link className="bg-[#00adb5] flex justify-center items-center  hover:bg-[#059299] active:bg-[#047481] text-gray-100 text-lg font-bold text-center  px-4 py-[6px] rounded-lg ">
                  <span className="">Add Supervisor</span>
                </Link>
              </button>
            </div>
          </div>
        </div>
        <div className="w-full ">
          <div className="flex flex-wrap items-center justify-center w-full h-auto gap-2 p-2 lg:gap-0 labelInputContainer">
            <div className="flex flex-col w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5">
              <div className="flex w-auto h-full mx-5">
                <button
                  className={
                    "text-xs font-bold uppercase px-5 py-3  w-full rounded block leading-normal " +
                    (openTab === 1
                      ? "text-[#222831] bg-[#00ADB5] "
                      : "text-[#00ADB5] bg-[#222831]")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setUserType("superAdmin");
                    setOpenTab(1);
                  }}
                >
                  Super Admin
                </button>
              </div>
            </div>
            <div className="flex flex-col w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5">
              <div className="flex w-auto h-full mx-5">
                <button
                  className={
                    "text-xs font-bold uppercase px-5 py-3  w-full rounded block leading-normal " +
                    (openTab === 2
                      ? "text-[#222831] bg-[#00ADB5] "
                      : "text-[#00ADB5] bg-[#222831]")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setUserType("admin");
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                  Admin
                </button>
              </div>
            </div>
            <div className="flex flex-col w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5">
              <div className="flex w-auto h-full mx-5">
                <button
                  className={
                    "text-xs font-bold uppercase px-5 py-3  w-full rounded block leading-normal " +
                    (openTab === 3
                      ? "text-[#222831] bg-[#00ADB5] "
                      : "text-[#00ADB5] bg-[#222831]")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setUserType("supervisor");
                    setOpenTab(3);
                  }}
                >
                  Supervisor
                </button>
              </div>
            </div>
            <div className="flex flex-col invisible w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5"></div>
            <div className="flex flex-col invisible w-full h-full labelInput sm:w-1/2 md:w-1/3 lg:w-1/5"></div>
          </div>
        </div>
      </div>

      {showPopUp === 1 && (
        <AddSuperAdmin
          setShowPopUp={setShowPopUp}
          id={id}
          getUsers={getUsers}
        />
      )}
      {showPopUp === 2 && (
        <AddAdmin setShowPopUp={setShowPopUp} id={id} getUsers={getUsers} />
      )}
      {showPopUp === 3 && (
        <AddSupervisor
          id={id}
          companyList={companyList}
          setCompanyList={setCompanyList}
          setShowPopUp={setShowPopUp}
          getUsers={getUsers}
        />
      )}

      <br />

      <div className="table w-full h-auto ">
        <div className="w-full h-auto rounded-lg ">
          <div className="flex flex-col gap-6 w-full  rounded-lg -md">
            <Table
              columns={columns}
              rows={rows}
              headerStyle={[
                " text-center ",
                "text-left pl-0",
                "text-center",
                "text-left pl-0",
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
}
