import DataTable from "react-data-table-component";
import Layout from "../../../Layout/Layout";
import { Link, useLocation } from "react-router-dom";
import ExcelJS from "exceljs";
import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import html2pdf from "html2pdf.js";
import axios from "axios";
import { format } from "date-fns";
import Table from "../../Driver/Table";
import backButton from "../../../assets/Icons/IconsForPages/backArrow.png";

export default function TableDetails() {
  const [rowsAccountDetails, setRowsAccountDetails] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const companyId = searchParams.get("companyId");
  const invoicePaidBy = searchParams.get("invoicePaidBy");
  const toDateAccount = searchParams.get("toDateAccount");
  const fromDateAccount = searchParams.get("fromDateAccount");

  useEffect(() => {
    if(localStorage.getItem("userType") == "supervisor" ||
    localStorage.getItem("userType") == "admin" ){
      alert("You are not authorized to access this page");
      window.location.href = "/";
    }
  }, []);
    

  useEffect(() => {
    getTrips();
  }, []);

  const getTrips = async () => {
    try {
      const response = await axios.get(
        window.api + "report/getTripsAccountDetails",
        {
          headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "email": localStorage.getItem("email"),
            "usertype": localStorage.getItem("userType")
          },
          params: {
            fromDateAccount,
            toDateAccount,
            companyId,
            invoicePaidBy,
          },
        }
      );
  
      if (response.status === 200) {
        const trips = response.data.data;
        const outputArray = trips.map((item, index) => {
          return {
            "Sr. No.": index + 1,
            "Route ID": item.routeId,
            "Route Name": item.routeName,
            "Trip Date": format(new Date(item.tripDate), "dd-MM-yyyy"),
            "Trip Type": item.tripType,
            "Driver ID": item.driverID ? item.driverID.toUpperCase() : "-",
            "Driver Name": item.driverName ? item.driverName.toUpperCase() : "-",
            "Trip Amount": item.tripAmount,
            "Paid Amount": item.invoicePaidAmount,
            "Payment Mode": item.invoicePaymentMode.toUpperCase(),
            "Paid By": item.invoicePaidBy.toUpperCase(),
            "Paid Date": format(
              new Date(item.invoicePaymentDate),
              "dd-MM-yyyy"
            ),
          };
        });
  
        const outputArrayFixedSequence = outputArray.map((item) => {
          return {
            "Sr. No.": item["Sr. No."],
            "Route ID": item["Route ID"],
            "Route Name": item["Route Name"],
            "Trip Date": item["Trip Date"],
            "Trip Type": item["Trip Type"],
            "Driver ID": item["Driver ID"],
            "Driver Name": item["Driver Name"],
            "Trip Amount": item["Trip Amount"],
            "Paid Amount": item["Paid Amount"],
            "Payment Mode": item["Payment Mode"],
            "Paid By": item["Paid By"],
            "Paid Date": item["Paid Date"],
          };
        });
  
        setRowsAccountDetails(outputArrayFixedSequence);
      }
    } catch (e) {
      console.error("Error fetching trip account details:", e);
      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      } else {
        // handle other types of errors if necessary
      }
    }
  };
  

  const columns = [
    {
      name: "Sr.No.",
      selector: "srNo",
      sortable: true,
      width: "5%",
      cell: (row) => (
        <td className=" flex justify-center items-center w-full py-3 text-[#393e46] text-center font-medium text-[13px] font-roboto">
          <div className="text-[#393e46] text-center pl-4 uppercase font-medium">
            {row["Sr. No."]}
          </div>
        </td>
      ),
    },
    {
      name: "Route ID",
      selector: "routeId",
      sortable: true,
      width: "7%",
      cell: (row) => (
        <div className=" w-full text-[#393e46]   font-medium text-[13px] font-roboto">
          <div className="text-[#393e46] uppercase font-medium text-center ">
            {row["Route ID"]}
          </div>
        </div>
      ),
    },
    {
      name: "Route Name",
      selector: "routeName",
      width: "12%",
      sortable: true,
      cell: (row) => (
        <td className=" flex justify-start items-center  py-3 text-[#393e46] text-left  w-full   font-medium text-[13px] font-roboto">
          <div className="text-[#393e46]  pl-2   uppercase font-medium">
            {row["Route Name"]}
          </div>
        </td>
      ),
    },
    {
      name: "Trip Date",
      selector: "tripDate",
      sortable: true,
      cell: (row) => (
        <td className=" flex justify-center items-center w-full py-3 text-[#393e46] text-center font-medium text-[13px] font-roboto">
          <div className="text-[#393e46] uppercase font-medium">
            {row["Trip Date"]}
          </div>
        </td>
      ),
    },
    {
      name: "Trip Type",
      selector: "tripType",
      sortable: true,
      cell: (row) => (
        <td className=" flex justify-center items-center w-full py-3 text-[#393e46] text-center font-medium text-[13px] font-roboto">
          <div className="text-[#393e46] uppercase font-medium">
            {row["Trip Type"]}
          </div>
        </td>
      ),
    },
    {
      name: "Driver ID",
      selector: "driverID",
      sortable: true,
      cell: (row) => (
        <td className="flex justify-center items-center w-full py-3 text-[#393e46] text-center font-medium text-[13px] font-roboto">
          <div className="text-[#393e46] uppercase font-medium">
            {row["Driver ID"] || "-"}
          </div>
        </td>
      ),
    },
    {
      name: "Driver Name",
      selector: "driverName",
      sortable: true,
      cell: (row) => (
        <td className="flex justify-center items-center w-full py-3 text-[#393e46] text-left font-medium text-[13px] font-roboto">
          <div className="text-[#393e46] uppercase font-medium">
            {row["Driver Name"] || "-"}
          </div>
        </td>
      ),
    },

    {
      name: "Trip Amount",
      selector: "tripAmount",
      sortable: true,
      cell: (row) => (
        <td className=" flex justify-center items-center w-full py-3 text-[#393e46] text-center font-medium text-[13px] font-roboto">
          <div className="text-[#393e46] uppercase font-medium">
            {row["Trip Amount"]}
          </div>
        </td>
      ),
    },
    {
      name: "Paid Amount",
      selector: "paidAmount",
      sortable: true,
      // width:"5%",
      cell: (row) => (
        <td className=" flex justify-center items-center w-full py-3 text-[#393e46] text-center font-medium text-[13px] font-roboto">
          <div className="text-[#393e46] uppercase font-medium">
            {row["Paid Amount"]}
          </div>
        </td>
      ),
    },
    {
      name: "Payment Mode",
      selector: "paymentMode",
      sortable: true,
      cell: (row) => (
        <td className=" flex justify-center items-center w-full py-3 text-[#393e46] text-center font-medium text-[13px] font-roboto">
          <div className="text-[#393e46] uppercase font-medium">
            {row["Payment Mode"]}
          </div>
        </td>
      ),
    },
    {
      name: "Paid By",
      selector: "paidBy",
      sortable: true,
      // width:"%",
      cell: (row) => (
        <div className="  w-full py-3 text-[#393e46]  font-medium text-[13px] font-roboto">
          <div className="text-[#393e46] uppercase text-center font-medium">
            {row["Paid By"]}
          </div>
        </div>
      ),
    },
    {
      name: "Paid Date",
      selector: "paidDate",
      sortable: true,
      cell: (row) => (
        <td className=" flex justify-center items-center w-full py-3 text-[#393e46] text-center font-medium text-[13px] font-roboto">
          <div className="text-[#393e46] uppercase font-medium">
            {row["Paid Date"]}
          </div>
        </td>
      ),
    },
  ];

  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Define header style
    const headerStyle = {
      font: { bold: true },
      alignment: { horizontal: "center" },
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "C0C0C0" } },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
      numFmt: "dd-mmm-yyyy", // Format the date if needed
      textRotation: 90, // Rotate the header text if needed
    };

    // Define cell style
    const cellStyle = {
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "DDDDDD" } },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
    };

    // Define headers and columns
    const headers = [
      "Sr. No.",
      "Route ID",
      "Route Name",
      "Trip Date",
      "Trip Type",
      "Driver ID",
      "Driver Name",
      "Trip Amount",
      "Paid Amount",
      "Payment Mode",
      "Paid By",
      "Paid Date",
    ];
    const columns = [
      { key: "srNo", header: "Sr. No." },
      { key: "routeId", header: "Route ID" },
      { key: "routeName", header: "Route Name" },
      { key: "tripDate", header: "Trip Date" },
      { key: "tripType", header: "Trip Type" },
      { key: "driverId", header: "Driver ID" },
      { key: "driverName", header: "Driver Name" },
      { key: "tripAmount", header: "Trip Amount" },
      { key: "paidAmount", header: "Paid Amount" },
      { key: "paymentMode", header: "Payment Mode" },
      { key: "paidBy", header: "Paid By" },
      { key: "paidDate", header: "Paid Date" },
    ];

    // Add header row
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.style = headerStyle;
    });

    // Add data rows
    rowsAccountDetails.forEach((row, index) => {
      const dataRow = worksheet.addRow([
        index + 1,
        row["Route ID"],
        row["Route Name"],
        row["Trip Date"],
        row["Trip Type"],
        row["Driver ID"],
        row["Driver Name"],
        row["Trip Amount"],
        row["Paid Amount"],
        row["Payment Mode"],
        row["Paid By"],
        row["Paid Date"],
      ]);
      dataRow.eachCell((cell) => {
        cell.style = cellStyle;
        if (cell.col === 1) {
          // Adjust the column index if needed
          cell.alignment = { horizontal: "center" };
        }
      });
    });

    // Auto-fit column widths
    columns.forEach((column, index) => {
      const columnWidth =
        headers[index].length < column.header.length
          ? column.header.length
          : headers[index].length;
      worksheet.getColumn(index + 1).width =
        columnWidth < 12 ? 12 : columnWidth;
    });

    // Generate buffer
    const excelBuffer = await workbook.xlsx.writeBuffer();

    // Create and download the file
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}${currentDate
      .toLocaleString("default", { month: "long" })
      .toLowerCase()}${currentDate.getFullYear()}`;
    const fileName = `report_paid_by_${invoicePaidBy}_${formattedDate}.xlsx`;

    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
  };

  return (
    <Layout>
      <div className="flex items-center justify-between w-full pr-10 py-5  mb-5 rounded-lg shadow  shadow-gray-500">
        <button className="flex   ">
          <Link
            to={"/Report"}
            className="flex items-center justify-center ml-5 w-10 h-10 text-white"
          >
            <img src={backButton} className="w-5" alt="" srcset="" />
          </Link>
        </button>
        <button onClick={handleExport} className="flex   ">
          <Link className="bg-[#00adb5] flex justify-between items-center  hover:bg-[#059299] active:bg-[#047481] text-gray-100 text-xl font-bold text-center  py-3 px-5 rounded-lg ">
            <span className="mr-2">Download Now</span>

            <svg
              className="text-2xl "
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-download"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
            </svg>
          </Link>
        </button>
      </div>

      <div className="h-auto">
        <div className="flex flex-col h-auto">
          <div className="table w-full h-auto ">
            <div className="w-full h-auto rounded-lg shadow-xl shadow-slate-250">
              <div className="flex flex-col w-full rounded-lg shadow-md">
                <Table
                  columns={columns}
                  rows={rowsAccountDetails}
                  headerStyle={[
                    " text-center pl-0 pr-0 ",
                    "text-center pl-0 pr-0",
                    "text-left pl-2 pr-0",
                    "text-center pl-0 pr-0",
                    "text-center pl-0 pr-0",
                    "text-center pl-0 pr-0",
                    "text-center pl-0 pr-0",
                    "text-center pl-0 pr-0",
                    "text-center pl-0 pr-0",
                    "text-center pl-0 pr-0",

                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
