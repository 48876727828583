import react from "react";
import close from "../../../../assets/images/close.png";
import DownloadPdfButton from "./download-pdf.png";
import { Link } from "react-router-dom";
export default function AccountPopup({
  visible,
  onClose,
  handleDownloadPDF,
  data,
}) {
  if (!visible) return null;

  return (
    <div className="fixed z-50 inset-0 bg-[#222831] bg-opacity-40 backdrop-blur-sm flex justify-center items-center">
      <div className="bg-white relative px-10 py-5 rounded w-full  max-w-[1000px] mx-auto ">
        <div className="mb-4 flex  w-full items-center    gap-4 pr-3 justify-end">
          <button onClick={handleDownloadPDF} className="flex  ">
            <div className="bg-[#00adb5] flex justify-between items-center  hover:bg-[#059299] active:bg-[#047481] text-gray-100 text-xl font-bold text-center p-2 px-3 rounded-lg ">
              <span className="mr-2 text-sm">Download Now</span>

              <img
                src={DownloadPdfButton}
                alt="download pdf"
                className="w-6 h-6 mr-2"
              />
            </div>
          </button>
          <div className=" w-10">
            <img
              className="w-full cursor-pointer  "
              onClick={onClose}
              src={close}
              alt="close"
            />
          </div>
        </div>

        <br />

        <div
          className=" max-w-[1000px] max-h-[700px] overflow-y-auto"
          dangerouslySetInnerHTML={{ __html: data }}
        />
      </div>
    </div>
  );
}
