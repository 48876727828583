import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { IoMdArrowRoundBack } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import Select from "react-select";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import DriverDetailsAlloted from "./EditTripComponent/DriverDetailsAlloted";
import Swal from "sweetalert2";
import axios from "axios";
import Layout from "../../Layout/Layout";
import Hashids from "hashids";

export default function EditTrip() {
  const [RouteId, setRouteId] = useState("");
  const [RouteName, setRouteName] = useState("");
  const [TripDate, setTripDate] = useState("");
  const [ShiftTime, setShiftTime] = useState("");
  const [FromLocation, setFromLocation] = useState("");
  const [ToLocation, setToLocation] = useState("");
  const [PickupTime, setPickupTime] = useState("");
  const [TripType, setTripType] = useState("");
  const [VehicleType, setVehicleType] = useState("");
  const [NoOfPickup, setNoOfPickup] = useState("");
  const [CompanyId, setCompanyId] = useState("");
  const [TripAmount, setTripAmount] = useState("");
  const [CompanyList, setCompanyList] = useState([]);
  const [DriverList, setDriverList] = useState([]);
  const [PreEmployeesList, setPreEmployeesList] = useState([]);
  const [EmployeesList, setEmployeesList] = useState([]);
  const [SelectedEmployees, setSelectedEmployees] = useState([]);
  const [ViewTrip, setViewTrip] = useState(true);
  const [SelectedDriver, setSelectedDriver] = useState("");
  const [DriverName, setDriverName] = useState("");
  const [DriverMobileNumber, setDriverMobileNumber] = useState("");
  const [DriverVehicleType, setDriverVehicleType] = useState("");
  const [DriverVehicleNumber, setDriverVehicleNumber] = useState("");
  const [DriverId, setDriverId] = useState("");
  const [TripStatus, setTripStatus] = useState("");
  const [EmrTrip, setEmrTrip] = useState("");

  const [RouteIdError, setRouteIdError] = useState("");
  const [RouteNameError, setRouteNameError] = useState("");
  const [TripDateError, setTripDateError] = useState("");
  const [ShiftTimeError, setShiftTimeError] = useState("");
  const [FromLocationError, setFromLocationError] = useState("");
  const [ToLocationError, setToLocationError] = useState("");
  const [PickupTimeError, setPickupTimeError] = useState("");
  const [TripTypeError, setTripTypeError] = useState("");
  const [VehicleTypeError, setVehicleTypeError] = useState("");
  const [NoOfPickupError, setNoOfPickupError] = useState("");
  const [CompanyIdError, setCompanyIdError] = useState("");
  const [TripAmountError, setTripAmountError] = useState("");
  const [allocatedDriverError, setAllocatedDriverError] = useState("");

  // const hashids = new Hashids();
  const hashids = new Hashids("PrrDmOJKJtjmKZTmotTkPRNmxA2naEHKI4gnr58IVRj6ZWz3H7", 10);

  const [superAdminPlus, setSuperAdminPlus] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("userType") == "superAdminPlus") {
      setSuperAdminPlus(true);
    }
  }, []);

  const navigate = useNavigate();
  const { id } = useParams();

  const fetchData = async () => {
    const fetchEmployeesByCompanyId = async (CompanyId) => {
      try {
        const response = await axios.get(window.api + "employee/getEmployees", {
          headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            email: localStorage.getItem("email"),
            usertype: localStorage.getItem("userType"),
          },
          params: {
            companyId: CompanyId,
          },
        });
        if (response.status === 200) {
          const employees = response.data.data;
          if (employees) {
            const convertedEmployeeList = employees.map((employee) => ({
              value: employee.id,
              label: employee.employeeId,
            }));
            setEmployeesList(convertedEmployeeList);
          } else {
            setEmployeesList([]);
          }
        }
      } catch (e) {
        console.error("Error getting employees by company ID:", e);
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      }
    };

    if (id) {
      getCompany();
      getDriver();
      getTripById(id);
      if (SelectedEmployees) {
        const convertedEmployeeList = SelectedEmployees.map((employee) => ({
          value: employee.id,
          label: employee.employeeId,
        }));
        setPreEmployeesList(convertedEmployeeList);
      } else {
        setPreEmployeesList([]);
      }

      await fetchEmployeesByCompanyId(CompanyId);
    } else {
      setViewTrip(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, CompanyId]);

  const getTripById = async (searchId) => {
    try {
      const headers = {
        "x-access-token": localStorage.getItem("accessToken"),
        email: localStorage.getItem("email"),
        usertype: localStorage.getItem("userType"),
      };

      const response = await axios.get(window.api + "trip/getTripOne", {
        params: {
          id: searchId,
        },
        headers, // Include the headers in the request
      });

      if (response.status === 200) {
        const tripWithSelectedEmployees = {
          id: response.id,
          ...response.data.data,
          selectedEmployees: response.data.data.selectedEmployees,
        };

        setRouteId(tripWithSelectedEmployees.routeId);
        setRouteName(tripWithSelectedEmployees.routeName);
        setTripDate(tripWithSelectedEmployees.tripDate);
        setShiftTime(tripWithSelectedEmployees.shiftTime);
        setFromLocation(tripWithSelectedEmployees.fromLocation);
        setToLocation(tripWithSelectedEmployees.toLocation);
        setPickupTime(tripWithSelectedEmployees.pickupTime);
        setTripType(tripWithSelectedEmployees.tripType);
        setVehicleType(tripWithSelectedEmployees.vehicleType);
        setNoOfPickup(tripWithSelectedEmployees.noOfPickup);
        setCompanyId(tripWithSelectedEmployees.companyId);
        setTripAmount(tripWithSelectedEmployees.tripAmount);
        const sortedSelectedEmployees =
          tripWithSelectedEmployees.selectedEmployees.sort((a, b) =>
            a.pickupTime.localeCompare(b.pickupTime)
          );
        setSelectedEmployees(sortedSelectedEmployees);
        setTripStatus(tripWithSelectedEmployees.tripStatus);
        setEmrTrip(tripWithSelectedEmployees.emrTrip);
        const driverId =
          tripWithSelectedEmployees && tripWithSelectedEmployees.allocatedDriver
            ? tripWithSelectedEmployees.allocatedDriver
            : null;

        setSelectedDriver(driverId);
        handleDriverChange({ target: { value: driverId } });
      } else {
        console.log("Trip not found");
      }
    } catch (error) {
      console.error("Error getting trip:", error);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  const UpdateTrip = async (e) => {
    e.preventDefault();
    if (handleFormValidation()) {
      try {
        const data = {
          routeId: RouteId,
          companyId: CompanyId,
          tripAmount: TripAmount,
          routeName: RouteName,
          tripDate: TripDate,
          shiftTime: ShiftTime,
          fromLocation: FromLocation,
          toLocation: ToLocation,
          pickupTime: PickupTime,
          tripType: TripType,
          vehicleType: VehicleType,
          noOfPickup: NoOfPickup,
          selectedEmployees: SelectedEmployees,
        };

        const headers = {
          "x-access-token": localStorage.getItem("accessToken"),
          email: localStorage.getItem("email"),
          usertype: localStorage.getItem("userType"),
        };

        const response = await axios.patch(
          window.api + `trip/update/${id}`,
          data,
          { headers }
        );

        if (response.status === 200) {
          toast("Trip Updated Successfully!", {
            icon: "👏",
            style: {
              borderRadius: "7px",
              background: "#222831",
              color: "#fff",
            },
          });

          setTimeout(() => {
            navigate("/trip-list");
          }, 2000);
          fetchData();
          setViewTrip(true);
        }
      } catch (error) {
        console.error("Error updating trip: ", error);
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      }
    }
  };

  const getCompany = async () => {
    try {
      const response = await axios.get(window.api + "company/getCompanyList", {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          email: localStorage.getItem("email"),
          usertype: localStorage.getItem("userType"),
        },
      });

      if (response.status === 200) {
        const companyList = response.data.data;
        setCompanyList(companyList);
      }
    } catch (error) {
      console.error("Error getting company list:", error);

      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  let serialNumber = 1;

  const handleChangeEmployees = async (selectedValues) => {
    const selectedValuesArray = selectedValues.map((option) => option.value);

    if (selectedValuesArray.length === 0) {
      setNoOfPickup(0);
      setSelectedEmployees([]);
      setPreEmployeesList([]);
      return;
    }

    const getSelectedEmployees = async () => {
      try {
        const response = await axios.get(window.api + "employee/getEmployees", {
          headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            email: localStorage.getItem("email"),
            usertype: localStorage.getItem("userType"),
          },
          params: {
            selectedValuesArray: selectedValuesArray,
          },
        });

        if (response.status === 200) {
          const employees = response.data.data;

          const selectedEmployees = employees.map((employee) => {
            const existingEmployee = SelectedEmployees.find(
              (prevEmployee) => prevEmployee.id === employee.id
            );
            if (existingEmployee) {
              return { ...existingEmployee };
            } else {
              return {
                ...employee,
                pickupLocation: "",
                pickupTime: "",
                dropLocation: "",
              };
            }
          });

          if (selectedEmployees) {
            const convertedEmployeeList = selectedEmployees.map((employee) => ({
              value: employee.id,
              label: employee.employeeId,
            }));
            setPreEmployeesList(convertedEmployeeList);
          } else {
            setPreEmployeesList([]);
          }

          setNoOfPickup(selectedEmployees.length);
          setSelectedEmployees(selectedEmployees);
        }
      } catch (e) {
        console.error("Error getting selected employees:", e);
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      }
    };

    getSelectedEmployees();
  };

  const handleInputChange = (e, index, field) => {
    const { value } = e.target;
    setSelectedEmployees((prevEmployees) => {
      const updatedEmployees = [...prevEmployees];
      const updatedEmployee = { ...updatedEmployees[index], [field]: value };
      updatedEmployees[index] = updatedEmployee;
      return updatedEmployees;
    });
  };

  const handleFormValidation = () => {
    let formIsValid = true;
    if (EmrTrip === false) {
      if (!RouteId) {
        formIsValid = false;
        setRouteIdError("Please enter route id");
      } else {
        setRouteIdError("");
      }
      if (!RouteName) {
        formIsValid = false;
        setRouteNameError("Please enter route name");
      } else {
        setRouteNameError("");
      }
      if (!TripDate) {
        formIsValid = false;
        setTripDateError("Please enter trip date");
      } else {
        setTripDateError("");
      }
      if (!ShiftTime) {
        formIsValid = false;
        setShiftTimeError("Please enter shift time");
      } else {
        setShiftTimeError("");
      }
      if (!FromLocation) {
        formIsValid = false;
        setFromLocationError("Please enter from location");
      } else {
        setFromLocationError("");
      }
      if (!ToLocation) {
        formIsValid = false;
        setToLocationError("Please enter to location");
      } else {
        setToLocationError("");
      }
      if (!PickupTime) {
        formIsValid = false;
        setPickupTimeError("Please enter pickup time");
      } else {
        setPickupTimeError("");
      }
      if (!TripType) {
        formIsValid = false;
        setTripTypeError("Please select trip type");
      } else {
        setTripTypeError("");
      }
      if (!VehicleType) {
        formIsValid = false;
        setVehicleTypeError("Please select vehicle type");
      } else {
        setVehicleTypeError("");
      }
      if (!NoOfPickup) {
        formIsValid = false;
        setNoOfPickupError("Please select atlest one pickup");
      } else {
        setNoOfPickupError("");
      }
      if (!CompanyId) {
        formIsValid = false;
        setCompanyIdError("Please select company");
      } else {
        setCompanyIdError("");
      }
      if (!TripAmount) {
        formIsValid = false;
        setTripAmountError("Please enter trip amount");
      } else {
        setTripAmountError("");
      }
    } else {
      if (!TripType) {
        formIsValid = false;
        setTripTypeError("Please select trip type");
      } else {
        setTripTypeError("");
      }
      if (!VehicleType) {
        formIsValid = false;
        setVehicleTypeError("Please select vehicle type");
      } else {
        setVehicleTypeError("");
      }
      if (!FromLocation) {
        formIsValid = false;
        setFromLocationError("Please enter from location");
      } else {
        setFromLocationError("");
      }
      if (!ToLocation) {
        formIsValid = false;
        setToLocationError("Please enter to location");
      } else {
        setToLocationError("");
      }
      if (!TripDate) {
        formIsValid = false;
        setTripDateError("Please enter trip date");
      } else {
        setTripDateError("");
      }
    }

    return formIsValid;
  };

  const handleAllocatedValidation = () => {
    let formIsValid = true;
    if (!SelectedDriver) {
      formIsValid = false;
      setAllocatedDriverError("Please select driver");
    } else {
      setAllocatedDriverError("");
    }
    return formIsValid;
  };

  const handleEdit = () => {
    setViewTrip(false);
  };

  const getDriver = async () => {
    try {
      const response = await axios.get(window.api + "driver/getDrivers", {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          email: localStorage.getItem("email"),
          usertype: localStorage.getItem("userType"),
        },
      });

      if (response.status === 200) {
        const drivers = response.data.data;
        setDriverList(drivers);
      }
    } catch (e) {
      console.error("Error getting drivers:", e);

      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  const handleDriverChange = async (event) => {
    setSelectedDriver(event.target.value);

    try {
      const response = await axios.get(window.api + "driver/getDriverOne", {
        params: {
          id: event.target.value,
        },
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          email: localStorage.getItem("email"),
          usertype: localStorage.getItem("userType"),
        },
      });

      if (response.status === 200) {
        const driverData = response.data.data;
        setDriverName(driverData.driverName);
        setDriverMobileNumber(driverData.mobileNo);
        setDriverVehicleType(driverData.vehicleType);
        setDriverVehicleNumber(driverData.vehicleNo);
        setDriverId(driverData.driverID);
      }
    } catch (e) {
      console.error("Error fetching driver data:", e);

      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }

      setDriverName("");
      setDriverMobileNumber("");
      setDriverVehicleType("");
      setDriverVehicleNumber("");
      setDriverId("");
    }
  };

  async function handleAllocateDriver() {
    if (handleAllocatedValidation()) {
      setViewTrip(true);
      try {
        const data = {
          allocatedDriver: SelectedDriver,
          tripStatus: "Alloted",
          tripDate: TripDate,
        };

        const headers = {
          "x-access-token": localStorage.getItem("accessToken"),
          email: localStorage.getItem("email"),
          usertype: localStorage.getItem("userType"),
        };

        const response = await axios.patch(
          window.api + `trip/update/${id}`,
          data,
          { headers }
        );

        if (response.status === 200) {
          console.log("Driver allocated successfully");

          setTripStatus("Alloted");

          const sortedSelectedEmployees = SelectedEmployees.sort((a, b) =>
            a.pickupTime.localeCompare(b.pickupTime)
          );

          // Create an array of promises for sending messages
          const employeePromises = sortedSelectedEmployees.map(
            async (employee) => {
              let campaignName = "";
              if (CompanyId == 1 || CompanyId == 3) {
                campaignName = "123_employestripdetails_1_3_new_join_group";
                //                 message = `Dear ${employee.employeeName},

                // Greetings from *Chennai Cabs* ! 🌞🌟

                // 🚖 *Trip Details*:

                // Route ID: *${RouteId}*
                // Route Name: *${RouteName}*
                // Trip Type: *${TripType}*
                // Trip Date: *${TripDate}*

                // 🚗 *Driver Information*:

                // Driver ID: ${DriverId}
                // Driver Name: ${DriverName}
                // Driver Contact: ${DriverMobileNumber}
                // Vehicle Number: *${DriverVehicleNumber}*

                // If you have any queries or require assistance during your trip, please feel free to contact our Chennai Cabs helpline:

                // 📞 *Chennai Cabs Help Line*:
                // 1️⃣ Sugumar - 8825981764
                // 2️⃣ Purushothaman  - 9551114411

                // Have a great trip! ✨🚖

                // Best regards,
                // *Chennai Cabs*`;
              }

              if (CompanyId != 1 && CompanyId != 3) {
                campaignName = "123_employestripdetailsnot12company_new_join_group";

                //                 message = `Dear ${employee.employeeName},

                // Greetings from *Chennai Cabs* ! 🌞🌟

                // 🚖 *Trip Details*:

                // Route ID: *${RouteId}*
                // Route Name: *${RouteName}*
                // Trip Type: *${TripType}*
                // Trip Date: *${TripDate}*

                // 🚗 *Driver Information*:

                // Driver ID: ${DriverId}
                // Driver Name: ${DriverName}
                // Driver Contact: ${DriverMobileNumber}
                // Vehicle Number: *${DriverVehicleNumber}*

                // If you have any queries or require assistance during your trip, please feel free to contact our Chennai Cabs helpline:

                // 📞 *Chennai Cabs Help Line*:
                // 1️⃣ Prakash - 9500047822
                // 2️⃣ Saran Kumar - 7358349442

                // Have a great trip! ✨🚖

                // Best regards,
                // *Chennai Cabs*`;
              }

              try {
                const response = await axios.post(
                  "https://backend.aisensy.com/campaign/t1/api/v2",
                  {
                    apiKey:
                      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1NmMyZWViZTIyNWZhNDE5ZTQ0ZWM0MSIsIm5hbWUiOiJDaGVubmFpIENiYXMiLCJhcHBOYW1lIjoiQWlTZW5zeSIsImNsaWVudElkIjoiNjU2YzJlZWFlMjI1ZmE0MTllNDRlYzNhIiwiYWN0aXZlUGxhbiI6IkJBU0lDX01PTlRITFkiLCJpYXQiOjE3MDE1ODg3MTV9.tenhwxFtZaO17Epki82dEqYjMjyMeeHWF_mLsravAqA",
                    campaignName: campaignName,
                    destination: `+91${employee.mobileNo}`,
                    userName: "mukundpasi.developer@gmail.com",
                    templateParams: [
                      `${employee.employeeName}`,
                      `${RouteId}`,
                      `${RouteName}`,
                      `${TripType}`,
                      `${TripDate}`,
                      `${DriverId}`,
                      `${DriverName}`,
                      `${DriverMobileNumber}`,
                      `${DriverVehicleNumber}`,
                    ],
                  }
                );
                console.log("SMS sent successfully:", response.data);
              } catch (error) {
                console.log("Error sending SMS: ", error);
              }

              // try {
              //   await axios.get(
              //     `https://api.textmebot.com/send.php?recipient=+91${
              //       employee.mobileNo
              //     }&apikey=Hwd2BzkcxSY4&text=${encodeURIComponent(message)}`
              //   );
              //   console.log(
              //     "Message sent successfully for employee:",
              //     employee.employeeId
              //   );
              // } catch (error) {
              //   console.log(
              //     "Error sending message for employee:",
              //     employee.employeeId
              //   );
              //   console.log("Error:", error);
              // }
            }
          );

          const parts = TripDate.split("-");
          const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

          // // Send message to the driver
          // let driverMessage = "*Chennai Cabs - Employee Details:*\n";

          // driverMessage += `\n*${RouteId} - ${RouteName} - ${TripType}*\n\n`;
          // driverMessage += `*Trip date:* ${formattedDate}\n\n`;
          // driverMessage += `*No. of Employees:* ${NoOfPickup}\n\n`;

          // for (const employee of SelectedEmployees) {
          //   driverMessage += `*Employee Id:* ${employee.employeeId}\n`;
          //   driverMessage += `*Employee Name:* ${employee.employeeName}\n`;
          //   driverMessage += `*Mobile No:* ${employee.mobileNo}\n`;
          //   driverMessage += `*Pickup Location:* *${employee.pickupLocation}*\n`;
          //   driverMessage += `*Pickup Time:* ${employee.pickupTime}\n\n`;
          // }

          // // Remove trailing newline
          // driverMessage = driverMessage.trimEnd();

          // // Add the additional line
          // driverMessage += "\n\n*CHENNAI CABS HELP LINE*\n";
          // driverMessage += "1) Prakash - 9500047822\n";
          // driverMessage += "2) Saran Kumar - 7358349442";
          const tripIdEncode = hashids.encode(id);
          try {
            const response = await axios.post(
              "https://backend.aisensy.com/campaign/t1/api/v2",
              {
                apiKey:
                  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1NmMyZWViZTIyNWZhNDE5ZTQ0ZWM0MSIsIm5hbWUiOiJDaGVubmFpIENiYXMiLCJhcHBOYW1lIjoiQWlTZW5zeSIsImNsaWVudElkIjoiNjU2YzJlZWFlMjI1ZmE0MTllNDRlYzNhIiwiYWN0aXZlUGxhbiI6IkJBU0lDX01PTlRITFkiLCJpYXQiOjE3MDE1ODg3MTV9.tenhwxFtZaO17Epki82dEqYjMjyMeeHWF_mLsravAqA",
                campaignName: "2_link_employee_details_join_group",
                destination: `+91${DriverMobileNumber}`,
                userName: "mukundpasi.developer@gmail.com",
                templateParams: [
                  `${RouteId}`,
                  `${RouteName}`,
                  `${TripType}`,
                  `${formattedDate}`,
                  `${NoOfPickup}`,
                  `https://dashboard.chennaicabs.in/employeedetails/${tripIdEncode}`,
                ],
              }
            );
          } catch (error) {
            console.log("Error sending SMS: ", error);
          }

          function getCompanyNameById(id) {
            const company = CompanyList.find(
              (company) => company.id === id
            );
            return company ? company.companyName : null;
          }

          const companyName = getCompanyNameById(CompanyId);

          try {
            const response = await axios.post(
              "https://backend.aisensy.com/campaign/t1/api/v2",
              {
                apiKey:
                  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1NmMyZWViZTIyNWZhNDE5ZTQ0ZWM0MSIsIm5hbWUiOiJDaGVubmFpIENiYXMiLCJhcHBOYW1lIjoiQWlTZW5zeSIsImNsaWVudElkIjoiNjU2YzJlZWFlMjI1ZmE0MTllNDRlYzNhIiwiYWN0aXZlUGxhbiI6IkJBU0lDX01PTlRITFkiLCJpYXQiOjE3MDE1ODg3MTV9.tenhwxFtZaO17Epki82dEqYjMjyMeeHWF_mLsravAqA",
                campaignName: "0410_supervisor_message1",
                destination: `+918220738327`,
                userName: "mukundpasi.developer@gmail.com",
                templateParams: [
                  `${formattedDate}`,
                  `${companyName}`,
                  `${RouteId}`,
                  `${RouteName}`,
                  `${TripType}`,
                  `${DriverId}`,
                  `${DriverName}`,
                  `${DriverMobileNumber}`,
                  `${DriverVehicleNumber}`,
                ],
              }
            );
            console.log("SMS sent successfully:", response.data);
          } catch (error) {
            console.log("Error sending SMS: ", error);
          }

          // try {
          //   await axios.get(
          //     `https://api.textmebot.com/send.php?recipient=+91${DriverMobileNumber}&apikey=Hwd2BzkcxSY4&text=${encodeURIComponent(
          //       driverMessage
          //     )}`
          //   );
          //   console.log("Message sent successfully to the driver.");
          // } catch (error) {
          //   console.log("Error sending message to the driver.");
          //   console.log("Error:", error);
          // }

          //           const supervisorMessage = `Chennai Cabs - Driver Details - [ ${formattedDate} ]

          // ${RouteId} - ${RouteName} - ${TripType}

          // Driver ID: ${DriverId}
          // Driver Name: ${DriverName}
          // Driver Contact: ${DriverMobileNumber}
          // Vehicle Number: ${DriverVehicleNumber}`;

          //           if (CompanyId == 3) {
          //             const supervisorNumbers = ["8825981764", "9551114411"];

          //             const sendMessages = async () => {
          //               await new Promise((resolve) => setTimeout(resolve, 5000)); // Delay for 5 seconds before the loop starts

          //               for (const number of supervisorNumbers) {
          //                 try {
          //                   await axios.get(
          //                     `https://api.textmebot.com/send.php?recipient=+91${number}&apikey=Hwd2BzkcxSY4&text=${encodeURIComponent(
          //                       supervisorMessage
          //                     )}`
          //                   );
          //                   console.log(
          //                     `Message sent successfully to supervisor ${number}.`
          //                   );
          //                 } catch (error) {
          //                   console.log(`Error sending message to supervisor ${number}.`);
          //                   console.log("Error:", error);
          //                 }
          //                 await new Promise((resolve) => setTimeout(resolve, 5000)); // Pause execution for 5 second between iterations
          //               }
          //               console.log("All messages sent successfully.");
          //             };

          //             sendMessages().catch((error) => {
          //               console.log("Error sending messages to supervisors.");
          //               console.log("Error:", error);
          //             });
          //           } else {
          //             console.log(
          //               "CompanyId is not Lapiz. Message not sent to supervisors."
          //             );
          //           }

          //           if (CompanyId == 2) {
          //             const supervisorNumbers = ["9500166654", "7358349442"];

          //             const sendMessages = async () => {
          //               await new Promise((resolve) => setTimeout(resolve, 5000)); // Delay for 5 seconds before the loop starts

          //               for (const number of supervisorNumbers) {
          //                 try {
          //                   await axios.get(
          //                     `https://api.textmebot.com/send.php?recipient=+91${number}&apikey=Hwd2BzkcxSY4&text=${encodeURIComponent(
          //                       supervisorMessage
          //                     )}`
          //                   );
          //                   console.log(
          //                     `Message sent successfully to supervisor ${number}.`
          //                   );
          //                 } catch (error) {
          //                   console.log(`Error sending message to supervisor ${number}.`);
          //                   console.log("Error:", error);
          //                 }
          //                 await new Promise((resolve) => setTimeout(resolve, 5000)); // Pause execution for 5 second between iterations
          //               }
          //               console.log("All messages sent successfully.");
          //             };

          //             sendMessages().catch((error) => {
          //               console.log("Error sending messages to supervisors.");
          //               console.log("Error:", error);
          //             });
          //           } else {
          //             console.log(
          //               "CompanyId is not R1. Message not sent to supervisors."
          //             );
          //           }

          //           if (CompanyId == 1) {
          //             const supervisorNumbers = ["8825981764", "9551114411"];

          //             const sendMessages = async () => {
          //               await new Promise((resolve) => setTimeout(resolve, 5000)); // Delay for 5 seconds before the loop starts

          //               for (const number of supervisorNumbers) {
          //                 try {
          //                   await axios.get(
          //                     `https://api.textmebot.com/send.php?recipient=+91${number}&apikey=Hwd2BzkcxSY4&text=${encodeURIComponent(
          //                       supervisorMessage
          //                     )}`
          //                   );
          //                   console.log(
          //                     `Message sent successfully to supervisor ${number}.`
          //                   );
          //                 } catch (error) {
          //                   console.log(`Error sending message to supervisor ${number}.`);
          //                   console.log("Error:", error);
          //                 }
          //                 await new Promise((resolve) => setTimeout(resolve, 5000)); // Pause execution for 5 second between iterations
          //               }
          //               console.log("All messages sent successfully.");
          //             };

          //             sendMessages().catch((error) => {
          //               console.log("Error sending messages to supervisors.");
          //               console.log("Error:", error);
          //             });
          //           } else {
          //             console.log(
          //               "CompanyId is not Medico. Message not sent to supervisors."
          //             );
          //           }

          // Wait for all messages to be sent
          await Promise.all(employeePromises);

          console.log("All messages sent successfully");

          toast("Driver Allocated Successfully", {
            icon: "🚗",
            style: {
              borderRadius: "5px",
              background: "#1a1a1a",
              color: "#fff",
              fontFamily: "Arial, sans-serif",
              fontSize: "16px",
              padding: "12px",
              boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
            },
          });
        }
      } catch (error) {
        console.error("Error allocating driver: ", error);
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      }
    }
  }

  const handleTripStatusChange = async (e) => {
    if (handleAllocatedValidation()) {
      setTripStatus("Alloted");
      setViewTrip(true);

      try {
        const data = {
          allocatedDriver: SelectedDriver,
          tripStatus: "Alloted",
          tripDate: TripDate,
        };

        const headers = {
          "x-access-token": localStorage.getItem("accessToken"),
          email: localStorage.getItem("email"),
          usertype: localStorage.getItem("userType"),
        };

        const response = await axios.patch(
          window.api + `trip/update/${id}`,
          data,
          { headers }
        );
        if (response.status === 200) {
          console.log("Driver allocated successfully");

          toast("Driver Allocated Successfully", {
            icon: "🚗",
            style: {
              borderRadius: "5px",
              background: "#1a1a1a",
              color: "#fff",
              fontFamily: "Arial, sans-serif",
              fontSize: "16px",
              padding: "12px",
              boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
            },
          });
        }
      } catch (error) {
        console.error("Error allocating driver: ", error);
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      }
    }
  };

  const handleDeleteTrip = async () => {
    const result = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You are about to delete this driver. This action cannot be undone!",
      confirmButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        const headers = {
          "x-access-token": localStorage.getItem("accessToken"),
          email: localStorage.getItem("email"),
          usertype: localStorage.getItem("userType"),
        };

        await axios.put(window.api + `trip/delete/${id}`, null, {
          headers, // Include the headers in the request
        });
        navigate("/trip-list");
      } catch (error) {
        console.error("Error deleting driver:", error);
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      }
    }
  };

  return (
    <Layout>
      <div>
        <Toaster />
        <div className="flex flex-col gap-2">
          <div className="flex flex-row items-center sm:mx-0">
            <div className="flex flex-row gap-4 mt-3 ml-5">
              <div className=" bg-[#ffffff] cursor-pointer  shadow-lgrounded-xl">
                <Link
                  to={"/trip-list"}
                  className="flex items-center justify-center w-10 h-10 text-black"
                >
                  <IoMdArrowRoundBack className="w-6 h-6 text-black" />
                </Link>
              </div>
              {!superAdminPlus ? null : (
                <div
                  className="p-2 bg-white rounded-lg shadow-md cursor-pointer drop-shadow-md hover:bg-gray-200 active:bg-gray-400"
                  onClick={handleDeleteTrip}
                >
                  <RiDeleteBin6Line className="text-[#ff0000] w-6 h-6" />
                </div>
              )}
              {!ViewTrip || TripStatus === "Completed" ? null : (
                <Link to="#" onClick={handleEdit}>
                  <div className="p-2 bg-white rounded-lg shadow-md cursor-pointer drop-shadow-md hover:bg-gray-200 active:bg-gray-400">
                    <FiEdit className="text-[#38e54d] w-6 h-6 " />
                  </div>
                </Link>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-4 m-4">
            <div className="flex flex-col gap-1 md:flex-row md:gap-1">
              <div className="container mx-auto ">
                <div className="max-w-screen-md px-8 py-6 mx-auto bg-white border-2 rounded-lg shadow-lg">
                  <div className="flex flex-col mb-6 md:flex-row md:-mx-3">
                    <div className="w-full mb-6 md:w-1/2 md:px-3 md:mb-0">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-first-name"
                      >
                        Route ID
                      </label>
                      <input
                        onChange={(e) => setRouteId(e.target.value)}
                        placeholder="Route ID"
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        type="text"
                        value={RouteId}
                        disabled={ViewTrip}
                      />
                      {RouteIdError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {RouteIdError}
                        </p>
                      )}
                    </div>
                    <div className="w-full mb-6 md:w-1/2 md:px-3 md:mb-0">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-last-name"
                      >
                        Route Name
                      </label>
                      <input
                        onChange={(e) => setRouteName(e.target.value)}
                        placeholder="Route Name"
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        type="text"
                        value={RouteName}
                        disabled={ViewTrip}
                      />
                      {RouteNameError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {RouteNameError}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col mb-6 md:flex-row md:-mx-3">
                    <div className="w-full mb-6 md:w-1/2 md:px-3 md:mb-0">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-first-name"
                      >
                        Company
                      </label>
                      <select
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        // onChange={handleCompanyChange}
                        value={CompanyId}
                        id="company-name"
                        disabled
                      >
                        <option value="">--Select Company-- </option>
                        {CompanyList &&
                          CompanyList.map((company) => (
                            <option value={company.id} key={company.id}>
                              {company.companyName}
                            </option>
                          ))}
                      </select>
                      {CompanyIdError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {CompanyIdError}
                        </p>
                      )}
                    </div>
                    <div className="w-full mb-6 md:w-1/2 md:px-3 md:mb-0">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-last-name"
                      >
                        Trip Amount
                      </label>
                      <input
                        onChange={(e) => setTripAmount(e.target.value)}
                        placeholder="Trip Amount"
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        type="text"
                        value={TripAmount}
                        disabled={ViewTrip}
                      />
                      {TripAmountError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {TripAmountError}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col mb-6 md:flex-row md:-mx-3">
                    <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0 ">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-first-name"
                      >
                        Trip Date
                      </label>
                      <input
                        onChange={(e) => setTripDate(e.target.value)}
                        placeholder="Date"
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        type="date"
                        value={TripDate}
                        disabled={ViewTrip}
                      />
                      {TripDateError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {TripDateError}
                        </p>
                      )}
                    </div>
                    <div className="w-full px-3 md:w-1/2">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-last-name"
                      >
                        From Location
                      </label>
                      <input
                        onChange={(e) => setFromLocation(e.target.value)}
                        placeholder="From Location"
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        type="text"
                        value={FromLocation}
                        disabled={ViewTrip}
                      />
                      {FromLocationError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {FromLocationError}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col mb-6 md:flex-row md:-mx-3">
                    <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0 ">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-first-name"
                      >
                        Pickup Time
                      </label>
                      <input
                        onChange={(e) => setPickupTime(e.target.value)}
                        placeholder="Pickup Time"
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        type="time"
                        value={PickupTime}
                        disabled={ViewTrip}
                      />
                      {PickupTimeError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {PickupTimeError}
                        </p>
                      )}
                    </div>
                    <div className="w-full px-3 md:w-1/2">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-last-name"
                      >
                        To Location
                      </label>
                      <input
                        onChange={(e) => setToLocation(e.target.value)}
                        placeholder="To Location"
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        type="text"
                        value={ToLocation}
                        disabled={ViewTrip}
                      />
                      {ToLocationError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {ToLocationError}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col mb-6 md:flex-row md:-mx-3">
                    <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0 ">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-first-name"
                      >
                        Shift Time
                      </label>
                      <input
                        onChange={(e) => setShiftTime(e.target.value)}
                        placeholder="Shift Time"
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        type="time"
                        value={ShiftTime}
                        disabled={ViewTrip}
                      />
                      {ShiftTimeError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {ShiftTimeError}
                        </p>
                      )}
                    </div>
                    <div className="w-full px-3 md:w-1/2">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-last-name"
                      >
                        No Of Pickups
                      </label>
                      <input
                        placeholder="No of Pickups"
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        type="text"
                        value={NoOfPickup}
                        disabled
                      />
                      {NoOfPickupError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {NoOfPickupError}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col mb-6 md:flex-row md:-mx-3">
                    <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0 ">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-first-name"
                      >
                        Trip Type
                      </label>
                      <select
                        onChange={(e) => setTripType(e.target.value)}
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        id="grid-state"
                        value={TripType}
                        disabled={ViewTrip}
                      >
                        <option value="">--- Select Trip Type ---</option>
                        <option value="Drop">Drop</option>
                        <option value="Pickup">Pickup</option>
                      </select>
                      {TripTypeError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {TripTypeError}
                        </p>
                      )}
                    </div>
                    <div className="w-full px-3 md:w-1/2">
                      <label
                        className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-last-name"
                      >
                        Vehicle Type
                      </label>
                      <select
                        onChange={(e) => setVehicleType(e.target.value)}
                        className="w-[229px] bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                        id="vehicle-type"
                        value={VehicleType}
                        disabled={ViewTrip}
                      >
                        <option value="">--- Select Vehicle Type ---</option>
                        <option value="SUV">SUV</option>
                        <option value="Sedan">Sedan</option>
                        <option value="Tempo">Tempo</option>
                        <option value="Coach">Coach</option>
                      </select>
                      {VehicleTypeError && (
                        <p className="mt-1 text-sm text-red-500 ">
                          {VehicleTypeError}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col mb-6 md:flex-row md:-mx-3">
                    <div className="w-full px-3 md:w-1/2">
                      <label
                        class="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                        for="grid-state"
                      >
                        Add Employees
                      </label>
                      <Select
                        className="w-full"
                        isMulti
                        value={PreEmployeesList}
                        onChange={handleChangeEmployees}
                        options={EmployeesList}
                        placeholder="---Select Employees---"
                        isDisabled={ViewTrip}
                      />
                    </div>
                  </div>
                  {ViewTrip ? null : (
                    <button
                      onClick={UpdateTrip}
                      className="bg-[#00adb5] rounded-lg py-3 px-2 text-white w-full hover:bg-[#00b5ad] active:bg-[#008080]"
                      type="submit"
                    >
                      Update Trip
                    </button>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2 ml-4 ">
                <div className="w-full md:w-[550px] h-auto border-2 bg-white rounded-lg mx-auto">
                  <div className="container h-auto p-8">
                    <div className="flex flex-col mb-6 -mx-2 md:flex-row">
                      <div className="w-full px-2 mb-6 md:w-1/2 md:mb-0">
                        <label
                          className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                          htmlFor="grid-first-name"
                        >
                          Driver ID
                        </label>
                        <select
                          placeholder="Driver ID"
                          className="appearance-none block w-full md:w-[229px] focus:outline-none focus:bg-white focus:border-gray-500 bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                          value={SelectedDriver}
                          onChange={handleDriverChange}
                          disabled={ViewTrip}
                        >
                          <option value="">--Select Driver--</option>
                          {DriverList &&
                            DriverList.map((driver) => (
                              <option value={driver.id} key={driver.id}>
                                {driver.driverID}
                              </option>
                            ))}
                        </select>
                        {allocatedDriverError && (
                          <p className="mt-1 text-sm text-red-500 ">
                            {allocatedDriverError}
                          </p>
                        )}
                      </div>
                      <div className="w-full px-2 mb-6 md:w-1/2 md:mb-0 ">
                        <label
                          className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                          for="grid-first-name"
                        >
                          Driver Name
                        </label>
                        <input
                          placeholder="Driver Name"
                          type="text"
                          className="appearance-none block w-full md:w-[229px] focus:outline-none focus:bg-white focus:border-gray-500 bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                          value={DriverName}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="flex flex-col mb-6 -mx-2 md:flex-row ">
                      <div className="w-full px-2 md:w-1/2">
                        <label
                          className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                          for="grid-last-name"
                        >
                          Mobile Number
                        </label>
                        <input
                          placeholder="Mobile Number"
                          type="text"
                          className="appearance-none block w-full md:w-[229px] focus:outline-none focus:bg-white focus:border-gray-500 bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                          value={DriverMobileNumber}
                          disabled
                        />
                      </div>
                      <div className="w-full px-2 mb-6 md:w-1/2 md:mb-0 ">
                        <label
                          className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                          for="grid-first-name"
                        >
                          Vehicle Type
                        </label>
                        <input
                          placeholder="Vehicle Type"
                          type="text"
                          className="appearance-none block w-full md:w-[229px] focus:outline-none focus:bg-white focus:border-gray-500 bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                          value={DriverVehicleType}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="flex flex-col mb-6 -mx-2 md:flex-row ">
                      <div className="w-full px-3 md:w-1/2">
                        <label
                          className="block text-base font-roboto font-semibold text-[#393E46] mb-1 tracking-wide"
                          for="grid-last-name"
                        >
                          Vehicle Number
                        </label>
                        <input
                          placeholder="Vehicle Number"
                          type="text"
                          className="appearance-none block w-full md:w-[229px] focus:outline-none focus:bg-white focus:border-gray-500 bg-white font-roboto font-medium text-base text-[#393E46] border-2 border-gray-200 rounded-md hover:border-[#393E46] py-3 px-4 leading-tight hover:outline-none hover:bg-white"
                          value={DriverVehicleNumber}
                          disabled
                        />
                      </div>
                    </div>

                    {/* <AllocateDriver
                      DriverName={DriverName}
                      DriverMobileNumber={DriverMobileNumber}
                      DriverVehicleType={DriverVehicleType}
                      DriverVehicleNumber={DriverVehicleNumber}
                    /> */}
                    <div className="flex w-full ">
                      <div className="flex items-end w-full px-2 md:w-1/2 justify-evenly">
                        {ViewTrip === true ? null : (
                          <>
                            <button
                              type="submit"
                              className="bg-[#00adb5] text-white rounded py-3 px-8 text-sm font-bold md:text-base focus:border-[#4dd9e0] hover:bg-[#008c8f] active:bg-[#006d6f]"
                              onClick={handleAllocateDriver}
                            >
                              Allocate
                            </button>
                          </>
                        )}
                      </div>
                      <div className="flex items-end w-full px-2 md:w-1/2 justify-evenly">
                        {ViewTrip === true ? null : (
                          <>
                            <button
                              type="submit"
                              className="bg-red-400 text-white rounded py-3 px-8 text-sm font-bold md:text-base focus:border-[#4dd9e0] hover:bg-red-500 active:bg-red-500"
                              onClick={handleTripStatusChange}
                            >
                              Force Allocate
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row gap-6">
                  <div className="w-full md:w-[154px]  md:h-[167px] lg:w-full border bg-white flex flex-col shadow-lg rounded-lg p-4 gap-5 ">
                    <div className="flex items-center justify-center w-full h-full gap-8">
                      <div className="text-lg font-sf_bold">Trip Status</div>
                      <div
                        className={
                          TripStatus === "Alloted"
                            ? "bg-[#ff8103] w-12 h-6 rounded "
                            : TripStatus === "Not Alloted"
                            ? "bg-[#31007a] w-12 h-6 rounded "
                            : TripStatus === "Ongoing"
                            ? "bg-[#e8d100] w-12 h-6 rounded "
                            : "bg-[#2daa00] w-12 h-6 rounded "
                        }
                      />
                      <div>
                        <select
                          value={TripStatus}
                          className="bg-gray-300 text-black p-2 rounded-lg border-2 border-[#222831] appearance-none hover:bg-[#222831] hover:text-white cursor-pointer text-center"
                          disabled={ViewTrip}
                        >
                          <option value="Alloted">Alloted</option>
                          <option value="Not Alloted">Not Alloted</option>
                          <option value="Ongoing">Ongoing</option>
                          <option value="Completed">Completed</option>
                        </select>
                      </div>
                    </div>

                    {/* <FetchTripStatus TripStatus={TripStatus} setTripStatus={setTripStatus} /> */}
                  </div>
                </div>

                <div className="flex">
                  <DriverDetailsAlloted
                    DriverName={DriverName}
                    DriverVehicleType={DriverVehicleType}
                    DriverVehicleNumber={DriverVehicleNumber}
                    DriverId={DriverId}
                  />
                </div>
              </div>
            </div>
            <div className="relative w-full h-full mt-4 overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full mt-3 ">
                <thead className="text-xs text-white uppercase  w-full  border-[1px] border-[#3C4048] bg-[#3C4048] ">
                  <tr>
                    <th
                      className={` py-4 font-[600]   tracking-wider  text-center `}
                    >
                      Sl.No
                    </th>
                    <th
                      className={` py-4 font-[600]   tracking-wider  text-center `}
                    >
                      Employee ID
                    </th>
                    <th
                      className={` py-4 font-[600]   tracking-wider  text-center `}
                    >
                      Employee Name
                    </th>
                    <th
                      className={` py-4 font-[600]   tracking-wider  text-center `}
                    >
                      Gender
                    </th>
                    <th
                      className={` py-4 font-[600]   tracking-wider  text-center `}
                    >
                      Contact Number
                    </th>
                    <th
                      className={` py-4 font-[600]   tracking-wider  text-center `}
                    >
                      Pickup Location
                    </th>
                    <th
                      className={` py-4 font-[600]   tracking-wider  text-center `}
                    >
                      Pickup Time
                    </th>
                    <th
                      className={` py-4 font-[600]   tracking-wider  text-center `}
                    >
                      Drop Location
                    </th>
                  </tr>
                </thead>

                {SelectedEmployees.length > 0 && (
                  <tbody className="table-row-group p-4 text-lg uppercase divide-x-0 rounded-md">
                    {SelectedEmployees.map((row, index) => {
                      return (
                        <tr
                          className="justify-center table-row text-center align-middle rounded-lg shadow-lg outline-none drop-shadow-2xl"
                          tabIndex={-1}
                          // key={}
                        >
                          <td className="  text-[#393e46]  text-left  font-medium text-sm font-roboto">
                            <span className="text-[#393e46]">
                              {serialNumber++}
                            </span>
                          </td>
                          <td className="  text-[#393e46]  text-left  font-medium text-sm font-roboto">
                            <span className="text-[#393e46]">
                              {row.employeeId}
                            </span>
                          </td>
                          <td className=" py-3 text-[#393e46]  text-left  font-medium text-sm font-roboto">
                            <span className="text-[#393e46]">
                              {row.employeeName}
                            </span>
                          </td>
                          <td className=" py-3 text-[#393e46]  text-center  font-medium text-sm font-roboto">
                            <span className="text-[#393e46]">{row.gender}</span>
                          </td>
                          <td className=" py-3 text-[#393e46]  text-center  font-medium text-sm font-roboto">
                            <span className="text-[#393e46]">
                              {row.mobileNo}
                            </span>
                          </td>
                          <td className=" py-3 text-[#393e46]  text-center  font-medium text-sm font-roboto">
                            <input
                              type="text"
                              placeholder="Enter Pickup Location"
                              className="w-full text-[#393E46]  mr-2 mt-2 mb-2  font-medium text-sm font-roboto p-2 text-center border-2 border-[#D6D6D6] rounded-lg focus:outline-none focus:border-[#393E46]"
                              value={row.pickupLocation}
                              onChange={(e) =>
                                handleInputChange(e, index, "pickupLocation")
                              }
                              disabled={ViewTrip}
                            />
                          </td>
                          <td className=" py-3 text-[#393e46]  text-center  font-medium text-sm font-roboto">
                            <input
                              type="time"
                              placeholder="Enter Pickup Time"
                              className="w-full text-[#393E46]  mr-2 mt-2 mb-2  font-medium text-sm font-roboto p-2 text-center border-2 border-[#D6D6D6] rounded-lg focus:outline-none focus:border-[#393E46]"
                              value={row.pickupTime}
                              onChange={(e) =>
                                handleInputChange(e, index, "pickupTime")
                              }
                              disabled={ViewTrip}
                            />
                          </td>
                          <td className="py-3 text-[#393e46] text-center font-medium text-sm font-roboto">
                            <input
                              type="text"
                              placeholder="Enter Drop Location"
                              className="w-full text-[#393E46] mr-2 mt-2 mb-2 font-medium text-sm font-roboto p-2 text-center border-2 border-[#D6D6D6] rounded-lg focus:outline-none focus:border-[#393E46]"
                              value={row.dropLocation}
                              onChange={(e) =>
                                handleInputChange(e, index, "dropLocation")
                              }
                              disabled={ViewTrip}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
