import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Layout";
import { Link } from "react-router-dom";
import Table from "../Driver/Table";
import InputBoxWithLabel from "../Driver/LabelInput";
import Dropdown from "../Driver/DropDown";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import moment from "moment-timezone";
import DateUtils from "../../Utility/DateUtils";
import eye from "../../assets/Icons/eye.png";

export default function Expenses() {
  const [showPopUp, setShowPopUp] = React.useState(false);
  const handlePopUp = () => {
    setShowPopUp(!showPopUp);
  };
  const [value, setValue] = useState("");

  const [paidBy, setPaidBy] = useState("");
  const [paidDate, setPaidDate] = useState("");
  const [expenses, setExpenses] = useState("");
  const [amount, setAmount] = useState("");
  const [reason, setReason] = useState("");
  const [searchExpenses, setSearchExpenses] = useState("");
  const [payerList, setPayerList] = useState([]);
  const [expenseList, setExpenseList] = useState([]);

  const [fromDate, setFromDate] = useState(DateUtils.getTMinusDate(6));
  const [toDate, setToDate] = useState(DateUtils.getCurrentDate);

  const [paidByError, setPaidByError] = useState("");
  const [paidDateError, setPaidDateError] = useState("");
  const [expensesError, setExpensesError] = useState("");
  const [amountError, setAmountError] = useState("");
  const [reasonError, setReasonError] = useState("");
  const overflowStyle = () => {
    return (
      <style>
        {`
    .overflow-x-auto::-webkit-scrollbar {
    
      height: 0px;
      margin-top: 5px;
    }
    .overflow-x-auto::-webkit-scrollbar-thumb {
      background-color: rgba(200, 200, 200);
      border-radius: 2px;
    }
    .overflow-x-auto::-webkit-scrollbar-track {
      background-color: rgba(155, 155, 155, 0.1);
    }
  `}
      </style>
    );
  };

  useEffect(() => {
    if (
      localStorage.getItem("userType") == "supervisor" ||
      localStorage.getItem("userType") == "admin"
    ) {
      alert("You are not authorized to access this page");
      window.location.href = "/";
    }
  }, []);

  const handleSaveClick = async () => {
    if (handleFormValidation()) {
      try {
        const data = {
          paidBy: paidBy,
          paidDate: paidDate,
          expenses: expenses,
          amount: amount,
          reason: reason,
        };

        const response = await axios.post(
          window.api + "passbook/createExpenses",
          data,
          {
            headers: {
              "x-access-token": localStorage.getItem("accessToken"),
              email: localStorage.getItem("email"),
              usertype: localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          toast("Expenses Added Successfully!", {
            icon: "👏",
            style: {
              borderRadius: "7px",
              background: "#222831",
              color: "#fff",
            },
          });
          setShowPopUp(false);
          getExpenses();
        }
      } catch (e) {
        console.error("Errors adding expenses: ", e);
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        } else if (e.response) {
          toast.error(e.response.data.message, {
            autoClose: false,
            closeButton: true,
            className: "toast-error",
            bodyClassName: "toast-error-body",
          });
        }
      }
    }
  };

  const handleFormValidation = () => {
    let formIsValid = true;

    if (!paidBy) {
      formIsValid = false;
      setPaidByError("Please select paid By");
    } else {
      setPaidByError("");
    }
    if (!paidDate) {
      formIsValid = false;
      setPaidDateError("Please select paid date");
    } else {
      setPaidDateError("");
    }
    if (!expenses) {
      formIsValid = false;
      setExpensesError("Please select expenses");
    } else {
      setExpensesError("");
    }
    if (!amount) {
      formIsValid = false;
      setAmountError("Please enter amount");
    } else {
      setAmountError("");
    }
    if (!reason) {
      formIsValid = false;
      setReasonError("Please enter reason");
    } else {
      setReasonError("");
    }
    return formIsValid;
  };

  const getPayer = async () => {
    try {
      const response = await axios.get(window.api + "passbook/getPayer", {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          email: localStorage.getItem("email"),
          usertype: localStorage.getItem("userType"),
        },
      });
      if (response.status === 200) {
        const payerList = response.data.data;
        setPayerList(payerList);
      }
    } catch (e) {
      console.error("Error getting payer list:", e);
      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  const getExpenses = async () => {
    try {
      const response = await axios.get(window.api + "passbook/getExpenses", {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          email: localStorage.getItem("email"),
          usertype: localStorage.getItem("userType"),
        },
        params: {
          fromDate: fromDate,
          toDate: toDate,
          expensesType: searchExpenses,
        },
      });

      if (response.status === 200) {
        const expenseList = response.data.data;
        setExpenseList(expenseList);
      }
    } catch (e) {
      console.error("Error getting expenses:", e);
      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  useEffect(() => {
    getPayer();
    getExpenses();
  }, [toDate, fromDate, searchExpenses]);

  // const [showDetails, setShowDetails] = useState("hey checking");
  // const [forceRender, setForceRender] = useState(false); // Dummy state
  
  // useEffect(() => {
  //   setForceRender(prevState => !prevState); // Trigger re-render
  // }, [showDetails]);

  const columns = React.useMemo(
    () => [
      {
        name: "Paid by",
        selector: "routeId",
        sortable: true,
        width: "10%",
        cell: (row) => (
          <div className="py-3   w-full  text-[#393e46] text-center font-medium text-sm font-roboto">
            <div className="text-center text-sm font-roboto text-[#323EDD] cursor-pointer  font-semibold ">
              {row.payer.payerName}
            </div>
          </div>
        ),
      },
      {
        name: "Paid Date",
        selector: "paidDate",

        sortable: true,
        cell: (row) => (
          <div className="py-3   w-full  text-[#393e46] font-medium text-sm font-roboto">
            <div className="text-[#393e46] uppercase text-center  font-medium ">
              {moment.utc(row.paidDate).format("DD-MM-YYYY")}
            </div>
          </div>
        ),
      },
      {
        name: "Expenses",
        selector: "description",
        width: "15%",
        sortable: true,
        cell: (row) => (
          <div className="py-3 text-[#393e46] text-left flex justify-start  font-medium text-sm font-roboto">
            <div className="text-[#393e46] uppercase font-medium ">
              {row.description}
            </div>
          </div>
        ),
      },
      {
        name: "Amount",
        selector: "paidAmount",

        sortable: true,
        cell: (row) => (
          <div className="py-3 flex  justify-center items-center  w-full text-[#393e46] text-center font-medium text-sm font-roboto">
            <div className="text-[#393e46] uppercase font-medium ">
              {row.debitAmount}
            </div>
          </div>
        ),
      },
      {
        name: "Reason",
        selector: "transactionId",

        sortable: true,
        cell: (row) => (
          <div className="py-3 flex  justify-center items-center w-full text-[#393e46] text-center font-medium text-sm font-roboto">
            <div className="text-[#393e46] uppercase relative font-medium ">
              
            <div className="overflow-x-auto w-96 whitespace-nowrap py-2 px-4 border  rounded-lg">{row.transactionId}</div>
{overflowStyle()}

              {/* <img
                onMouseEnter={() => setShowDetails(row.transactionId)}
                onMouseLeave={() => setShowDetails("")}
                className=" w-8  "
                src={eye}
                alt=""
              /> */}
            </div>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Layout>
      <div className="flex flex-wrap justify-end w-full pr-10 pb-5 bg-white rounded-lg">
        <button onClick={handlePopUp} className="flex mt-9">
          <Link className="bg-[#00adb5] flex justify-center items-center hover:bg-[#059299] active:bg-[#047481] text-gray-100 text-xl font-bold text-center p-3 pl-5 pr-5 rounded-lg">
            <span className="mr-2">Add Expenses</span>
          </Link>
        </button>
      </div>
      <div className="w-full h-full">
        <div className="flex flex-wrap items-center justify-between w-full rounded-lg shadow-lg lg:flex-nowrap">
          <div className="flex items-center flex-wrap lg:flex-nowrap  justify-start w-full ">
            <div className="flex flex-col w-full h-full labelInput max-w-[250px] min-w-[200px]    lg:w-full">
              <div className="flex flex-col w-auto h-full m-5 ">
                <label className="pl-1 font-bold ">From Date</label>
                <input
                  type="date"
                  className="font-medium font-roboto text-sm bg-white text-[#393e46] border-2 border-[#D6D6D6] focus:bg-white focus:border-[#393E46] hover:border-gray-500 px-2 py-2 rounded-lg shadow leading-tight focus:outline-none focus:shadow-outline"
                  id="from"
                  onChange={(e) => setFromDate(e.target.value)}
                  value={fromDate}
                />
              </div>
            </div>
            <div className="flex flex-col w-full h-full labelInput max-w-[250px] min-w-[200px]    lg:w-full">
              <div className="flex flex-col w-auto h-full m-5 ">
                <label className="pl-1 font-bold ">To Date</label>
                <input
                  type="date"
                  className="font-medium font-roboto text-sm bg-white text-[#393e46] border-2 border-[#D6D6D6] focus:bg-white focus:border-[#393E46] hover:border-gray-500 px-2 py-2 rounded-lg shadow leading-tight focus:outline-none focus:shadow-outline"
                  id="to"
                  onChange={(e) => setToDate(e.target.value)}
                  value={toDate}
                />
              </div>
            </div>
            <div className="flex flex-col w-full h-full m-5 max-w-[210px] min-w-[200px] ">
              <label className="pl-1 font-bold">Expenses</label>
              <select
                className="font-medium font-roboto text-sm bg-white text-[#393e46] border-2 border-[#D6D6D6] focus:bg-white focus:border-[#393E46] hover:border-gray-500 px-2 py-2 rounded-lg shadow leading-tight focus:outline-none focus:shadow-outline"
                id="expenses"
                onChange={(e) => setSearchExpenses(e.target.value)}
                value={searchExpenses}
              >
                <option value="">Select Expenses</option>
                <option value="Fuel">Fuel</option>
                <option value="Stationary">Stationary</option>
                <option value="Office Rent">Office Rent</option>
                <option value="Travel Allowance">Travel Allowance</option>
                <option value="Broadband Charges">Broadband Charges</option>
                <option value="Mobile Bill">Mobile Bill</option>
                <option value="Creditcard Bill">Creditcard Bill</option>
                <option value="Electricity Bill">Electricity Bill</option>
                <option value="Advance">Advance</option>
                <option value="Outstation">Outstation</option>
                <option value="Settlement">Settlement</option>
                <option value="Others">Others</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <Table
        columns={columns}
        rows={expenseList}
        headerStyle={[" text-center ", "text-center", "text-left pl-0"]}
      />
      {showPopUp && (
        <div
          className={`  fixed left-0 top-0    bg-[#2b2a2a69] w-screen  h-screen  overflow-y-scroll overflow-scroll overscroll-y-auto py-2  flex justify-center items-center`}
        >
          <div className=" h-auto w-40  lg:w-[900px]  flex flex-wrap justify-center items-center px-16 ">
            <div
              class={`w-[900px] h-full    rounded-[8px]  bg-white  shadow-box `}
            >
              <div className="flex relative flex-col p-5 w-full rounded-[8px] h-full   ">
                <div className="flex justify-end mx-[30px] ">
                  <button
                    onClick={handlePopUp}
                    className="flex justify-center items-center bg-[#D9D9D985] rounded-full w-[40px] h-[40px]"
                  >
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 1L1 16M1 1L16 16"
                        stroke="#344054"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>

                <div className="flex flex-col items-center justify-center w-full h-full gap-8 mt-5 ">
                  <div className="flex items-center justify-around w-full px-8">
                    <Dropdown
                      lableName={"Paid by"}
                      placeholder={"Enter Payer Name"}
                      updateValue={setPaidBy}
                      options={payerList}
                      objName={"payerName"}
                      validationError={paidByError}
                      // valueKey={"payerName"}
                    />

                    <InputBoxWithLabel
                      label={"Paid date"}
                      type="date"
                      onChange={(e) => setPaidDate(e.target.value)}
                      validationError={paidDateError}
                    />
                  </div>
                  <div className="flex items-center justify-around w-full px-8">
                    <Dropdown
                      lableName={"Expenses"}
                      placeholder={"Select Expenses"}
                      updateValue={setExpenses}
                      options={[
                        { name: "Fuel", id: "Fuel" },
                        { name: "Stationary", id: "Stationary" },
                        { name: "Office Rent", id: "Office Rent" },
                        { name: "Travel Allowance", id: "Travel Allowance" },
                        { name: "Broadband Charges", id: "Broadband Charges" },
                        { name: "Mobile Bill", id: "Mobile Bill" },
                        { name: "Creditcard Bill", id: "Creditcard Bill" },
                        { name: "Electricity Bill", id: "Electricity Bill" },
                        { name: "Advance", id: "Advance" },
                        { name: "Outstation", id: "Outstation" },
                        { name: "Settlement", id: "Settlement" },
                        { name: "Others", id: "Others" },
                      ]}
                      objName={"name"}
                      validationError={expensesError}
                    />
                    <InputBoxWithLabel
                      label={"Amount"}
                      placeholder={"Enter Amount Paid"}
                      type={"text"}
                      onChange={(e) => setAmount(e.target.value)}
                      validationError={amountError}
                    />
                  </div>
                  <div className="flex items-center justify-around w-full px-12">
                    <div
                      className={`  w-full flex flex-col items-start my-1  `}
                    >
                      <div className="  text-sm font-medium text-[#344053] self-start pl-[1px]">
                        Reason
                      </div>
                      <div
                        className={`flex items-center justify-center h-auto w-full  mt-1   self-stretch  gap-8px `}
                      >
                        <textarea
                          rows={4}
                          className={` text-md  w-full   px-4 py-2    text-[#344053]  bg-white border border-gray-300 rounded-md shadow-xs outline-none`}
                          placeholder="Enter Reason"
                          onChange={(e) => setReason(e.target.value)}
                        />
                        {reasonError && (
                          <p className="text-red-500 text-sm mt-1">
                            {reasonError}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-center mt-10 font-semibold tracking-normal text-center item font-roboto text-12 leading-20">
                  <button
                    onClick={handleSaveClick}
                    className=" bg-[#175CD3] text-white w-[77px] h-[30px] border border-gray-500 rounded-md "
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
