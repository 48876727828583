import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SelectOption from "../Driver/SelectOption";
import InputBoxWithLabel from "../Driver/LabelInput";
import Dropdown from "../Driver/DropDown";
import Table from "../Driver/Table";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import Button from "../Driver/Button";
import moment from "moment-timezone";
import DateUtils from '../../Utility/DateUtils'

export default function Credits({getPayerHome}) {
  const [showPopUp, setShowPopUp] = React.useState(false);
  const [paidToId, setPaidToId] = useState("");
  const [payerId, setPayerId] = useState("");
  const [paidBy, setPaidBy] = useState("");
  const [paidAmount, setPaidAmount] = useState("");
  const [date, setDate] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [toDate, setToDate] = useState(DateUtils.getCurrentDate());
  const [fromDate, setFromDate] = useState(DateUtils.getCurrentDate());

  const [payerNameError, setPayerNameError] = useState("");
  const [paidByError, setPaidByError] = useState("");
  const [paidAmountError, setPaidAmountError] = useState("");
  const [transactionIdError, setTransactionIdError] = useState("");
  const [paymentModeError, setPaymentModeError] = useState("");

  const [payerList, setPayerList] = useState([]);
  const [creditList, setCreditList] = useState([]);

  useEffect(() => {
    getPayer();
    getCredits();
  }, [fromDate, toDate, payerId]);

  const getPayer = async () => {
    try {
      const response = await axios.get(window.api + "passbook/getPayer", {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          "email": localStorage.getItem("email"),
          "usertype": localStorage.getItem("userType")
        }
      });
      if (response.status === 200) {
        const payerList = response.data.data;
        setPayerList(payerList);
      }
    } catch (e) {
      console.error("Error getting payer list:", e);
      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      }
    }
  };
  

  const getCredits = async () => {
    try {
      const response = await axios.get(window.api + "passbook/getCredits", {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          "email": localStorage.getItem("email"),
          "usertype": localStorage.getItem("userType")
        },
        params: {
          toDate,
          fromDate,
          payerId,
        },
      });
  
      if (response.status === 200) {
        const creditList = response.data.data;
        const dataWithSrNo = creditList.map((item, index) => ({
          ...item,
          srNo: index + 1,
        }));
        setCreditList(dataWithSrNo);
      } else {
        setCreditList([]);
      }
    } catch (e) {
      console.error("Error getting credit list:", e);
      setCreditList([]);
      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      }
    }
  };
  

  const handleFormValidation = () => {
    let formIsValid = true;

    if (!paidToId) {
      formIsValid = false;
      setPayerNameError("Please enter payer name");
    } else {
      setPayerNameError("");
    }

    if (!paidBy) {
      formIsValid = false;
      setPaidByError("Please enter paid by");
    } else {
      setPaidByError("");
    }

    if (!paidAmount) {
      formIsValid = false;
      setPaidAmountError("Please enter paid amount");
    } else {
      setPaidAmountError("");
    }

    if (!transactionId) {
      formIsValid = false;
      setTransactionIdError("Please enter transaction id");
    } else {
      setTransactionIdError("");
    }

    if (!paymentMode) {
      formIsValid = false;
      setPaymentModeError("Please enter payment mode");
    } else {
      setPaymentModeError("");
    }

    return formIsValid;
  };

  const handleSaveClick = async () => {
    if (handleFormValidation()) {
      try {
        const data = {
          paidTo: paidToId,
          paidBy: paidBy,
          paidAmount: paidAmount,
          transactionId: transactionId,
          paymentMode: paymentMode,
          entryStatus: "Credit",
        };
  
        const response = await axios.post(
          window.api + "passbook/createCredits",
          data,
          {
            headers: {
              "x-access-token": localStorage.getItem("accessToken"),
              "email": localStorage.getItem("email"),
              "usertype": localStorage.getItem("userType")
            }
          }
        );
        if (response.status === 200) {
          toast("New Credit Added Successfully!", {
            icon: "👏",
            style: {
              borderRadius: "7px",
              background: "#222831",
              color: "#fff",
            },
          });
          setShowPopUp(false);
          getCredits();
          getPayer();
          // getPayerHome();
        }
      } catch (e) {
        console.error("Errors adding credit: ", e);
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else if (e.response) {
          toast.error(e.response.data.message, {
            autoClose: false,
            closeButton: true,
            className: "toast-error",
            bodyClassName: "toast-error-body",
          });
        }
      }
    }
  };
  

  const paymentModeList = [
    { id: "Cash", name: "Cash" },
    { id: "Gpay", name: "Gpay" },
    { id: "Amazon Pay", name: "Amazon Pay" },
    { id: "Phone pay", name: "Phone pay" },
    { id: "Paytm", name: "Paytm" },
    { id: "Account Transfer", name: "Account Transfer" },
    { id: "Others", name: "Others" },
  ];

  const handlePopUp = () => {
    setShowPopUp(!showPopUp);
  };
  const sampleValue = () => {
    console.log("sampleValue");
  };
  const columns = [
    {
      name: "Sr No",
      sortable: true,
      cell: (row) => (
        <div className="text-[#3C4048] pl-1 font-medium text-sm font-roboto">
          <span className="text-[#3C4048] uppercase font-medium">
            {row.srNo}
          </span>
        </div>
      ),
    },
    {
      name: "Paid To",
      selector: "paidTo",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#3C4048] text-left w-full font-medium text-sm font-roboto">
          <span className="text-[#3C4048] uppercase  font-medium">
            {row.paidTo}
          </span>
        </div>
      ),
    },
    {
      name: "Paid By",
      selector: "paidBy",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#3C4048] text-center w-full font-medium text-sm font-roboto">
          <span className="text-[#3C4048] uppercase  font-medium">
            {row.paidBy}
          </span>
        </div>
      ),
    },
    {
      name: "Paid Amount",
      selector: "paidAmount",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#3C4048] text-center w-full font-medium text-sm font-roboto">
          <span className="text-[#3C4048] uppercase  font-medium">
            {row.paidAmount}
          </span>
        </div>
      ),
    },
    {
      name: "Paid Date",
      selector: "paidDate",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#3C4048] text-center w-full font-medium text-sm font-roboto">
          <span className="text-[#3C4048] uppercase  font-medium">
            {moment(row.paidDate).tz('Asia/Kolkata').format("DD-MM-YYYY")}
          </span>
        </div>
      ),
    },
    {
      name: "Transaction ID",
      selector: "transactionId",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#3C4048] text-center w-full font-medium text-sm font-roboto">
          <span className="text-[#3C4048] uppercase  font-medium">
            {row.transactionId}
          </span>
        </div>
      ),
    },
    {
      name: "Payment Mode",
      selector: "paymentMode",
      sortable: true,
      cell: (row) => (
        <div className="py-3 text-[#3C4048] text-center w-full font-medium text-sm font-roboto">
          <span className="text-[#3C4048] uppercase  font-medium">
            {row.paymentMode}
          </span>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="w-full h-full py-2 ">
        <div className="flex flex-wrap items-center justify-around pb-2 bg-white rounded-lg shadow-lg w-80 lg:w-full">
          <div className="flex flex-wrap items-center justify-around h-auto px-1 pt-5 lg:justify-between w-80 lg:w-full lg:px-8 labelInputContainer">
            <Dropdown
              lableName={"Paid To"}
              options={payerList}
              updateValue={setPayerId}
              placeholder={"Name"}
              objName={"payerName"}
            />
            <InputBoxWithLabel
              widthForInput={"w-[250px]"}
              label={"From Date"}
              type={"date"}
              onChange={(e) => setFromDate(e.target.value)}
              value={fromDate}
            />
            <InputBoxWithLabel
              widthForInput={"w-[250px]"}
              label={"To Date"}
              type={"date"}
              onChange={(e) => setToDate(e.target.value)}
              value={toDate}
            />{" "}
            <Link
              onClick={handlePopUp}
              className="bg-[#00adb5] border-4 border-[#EEEEEE]  border-solid flex justify-between items-center  hover:bg-[#059299] active:bg-[#047481] text-gray-100  text-[16px] font-semibold text-center py-3  px-8 rounded-xl "
            >
              <span className="text-center ">ADD PAYMENT</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full h-full  py-2  ">
        <Table
          columns={columns}
          rows={creditList}
          headerStyle={[" text-center pl-2 ", "text-left pl-2", " text-center"]}
        />
      </div>
      {showPopUp && (
        <div
          className={`  fixed left-0 top-0    bg-[#2b2a2a69] w-screen  h-screen  overflow-y-scroll overflow-scroll overscroll-y-auto py-2  flex justify-center items-center`}
        >
          <div className=" h-auto w-40 flex-wrap   lg:w-[920px] flex justify-center items-center px-16 ">
            <div
              class={` w-96 lg:w-[900px] h-full     rounded-[8px]  bg-white  shadow-box `}
            >
              <div className="flex relative flex-col p-5 w-full rounded-[8px] h-full   ">
                <div className="flex justify-end mx-[30px] ">
                  <button
                    onClick={handlePopUp}
                    className="flex justify-center items-center bg-[#D9D9D985] rounded-full w-[40px] h-[40px]"
                  >
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 1L1 16M1 1L16 16"
                        stroke="#344054"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className="text text-[#101828] font-bold">
                  Add Credit Details
                </div>
                <div className="flex flex-col w-full h-full gap-10 mt-10 ">
                  <div className="flex flex-wrap items-center justify-between w-full px-8">
                    <Dropdown
                      lableName={"Paid To"}
                      options={payerList}
                      updateValue={setPaidToId}
                      placeholder={"Name"}
                      objName={"payerName"}
                      validationError={payerNameError}
                    />
                    <Dropdown
                      lableName={"Paid By"}
                      options={payerList}
                      updateValue={setPaidBy}
                      placeholder={"Name"}
                      objName={"payerName"}
                      validationError={paidByError}
                    />
                  </div>
                  <div className="flex flex-wrap items-center justify-between w-full px-8">
                    <InputBoxWithLabel
                      label={"Paid Amount"}
                      placeholder={"Enter Amount"}
                      onChange={(e) => setPaidAmount(e.target.value)}
                      validationError={paidAmountError}
                    />
                    <InputBoxWithLabel
                      label={"Date"}
                      type="text"
                      placeholder={moment().tz('Asia/Kolkata').format("DD-MM-YYYY")}
                      value={moment().tz('Asia/Kolkata').format("DD-MM-YYYY")}
                      onChange={(e) => setDate(e.target.value)}
                      disabled={true}
                    />
                  </div>

                  <div className="flex flex-wrap items-center justify-between w-full px-8">
                    <InputBoxWithLabel
                      label="Transaction ID"
                      placeholder="Transaction ID"
                      onChange={(e) => setTransactionId(e.target.value)}
                      validationError={transactionIdError}
                    />
                    <Dropdown
                      lableName={"Payment Mode"}
                      options={paymentModeList}
                      updateValue={setPaymentMode}
                      placeholder={"Payment Mode"}
                      objName={"name"}
                      validationError={paymentModeError}
                    />
                  </div>
                </div>

                <div className="flex flex-wrap justify-center mt-10 font-semibold tracking-normal text-center item font-roboto text-12 leading-20">
                  <button
                    onClick={handleSaveClick}
                    className=" bg-[#175CD3] text-white w-[77px] h-[30px] border border-gray-500 rounded-md "
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
