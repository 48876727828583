import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import Background from './Layout/Background';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Background>
      <App />
    </Background>
);
window.api="https://ccadminapi.chennaicabs.in/api/"
// window.api="http://localhost:5052/api/"