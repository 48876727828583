import DataTable from "react-data-table-component";
import TableDetails from "./TableDetails";
import { Link } from "react-router-dom";
import Table from "../../Driver/Table";
import eye from "../../../assets/Icons/eye.png";

export default function AccountTable({
  rowsAccount,
  toDateAccount,
  fromDateAccount,
}) {
  const columns = [
    {
      name: "Sr.No.",
      selector: "srNo",
      sortable: true,
      width: "10%",
      cell: (row) => (
        <div className="  w-full  py-3 text-[#393e46] text-center font-medium text-sm font-roboto">
          <div className="text-[#393e46] uppercase  font-medium">
            {row.serialNumber}
          </div>
        </div>
      ),
    },
    {
      name: "Company Name",
      selector: "companyName",
      sortable: true,
      cell: (row) => (
        <div className="  w-full  text-[#393e46]    font-medium text-sm font-roboto">
          <div className="text-[#393e46] text-center  uppercase font-medium">
            {row.companyName}
          </div>
        </div>
      ),
    },
    {
      name: "Paid By",
      selector: "paidBy",
      sortable: true,
      cell: (row) => (
        <div className="   py-3 text-[#393e46]   font-medium text-sm font-roboto">
          <div className="text-[#393e46] uppercase text-center font-medium">
            {row.invoicePaidBy}
          </div>
        </div>
      ),
    },
    {
      name: "Total Trips",
      selector: "totalNoOfTrips",
      sortable: true,
      cell: (row) => (
        <div className=" flex justify-center items-center w-full  py-3 text-[#393e46] text-center font-medium text-sm font-roboto">
          <div className="text-[#393e46] uppercase  font-medium">
            {row.totalCountOfInvoicePaidByTrips}
          </div>
        </div>
      ),
    },
    {
      name: "Paid Amount",
      selector: "paidBy",
      sortable: true,
      cell: (row) => (
        <div className=" flex justify-center  items-center w-full  py-3 text-[#393e46] text-center font-medium text-sm font-roboto">
          <div className="text-[#393e46] uppercase  font-bold">
            {row.sumOfInvoicePaidAmount}
          </div>
        </div>
      ),
    },
    {
      name: "Actions",
      width: "20%",
      cell: (row) => (
        <button className="pr-1 text-blue-600">
          <Link
            to={`/accounts-details?companyId=${encodeURIComponent(
              row.companyId
            )}&invoicePaidBy=${encodeURIComponent(
              row.invoicePaidById
            )}&toDateAccount=${encodeURIComponent(
              toDateAccount
            )}&fromDateAccount=${encodeURIComponent(fromDateAccount)}`}
          >
            <img className=" w-10 " src={eye} alt="" />
          </Link>
        </button>
      ),
      button: true,
    },
  ];

  return (
    <div>
      <div className="flex flex-col gap-4">
        <div className="table w-full h-auto ">
          <div className="w-full h-auto rounded-lg shadow-xl shadow-slate-250">
            <div className="flex w-full flex-col gap-6   rounded-lg shadow-md">
              <Table
                columns={columns}
                rows={rowsAccount}
                headerStyle={[
                  "text-center",
                  "text-center",
                  " text-center",
                  "text-center",
                  "text-center",
                  "text-center",
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
