import React from "react";
import { Link } from "react-router-dom";
import backButton from "../../assets/Icons/IconsForPages/backArrow.png";

export default function Button({ onClick, name, link }) {
  return (
    <div className=" flex justify-center items-center ">
      <button
        className="flex items-center justify-center w-full"
        onClick={onClick}
      >
        <Link
          to={link}
          className="bg-[#00ABB3] hover:bg-[#059299] active:bg-[#047481] text-gray-100 text-[16px]  font-[600] w-full text-center  px-6 py-2  rounded-md my-6"
        >
          {name}
        </Link>
      </button>
    </div>
  );
}